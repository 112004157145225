const { Typography, Box } = require("@mui/material")
const { DataGrid, GridToolbar } = require("@mui/x-data-grid")
const { prop } = require("ramda")
const { Link } = require("react-router-dom")

const EventTab = ({ data, loading }) => {
  const rows = (data?.searchEvents?.edges || []).map(prop('node'));
  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 250,
      renderCell: ({ row }) => {
        return <Link
          to={`/e/${row.slug}`}
        >{`${row.title}`}</Link>
      },
    },
    {
      field: 'community',
      headerName: 'Community',
      width: 300,
      renderCell: ({ row }) => {
        return <Box>{`${row.community?.title}`}</Box>
      },
    },
    {
      field: 'leader',
      headerName: 'Leader Email',
      width: 300,
      renderCell: ({ row }) => {
        return <Box
          onClick={() => navigator.clipboard.writeText(row.createdBy?.email)}
          sx={{ cursor: 'pointer' }}
        >{`${row.createdBy?.email}`}</Box>
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 200,
    },
  ];

  return (
    <>
      <Typography sx={{ mb: 1 }}>
        <b>User-Posted Events</b> {data?.platform?.stats?.eventCount}
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading || !data}
        slots={{ toolbar: GridToolbar }}
      />
    </>
  )
}

export default EventTab;