import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { $getRoot } from "lexical";

import { useTheme } from "@mui/material";
import { useEffect } from "react";
import { pick } from "ramda";

const iOS =
  typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

export default function Input({ onSend, onFileUpload, files, focused }) {
  const [editor] = useLexicalComposerContext();
  const isEmpty = useLexicalIsTextContentEmpty(editor);
  const theme = useTheme();

  useEffect(() => {
    if (focused) editor.focus();
  }, [focused, editor]);

  const handleKeyDown = (e) => {
    // only allow key down send on desktop
    if (e.keyCode !== 13 || (e.keyCode === 13 && e.shiftKey)) return;
    e.preventDefault();
    if (isEmpty || iOS) return;
    const editorState = editor.getEditorState();
    editor.update(() => $getRoot().clear());
    editor.blur();
    const media = files ? files.map(pick(['id', 'publicId'])) : [];
    onSend(JSON.stringify({ editorState, media }), {})
      .then(() => onFileUpload());
  }

  return (
    <div
      onKeyDown={handleKeyDown}
    >
      <ContentEditable
        id='lexical-input'
        style={{
          resize: 'none',
          fontSize: '18px',
          caretColor: theme.palette.transparentColors.dark.fortyFive,
          position: 'relative',
          tabSize: 1,
          outline: 0,
          flexGrow: 2,
          alignSelf: 'center'
        }}
      />
    </div>
  );
}
