import { useEffect } from 'react';
import useCustomAuth0 from 'hooks/useCustomAuth0';

export default function Signup() {
    const { loginWithRedirect } = useCustomAuth0();
    useEffect(() => {
        loginWithRedirect({
            authorizationParams: {
                screen_hint: "signup",
            }
        });
    })
    return null;
}
