export const calculateDistance = (userLocation, groupLocation) => {
  const R = 6371; // Radius of the Earth in kilometers
  const lat1 = userLocation.lat;
  const lon1 = userLocation.lng;
  const lat2 = groupLocation.lat;
  const lon2 = groupLocation.lng;

  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
}

// Function to sort groups by distance from the user's location
export const sortGroupsByLocation = (userLocation, groups) => {
  // Calculate distances for each group
  const groupsWithDistance = groups.map(group => ({
    ...group,
    distance: calculateDistance(userLocation, group?.node?.location)
  }));

  // Sort groups by distance
  groupsWithDistance.sort((a, b) => a.distance - b.distance);

  return groupsWithDistance;
}