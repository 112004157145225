import React from 'react';
import { IconButton } from '@mui/material';
import CheckedIcon from 'components/icons/CheckedIcon';

function LoadingIcon({ color = 'black ' }) {
  return (
    <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 3.01228C12.6656 3.00413 12.8323 3 13 3C18.5228 3 23 7.47715 23 13C23 18.5228 18.5228 23 13 23C7.47715 23 3 18.5228 3 13C3 11.6882 3.2526 10.4353 3.71181 9.28749L1.05456 7.86259C0.375929 9.43844 0 11.1753 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C12.8326 0 12.6659 0.00316498 12.5 0.00943839V3.01228Z" fill={color} fillOpacity="0.2" />
    </svg>
  )
}

export default function LoadingIconButton({ onClick, children = <CheckedIcon />, loading, sx, color, ...props }) {
  const handleClick = (evt) => {
    if (!loading) onClick(evt);
  }

  return (
    <IconButton variant='contained' onClick={handleClick} sx={{
      p: 2,
      animation: loading ? 'spin-anim 1.2s linear infinite' : 'unset',
      ...sx
    }} {...props}>
      {loading ? <LoadingIcon color={color} /> : children}
    </IconButton>
  )
}
