import { memo, useEffect } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useNavigate } from 'react-router-dom';
import Progress from 'components/Progress';

export default applyTo(() => {
  const navigate = useNavigate();

  useEffect(() => {
    const slug = localStorage.getItem('booth-community-slug');
    if (!slug) {
      navigate('/discover');
      return;
    }
    navigate(`/c/${slug}/booth`);
  }, [navigate]);

  return <Progress />;
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('Booth'),
  memo,
));
