import React, { memo } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { pipe, applyTo } from 'ramda';
import PropTypes from 'prop-types';
import { propTypes, displayName } from 'lib/react';


export default applyTo(({ label, ...props }) => {
  return (
    <Box>
      {label && <Typography
        sx={{ fontSize: 18, fontWeight: 500, mb: 1 }}
      >{label}</Typography>}
      <TextField hiddenLabel {...props} />
    </Box>
  );
}, pipe(
  propTypes({
    label: PropTypes.string,
  }),
  displayName('CustomInput'),
  memo,
));
