import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { useQuery } from '@apollo/client';
import { propTypes, defaultProps, displayName } from 'lib/react';
import Channels from './Channels';
import { setUserId } from 'firebase/analytics';
import { analytics } from 'lib/firebase';
import { useParams } from 'react-router-dom';
import { PRIVATE_COMMUNITY } from 'data/queries/community';
import useCustomAuth0 from 'hooks/useCustomAuth0';

export default applyTo(({
  className,
}) => {
  const { community: slug } = useParams();
  const {
    // loginWithRedirect,
    isAuthenticated,
  } = useCustomAuth0();
  const lastCommunitySlug = window.localStorage.getItem('lastCommunitySlug');
  const { data, loading, refetch } = useQuery(PRIVATE_COMMUNITY, {
    variables: { slug: slug || lastCommunitySlug },
    skip: !isAuthenticated || (!slug && !lastCommunitySlug),
  });

  useEffect(() => {
    if (data?.whoami?.id) setUserId(analytics, data?.whoami?.id);
  }, [data?.whoami?.id])

  return (
    <Channels
      refetch={refetch}
      loading={loading}
      showWelcome={data?.whoami?.community?.showWelcome}
      showEditTooltip={data?.whoami?.community?.showEditTooltip}
      channels={data?.whoami?.community?.node?.channels?.edges}
      community={data?.whoami?.community?.node}
      isOwner={data?.whoami?.community?.isOwner}
      isLead={data?.whoami?.community?.isLead}
      isMember={data?.whoami?.community?.isMember}
      name={data?.whoami?.displayName}
    />
  );
}, pipe(
  propTypes({
    className: PropTypes.string,
  }),
  defaultProps({
    className: null,
  }),
  displayName('Navigation'),
  memo,
));
