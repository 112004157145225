const { gql } = require("@apollo/client");

export const PRIVATE_COMMUNITY = gql`
    query PrivateCommunity($slug: String!) {
      whoami {
        id
        username
        displayName
        joinDate
        avatar {
          id
          url(input: {
            transformations: [{
              aspect_ratio: "1:1"
              crop: "fill",
            }]
          })
        }
        community(slug: $slug) {
          isLead
          isMember
          isOwner
          showWelcome
          showEditTooltip
          application {
            status
          }
          node { 
            id
            slug
            title
            description
            isPrivate
            isPromoted
            isPublished
            isPaused
            yearFounded
            type
            otherType: type
            onboardingQuestions {
              type
              question
            }
            categories
            logo {
              id
              url(input: { transformations: [{
                width: 80,
                height: 80,
                aspect_ratio: "1:1",
                crop: "fill"
              }] })
            }
            cover {
              type
              asset {
                ...on Image {
                  id
                  url
                }
                ...on Background {
                  background
                }
              }
            }
            banners {
              id
              url(input: {
                transformations: [{
                  aspect_ratio: "3:2"
                  crop: "fill",
                  gravity: "auto"
                }]
              })
            }
            channels {
              edges {
                node {
                  id
                  emoji
                  slug
                  section
                  name
                  description
                  isPublished
                  isPrivate
                  hasUnreadMessages
                  members {
                    edges {
                      node {
                        id
                        displayName
                      }
                    }
                  }
                }
              }
            }
            members {
              total
            }
            leads {
              edges {
                node {
                  id
                  firstName
                  lastName
                  username
                  displayName
                  avatar { id url }
                }
              }
            }
            locations {
              description
              googlePlaceId
              lat
              lng
              locality
              postalCode
              state
            }
          }
        }
      }
    }
`;


export const PUBLIC_COMMUNITY = gql`
  query GetPublicCommunity($slug: String!) {
    community(slug: $slug) {
      id
      slug
      title
      color
      description
      locations {
        locality
        state
      }
      isPrivate
      logo {
        id
        url(input: { transformations: [{
          width: 80,
          height: 80,
          aspect_ratio: "1:1",
          crop: "fill"
        }] })
      }
      cover {
        type
        asset {
          ...on Image {
            id
            url
          }
          ...on Background {
            background
          }
        }
      }
    }
  }
`;

export const COMMUNITY_MEMBERS = gql`
  query CommunityMembers($slug: String!, $after: String) {
    whoami {
      id
      community(slug: $slug) {
        isMember
        isLead
        node {
          id
          members(input: { first: 100, includeLeads: true, after: $after }) {
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              joinDate
              node {
                id
                firstName
                lastName
                displayName
                username
                avatar { id url }
              }
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_COMMUNITY_MEMBERS = gql`
  query SearchCommunityMembers($input: SearchUsersInput!, $communityId: ID!) {
    searchUsers(input: $input) {
      edges {
        node {
          id
          firstName
          lastName
          displayName
          username
          avatar {
            id
            url
          }
          community(id: $communityId) {
            isLead
          }
        }
      }
    }
  }
`;

export const COMMUNITY_SUBSCRIPTION = gql`
query CommunitySubscription($slug: String!) {
  whoami {
    id
    customer {
      portalUrl
    }
    community(slug: $slug) {
      node {
        id
        subscription {
          id
          status
          actionDate
        }
      }
    }
  }
}
`;

export const PUBLIC_COMMUNITY_CHANNELS = gql`
    query CommunityChannels($slug: String!) {
      whoami {
        id
        community(slug: $slug) {
          isMember
          isLead
          node {
            id
            slug
            channels(input: { isPrivate: false }) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
`;

export const ADMIN_SEARCH_COMMUNITIES = gql`
query AdminSearchCommunities {
  platform {
    stats {
      communityCount
      userCount
      eventCount
    }
  }
  searchEvents(input: {
    first: 100
  }) {
    edges {
      node {
        id
        title
        slug
        community {
          id
          title
        }
        createdBy {
          id
          email
        }
        startDate
      }
    }
  }
  adminSearchCommunities {
    edges {
      node {
        id
        title
        slug
        createdDate
        stage
        featuredImage {
          id
          url(input: {
            transformations: [{
              width: 150,
              height: 150,
              crop: "fill",
            }]
          })
        }
        description
        type
        otherType: type
        onboardingQuestions {
          type
          question
        }
        categories
        logo {
          id
          url(input: { transformations: [{
            width: 80,
            height: 80,
            aspect_ratio: "1:1",
            crop: "fill"
          }] })
        }
        cover {
          type
          asset {
            ...on Image {
              id
              url
            }
            ...on Background {
              background
            }
          }
        }
        isPrivate
        isPromoted
        isPaused
        isPublished
        subscription {
          status
        }
        members {
          total
        }
        owner {
          id
          firstName
          lastName
          email
        }
        locations {
          description
          googlePlaceId
          lat
          lng
          locality
          postalCode
          state
        }
      }
    }
  }
}
`;

export const VALIDATE_INVITE_CODE = gql`
    query ValidateInvitation($code: String!) {
      invitation(code: $code) {
        id
        code
        type
        role
        user {
          id
        }
        community {
          id
          slug
        }
      }
    }
`;