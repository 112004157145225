import { Autocomplete, Box, Grid, Typography } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import config from "config";
import CustomInput from "components/CustomInput";

const defaultIsOptionEqualToValue = (option, value) => {
  return typeof value === "string"
    ? option?.description === value
    : option?.description === value?.description;
};

export default function GoogleAutoComplete({
  isOptionEqualToValue,
  onOptionSelected,
  value = "",
  multiple = false,
  placeholder = "City, State",
  label = "Add a location",
  hiddenLabel,
  ...props
}) {
  const { placePredictions, getPlacePredictions, placesService } =
    usePlacesService({
      apiKey: config.GOOGLE_API_KEY,
    });

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description || ""
      }
      filterOptions={(x) => x}
      options={placePredictions}
      isOptionEqualToValue={isOptionEqualToValue || defaultIsOptionEqualToValue}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(evt, newValue) => {
        if (!newValue) return onOptionSelected(newValue);
        placesService?.getDetails(
          {
            placeId: newValue.place_id,
          },
          (placeDetails) => onOptionSelected({ ...newValue, ...placeDetails })
        );
      }}
      onInputChange={(evt, newInputValue) =>
        getPlacePredictions({ input: newInputValue })
      }
      renderInput={(params) => (
        <CustomInput
          {...params}
          {...props}
          label={!hiddenLabel && label}
          placeholder={placeholder}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <div {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
      }}
    />
  );
}
