import { memo, useCallback, useEffect, useState } from "react";
import { Button, Stack, TextField, Typography, Box } from "@mui/material";
import { pipe, applyTo, pathOr, last } from "ramda";
import { propTypes, defaultProps, displayName } from "lib/react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import withMemberRequired from 'components/withMemberRequired';
import NavLayout from 'components/NavLayout';
import MemberRow from 'components/MemberRow';
import MemberActions from 'components/MemberActions';
import { COMMUNITY_MEMBERS, SEARCH_COMMUNITY_MEMBERS } from 'data/queries/community';
import ScrollView from 'components/ScrollView';
import PlusIcon from "components/icons/PlusIcon";
import InviteLink from "components/InviteLink";
import LoadingList from "components/LoadingList";

export default applyTo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [user, setUser] = useState();
  const [inviteOpen, setInviteOpen] = useState(false);
  const { community: slug } = useParams();
  // queries
  const [fetchMembers, {
    data,
    loading,
    error,
    fetchMore
  }] = useLazyQuery(COMMUNITY_MEMBERS, {
    variables: {
      slug,
    },
  })
  const [searchUsers, { data: searchData, loading: searching }] = useLazyQuery(SEARCH_COMMUNITY_MEMBERS);
  const community = pathOr({}, ['whoami', 'community', 'node'], data);
  const isLead = pathOr(false, ['whoami', 'community', 'isLead'], data);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  const openActions = (user) => {
    setUser(user);
    setIsOpen(true);
  };

  const closeActions = useCallback(() => {
    setUser();
    setIsOpen(false);
  }, [setUser, setIsOpen]);

  useEffect(() => {
    if (searchTerm?.length < 3 || searching || !data?.whoami?.community?.node?.id) return;

    searchUsers({
      variables: {
        input: {
          searchTerm,
          communityId: data?.whoami?.community?.node?.id,
        },
        communityId: data?.whoami?.community?.node?.id
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.whoami?.community?.node?.id, searchTerm]);

  if (error) return null;

  const members = pathOr([], ["whoami", "community", "node", "members"], data);

  return (
    <NavLayout>
      <ScrollView
        top={
          <>
            <Typography variant="h2">Members</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button variant="tertiary" onClick={() => setInviteOpen(true)}>
              <Stack direction="row" gap={1} alignItems="center">
                Invite
                <PlusIcon width={16} height={16} />
              </Stack>
            </Button>
          </>
        }
      >
        <Stack>
          <TextField
            label="Search by name or username"
            variant="filled"
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            sx={{ mb: 3 }}
            onChange={(evt) => setSearchTerm(evt.target.value)}
          />
          {searchTerm ? (
            <>
              {pathOr([], ["searchUsers", "edges"], searchData).map((m) => (
                <MemberRow
                  member={m?.node}
                  onOpenActions={
                    m.node.id === data?.whoami?.id
                      ? false
                      : () =>
                        openActions({
                          isLead: m?.node?.community?.isLead,
                          ...(m?.node || {}),
                        })
                  }
                />
              ))}
            </>
          ) : (
            <>
              {loading ? <LoadingList
                height={75}
                count={5}
                sx={{
                  borderRadius: '10px'
                }}
              /> : members?.edges?.map((m) => (
                <MemberRow
                  member={{ ...m?.node, joinDate: m?.joinDate }}
                  onOpenActions={
                    m.node.id === data?.whoami?.id
                      ? false
                      : () =>
                        openActions({
                          isLead: m?.node?.community?.isLead,
                          ...(m?.node || {}),
                        })
                  }
                  showMemberDate={true}
                />
              ))}
              {members?.pageInfo?.hasNextPage && (
                <Button
                  onClick={() => {
                    fetchMore({
                      variables: {
                        after: last(members)?.cursor,
                      },
                    });
                  }}
                >
                  More
                </Button>
              )}
            </>
          )}
        </Stack>
        <MemberActions
          community={community}
          isOpen={isOpen}
          onClose={closeActions}
          user={user}
          isLead={isLead}
        />
        <InviteLink
          isLead={isLead}
          community={{
            id: community?.id,
            slug
          }}
          isOpen={inviteOpen}
          onClose={() => setInviteOpen(false)}
        />
      </ScrollView>
    </NavLayout>
  );
}, pipe(withMemberRequired, propTypes({}), defaultProps({}), displayName("Members"), memo));
