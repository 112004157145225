import { MoreHoriz } from '@mui/icons-material';
import { Button, IconButton, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import UserAvatar from 'components/UserAvatar';
import { format } from 'date-fns';

export default function MemberRow({ showMemberDate, member, onOpenActions, onAddMember, isAdded }) {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Stack sx={{
      backgroundColor: theme.palette.message.background,
      borderRadius: '10px',
      alignItems: 'center',
      p: '10px',
      mt: 1,
      justifyContent: 'space-between'
    }} direction='row' gap={1}>
      <Stack
        direction='row'
        onClick={() => navigate(`/u/${member?.username}`)}
        sx={{
          display: 'flex',
          columnGap: '10px',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <UserAvatar
          variant='rounded'
          sx={{ width: 55, height: 55 }}
          src={member?.avatar?.url}
        />
        <div>
          <Typography sx={{ width: '100px' }}>{member?.displayName}</Typography>
        </div>
      </Stack>
      {showMemberDate && <Typography
        sx={{ color: theme.palette.transparentColors.dark.fortyFive }}
      >Member since {format(new Date(member?.joinDate), 'PP')}</Typography>}
      <Stack direction='row' alignItems='center' gap={1}>
        {onOpenActions && <IconButton onClick={onOpenActions}>
          <MoreHoriz fontSize='large' />
        </IconButton>}
        {!!onAddMember && (
          <Button
            variant={'tertiary'}
            disabled={isAdded}
            onClick={onAddMember}
          >{isAdded ? 'Added' : 'Add'}</Button>
        )}
      </Stack>
    </Stack>
  );
}