import { memo, useContext, useMemo } from 'react';
import { pipe, applyTo, path } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Navigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { AppContext } from 'context';
import EventDetails from 'components/EventDetails';
import { publicClient } from 'lib/apollo';
import { Box } from '@mui/system';
import NavLayout from 'components/NavLayout';
import Navigation from 'components/Navigation';
import TopBar from 'components/TopBar';
import ScrollView from 'components/ScrollView';
import { PUBLIC_COMMUNITY_EVENT } from 'data/queries/event';
import { Helmet } from "react-helmet";

export default applyTo(() => {
  const { onError } = useContext(AppContext);
  const { event: eventSlug } = useParams();
  const { data, loading, error } = useQuery(PUBLIC_COMMUNITY_EVENT, {
    variables: {
      eventSlug
    },
    client: publicClient
  });

  const event = useMemo(() => {
    return data?.event
  }, [data]);

  const {
    title,
    description,
    image,
    url,
  } = useMemo(() => {
    console.log('>>>', event)
    if (!event) return {};
    return {
      title: `${event?.title} | Rally`,
      description: event?.description,
      image: path(['banner', 0, 'url'], event),
      url: window.location.href,
    };
  }, [event]);

  if (event?.isPrivate) {
    return <Navigate to={`/c/${event?.community?.slug}`} />;
  }
  if (error) return onError(error.message);

  return (
    <NavLayout
      navigation={<Navigation />}
      fallbackSlug={event?.community?.slug}
      appBar={<TopBar fallbackSlug={event?.community?.slug} showSidebar={false} showBack={false} />}
      showSidebar={false}
    >
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {url && <meta property="og:url" content={url} />}
        <meta property="og:type" content="website" />
        {title && <meta property="og:title" content={title} />}
        {description && <meta property="og:description" content={description} />}
        {image && <meta property="og:image" content={image} />}
      </Helmet>
      <ScrollView>
        <Box>
          {event && <EventDetails
            details={event}
            full={true}
            isLead={false}
            community={event?.community}
            loading={loading}
          />}
        </Box>
      </ScrollView>
    </NavLayout>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('Events'),
  memo,
));
