const { toPairs } = require("ramda");

export const REACTIONS_MAP = {
  AWESOME: '26a1',
  LIKE: '1f918',
  SUPPORT: '1f3cd-fe0f',
  FUNNY: '1f602',
  CELEBRATE: '1f37b',
  LOVE: '2764-fe0f-200d-1f525',
  RAD: '1f919',
  CONGRATS: '1f3c1',
  COOL: '1f60e',
  WOAH: '1f633',
};

export const REACTIONS_ARR = toPairs(REACTIONS_MAP);