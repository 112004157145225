import React from "react";
import { IconButton, Stack, useTheme } from "@mui/material";
import CloseIcon from "components/icons/CloseIcon";
import CheckedIcon from "components/icons/CheckedIcon";
import LoadingIconButton from "components/LoadingIconButton";

export default function FormButtons({
  onConfirm = () => { },
  onCancel,
  loading,
  children,
  leftContent,
  variant = 'primary',
  ...props
}) {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: '70px',
        p: 3,
        paddingY: 5,
        alignItems: 'center',
        backgroundColor: variant === 'primary'
          ? theme.palette.overlay.cta
          : variant === 'dark' ? theme.palette.transparentColors.dark.thirty : 'none',
      }}
      {...props}
    >
      <Stack>
        {leftContent}
      </Stack>

      {children || (
        <Stack direction="row" gap={1}>
          {onCancel && <IconButton
            onClick={onCancel}
            disabled={loading}
            sx={{
              backgroundColor: theme.palette.transparentColors.light.seventyFive,
              p: 2,
              height: '50px',
              width: '50px',
            }}
          >
            <CloseIcon />
          </IconButton>}
          {onConfirm && <LoadingIconButton loading={loading} onClick={onConfirm} type="submit" sx={{
            height: '50px',
            width: '50px',
          }}>
            <CheckedIcon />
          </LoadingIconButton>}
        </Stack>
      )}
    </Stack>
  );
}
