import React, { memo, useContext, useEffect } from 'react';
import { applyTo, filter, map, path, pipe, remove, toLower, trim, without } from 'ramda';
import { Typography, Divider, Button, Box, Checkbox, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Stack } from '@mui/system';
import { useState } from 'react';

import UnreadIcon from 'components/icons/UnreadIcon';
import { InputWrapper } from './SearchField';
import { AppContext } from 'context';
import UserAvatar from 'components/UserAvatar';
import FormButtons from 'components/FormButtons';
import { SEARCH_USERS, WHO_AM_I } from 'data/queries/users';
import { MESSAGE_USERS } from 'data/mutations/users';

function XIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7279 1.55044L11.3137 0.13623L6.36398 5.08595L1.41426 0.13623L4.86374e-05 1.55045L4.94977 6.50017L0 11.4499L1.41421 12.8642L6.36398 7.91438L11.3138 12.8642L12.728 11.4499L7.7782 6.50017L12.7279 1.55044Z" fill="white" fill-opacity="0.4" />
    </svg>
  )
}

export default applyTo(({ loading }) => {
  const { onError } = useContext(AppContext);
  const { data } = useQuery(WHO_AM_I);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [searchUsers, { data: searchData }] = useLazyQuery(SEARCH_USERS);
  const [usersToMessage, setUsersToMessage] = useState([]);
  const [messageUsers, { loading: messageLoading }] = useMutation(MESSAGE_USERS);
  const theme = useTheme();

  const handleRemove = (index) => {
    setUsersToMessage(remove(index, 1, usersToMessage));
  }

  const handleMessageUsers = async () => {
    if (!usersToMessage.length === 0) return;
    const channel = await messageUsers({
      variables: {
        input: {
          userIds: usersToMessage.map((user) => user.node?.id)
        }
      }
    })
      .then(path(['data', 'messageUsers', 'channel']))
      .catch(err => {
        onError(err.message);
        return;
      })
    if (!channel) {
      onError('Unable to message users. Please try again.');
      return;
    }
    setUsersToMessage([]);
    setSearchTerm('');

    navigate(`/messages/${channel.slug}`);
  };

  useEffect(() => {
    if (!searchTerm || searchTerm?.length < 2 || loading) return;

    searchUsers({
      variables: {
        input: {
          searchTerm: trim(searchTerm),
          first: 5
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchUsers]);

  const channels = data?.whoami?.channels?.edges?.map((c) => {
    const members = filter((m) => {
      return m?.node?.id !== data?.whoami?.id;
    }, c?.node?.members?.edges || [])
    const title = map(path(['node', 'displayName']), members || []).join(', ');
    return {
      ...(c?.node || {}),
      title,
      members
    }
  })
  const filteredChannels = trim(searchTerm) || usersToMessage.length > 0
    ? channels?.filter(({ title, members }) => {
      if (toLower(title).includes(toLower(searchTerm))) return true;
      if (usersToMessage.length > 0) {
        for (const user of usersToMessage) {
          if (members.find(member => member.node.id === user.node.id)) return true;
        }
        return false;
      }
      return false;
    })
    : channels

  return (
    <Stack sx={{ pt: 3, px: 3, pb: 12 }}>
      <Box sx={{ mt: 2, mx: 2 }}>
        <Typography variant='h2'>Find or start a conversation</Typography>
        <InputWrapper>
          {usersToMessage.map((user, index) => (
            <Button
              variant='tertiary'
              label={user?.node?.displayName}
              onClick={() => handleRemove(index)}
              endIcon={<XIcon />}
            >{user?.node?.displayName}</Button>
          ))}
          <input
            value={searchTerm}
            onChange={(evt) => setSearchTerm(evt.target.value)}
            placeholder='Search by name or username'
          />
        </InputWrapper>
      </Box>
      <FormButtons variant='dark'>
        <Box sx={{ p: '12px 37px' }}>
          <Button
            variant='contained'
            onClick={handleMessageUsers}
            loading={messageLoading}
            disabled={usersToMessage.length === 0}
          >Start</Button>
        </Box>
      </FormButtons>
      {trim(searchTerm) && <Stack sx={{ m: 2 }} gap={2}>
        <div style={{ position: 'relative' }}>
          <Box
            style={{
              position: 'absolute',
              backgroundColor: "#ededea",
              borderRadius: '16px',
              padding: '3px 18px',
              width: '80px',
              marginLeft: 'auto',
              marginRight: 'auto',
              left: 0,
              right: 0
            }}
          >Active</Box>
          <Divider sx={{ my: 2 }} />
        </div>
        <Stack gap={1}>
          {(filteredChannels || [])
            .map(({ title, members, unreadMessagesCount, id, slug }) => {
              return (
                <Box
                  key={id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/messages/${slug}`);
                  }}
                  sx={{
                    backgroundColor: theme.palette.overlay.contrast,
                    px: '25px',
                    py: '15px',
                    borderRadius: '8px',
                  }}
                >
                  <Stack direction="row" gap={2} alignItems="center">
                    <UserAvatar
                      src={members[0]?.node?.avatar?.url}
                      variant='rounded'
                    />
                    <Typography>{title}</Typography>
                    {unreadMessagesCount > 0 && <UnreadIcon />}
                  </Stack>
                </Box>
              )
            }
            )}
        </Stack>
        {(trim(searchTerm) || usersToMessage.length > 0) && <div style={{ position: 'relative' }}>
          <Box
            style={{
              position: 'absolute',
              backgroundColor: '#ededea',
              borderRadius: '16px',
              padding: '3px 18px',
              width: '56px',
              marginLeft: 'auto',
              marginRight: 'auto',
              left: 0,
              right: 0
            }}
          >All</Box>
          <Divider sx={{ my: 2 }} />
        </div>}
        <Stack gap={1}>
          {usersToMessage.map((option, index) => {
            return (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                  backgroundColor: theme.palette.overlay.contrast,
                  px: '25px',
                  py: '15px',
                  borderRadius: '8px',
                }}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  <UserAvatar
                    src={option?.node?.avatar?.url}
                    variant='rounded'
                  />
                  <Typography>{option?.node?.displayName}</Typography>
                </Stack>
                <Checkbox
                  checked={usersToMessage.find((user) => user.node.id === option?.node?.id)}
                  onChange={(evt) => {
                    if (evt.target.checked) {
                      setUsersToMessage([...usersToMessage, option])
                    } else {
                      setUsersToMessage(without([option], usersToMessage));
                    }
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            )
          })}
          {trim(searchTerm) && (searchData?.searchUsers.edges || [])
            .filter((option) => !usersToMessage.find((user) => user.node.id === option?.node?.id))
            .map((option, index) => {
              return (
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Stack direction="row" gap={2} alignItems="center">
                    <UserAvatar src={option?.node?.avatar?.url} />
                    <Typography>{option?.node?.displayName}</Typography>
                  </Stack>
                  <Checkbox
                    checked={usersToMessage.find((user) => user.node.id === option?.node?.id)}
                    onChange={(evt) => {
                      if (evt.target.checked) {
                        setUsersToMessage([...usersToMessage, option])
                      } else {
                        setUsersToMessage(without([option], usersToMessage));
                      }
                      setSearchTerm(' ');
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Stack>
              )
            })}
        </Stack>
      </Stack>}
    </Stack>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('FindOrStart'),
  memo,
));
