import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  LexicalTypeaheadMenuPlugin,
  TypeaheadOption,
  useBasicTypeaheadTriggerMatch
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { head, prepend, startsWith } from "ramda";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Stack } from "@mui/material";

import { $createMentionLinkNode, MentionLinkNode } from "../nodes/MentionLinkNode";
import { useParams } from "react-router-dom";
import { $getNodeByKey } from "lexical";
import UserAvatar from "components/UserAvatar";
import { SEARCH_USERS } from "data/queries/users";
import { SEARCH_CHANNELS } from "data/queries/channel";
import { PRIVATE_COMMUNITY } from "data/queries/community";

const PUNCTUATION =
  "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION
};

const CapitalizedNameMentionsRegex = new RegExp(
  "(^|[^#])((?:" + DocumentMentionsRegex.NAME + "{" + 1 + ",})$)"
);

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ["@", "#"].join("");

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  " |" + // E.g. " " in "Josh Duck"
  "[" +
  PUNC +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")";

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  "(^|\\s|\\()(" +
  "[" +
  TRIGGERS +
  "]" +
  "((?:" +
  VALID_CHARS +
  VALID_JOINS +
  "){0," +
  LENGTH_LIMIT +
  "})" +
  ")$"
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  "(^|\\s|\\()(" +
  "[" +
  TRIGGERS +
  "]" +
  "((?:" +
  VALID_CHARS +
  "){0," +
  ALIAS_LENGTH_LIMIT +
  "})" +
  ")$"
);

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5;

function checkForCapitalizedNameMentions(
  text,
  minMatchLength
) {
  const match = CapitalizedNameMentionsRegex.exec(text);
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[2];
    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString
      };
    }
  }
  return null;
}

class MentionTypeaheadOption extends TypeaheadOption {
  type;
  id;
  name;
  slug;
  picture;
  displayName;

  constructor(type, id, name, displayName, slug, picture) {
    super(name);
    this.type = type;
    this.id = id;
    this.name = name;
    this.displayName = displayName;
    this.slug = slug;
    this.picture = picture;
  }
}

function checkForAtSignMentions(
  text,
  minMatchLength
) {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
        trigger: head(match[2] || [])
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text) {
  const match = checkForAtSignMentions(text, 1);
  return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
}

function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option
}) {
  return (
    <Stack
      direction='row'
      gap={2}
      key={option.key}
      tabIndex={-1}
      style={{ cursor: 'pointer' }}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={"typeahead-item-" + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      alignItems='center'
    >
      {option.picture}
      <span>{option.name}</span>
    </Stack>
  );
}

export default function MentionsPlugin({ onAddMention, onRemoveMention, isLead }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeMutationListener = editor.registerMutationListener(
      MentionLinkNode,
      (mutatedNodes) => {
        // mutatedNodes is a Map where each key is the NodeKey, and the value is the state of mutation.
        for (let [key, mutation] of mutatedNodes) {
          if (mutation === 'destroyed') return onRemoveMention(key);
          // eslint-disable-next-line no-loop-func
          editor.update(() => {
            const mention = $getNodeByKey(key);
            if (mention.mentionType !== 'channel') onAddMention(mention);
          });
        }
      },
    );

    return removeMutationListener;
  })

  const { community } = useParams();
  const { data } = useQuery(PRIVATE_COMMUNITY, {
    variables: {
      slug: community,
    },
    skip: !community
  });
  const communityId = data?.whoami?.community?.node?.id;
  const [searchUsers, { data: userData, loading: searchingUsers }] = useLazyQuery(SEARCH_USERS);
  const [searchChannels, { data: channelData, loading: searchingChannels }] = useLazyQuery(SEARCH_CHANNELS);
  const [trigger, setTrigger] = useState();
  const [searchTerm, setSearchTerm] = useState();

  useEffect(() => {
    if (!searchTerm || searchTerm.length < 2 || searchingUsers || searchingChannels) return;
    trigger === '#' && communityId
      ? searchChannels({
        variables: {
          input: {
            first: 5,
            searchTerm,
            communityId
          }
        }
      })
      : searchUsers({
        variables: {
          input: {
            first: 5,
            searchTerm,
            communityId
          }
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, communityId])

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
    minLength: 0
  });

  const options = useMemo(() => {
    const results = trigger === '@'
      ? userData?.searchUsers?.edges?.map((u) => new MentionTypeaheadOption(
        'user',
        u?.node?.id,
        u?.node?.firstName ? `${u?.node.firstName} ${u?.node.lastName}` : u?.node?.displayName,
        u?.node?.displayName,
        u?.node?.username,
        <UserAvatar
          src={u?.node?.avatar?.url}
          variant='rounded'
        />
      ))
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT)
      : channelData?.searchChannels?.edges?.map((c) => new MentionTypeaheadOption(
        'channel',
        c?.node?.id,
        c?.node?.slug,
        c?.node?.slug,
        c?.node?.slug,
        <>#</>
      ))
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT);

    if (isLead && trigger === '@'
      && searchTerm
      && (startsWith(searchTerm, 'here') || searchTerm === 'here')
    ) {
      return prepend(new MentionTypeaheadOption(
        'here',
        'ALL',
        '@here',
        'here',
        'here',
        <></>
      ), results)
    }

    return results;
  }, [userData, channelData, trigger, searchTerm, isLead]);

  const onSelectOption = useCallback((
    selectedOption,
    nodeToReplace,
    closeMenu
  ) => {
    editor.update(() => {
      const mentionNode = $createMentionLinkNode({
        id: selectedOption?.id,
        text: selectedOption?.displayName,
        slug: selectedOption?.slug,
        mentionType: selectedOption?.type,
        communitySlug: community
      });
      if (nodeToReplace) {
        nodeToReplace.replace(mentionNode);
      }
      closeMenu();
    });
  },
    [editor, community]
  );

  const checkForMentionMatch = useCallback(
    (text) => {
      const mentionMatch = getPossibleQueryMatch(text);
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      !slashMatch && mentionMatch && setTrigger(mentionMatch?.trigger);
      return !slashMatch && mentionMatch ? mentionMatch : null;
    },
    [checkForSlashTriggerMatch, editor]
  );

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={setSearchTerm}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={(
        _,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) =>
        (options && options.length > 0) &&
        <Stack
          gap={2}
          sx={{
            position: 'absolute',
            bottom: '60px',
            left: 0,
            borderRadius: '8px',
            backgroundColor: 'white',
            width: '100%',
            padding: '20px',
          }}
        >
          {options?.map((option, i) => (
            <MentionsTypeaheadMenuItem
              index={i}
              isSelected={selectedIndex === i}
              onClick={() => {
                setHighlightedIndex(i);
                selectOptionAndCleanUp(option);
              }}
              onMouseEnter={() => {
                setHighlightedIndex(i);
              }}
              key={option.key}
              option={option}
            />
          ))}
        </Stack>
      }
    />
  );
}
