import { Box, Stack, useTheme } from "@mui/material";
import { format } from "date-fns";

export default function Calendar({ date = new Date() }) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        maxWidth: '70px',
        width: '70px',
        height: '81px',
        maxHeight: '81px'
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: theme.palette.transparentColors.dark.seventy,
          borderRadius: '5px 5px 0px 0px',
          padding: '5px 15px',
          color: theme.palette.transparentColors.light.eighty,
          fontSize: '18px'
        }}
      >
        {format(date, 'LLL')}
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.transparentColors.dark.ten,
          padding: '5px 0px',
          textAlign: 'center',
          borderRadius: '0px 0px 5px 5px',
          color: theme.palette.transparentColors.dark.seventy,
          fontSize: '30px'
        }}
      >
        {format(date, 'd')}
      </Box>
    </Stack>
  )
}