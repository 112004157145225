import { ElementNode, $createParagraphNode } from "lexical";
import { addClassNamesToElement } from '@lexical/utils';

function convertBlockquoteElement() {
  const node = $createQuoteNode();
  return {node};
}

export class QuoteNode extends ElementNode {
  static getType() {
    return 'quote';
  }

  static clone(node) {
    return new QuoteNode(node.__key);
  }

  // View

  createDOM(config) {
    const element = document.createElement('blockquote');
    element.style.borderLeftColor = config.theme.quoteColor;
    addClassNamesToElement(element, config.theme.quote);
    return element;
  }
  updateDOM(prevNode, dom) {
    return false;
  }

  static importDOM() {
    return {
      blockquote: (node) => ({
        conversion: convertBlockquoteElement,
        priority: 0,
      }),
    };
  }

  static importJSON(serializedNode) {
    const node = $createQuoteNode();
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'quote',
    };
  }

  // Mutation

  insertNewAfter(_, restoreSelection) {
    const newBlock = $createParagraphNode();
    const direction = this.getDirection();
    newBlock.setDirection(direction);
    this.insertAfter(newBlock, restoreSelection);
    return newBlock;
  }

  collapseAtStart() {
    const paragraph = $createParagraphNode();
    const children = this.getChildren();
    children.forEach((child) => paragraph.append(child));
    this.replace(paragraph);
    return true;
  }
}

export function $createQuoteNode() {
  return new QuoteNode();
}

export function $isQuoteNode(node) {
  return node instanceof QuoteNode;
}