import { memo, useEffect } from 'react';
import { pipe, applyTo, split, slice, join } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Routes, Route, Navigate, useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import useLocalStorage from 'hooks/useLocalStorage';

import Channels from './Channels';
import Channel from './Channel';
import CommunityHome from './CommunityHome';
import Members from './Members';
import Join from './Join';
import MediaChannel from './MediaChannel';
import EventChannel from './EventChannel';
import Application from './Application';
import ActivityChannel from './ActivityChannel';
import WelcomeChannel from './WelcomeChannel';
import About from './About';
import Apply from './Apply';
import { PRIVATE_COMMUNITY } from 'data/queries/community';
import Progress from 'components/Progress';
import useCustomAuth0 from 'hooks/useCustomAuth0';
import CreateEvent from './CreateEvent';

const getCommunityPath = pipe(
  split('/'),
  slice(3, Infinity),
  join('/'),
  (str) => `/${str}`
)

export default applyTo(() => {
  const { community: slug } = useParams();
  const { pathname } = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [_, setLastCommunityPath] = useLocalStorage('lastCommunityPath');
  // eslint-disable-next-line no-unused-vars
  const [__, setLastCommunitySlug] = useLocalStorage('lastCommunitySlug');
  const {
    isAuthenticated,
  } = useCustomAuth0();
  const { data, loading } = useQuery(PRIVATE_COMMUNITY, {
    variables: {
      slug,
    },
    skip: !isAuthenticated,
  });
  const community = data?.whoami?.community;

  // on mount set the 'last seen' community and path
  useEffect(() => {
    if (community?.node?.slug !== slug) return;
    setLastCommunityPath(getCommunityPath(pathname));
    setLastCommunitySlug(slug);
  }, [community?.node]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Progress />;

  return (
    <Routes>
      {/* public routes */}
      <Route index element={<CommunityHome />} />
      <Route path="booth" element={<CommunityHome />} />
      <Route path="apply" element={<Apply />} />
      <Route path="join/:mode?" element={<Join />} />
      {/* private routes */}
      <Route path="application" element={<Application />} />
      {/* with nav */}
      <Route path="about" element={<About />} />
      <Route path="media/*" element={<MediaChannel />} />
      <Route path="events/create" element={<CreateEvent />} />
      <Route path="events/*" element={<EventChannel />} />
      <Route path="activity/*" element={<ActivityChannel />} />
      <Route path="welcome/*" element={<WelcomeChannel />} />
      <Route path="channels" element={<Channels />} />
      <Route path="channel/:channel/*" element={<Channel />} />
      <Route path="members" element={<Members />} />

      <Route path="*" element={<Navigate to={`/c/${slug}`} />} />
    </Routes>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('Community'),
  memo,
));
