import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Stack, Typography, Box, Button } from '@mui/material'
import { pipe, applyTo } from 'ramda';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { AppContext } from 'context';
import { propTypes, defaultProps, displayName } from 'lib/react';
import withMemberRequired from 'components/withMemberRequired';
import PlusIcon from 'components/icons/PlusIcon';

import EventCard from './EventCard';
import NavLayout from 'components/NavLayout';
import ScrollView from 'components/ScrollView';
import { COMMUNITY_EVENTS_QUERY } from 'data/queries/event';
import NoContentModal from 'components/NoContentModal';
import { JOIN_CHANNEL } from 'data/mutations/channel';

export default applyTo(() => {
  const { chat, onError } = useContext(AppContext);
  const { community: slug } = useParams();
  const [section, setSection] = useState('UPCOMING');
  const [showNoMessages, setShowNoMessages] = useState(false);

  const { data, loading, error, refetch } = useQuery(COMMUNITY_EVENTS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      slug,
      input: {
        filter: 'UPCOMING',
      }
    }
  });
  const [joinChannel, { error: joinError }] = useMutation(JOIN_CHANNEL);
  const channel = data?.whoami.community?.node.channel?.node;
  const events = useMemo(() => (data?.whoami.community.node.events.edges || []), [data]);
  const isLead = data?.whoami.community.isLead;
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && data && !joinError && !channel) {
      joinChannel({
        variables: {
          input: {
            channelSlug: 'events',
            communityId: data?.whoami.community?.node.id
          }
        }
      })
        .then(refetch)
        .catch(err => onError(err.message))
    }
  }, [channel, data, loading, joinChannel, refetch, joinError, onError])

  useEffect(() => {
    if (!loading && events.length === 0) {
      setShowNoMessages(true);
    } else {
      setShowNoMessages(false);
    }
  }, [loading, events]);

  useEffect(() => {
    if (chat && channel?.twilioSid) {
      chat?.getConversationBySid(channel?.twilioSid)
        .then(chatRoom => chatRoom.setAllMessagesRead())
    }
  }, [channel, chat])

  const refetchEvents = (filter) => {
    setShowNoMessages(false);
    setSection(filter);
    refetch({
      slug,
      input: {
        filter,
      }
    })
  }

  if (error) return onError(error.message);

  return (
    <NavLayout>
      <ScrollView
        top={<>
          <Typography variant="h2">Events</Typography>
          <Box sx={{ flexGrow: 1 }} />
          {data?.whoami.community.isLead && !data?.whoami.community?.node?.isPaused && (
            <Button
              variant='tertiary'
              onClick={() => navigate('create')}
            >
              <Stack direction='row' gap={1} alignItems='center'>
                Add Event
                <PlusIcon width={16} height={16} />
              </Stack>
            </Button>
          )}
        </>}
      >
        {!loading && <Stack gap={1} direction='row' flexWrap='wrap'>
          <Button
            variant='contained'
            onClick={() => refetchEvents('UPCOMING')}
            disabled={section === 'UPCOMING'}
            sx={{
              fontSize: '14px',
              p: '8px 16px',
              borderRadius: '8px',
            }}
          >
            Upcoming Events
          </Button>
          <Button
            variant='contained'
            onClick={() => refetchEvents('PAST')}
            disabled={section === 'PAST'}
            sx={{
              fontSize: '14px',
              p: '8px 16px',
              borderRadius: '8px',
            }}
          >
            Past Events
          </Button>
          {isLead && <Button
            variant='contained'
            onClick={() => refetchEvents('DRAFT')}
            disabled={section === 'DRAFT'}
            sx={{
              fontSize: '14px',
              p: '8px 16px',
              borderRadius: '8px',
            }}
          >
            Draft Events
          </Button>}
        </Stack>}
        <Stack spacing={2} sx={{ py: 2 }}>
          {events.map(({ node, isAttending, isInterested }, i) => (
            <EventCard
              key={i}
              details={node}
              isAttending={isAttending}
              isInterested={isInterested}
              community={data?.whoami.community?.node}
              refetch={refetch}
            />
          ))}
        </Stack>
      </ScrollView>
      <NoContentModal
        isOpen={showNoMessages}
        onClose={() => {
          navigate('create');
        }}
        message='There are currently no community events.'
        ctaText={isLead ? 'New Event' : null}
      />
    </NavLayout>
  );
}, pipe(
  withMemberRequired,
  propTypes({}),
  defaultProps({}),
  displayName('EventChannel'),
  memo,
));
