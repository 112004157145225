import { useContext, useState } from 'react';
import { path } from 'ramda';
import { Box, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import { AppContext } from 'context';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'lib/firebase';
import BottomDrawer from 'components/BottomDrawer';
import ReplyInput from 'components/Lexical/ReplyInput';
import { MESSAGE_USERS } from 'data/mutations/users';

export default function MessageDrawer({ isOpen, onClose, displayName, userId }) {
  const { onError, chat } = useContext(AppContext);
  const [dmSlug, setDmSlug] = useState();

  const [messageUsers] = useMutation(MESSAGE_USERS);

  const handleMessageUser = async (msg) => {
    if (!userId) return;
    const channel = await messageUsers({
      variables: {
        input: {
          name: displayName,
          userIds: [userId]
        }
      }
    })
      .then(path(['data', 'messageUsers', 'channel']))
      .catch(err => {
        onError(err.message);
        return;
      })
    if (!channel) return;
    // setup chat room on the fly and send message
    return chat?.getConversationBySid(channel?.twilioSid)
      .then(chatRoom => chatRoom.sendMessage(msg))
      .then(() => {
        setDmSlug(channel?.slug);
        logEvent(analytics, "send_message", {
          channel: channel?.slug
        });
      })
      .catch(() => onError('Unable to message user. Please try again.'))
  };

  return (
    <BottomDrawer
      isOpen={isOpen}
      onClose={() => {
        setDmSlug();
        onClose();
      }}
      title={dmSlug ? '' : `Message ${displayName}`}
    >
      {dmSlug ? (
        <Box sx={{ my: 2 }}>
          <Typography sx={{ color: 'text.secondary', mb: 2 }}>You messaged <span style={{ color: 'white' }}>{displayName}</span></Typography>
          <Link to={`/messages/${dmSlug}`}>Go to Message Thread</Link>
        </Box>
      ) : (
        <ReplyInput
          onSend={handleMessageUser}
        />
      )}
    </BottomDrawer>
  );
}