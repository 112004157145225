import { memo } from "react";
import { pipe, applyTo } from "ramda";
import { propTypes, defaultProps, displayName } from "lib/react";
import { Stack } from "@mui/system";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import Progress from "components/Progress";
import Image from "components/Image";
import { publicClient } from "lib/apollo";
import { sortGroupsByLocation } from "lib/location";
import { truncate } from "lib/string";

const PUBLIC_SEARCH_COMMUNITIES = gql`
  query SearchCommunities {
    searchCommunities(input: { first: 14 }) {
      edges {
        node {
          id
          title
          type
          categories
          logo {
            url
          }
          description
          location {
            locality
            state
            lat
            lng
          }
          members {
            total
          }
          slug
          featuredImage {
            id
            url(input: {
              transformations: [{
                aspect_ratio: "1:1"
                crop: "fill",
                gravity: "auto"
              }]
            }) 
          }
        }
      }
    }
  }
`;

export default applyTo(() => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const interests = searchParams.get("interests")
    ? (searchParams.get("interests") || '').split(',')
    : [];
  const lat = searchParams.get("lat");
  const lng = searchParams.get("lng");

  const { data, loading } = useQuery(PUBLIC_SEARCH_COMMUNITIES, {
    client: publicClient,
  });

  const filteredCommunities = interests.length > 0
    ? (data?.searchCommunities?.edges || []).filter((c) => {
      return interests.some((i) => c?.node?.categories.includes(i));
    })
    : data?.searchCommunities?.edges || [];

  const sortedCommunities = lat && lng
    ? sortGroupsByLocation({ lat: Number(lat), lng: Number(lng) }, filteredCommunities)
    : filteredCommunities;
  const sortedCommunitiesLength = sortedCommunities.length;

  const handleCommunityClick = (slug) => {
    if (window.IS_REACT_NATIVE) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        action: 'open_community_sheet',
        value: slug
      }));
    } else {
      navigate(`/c/${slug}`);
    }
  }

  const handleCreateClick = () => {
    if (window.IS_REACT_NATIVE) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        action: 'navigate',
        value: '/community/create'
      }));
    } else {
      navigate(`/create`);
    }
  }

  if (loading) return <Progress />;

  return (
    <Box>
      <Box>
        {sortedCommunitiesLength > 0 && <Stack gap={3} sx={{ px: 2, mb: 2 }}>
          <Typography variant="h3" sx={{ mt: 3 }}>
            Groups You Might Like
          </Typography>
          {/* <Typography>Set your location to find clubs nearby</Typography>
            <PostalCode /> */}
        </Stack>}
        <Box sx={{ px: 2 }}>
          <Grid container spacing={'10px'}>
            {sortedCommunities?.map((c) => (
              <Grid item xs={6} md={3} key={c?.node.id}>
                <Image
                  src={c?.node.featuredImage?.url}
                  placeholder="https://placehold.co/300x300/999999/999999"
                  sx={{
                    borderTopLeftRadius: "13px",
                    borderTopRightRadius: "13px",
                    width: 1,
                    outline: 'none'
                  }}
                  onClick={() => handleCommunityClick(c?.node?.slug)}
                />
                <Box
                  sx={{
                    backgroundColor: 'black',
                    mt: '-10px',
                    padding: '10px',
                    borderBottomLeftRadius: "13px",
                    borderBottomRightRadius: "13px",
                  }}
                >
                  <Box sx={{ minHeight: '50px', display: 'table' }}>
                    <Typography
                      fontSize={14}
                      sx={{ color: 'white', verticalAlign: 'middle', display: 'table-cell' }}
                    >
                      {truncate(c?.node.title)}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ color: "#CCCCCC" }}
                    fontSize={14}
                  >
                    {truncate(c?.node.location?.locality, 20)}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      {sortedCommunitiesLength > 0 && <Divider sx={{ py: 1, mx: 2 }} />}
      <Stack
        sx={{ px: 2, pt: 1, pb: 3, height: sortedCommunitiesLength > 0 ? '100%' : '100vh' }}
        justifyContent={'space-around'}
      >
        <Box>
          <Stack sx={{ mb: 2 }}>
            {sortedCommunitiesLength > 0 ? (
              <Typography>
                Don’t see the group you’re looking for? <b>Become a community creator and start your own.</b>
              </Typography>
            ) : (
              <Typography>
                There are currently no groups in your area.<br /><b>Be the first to start one!</b>
              </Typography>
            )}
          </Stack>
          <Button
            onClick={handleCreateClick}
            variant="regular"
            sx={{
              width: isDesktop ? 'unset' : '100%',
            }}
          >
            Create a Group
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}, pipe(propTypes({}), defaultProps({}), displayName("Discover"), memo));