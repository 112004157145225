import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = 'black' }) => {
    return (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0267 20.4933C14.8665 20.4933 14.713 20.4399 14.5861 20.3264L9.41923 15.8004L4.93326 15.8271C2.20963 15.8271 1.50409e-05 13.6108 1.50409e-05 10.8938V5.43324C-0.00666052 2.71629 2.20963 0.5 4.92658 0.5H15.0668C17.7904 0.5 20 2.71629 20 5.43324V10.8938C20 13.3905 18.1175 15.4599 15.6876 15.787V19.8324C15.6876 20.0928 15.534 20.3331 15.2937 20.4399C15.2069 20.48 15.1135 20.5 15.02 20.5L15.0267 20.4933ZM9.66623 14.4653C9.82644 14.4653 9.98666 14.5254 10.1068 14.6322L14.3591 18.3571V15.1595C14.3591 14.7924 14.6595 14.492 15.0267 14.492C17.0361 14.492 18.6716 12.8765 18.6716 10.8938V5.43324C18.6716 3.4506 17.0561 1.83511 15.0734 1.83511H4.92658C2.94394 1.83511 1.32845 3.4506 1.32845 5.43324V10.8938C1.32845 12.8765 2.94394 14.492 4.92658 14.492L9.66623 14.4653Z" fill={color}/>
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('CommunityIcon'),
    memo,
));
