import { memo, useCallback, useContext, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material'
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useParams, useNavigate } from 'react-router-dom';
import FlexCenter from 'components/FlexCenter';
import rallyLoader from '../../assets/Logo_Animation_Small.gif';
import { useMutation, useQuery } from '@apollo/client';
import config from '../../config';
import { JOIN_COMMUNITY } from 'data/mutations/community';
import { VALIDATE_INVITE_CODE } from 'data/queries/community';
import { AppContext } from 'context';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(() => {
  const [message, setMessage] = useState('Validating your invite...');
  const navigate = useNavigate();
  const { code } = useParams();
  const theme = useTheme();
  const { onError } = useContext(AppContext);

  const handleJoinCompleted = useCallback(async (data) => {
    setMessage('Redirecting...', data)
    const slug = data?.joinCommunity?.community?.slug;
    navigate(`/c/${slug}/welcome`)
  }, [setMessage, navigate]);

  const handleError = useCallback(async (error) => {
    setMessage(error?.message)
  }, [setMessage]);

  const [joinCommunity] = useMutation(JOIN_COMMUNITY, {
    onCompleted: handleJoinCompleted,
    onError: handleError,
  });

  const handleCompleted = useCallback(async (data) => {
    setMessage('Getting you all set up with your new community...');
    await joinCommunity({
      variables: {
        input: {
          communityId: data?.invitation?.community?.id,
          application: {
            code: data?.invitation?.code,
            note: `joined via invite link`
          }
        }
      }
    }).catch(err => onError(err.message));
  }, [joinCommunity, setMessage, onError]);

  useQuery(VALIDATE_INVITE_CODE, {
    variables: {
      code
    },
    onCompleted: handleCompleted,
    onError: handleError,
  });

  return (
    <FlexCenter>
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ width: '92px', margin: '0 auto' }}>
          <img
            src={rallyLoader}
            alt="Creating community"
            style={{ width: '100%' }}
          />
        </Box>
        <Typography variant='h4' color={theme.palette.transparentColors.dark.fortyFive}>
          {message}
        </Typography>
      </Box>
    </FlexCenter>
  );
}, pipe(
  component => withAuthenticationRequired(component, {
    returnTo: `${window.location.protocol}//${config.BASE_URL}${window.location.pathname}`,
    loginOptions: {
      authorizationParams: {
        screen_hint: 'signup',
      },
    }
  }),
  propTypes({}),
  defaultProps({}),
  displayName('JoinViaInvite'),
  memo,
));
