import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = 'black' }) => {
    return (
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.315 0H4.68502C2.10477 0 0 2.10477 0 4.68502V14.315C0 16.9016 2.10477 19 4.68502 19H14.315C16.9016 19 19 16.8952 19 14.315V4.68502C19 2.09843 16.8952 0 14.315 0ZM4.68502 17.7321C4.61528 17.7321 4.55189 17.713 4.48215 17.713C4.82449 15.2469 6.94194 13.3387 9.50317 13.3387C12.0644 13.3387 14.1818 15.2469 14.5242 17.7194C14.4545 17.7194 14.3911 17.7384 14.3213 17.7384H4.68502V17.7321ZM17.7321 14.315C17.7321 15.6844 16.9206 16.8572 15.7541 17.4024C15.2723 14.3847 12.654 12.0644 9.50317 12.0644C6.35235 12.0644 3.73407 14.3847 3.24591 17.4024C2.07941 16.8572 1.26793 15.6844 1.26793 14.315V4.68502C1.26793 2.80214 2.80214 1.26793 4.68502 1.26793H14.315C16.1979 1.26793 17.7321 2.80214 17.7321 4.68502V14.315Z" fill={color} />
        <path d="M9.50285 2.85278C7.32834 2.85278 5.55957 4.62155 5.55957 6.79606C5.55957 8.97057 7.32834 10.7393 9.50285 10.7393C11.6774 10.7393 13.4461 8.97057 13.4461 6.79606C13.4461 4.62155 11.6774 2.85278 9.50285 2.85278ZM9.50285 9.47774C8.0257 9.47774 6.8275 8.27954 6.8275 6.8024C6.8275 5.32526 8.0257 4.12706 9.50285 4.12706C10.98 4.12706 12.1782 5.32526 12.1782 6.8024C12.1782 8.27954 10.98 9.47774 9.50285 9.47774Z" fill={color} />
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('MembersIcon'),
    memo,
));
