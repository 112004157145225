import Progress from 'components/Progress';
import React from 'react';
import config from 'config';
import { Navigate } from "react-router-dom";
import useCustomAuth0 from 'hooks/useCustomAuth0';

const isAdmin = (user) => {
  const roles = user[`${config.API_HOST}/roles`] || [];
  return roles.includes('ADMIN');
}

export default function AdminRoute({ component }) {
  const { isLoading: isAuthenticating, isAuthenticated, user } = useCustomAuth0();

  if (!isAuthenticating && isAuthenticated && !user.org_id && !isAdmin(user)) {
    return <Navigate to="/" replace />;
  }

  if (isAuthenticating || !isAuthenticated || (isAuthenticated && user && user.org_id)) {
    return <Progress />;
  }

  return component;
};