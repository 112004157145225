import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { pipe, applyTo, omit } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Stack } from '@mui/system';
import { useMutation } from '@apollo/client';

import ChatMessage from 'components/Lexical/ChatMessage';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'lib/firebase';

import RichText from 'components/Lexical/ChatMessage/RichText';
import { Link } from 'react-router-dom';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { formatDate } from 'lib/date';
import { REACTIONS_MAP } from 'lib/reactions';
import { Emoji } from 'emoji-picker-react';
import UserAvatar from 'components/UserAvatar';
import BackHeader from 'components/BackHeader';
import ReplyInput from 'components/Lexical/ReplyInput';
import { REPORT_POST } from 'data/mutations/channel';
import ScrollView from 'components/ScrollView';
import { AppContext } from 'context';

const parseBody = (body) => {
  try {
    const parsedBody = JSON.parse(body);
    if (
      !body
      || parsedBody?.root?.children?.length === 0
      || parsedBody?.editorState?.root?.children?.length === 0
    ) return { editorState: null, media: [] };
    return parsedBody?.editorState || parsedBody?.media
      ? { editorState: JSON.stringify(parsedBody.editorState), media: parsedBody.media }
      : { editorState: body, media: [] };
  } catch (err) {
    return { editorState: null, media: [] };
  }
}

export default applyTo(({
  threads,
  author,
  community,
  channel,
  isLead,
  onAddMedia,
  onReaction,
  onReplyTo,
  replyTo,
  onlyMedia = false,
  onSend,
  onBack
}) => {
  const theme = useTheme();
  const [flagPost, { loading: flagging }] = useMutation(REPORT_POST);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const replyRef = useRef();
  const [focused, setFocused] = useState(false);
  const { onError } = useContext(AppContext);

  const handleFlagMsg = msg => async () => {
    if (flagging) return;

    await flagPost({
      variables: {
        input: {
          postId: msg.sid,
          channelId: channel?.id,
        }
      }
    })
      .then(() => {
        logEvent(analytics, 'flagged_post', {
          channel: channel?.slug,
          community: community?.slug
        });
      })
      .catch(err => onError(err.message))
  }

  const handleUndoFlagOnMsg = msg => async () => {
    if (isLead) {
      msg.updateAttributes(omit(['reported'], msg?.attributes));
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (replyRef.current && !replyRef.current.contains(event.target)) {
        setFocused(false);
      } else {
        setFocused(true);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const msgAuthor = replyTo?.state?.userData;
  const { editorState, media } = parseBody(replyTo?.body);
  if (!editorState) return null;

  return (
    <ScrollView sx={{ px: 0 }} top={
      <BackHeader
        pageTitle='Reply'
        onClick={onBack}
      />
    }>
      <Stack gap={2} sx={{ pb: focused ? '290px' : 0, pt: isDesktop ? '70px' : 0 }}>
        <Stack gap={1}>
          <RichText
            editorState={editorState}
            files={media}
            textSize='large'
          />
          <Stack alignItems='center' direction='row' gap={1} sx={{ px: '20px', mb: 2, mt: onlyMedia ? '-35px' : 0 }}>
            <Stack direction='row' alignItems='center'>
              <Link
                to={`/${msgAuthor?.__typename === 'User' ? 'u' : 'c'}/${msgAuthor?.slug}`}
              >
                <UserAvatar
                  src={msgAuthor?.avatar?.url}
                  variant='rounded'
                  width={45}
                  height={45}
                  sx={{ mr: 1.5 }}
                />
              </Link>
              <Box sx={{
                paddingRight: '25px'
              }}>
                <Link
                  to={`/${msgAuthor?.__typename === 'User' ? 'u' : 'c'}/${msgAuthor?.slug}`}
                >
                  <Typography
                    variant='h5'
                  >{msgAuthor?.displayName}</Typography>
                </Link>
                {replyTo?.dateCreated && <Typography
                  color='text.info'
                  variant='subtitle1'
                >{formatDate(replyTo?.dateCreated)}</Typography>}
              </Box>
            </Stack>
            <Stack sx={{
              borderLeftStyle: 'solid',
              borderLeftColor: 'rgba(0, 0, 0, 0.10)',
              borderLeftRadius: '2px',
              paddingLeft: '10px'
            }} direction='row' alignItems='center' gap={1}>
              {Object.entries(replyTo?.attributes?.reactions || []).map(([type, count]) => (
                <>{count > 0 && (
                  <IconButton
                    onClick={() => onReaction(type)}
                    sx={{
                      background: theme.palette.overlay.contrast,
                      borderRadius: '8px',
                      p: '9px 10px'
                    }}
                  >
                    <Emoji unified={REACTIONS_MAP[type]} size={16} />
                    <Typography sx={{ ml: 1 }} color='text.secondary'>{count}</Typography>
                  </IconButton>
                )}</>
              )
              )}
            </Stack>
          </Stack>

        </Stack>
        <Stack sx={{ px: '20px', pb: '40px' }} gap={1}>
          {threads[replyTo?.sid]?.map(msg => (
            <div key={msg.sid} id={msg.sid}>
              <ChatMessage
                dateCreated={msg.dateCreated}
                body={msg.body}
                author={msg.state?.userData}
                attributes={msg.attributes}
                isLead={isLead}
                replyCount={threads[msg.sid]?.length}
                editable={author?.id === msg.author ||
                  (msg.author === community?.id && isLead)}
                onEdit={(body) => msg.updateBody(body)}
                deletable={isLead || author?.id === msg.author}
                pinnable={isLead}
                onDelete={() => msg.remove()}
                onReply={() => onReplyTo(msg)}
                onAddMedia={onAddMedia}
                onReaction={onReaction(msg)}
                flaggable={!!community?.id}
                onFlag={handleFlagMsg(msg)}
                onUndoFlag={handleUndoFlagOnMsg(msg)}
              />
            </div>
          ))}
        </Stack>
      </Stack>
      {!community?.isPaused && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: isDesktop ? '320px' : 0,
            right: 0,
            backgroundColor: theme.palette.transparentColors.light.ninety,
          }}
          onFocus={() => setFocused(true)}
          ref={replyRef}
        >
          <ReplyInput
            onSend={onSend}
            focused={focused}
            isLead={isLead}
            community={community}
            author={author}
            channelName={channel?.name}
          />
        </Box>)}
    </ScrollView>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('ChatThread'),
  memo,
));
