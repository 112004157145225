import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(() => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
        <path opacity="0.5" d="M14.6242 11.4284L10.3821 5.69238L6.32931 11.3784L3.8276 8.72302L0 13.5048H21L17.1724 8.72302L14.6242 11.4284Z" fill="black" />
        <path opacity="0.5" d="M17.3092 2.71894C17.3092 3.94665 16.314 4.94191 15.0863 4.94191C13.8585 4.94191 12.8633 3.94665 12.8633 2.71894C12.8633 1.49123 13.8585 0.496094 15.0863 0.496094C16.314 0.496094 17.3092 1.49123 17.3092 2.71894Z" fill="black" />
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('CameraIcon'),
    memo,
));
