import { Box, Typography, useTheme } from "@mui/material";

export default function EventDescription({ description, style = {} }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.transparentColors.dark.thirtyFive,
        padding: '30px',
        borderRadius: '20px',
        color: 'white',
        ...style
      }}
    >
      <Typography
        variant='h3'
        color={theme.palette.transparentColors.light.forty}
        sx={{ marginBottom: '15px' }}
      >Details</Typography>
      <Typography
        sx={{ whiteSpace: 'pre-wrap' }}
      >
        {description}
      </Typography>
    </Box>
  )
}