import React, { memo } from "react";
import {
  Typography,
  Button,
  Stack,
  IconButton,
  Divider,
  Grid,
  Box,
} from "@mui/material";
import { pipe, applyTo, cond, equals, always } from "ramda";
import { propTypes, defaultProps, displayName } from "lib/react";
import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import { Instagram } from "@mui/icons-material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Image from "components/Image";
import { capitalize } from "lib/string";

import Garage from "./Garage";
import UserAvatar from "components/UserAvatar";
import LoadingList from "components/LoadingList";

const toShirtSizeLabel = cond([
  [equals("XS"), always("Extra small")],
  [equals("SM"), always("Small")],
  [equals("M"), always("Medium")],
  [equals("L"), always("Large")],
  [equals("XL"), always("Extra large")],
  [equals("TWOXL"), always("XXL")],
  [equals("THREEXL"), always("XXXL")],
]);

export default applyTo(
  ({
    user,
    readOnly = true,
    isLead,
    onOpenSettings = () => { },
    onMessageUser = () => { },
    onEdit = () => { },
    onOpenOptions = () => { },
    hideGarage = false,
    hideCommunities = false,
    loading,
  }) => {
    const navigate = useNavigate();
    return (
      <div style={{ position: "relative" }}>
        <Stack>
          <Stack>
            <UserAvatar
              sx={{
                margin: "auto",
                marginBottom: "5%",
              }}
              width="150px"
              height="150px"
              loading={loading}
              src={user?.avatar?.url}
            />
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="h3">
                {user?.firstName} {user?.lastName}
              </Typography>
              {user?.socialLinks?.instagram && (
                <IconButton
                  onClick={() => window.open(user?.socialLinks?.instagram)}
                >
                  <Instagram sx={{ width: "20px" }} />
                </IconButton>
              )}
            </Stack>
            {!loading && <p style={{ marginTop: 0 }}>@{user?.username}</p>}

            <div style={{ marginTop: "3%", marginBottom: "5%" }}>
              {loading ? (
                <LoadingList count={1} height={40} />
              ) : (
                <p>{user?.bio}</p>
              )}
            </div>
            {!loading && (
              <div>
                {readOnly ? (
                  <Button
                    sx={{
                      backgroundColor: "white",
                      marginBottom: "3%",
                      color: "black",
                      padding: "10px 20px",
                      borderRadius: "8px",
                      marginRight: "10px",
                    }}
                    onClick={onMessageUser}
                  >
                    MESSAGE
                  </Button>
                ) : (
                  <div>
                    <Button
                      sx={{
                        backgroundColor: "white",
                        marginBottom: "3%",
                        color: "black",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        marginRight: "10px",
                      }}
                      onClick={onEdit("PROFILE")}
                    >
                      EDIT PROFILE
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: "white",
                        marginBottom: "3%",
                        color: "black",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        marginRight: "10px",
                      }}
                      onClick={onOpenSettings}
                    >
                      <SettingsOutlinedIcon />
                    </Button>
                  </div>
                )}
              </div>
            )}

            {!hideGarage && (
              <Garage
                readOnly={readOnly}
                user={user}
                onEdit={() => onEdit("GARAGE")}
                onOpenOptions={onOpenOptions}
                loading={loading}
              />
            )}

            {!hideCommunities &&
              user?.communities?.edges?.length > 0 &&
              (loading ? (
                <Box sx={{ mt: 2 }}>
                  <LoadingList count={1} />
                </Box>
              ) : (
                <>
                  <Divider sx={{ my: 2 }} />
                  <div
                    style={{
                      backgroundColor: "rgba(255,255,255,.7)",
                      padding: "20px 30px 30px 30px",
                      borderRadius: "20px",
                      gap: "15px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="h5">Clubs</Typography>

                      {/* {!readOnly && (
                      <Button
                        sx={{
                          backgroundColor: "#bbbbbb",
                          color: "white",
                          textTransform: "none",
                          fontWeight: "normal",
                          padding: "0 20px",
                          borderRadius: "8px",
                        }}
                        onClick={() => navigate("/discover")}
                      >
                        Add
                      </Button>
                    )} */}
                    </Stack>
                    <Grid container spacing={2}>
                      {user?.communities?.edges.map(
                        ({ node, memberNumber }) => (
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            onClick={() => navigate(`/c/${node.slug}`)}
                            style={{ cursor: "pointer" }}
                          >
                            {node.logo && (
                              <Image
                                src={node.logo?.url}
                                alt={node.title}
                                sx={{
                                  width: 1,
                                  borderRadius: 2,
                                  backgroundColor: "none",
                                  objectFit: "cover",
                                  objectPosition: "center center",
                                }}
                              />
                            )}
                            <Typography variant="h6">{node.title}</Typography>
                            {memberNumber && (
                              <Typography sx={{ color: "text.secondary" }}>
                                Member #{memberNumber}
                              </Typography>
                            )}
                          </Grid>
                        )
                      )}
                    </Grid>
                  </div>
                </>
              ))}
            {!loading && (user?.address || !readOnly) && (
              <>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Typography variant="h2">Personal Info.</Typography>

                  {!readOnly && (
                    <Button
                      sx={{
                        backgroundColor: "#bbbbbb",
                        color: "white",
                        textTransform: "none",
                        fontWeight: "normal",
                        padding: "0 20px",
                        borderRadius: "8px",
                      }}
                      onClick={onEdit("ADDITIONAL")}
                    >
                      Add
                    </Button>
                  )}
                </Stack>
                <div
                  style={{
                    backgroundColor: "rgba(230,230,230,1)",
                    padding: "25px 20px",
                    borderRadius: "15px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <Typography sx={{ marginBottom: "10px" }}>
                      <b>Gender:</b>{" "}
                      {user?.gender ? capitalize(user?.gender) : "(not added)"}
                    </Typography>
                    <Typography sx={{ marginBottom: "10px" }}>
                      <b>Birthday:</b>{" "}
                      {user?.birthday
                        ? format(new Date(user?.birthday), "MM/dd/yyyy")
                        : "(not added)"}
                    </Typography>
                    <Typography>
                      <b>T-Shirt Size:</b>{" "}
                      {user?.shirtSize
                        ? toShirtSizeLabel(user?.shirtSize)
                        : "(not added)"}
                    </Typography>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {user?.address ? (
                      <Typography>
                        <b>Mailing Address:</b> <br />
                        {user?.address?.address1 && `${user?.address?.address1}`}
                        {user?.address?.address1 && <br />}
                        {user?.address?.locality && `${user?.address?.locality}`}
                        {user?.address?.administrativeArea && `, ${user?.address?.administrativeArea}`}
                        {user?.address?.postalCode && ` ${user?.address?.postalCode}`}
                      </Typography>
                    ) : (
                      <div>
                        {readOnly && (
                          <>
                            <Typography>
                              <b>Address:</b>{" "}
                              {user?.address ? (
                                <>
                                  {`${user?.address?.locality}, ${user?.address?.administrativeArea} ${user?.address?.postalCode}`}
                                </>
                              ) : (
                                "(not added)"
                              )}
                            </Typography>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </Stack>
        </Stack>
      </div>
    );
  },
  pipe(propTypes({}), defaultProps({}), displayName("UserProfile"), memo)
);
