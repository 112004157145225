import { useQuery } from "@apollo/client";
import { Button, Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import BottomDrawer from "components/BottomDrawer";
import Image from "components/Image";
import { WHO_AM_I } from "data/queries/users";
import { Link } from "react-router-dom";

export default function CommunitySwitcher({ isOpen, onClose }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { data } = useQuery(WHO_AM_I);

  const otherCommunities = data?.whoami?.communities.edges;

  return (
    <BottomDrawer
      isOpen={isOpen}
      onClose={onClose}
      title='Switch Community'
    >
      <Divider sx={{ mt: '20px', mx: '-20px' }} />
      {isOpen && <Stack gap={1} sx={{
        minWidth: isDesktop ? '450px' : '100%',
        maxHeight: '350px',
        pt: '10px',
        pb: '10px',
        overflowY: 'scroll'
      }}>
        {otherCommunities?.map((c) => (
          <Link to={`/c/${c?.node.slug}`} onClick={onClose} key={c?.node?.id}>
            <Stack gap={2} direction='row' alignItems='center' sx={{
              backgroundColor: theme.palette.overlay.contrast,
              px: '25px',
              py: '15px',
              borderRadius: '8px'
            }}>
              <Image
                src={c?.node?.logo?.url}
                alt={c?.node?.title}
                sx={{ width: 46, height: 46, borderRadius: 2 }}
              />
              <Typography variant='h6' sx={{ flexGrow: 1 }}>{c?.node?.title}</Typography>
            </Stack>
          </Link>
        ))}
      </Stack>}
      <Divider sx={{ mb: '10px', mx: '-20px' }} />
      <Button
        component={Link}
        to='/create'
        variant='tertiary'
        sx={{
          mt: 2
        }}
      >Create a Community</Button>
    </BottomDrawer>
  )
}