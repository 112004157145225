import React from "react";
import { Stack, Typography, Button, Divider } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import config from "config";

export const Sent = ({ community }) => {
  const { community: slug } = useParams();
  return (
    <Stack
      gap={'30px'}
      sx={{
        padding: '30px',
        width: "720px",
        maxWidth: "100%",
      }}
    >
      <Stack justifyContent="space-between" gap={'30px'}>
        <Typography
          sx={{
            color: "#000",
            fontSize: 28,
            fontWeight: 500,
            fontFamily: 'GT-America-Medium',
            lineHeight: 'normal'
          }}
        >
          Your request to join {community?.title} is pending approval
        </Typography>

        <Typography variant="p">
          Once approved, you’ll receive an email notification and feel free to check&nbsp;
          <Typography component="a" href={`${slug}.${config.BASE_URL}`} sx={{ fontWeight: 600, textWrap: 'nowrap' }}>
            {`${slug}.${config.BASE_URL}`}
          </Typography>{" "}
          anytime to check your status.
        </Typography>
        <Divider />
      </Stack>
      <Typography
        sx={{
          color: "#000",
          fontSize: 18,
          fontWeight: 500,
          fontFamily: 'GT-America-Medium',
        }}
      >
        In the meantime
      </Typography>
      <Typography variant="p">
        Make sure your profile is up to date so it best represents you on Rally.
      </Typography>
      <Button
        component={Link}
        variant="contained"
        to="/profile"
        sx={{
          paddingX: 4,
          paddingY: 2,
        }}
        type="submit"
      >
        Update Profile
      </Button>
    </Stack>
  );
};

export default Sent;
