import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
      <g opacity="0.5">
        <path d="M0.00761905 13.8569L16 6.99972L0.00761905 0.142578L0 5.47591L11.4286 6.99972L0 8.52353L0.00761905 13.8569Z" fill="black" />
      </g>
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('SendIcon'),
  memo,
));
