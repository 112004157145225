import { memo, useContext } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'context';
import Creating from './Creating';
import { CREATE_COMMUNITY } from 'data/mutations/community';
import FormWrapper from './FormWrapper';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(() => {
  const [createCommunity, { loading: creating }] = useMutation(CREATE_COMMUNITY);
  const navigate = useNavigate();
  const { onError } = useContext(AppContext);

  const create = async (values) => {
    if (creating) return;
    await createCommunity({
      variables: {
        input: values
      }
    })
      .then(({ data }) => {
        navigate(`/c/${data?.createCommunity?.community?.slug}/welcome`);
      })
      .catch(err => onError(err.message));
  }

  if (creating) return <Creating />;

  return (
    <FormWrapper
      onCreate={create}
      creating={creating}
    />
  );
}, pipe(
  component => withAuthenticationRequired(component, {
    loginOptions: {
      authorizationParams: {
        screen_hint: 'signup',
      },
      appState: {
        returnTo: `/create`,
      },
    }
  }),
  propTypes({}),
  defaultProps({}),
  displayName('Create'),
  memo,
));
