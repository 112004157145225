import React from 'react';
import { remove } from 'ramda';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Option from 'components/Option';

export default function CategoriesForm({
  categories,
  setCategories,
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleCategorySelect = (category) => {
    if (categories.includes(category)) {
      const index = categories.indexOf(category);
      setCategories(remove(index, 1, categories));
    } else {
      setCategories([...categories, category]);
    }
  }

  return (
    <Box style={{ minWidth: isDesktop ? '394px' : '100%' }}>

      <Typography color='text.secondary' sx={{ mb: '10px' }}>Bike</Typography>
      <Option 
        selected={categories.includes('BIKE_ROAD')} 
        label='Road' 
        position='top' 
        onSelect={() => handleCategorySelect('BIKE_ROAD')} 
      />
      <Option 
        selected={categories.includes('BIKE_GRAVEL')} 
        label='Gravel' 
        position='center' 
        onSelect={() => handleCategorySelect('BIKE_GRAVEL')} 
      />
      <Option 
        selected={categories.includes('BIKE_MOUNTAIN')} 
        label='Mountain' 
        position='bottom' 
        onSelect={() => handleCategorySelect('BIKE_MOUNTAIN')} 
      />
      <Typography color='text.secondary' sx={{ mb: '10px', mt: '20px' }}>Moto</Typography>
      <Option 
        selected={categories.includes('MOTO_CLASSIC')} 
        label='Classic' 
        position='top' 
        onSelect={() => handleCategorySelect('MOTO_CLASSIC')} 
      />
      <Option 
        selected={categories.includes('MOTO_ADVENTURE')} 
        label='Adventure' 
        position='center' 
        onSelect={() => handleCategorySelect('MOTO_ADVENTURE')} 
      />
      <Option 
        selected={categories.includes('MOTO_ELECTRIC')} 
        label='Electric' 
        position='bottom' 
        onSelect={() => handleCategorySelect('MOTO_ELECTRIC')} 
      />
      <Typography color='text.secondary' sx={{ mb: '10px', mt: '20px' }}>Run</Typography>
      <Option 
        selected={categories.includes('RUN_ROAD')} 
        label='Road' 
        position='top' 
        onSelect={() => handleCategorySelect('RUN_ROAD')} 
      />
      <Option 
        selected={categories.includes('RUN_TRAIL')} 
        label='Trail' 
        position='bottom' 
        onSelect={() => handleCategorySelect('RUN_TRAIL')} 
      />

      <Option 
        selected={categories.includes('CLIMB')} 
        sx={{ mt: '30px' }} 
        label='Climb' 
        position='top' 
        onSelect={() => handleCategorySelect('CLIMB')} 
      />
      <Option 
        selected={categories.includes('CHALLENGE')} 
        label='Challenge' 
        position='center' 
        onSelect={() => handleCategorySelect('CHALLENGE')} 
      />
      <Option 
        selected={categories.includes('FISH')} 
        label='Fish' 
        position='center' 
        onSelect={() => handleCategorySelect('FISH')} 
      />
      <Option 
        selected={categories.includes('HIKE')} 
        label='Hike' 
        position='center' 
        onSelect={() => handleCategorySelect('HIKE')} 
      />
      <Option 
        selected={categories.includes('OVERLAND')} 
        label='Overland' 
        position='center' 
        onSelect={() => handleCategorySelect('OVERLAND')} 
      />
      <Option 
        selected={categories.includes('RUCK')} 
        label='Ruck' 
        position='center' 
        onSelect={() => handleCategorySelect('RUCK')} 
      />
      <Option
        selected={categories.includes('SURF')} 
        label='Surf' 
        position='bottom' 
        onSelect={() => handleCategorySelect('SURF')} 
      />
    </Box>
  )
}
