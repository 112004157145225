import { useMutation } from '@apollo/client';
import { SIGN_FILE_UPLOADS } from 'data/mutations/files';
import { equals, last, pipe, split, toUpper, when } from 'ramda';
import { useState } from 'react';

const mimeToFileType = pipe(
  split('/'),
  last,
  toUpper,
  when(equals('SVG+XML'), () => 'SVG')
)

const useMediaUpload = ({ onUploaded = () => null } = {}) => {
  const [signFileUploads] = useMutation(SIGN_FILE_UPLOADS);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const upload = async (files) => {
    const sizeErrors = files.map(file => {
      if (file.size > 10485760) {
        setError('File size is limited to 10mb');
        return 'File size is limited to 10mb'
      };
      return false;
    }).filter(Boolean);
    if (sizeErrors.length > 0) return { error: sizeErrors[0] };

    setLoading(true);
    const { data: urlData } = await signFileUploads({
      variables: {
        input: {
          files: files.map((file) => ({
            type: mimeToFileType(file?.type),
            // if file ID provided, will overwrite existing
            fileId: file?.fileId
          }), files)
        }
      }
    }).catch(() => {
      setError('Unable to upload files');
    });
    if (!urlData) return { error: 'Unable to upload file' };
    const newFiles = urlData?.signFileUploads?.files || [];

    const data = await Promise.all(newFiles.map(async (newFile, i) => {
      const formData = new FormData();
      formData.append('file', files[i]);
      const uploadRes = await fetch(newFile.signedUrl, {
        method: 'POST',
        body: formData,
      })
      if (!uploadRes.ok) {
        setError('Unable to upload files');
        return { error: 'Unable to upload file' };
      }
      onUploaded(newFile.file?.id);

      return uploadRes.json()
        .then(media => ({
          id: newFile.file?.id,
          publicId: media?.public_id,
          type: media?.resource_type
        }))
    }));
    setLoading(false);
    if (error) return { error };

    return { data };
  };

  return [
    // props
    upload,
    // state
    { loading, error }
  ];
}

export default useMediaUpload;