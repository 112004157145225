import { memo } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import EventPrivate from './EventPrivate';
import EventPublic from './EventPublic';
import useCustomAuth0 from 'hooks/useCustomAuth0';

export default applyTo(() => {
  const {
    isAuthenticated,
  } = useCustomAuth0();

  return isAuthenticated
    ? <EventPrivate />
    : <EventPublic />;
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('Event'),
  memo,
));