import { IconButton, Popover, useTheme } from '@mui/material';
import { Emoji } from 'emoji-picker-react';
import { REACTIONS_ARR } from 'lib/reactions';
import React from 'react';

const ReactionsPopover = ({ open, anchorEl, onClose, onSelect }) => {
  const theme = useTheme();
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        background: 'rgb(34, 34, 34, 0.5)',
        borderRadius: '10px',
      }}
      PaperProps={{
        sx: {
          background: theme.palette.overlay.secondary,
          borderRadius: '10px',
          maxWidth: '256px',
          p: 1
        }
      }}
    >
      {REACTIONS_ARR.map(([type, emoji]) => (
        <IconButton onClick={() => onSelect(type)} key={type}>
          <Emoji unified={emoji} />
        </IconButton>
      ))}
    </Popover>
  );
}

export default ReactionsPopover;