import { Typography, Stack } from "@mui/material";
import FlexCenter from "components/FlexCenter";

export default function Mobile() {
  return (
    <Stack style={{ backgroundColor: '#999999', height: '100vh', width: '100vw' }}>
      <FlexCenter>
        <Typography variant="h1">Mobile Auth Testing</Typography>
        <Typography variant="h1">Is Mobile: {window.IS_REACT_NATIVE ? 'true' : 'false'}</Typography>
        <Typography variant="h1">Platform: {window.PLATFORM || '-'}</Typography>
        <Typography variant="h1" style={{ maxWidth: '400px', width: '100%' }}>Token: {window.localStorage.getItem('token') || '-'}</Typography>
      </FlexCenter>
    </Stack>
  )
}