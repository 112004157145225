import React, { memo, useCallback, useContext, useState } from 'react';
import { pipe, applyTo, pick } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { Box, Button, Stack, Typography } from '@mui/material';
import withMemberRequired from 'components/withMemberRequired';
import CreateChannelDialog from 'components/CreateChannelDialog';
import NavLayout from 'components/NavLayout';
import ScrollView from 'components/ScrollView';
import PlusIcon from 'components/icons/PlusIcon';
import { PRIVATE_COMMUNITY } from 'data/queries/community';
import ChannelCard from './ChannelCard';
import BottomDrawer from 'components/BottomDrawer';
import ChannelForm from 'components/forms/ChannelForm';
import { AppContext } from 'context';
import { DELETE_CHANNEL, EDIT_CHANNEL } from 'data/mutations/channel';

export default applyTo(() => {
  const { community: slug } = useParams();
  const { onError } = useContext(AppContext);
  const [editOpen, setEditOpen] = useState(false);
  const [channel, setChannel] = useState({});
  const [addChannelOpen, setAddChannelOpen] = useState(false);
  const { data, refetch } = useQuery(PRIVATE_COMMUNITY, {
    variables: {
      slug,
    },
  });
  const channels = data?.whoami.community?.node.channels?.edges?.filter(channel => {
    return !['activity', 'events', 'media', 'start-here'].includes(channel?.node?.slug);
  });
  const community = data?.whoami?.community?.node;
  const isLead = data?.whoami?.community?.isLead;
  const [editChannel] = useMutation(EDIT_CHANNEL);
  const [deleteChannel] = useMutation(DELETE_CHANNEL);

  const handleSubmit = useCallback(async (values) => {
    await editChannel({
      variables: {
        input: {
          communityId: community?.id,
          channelId: channel?.id,
          ...values
        }
      }
    })
      .then(() => setEditOpen(false))
      .catch(err => onError(err.message))
  }, [editChannel, community, channel, onError]);

  const handleDelete = useCallback(async () => {
    await deleteChannel({
      variables: {
        input: {
          channelId: channel?.id,
          communityId: community?.id,
        }
      }
    })
      .then(() => {
        refetch();
        setEditOpen(false);
      })
      .catch(err => onError(err.message))
  }, [deleteChannel, channel, refetch, community, onError]);

  return (
    <NavLayout>
      <ScrollView
        top={<>
          <Typography variant="h2">Channels</Typography>
          {isLead && !community?.isPaused && (
            <Button
              variant='tertiary'
              onClick={() => setAddChannelOpen(true)}
            >
              <Stack direction='row' gap={1} alignItems='center'>
                New Channel
                <PlusIcon width={16} height={16} />
              </Stack>
            </Button>
          )}
        </>}
      >
        <Stack direction='column' gap={1}>
          {channels?.map(channel => (
            <ChannelCard
              channel={channel}
              community={community}
              onEdit={isLead && (() => {
                setEditOpen(true);
                setChannel(channel.node);
              })}
            />
          ))}
        </Stack>
      </ScrollView>
      {isLead && (
        <>
          <BottomDrawer
            isOpen={editOpen}
            onClose={() => setEditOpen(false)}
            title={<>Edit {channel?.slug}</>}
            size='large'
          >
            <Box sx={{ mt: 2 }}>
              <ChannelForm initialValues={{
                isPublic: !channel?.isPrivate,
                isOpen: !channel?.isOpen,
                isPublished: true,
                ...pick(['name', 'description', 'emoji', 'section'], channel)
              }} onSubmit={handleSubmit} onDelete={handleDelete} />
            </Box>
          </BottomDrawer>
          <CreateChannelDialog
            community={community}
            onComplete={() => {
              refetch();
              setAddChannelOpen(false);
            }}
            onClose={() => setAddChannelOpen(false)}
            isOpen={addChannelOpen}
          />
        </>
      )}
    </NavLayout>
  );
}, pipe(
  withMemberRequired,
  propTypes({}),
  defaultProps({}),
  displayName('Channels'),
  memo,
));
