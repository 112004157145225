import { Skeleton, Stack } from "@mui/material";

export default function LoadingList({ count = 3, sx, height = 189, ...props }) {
  return (
    <Stack direction='column' gap={2}>
      {[...Array(count)].map((_, i) => (
        <Skeleton
          key={i}
          height={height}
          variant="rounded"
          sx={{
            borderRadius: '20px',
            ...sx
          }}
          {...props}
        />
      ))}
    </Stack>
  )
}