import { fill } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";
import { useContext, useRef, useState } from "react";

import useMediaUpload from "hooks/useMediaUpload";
import { AppContext } from "context";
import BottomDrawer from "components/BottomDrawer";
import ImageDropZone from "components/ImageDropZone";
import FormButtons from "components/FormButtons";
import { Box, IconButton, LinearProgress, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useMutation } from "@apollo/client";
import { DELETE_FILE } from "data/mutations/files";
import { remove } from "ramda";

export default function UploadDialog({
  isOpen,
  onClose,
  onConfirm,
  width = 500,
  height = 500,
  ...props
}) {
  const [uploaded, setUploaded] = useState([]);
  const [uploadFiles, { loading }] = useMediaUpload({
    onUploaded: (id) => setUploaded([...uploaded, id])
  });
  const { cloudinary, onError } = useContext(AppContext);
  const dropzoneRef = useRef();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deleteFile] = useMutation(DELETE_FILE);

  const handleDelete = async (id) => {
    await deleteFile({
      variables: {
        input: {
          fileId: id
        }
      }
    }).then(() => {
      const index = uploadedFiles.findIndex(file => file.id === id);
      setUploadedFiles(remove(index, 1, uploadedFiles));
      setFiles(remove(index, 1, files));
      setUploaded(remove(index, 1, uploaded));
    }).catch(err => onError(err.message));
  }

  const handleCancel = async () => {
    await Promise.all(uploadedFiles.map(file => deleteFile({
      variables: {
        input: {
          fileId: file.id
        }
      }
    })));
    setFiles([]);
    setUploadedFiles([]);
    setUploaded([]);
    onClose();
  }

  const handleDrop = async acceptedFiles => {
    setFiles([...files, ...acceptedFiles]);
    const { data, error } = await uploadFiles(acceptedFiles)
    if (error) {
      onError(error);
      return;
    };
    setUploadedFiles([...uploadedFiles, ...data]);
  }

  const handleConfirm = () => {
    const images = uploadedFiles.map(image => ({
      ...image,
      url: cloudinary
        .image(image?.publicId)
        .resize(fill().width(width).height(height).gravity(autoGravity()))
        .toURL()
    }));

    onConfirm(images);
    setFiles([]);
    setUploadedFiles([]);
    setUploaded([]);
  }

  return (
    <BottomDrawer
      isOpen={isOpen}
      onClose={onClose}
    >
      <Stack gap={2} sx={{ pb: '68px' }}>
        <Box>
          <Typography variant='h3' sx={{ mb: 1 }}>Add Photo</Typography>
          <Typography
            variant='body2'
          >JPG and PNG files are supported</Typography>
        </Box>
        <ImageDropZone
          showPreview={false}
          showDesc={true}
          dropzoneRef={dropzoneRef}
          onDrop={handleDrop}
          maxFiles={10}
          width={isDesktop ? '520px' : '100%'}
          height={'220px'}
        />
        {loading && (
          <>
            <Typography>Loading...</Typography>
            <LinearProgress color='notify' />
          </>
        )}
        {files.map((file, i) => uploaded.includes(uploadedFiles[i]?.id) && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{
              borderRadius: '10px',
              background: theme.palette.transparentColors.light.seventy,
              padding: '10px 20px'
            }}
          >
            <Typography>{file.name}</Typography>
            <IconButton size='small' onClick={() => handleDelete(uploadedFiles[i]?.id)}>
              <RemoveIcon />
            </IconButton>
          </Stack>
        ))}
      </Stack>
      <FormButtons
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </BottomDrawer >
  );
}

function RemoveIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 2C6.97 2 2.5 6.47 2.5 12C2.5 17.53 6.97 22 12.5 22C18.03 22 22.5 17.53 22.5 12C22.5 6.47 18.03 2 12.5 2ZM15.09 8L12.5 10.59L9.91 8L8.5 9.41L11.09 12L8.5 14.59L9.91 16L12.5 13.41L15.09 16L16.5 14.59L13.91 12L16.5 9.41L15.09 8ZM4.5 12C4.5 16.41 8.09 20 12.5 20C16.91 20 20.5 16.41 20.5 12C20.5 7.59 16.91 4 12.5 4C8.09 4 4.5 7.59 4.5 12Z" fill="black" fill-opacity="0.5" />
    </svg>
  )
}