import { fill } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";
import Dropzone from 'react-dropzone';
import { useContext, useState } from "react";
import { head } from "ramda";

import useMediaUpload from "hooks/useMediaUpload";
import { AppContext } from "context";
import Image from "components/Image";
import { Box, Stack, Typography } from "@mui/material";
import UploadIcon from "components/icons/UploadIcon";

function Placeholder({ showDesc }) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <UploadIcon />
      {showDesc && <Typography sx={{ mt: 1 }} variant='body2'>
        Drag and drop here, or browse
      </Typography>}
    </Box>
  );
}

export default function ImageDropZone({
  dropzoneRef,
  showPreview = true,
  initialFile,
  children,
  onDrop,
  onComplete,
  width = 500,
  height = 500,
  maxFiles = 1,
  showDesc = false,
  sx,
  className,
  onClick = () => { },
  placeholder,
  ...props
}) {
  const [uploadFiles] = useMediaUpload();
  const { cloudinary, onError } = useContext(AppContext);
  const [src, setSrc] = useState(initialFile?.url);

  const handleDrop = async acceptedFiles => {
    const { data, error } = await uploadFiles(acceptedFiles)
    if (error) {
      onError(error);
      return;
    }
    const newFile = head(data);
    if (!newFile || !newFile.id) {
      onError('Unable to upload image. Please try again.');
      return;
    }

    const url = cloudinary
      .image(newFile?.publicId)
      .resize(fill().width(width).height(height).gravity(autoGravity()))
      .toURL();

    if (showPreview) setSrc(url);
    onComplete({ ...newFile, url });
  }

  return (
    <Dropzone
      ref={dropzoneRef}
      onDrop={onDrop || handleDrop}
      accept={{
        "image/*": [],
      }}
      maxFiles={maxFiles}
      {...props}
    >
      {({ getRootProps, getInputProps }) => {
        return (<div {...getRootProps()}
          style={{ cursor: 'pointer', alignSelf: 'center' }}
          className={className}
          ref={dropzoneRef}
        >
          <input {...getInputProps()} />
          {children}
          {!children && (
            <Stack
              sx={{
                width,
                height,
                maxWidth: '100%',
                border: src ? '' : '1px solid #00000033',
                borderRadius: '10px',
                my: 2,
                mx: 'auto',
                ...sx
              }}
              justifyContent='center'
              alignItems='center'
              onClick={() => onClick(initialFile)}
            >
              {(initialFile?.url || src)
                ? <Image
                  src={initialFile?.url || src}
                  sx={{ width: 1, height: 1, objectFit: 'cover', borderRadius: 2 }}
                />
                : placeholder || <Placeholder
                  showDesc={showDesc}
                />
              }
            </Stack>
          )}
        </div>
        );
      }}
    </Dropzone>
  );
}
