import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { Typography, Button, Box, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Stack } from '@mui/system';

import UnreadIcon from 'components/icons/UnreadIcon';
import UserAvatar from 'components/UserAvatar';
import SearchIcon from 'components/icons/SearchIcon';
import { WHO_AM_I } from 'data/queries/users';
import LoadingList from 'components/LoadingList';

export default applyTo(() => {
  const { data, loading } = useQuery(WHO_AM_I);
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Stack sx={{ m: '30px' }}>
      <Box>
        <Button
          variant='tertiary'
          component={Link}
          to={`/messages`}
          endIcon={<SearchIcon />}
        >Find or start a conversation</Button>
      </Box>
      <Stack sx={{ mt: 2 }} gap={1}>
        {loading && <LoadingList count={5} sx={{ borderRadius: '8px' }} height={70} />}
        {!loading && (data?.whoami?.channels?.edges || [])
          .map(({ node }) => {
            const { name, unreadMessagesCount, id, slug } = node;
            const members = node?.members?.edges?.filter(({ node }) => {
              return node?.id !== data?.whoami?.id;
            });
            return (
              <Box
                key={id}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(`/messages/${slug}`);
                }}
                sx={{
                  backgroundColor: theme.palette.overlay.contrast,
                  px: '25px',
                  py: '15px',
                  borderRadius: '8px',
                }}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  <UserAvatar
                    src={members[0]?.node?.avatar?.url}
                    variant='circular'
                  />
                  <Typography>{name}</Typography>
                  {unreadMessagesCount > 0 && <UnreadIcon />}
                </Stack>
              </Box>
            )
          }
          )}
      </Stack>
    </Stack>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('MessageList'),
  memo,
));
