import {
  FormControlLabel,
  MenuList,
  Stack,
  Typography,
  Divider,
  Checkbox,
  Button,
} from "@mui/material";
import { Form, Formik } from "formik";
import { pick } from "ramda";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import FormButtons from "components/FormButtons";

import BottomDrawer from "components/BottomDrawer/";

export default function SettingsMenu({
  open,
  onClose,
  emailNotificationSettings,
  onChangePassword,
  onDeleteAccount,
  onSubmit,
  onLogout,
  loading,
}) {
  return (
    <BottomDrawer isOpen={open} title="Settings" onClose={onClose}>
      <Stack sx={{ mx: 3, overflow: "auto", paddingBottom: 5 }}>
        <MenuList>
          <Typography variant="h2" sx={{ mt: 0, mb: 1 }}>
            Notifications
          </Typography>
          <Formik
            initialValues={emailNotificationSettings}
            onSubmit={async (values, { setSubmitting, isSubmitting }) => {
              if (isSubmitting) return;

              await onSubmit({
                emailNotifications: pick(
                  [
                    "directMessages",
                    "groupMessageSummary",
                    "directMention",
                    "communityAnnouncements",
                    "communityUpdate",
                  ],
                  values
                ),
              });
              setSubmitting(false);
            }}
          >
            {({ setFieldValue, resetForm, values, handleSubmit, ...props }) => (
              <Form onSubmit={handleSubmit} style={{ position: "relative" }}>
                <div
                  style={{
                    backgroundColor: "rgba(255,255,255,.7)",
                    padding: "20px 30px 80px",
                    borderRadius: "30px",
                    gap: "15px",
                    marginBottom: "20px",
                    position: "relative",
                  }}
                >
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                        checked={values.directMessages}
                        onChange={(event) => {
                          setFieldValue("directMessages", event.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ pl: 0 }}>
                        <b>Direct Messages</b> when they are sent
                      </Typography>
                    }
                    labelPlacement="start"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  />
                  <Divider />
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                        checked={values.groupMessageSummary}
                        onChange={(event) =>
                          setFieldValue(
                            "groupMessageSummary",
                            event.target.checked
                          )
                        }
                      />
                    }
                    label={
                      <Typography sx={{ pl: 0 }}>
                        <b>Group Message Summary</b> sent daily
                      </Typography>
                    }
                    labelPlacement="start"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  />
                  <Divider />

                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                        checked={values.directMention}
                        onChange={(event) =>
                          setFieldValue("directMention", event.target.checked)
                        }
                      />
                    }
                    label={
                      <Typography sx={{ pl: 0 }}>
                        <b>When you are @mentioned</b> as they occur
                      </Typography>
                    }
                    labelPlacement="start"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  />
                  <Divider />

                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                        checked={values.communityAnnouncements}
                        onChange={(event) =>
                          setFieldValue(
                            "communityAnnouncements",
                            event.target.checked
                          )
                        }
                      />
                    }
                    label={
                      <Typography sx={{ pl: 0 }}>
                        <b>Community announcements (@all)</b> as they occur
                      </Typography>
                    }
                    labelPlacement="start"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  />
                  <Divider />

                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                        checked={values.communityUpdate}
                        onChange={(event) =>
                          setFieldValue("communityUpdate", event.target.checked)
                        }
                      />
                    }
                    label={
                      <Typography sx={{ pl: 0 }}>
                        <b>Community update</b> sent weekly
                      </Typography>
                    }
                    labelPlacement="start"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  />
                </div>
                <FormButtons
                  loading={loading}
                  onCancel={() => {
                    resetForm();
                    onClose();
                  }}
                />
              </Form>
            )}
          </Formik>

          <Divider />
          <Typography variant="h2" sx={{ mt: 3, mb: 1 }}>
            Contact Us
          </Typography>
          <Button
            sx={{
              pl: 0,
              backgroundColor: "white",
              marginBottom: "3%",
              color: "black",
              padding: "10px 20px",
              borderRadius: "8px",
              marginRight: "10px",
              textTransform: "none",
            }}
          >
            <a
              href="mailto:support@letsrally.io"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              Get Support
            </a>
          </Button>
          <Button
            sx={{
              pl: 0,
              backgroundColor: "white",
              marginBottom: "3%",
              color: "black",
              padding: "10px 20px",
              borderRadius: "8px",
              marginRight: "10px",
              textTransform: "none",
            }}
          >
            <a
              href="mailto:feedback@letsrally.io"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black", textAlign: "center" }}
            >
              Share Feedback
            </a>
          </Button>
          <Divider />
          {/* <MenuItem sx={{ pl: 0 }}>
            <a href='ideas@letsrally.io' target='_blank' rel='noopener noreferrer'>
              Share an Idea
            </a>
          </MenuItem> */}
          <Typography variant="h2" sx={{ mt: 3, mb: 1 }}>
            Account
          </Typography>
          <Button
            sx={{
              pl: 0,
              backgroundColor: "white",
              marginBottom: "3%",
              color: "black",
              padding: "10px 20px",
              borderRadius: "8px",
              marginRight: "10px",
              textTransform: "none",
            }}
            onClick={onLogout}
          >
            Logout
          </Button>
          <Button
            sx={{
              pl: 0,
              backgroundColor: "white",
              marginBottom: "3%",
              color: "black",
              padding: "10px 20px",
              borderRadius: "8px",
              marginRight: "10px",
              textTransform: "none",
            }}
            onClick={onChangePassword}
          >
            Change Password
          </Button>
          <Button
            sx={{
              pl: 0,
              backgroundColor: "white",
              marginBottom: "3%",
              color: "black",
              padding: "10px 20px",
              borderRadius: "8px",
              marginRight: "10px",
              textTransform: "none",
            }}
            onClick={onDeleteAccount}
          >
            Delete Account
          </Button>
          <Divider />
          <Typography variant="h2" sx={{ mt: 3, mb: 1 }}>
            Documents
          </Typography>
          <Button
            sx={{
              pl: 0,
              backgroundColor: "white",
              marginBottom: "3%",
              color: "black",
              padding: "10px 20px",
              borderRadius: "8px",
              marginRight: "10px",
              textTransform: "none",
            }}
          >
            <a
              href="https://www.letsrally.io/code-of-conduct"
              target="_blank"
              rel="noreferrer"
            >
              Code of Conduct
            </a>
          </Button>
          <Button
            sx={{
              pl: 0,
              backgroundColor: "white",
              marginBottom: "3%",
              color: "black",
              padding: "10px 20px",
              borderRadius: "8px",
              marginRight: "10px",
              textTransform: "none",
            }}
          >
            <a
              href="https://www.letsrally.io/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </Button>
          <Button
            sx={{
              pl: 0,
              backgroundColor: "white",
              marginBottom: "3%",
              color: "black",
              padding: "10px 20px",
              borderRadius: "8px",
              marginRight: "10px",
              textTransform: "none",
            }}
          >
            <a
              href="https://www.letsrally.io/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </Button>
        </MenuList>
      </Stack>
    </BottomDrawer>
  );
}
