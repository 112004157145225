import { gql } from "@apollo/client";

export const DELETE_FILE = gql`
mutation DeleteFile($input: DeleteFileInput!) {
  deleteFile(input: $input) {
    success
  }
}
`;

export const DELETE_ALBUM = gql`
mutation DeleteAlbum($input: DeleteAlbumInput!) {
  deleteAlbum(input: $input) {
    success
  }
}
`;

export const SIGN_FILE_UPLOADS = gql`
  mutation SignFileUploads($input: SignFileUploadsInput!) {
    signFileUploads(input: $input) {
      files {
        signedUrl
        file { id, type }
      }
    }
  }
`;