import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from 'context';
import { findIndex, groupBy, map, omit, path, pathOr, prop, propOr, remove, replace, update, zipWith, reverse } from 'ramda';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { USERS } from 'data/queries/users';

export const CHAT_LIMIT = 30;

const useChatRoom = (chatId, limit = CHAT_LIMIT, direction = 'backwards') => {
  const { chat, onError } = useContext(AppContext);
  const [chatRoom, setChatRoom] = useState();
  const [messages, setMessages] = useState([]);
  const [search, setSearch] = useState();
  const [threads, setThreads] = useState({});
  const [loading, setLoading] = useState(!chatRoom);
  const [loadingPrev, setLoadingPrev] = useState(false);
  const [paginator, setPaginator] = useState({
    hasPrevPage: false
  });
  const { hash } = useLocation();
  const [getUsers] = useLazyQuery(USERS);

  const messageAdded = useCallback(async (msg) => {
    const userData = await getUsers({
      variables: {
        ids: [msg.author]
      }
    }).then(path(['data', 'nodes', 0]));
    msg.state.userData = userData;
    if (msg.attributes?.replyTo) {
      setThreads(t => ({
        ...t,
        [msg.attributes?.replyTo]: (t[msg.attributes?.replyTo] || []).concat(msg)
      }));
    } else {
      setMessages(m => m.concat([msg]));
    }
  }, [getUsers]);

  const messageUpdated = useCallback(async ({ message, updateReasons = [] }) => {
    if (updateReasons.includes('body') || updateReasons.includes('attributes')) {
      const userData = await getUsers({
        variables: {
          ids: [message.author]
        }
      }).then(path(['data', 'nodes', 0]));
      message.state.userData = userData;
      if (message.attributes?.replyTo) {
        const thread = threads[message.attributes?.replyTo] || [];
        const msgIdx = findIndex(msg => msg.sid === message.sid, thread);
        if (msgIdx >= 0) setThreads(t => ({
          ...t,
          [message.attributes?.replyTo]: update(msgIdx, message, thread)
        }));
      } else {
        const msgIdx = findIndex(msg => msg.sid === message.sid, messages);
        if (msgIdx >= 0) setMessages(update(msgIdx, message));
      }
    }
  }, [messages, threads, getUsers]);

  const messageRemoved = useCallback((msg) => {
    if (msg.attributes?.replyTo) {
      const thread = threads[msg.attributes?.replyTo] || [];
      const msgIdx = findIndex(message => message.sid === msg.sid, thread);
      if (msgIdx >= 0) setThreads(t => ({
        ...t,
        [msg.attributes?.replyTo]: remove(msgIdx, 1, thread)
      }));
    } else {
      const msgIdx = findIndex(message => msg.sid === message.sid, messages);
      if (msgIdx >= 0) setMessages(remove(msgIdx, 1));
    }
  }, [messages, threads]);

  const setupChatRoom = useCallback(async (chatRoom) => {
    setChatRoom(chatRoom);
    if (limit > 0) {
      await chatRoom.getMessages(limit, undefined, direction)
        .then(async msgs => {
          const userData = await getUsers({
            skip: !msgs?.items?.length,
            variables: {
              ids: map(prop('author'), msgs?.items || [])
            }
          });
          const messages = zipWith(
            (msg, author) => {
              msg.state.userData = author;
              return msg;
            },
            pathOr([], ['items'], msgs),
            pathOr([], ['data', 'nodes'], userData)
          )
          const grouped = groupBy(msg => {
            if (hash && msg.sid === replace('#', '', hash)) setSearch(msg);
            return msg.attributes?.replyTo || 'topLevel'
          }, messages);
          setMessages(propOr([], 'topLevel', grouped));
          setThreads(omit(['topLevel'], grouped));
          setPaginator(msgs);
          setLoading(false);
          chatRoom.setAllMessagesRead();
        })
        .catch(err => {
          onError('Unable to load chat messages');
        });
    }
    setLoading(false);
  }, [limit, getUsers, hash, direction, onError]);

  useEffect(() => {
    chatRoom?.on('messageAdded', messageAdded);
    chatRoom?.on('messageUpdated', messageUpdated);
    chatRoom?.on('messageRemoved', messageRemoved);

    return () => {
      chatRoom?.off('messageAdded', messageAdded);
      chatRoom?.off('messageUpdated', messageUpdated);
      chatRoom?.off('messageRemoved', messageRemoved);
    };
  }, [chatRoom, messageUpdated, messageRemoved, messageAdded]);

  useEffect(() => {
    setLoading(true);
    if (!chat || !chatId) return;
    chat.getConversationBySid(chatId)
      .then(setupChatRoom)
      .catch(err => {
        onError('Unable to load chat room');
      });
  }, [chat, chatId, setupChatRoom, onError])

  const loadPrevMessages = useCallback((onComplete) => {
    if (paginator.hasPrevPage) {
      setLoadingPrev(true);
      paginator.prevPage()
        .then(async msgs => {
          const userData = await getUsers({
            variables: {
              ids: map(prop('author'), msgs?.items || [])
            }
          })
          const messages = zipWith(
            (msg, author) => {
              msg.state.userData = author;
              return msg;
            },
            pathOr([], ['items'], msgs),
            pathOr([], ['data', 'nodes'], userData)
          )
          const grouped = groupBy(msg => {
            if (hash && msg.sid === replace('#', '', hash)) setSearch(msg);
            return msg.attributes?.replyTo || 'topLevel'
          }, messages);
          setMessages(m => [...propOr([], 'topLevel', grouped), ...m]);
          setThreads(t => ({ ...omit(['topLevel'], grouped), ...t }));
          setPaginator(msgs);
          onComplete?.();
          setLoadingPrev(false);
        })
        .catch(err => {
          onError('Unable to load previous messages');
          setLoadingPrev(false);
        });
    }
  }, [paginator, getUsers, hash, onError]);

  return [
    // props
    Object.assign((chatRoom || {}), {
      search,
      messages: reverse(messages),
      threads,
      hasPrevPage: paginator?.hasPrevPage,
      loadPrevMessages,
    }),
    // state
    { loading, loadingPrev, hasPrevPage: paginator.hasPrevPage }
  ];
}

export default useChatRoom;