import {motion} from "framer-motion";
import {Box} from "@mui/material";
import {useNavbar} from "../../context/NavBarContext";

const MotionBox = motion(Box);

export default function RetractableHeader({children, style, ...other}) {
  const {retraction} = useNavbar();

  return (
    <MotionBox
      {...other}
      style={{
        ...style,
        y: retraction,
      }}
    >
      {children}
    </MotionBox>
  )
}