import { memo, useContext, useEffect, useState } from 'react';
import { Button, Divider, MenuItem, MenuList, Stack, TextField, Typography } from '@mui/material'
import { pipe, applyTo, pathOr, last, find } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { AppContext } from 'context';
import MemberRow from 'components/MemberRow';
import BottomDrawer from 'components/BottomDrawer';

import SearchIcon from 'components/icons/SearchIcon';
import ScrollView from 'components/ScrollView';
import { SEARCH_COMMUNITY_MEMBERS } from 'data/queries/community';
import { COMMUNITY_PRIVATE_CHANNEL_MEMBERS, COMMUNITY_PUBLIC_CHANNEL_MEMBERS } from 'data/queries/channel';
import { ADD_CHANNEL_MEMBERS, REMOVE_CHANNEL_MEMBERS } from 'data/mutations/channel';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(({ community, channel, isAdmin }) => {
  const [searchTerm, setSearchTerm] = useState();
  const [addOpen, setAddOpen] = useState(false);
  const [manageOpen, setManageOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [confirm, setConfirm] = useState(false);
  const { onError } = useContext(AppContext);
  const [searchUsers, { data: searchData, loading: searching }] = useLazyQuery(SEARCH_COMMUNITY_MEMBERS);
  const [addChannelMembers] = useMutation(ADD_CHANNEL_MEMBERS);
  const [removeChannelMembers] = useMutation(REMOVE_CHANNEL_MEMBERS);
  const {
    data: privateMemberData,
    // loading: privateMembersLoading,
    fetchMore: fetchMorePrivateMembers,
  } = useQuery(COMMUNITY_PRIVATE_CHANNEL_MEMBERS, {
    variables: {
      communitySlug: community?.slug,
      channelSlug: channel?.slug
    },
  })
  const {
    data: publicMemberData,
    // loading: publicMembersLoading,
    fetchMore: fetchMorePublicMembers
  } = useQuery(COMMUNITY_PUBLIC_CHANNEL_MEMBERS, {
    variables: {
      communitySlug: community?.slug,
    },
  })

  const members = channel?.isPrivate
    ? privateMemberData?.whoami.community?.node.channel?.node.members?.edges || []
    : publicMemberData?.whoami.community?.node?.members?.edges || [];
  const hasNextPage = channel?.isPrivate
    ? privateMemberData?.whoami.community?.node.channel?.node.members?.pageInfo.hasNextPage
    : publicMemberData?.whoami.community?.node?.members?.pageInfo.hasNextPage

  useEffect(() => {
    if (!searchTerm || searchTerm?.length < 2 || searching) return;

    searchUsers({
      variables: {
        input: {
          searchTerm,
          communityId: community?.id,
          first: 5
        },
        communityId: community?.id
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community?.id, searchTerm])

  const handleAddMember = (member) => {
    return addChannelMembers({
      variables: {
        input: {
          channelId: channel?.id,
          userIds: [member.id]
        }
      }
    })
      .catch(err => onError(err.message))
  }

  const handleRemoveMember = () => {
    return removeChannelMembers({
      variables: {
        input: {
          channelId: channel?.id,
          userIds: [selectedUser?.id]
        }
      }
    })
      .then(() => {
        setManageOpen(false);
        setSelectedUser();
        setConfirm(false);
      })
      .catch(err => onError(err.message))
  }

  const handleMoreClick = () => {
    channel?.isPrivate
      ? fetchMorePrivateMembers({
        variables: {
          after: last(members)?.cursor
        }
      })
      : fetchMorePublicMembers({
        variables: {
          after: last(members)?.cursor
        }
      })
  }

  return (
    <ScrollView>
      <Stack
        direction='row'
        gap={1}
        alignItems='center'
        justifyContent='space-between'
        flexWrap='wrap'
        sx={{ pt: '20px' }}
      >
        <Typography variant='h3'>Channel Members</Typography>
        {isAdmin && channel?.isPrivate && <Button
          variant='tertiary'
          onClick={() => {
            setAddOpen(true)
          }}
        >
          <Stack direction='row' gap={1} alignItems='center'>
            Add member to this channel
            <SearchIcon />
          </Stack>
        </Button>}
      </Stack>
      <Stack sx={{ flexGrow: 1 }}>
        <Stack>
          {members.map((m) => {
            if (!m) return null;
            return (
              <MemberRow
                member={m?.node}
                onOpenActions={isAdmin ? () => {
                  setSelectedUser(m?.node);
                  setManageOpen(true);
                } : null}
              />
            )
          })}
          {hasNextPage && <Button onClick={handleMoreClick}>
            More
          </Button>}
        </Stack>
      </Stack>
      <BottomDrawer
        isOpen={manageOpen}
        onClose={() => {
          setManageOpen(false);
          setSelectedUser();
          setConfirm(false);
        }}
      >
        {confirm ? (
          <MenuList
            direction="column"
            gap={2}
          >
            <Typography
              sx={{ mb: 2 }}
            >Are you sure you want to remove member?
            </Typography>
            <MenuItem onClick={handleRemoveMember}>Confirm</MenuItem>
            <Divider sx={{ mt: 2 }} />
            <MenuItem onClick={() => {
              setManageOpen(false);
              setSelectedUser();
              setConfirm(false);
            }}>Cancel</MenuItem>
          </MenuList>
        ) : (
          <MenuList
            direction="column"
            gap={2}
          >
            <MenuItem onClick={() => {
              setConfirm(true);
            }}>Remove</MenuItem>
          </MenuList>
        )}
      </BottomDrawer>
      <BottomDrawer
        isOpen={addOpen}
        onClose={() => {
          setAddOpen(false);
        }}
        title='Search Members'
      >
        <TextField
          variant="outlined"
          autoFocus
          fullWidth
          sx={{
            margin: '20px 0'
          }}
          onChange={(evt) => setSearchTerm(evt.target.value)}
        />
        {pathOr([], ['searchUsers', 'edges'], searchData).map((m) => {
          return (
            <MemberRow
              member={m?.node}
              onAddMember={() => handleAddMember(m?.node)}
              isAdded={!!find((member) => {
                return member?.node?.id === m?.node?.id
              }, members)}
            />
          )
        })}
      </BottomDrawer>
    </ScrollView>
  );
}, pipe(
  withAuthenticationRequired,
  propTypes({}),
  defaultProps({}),
  displayName('Members'),
  memo,
));
