import { memo, useCallback, useContext, useRef } from 'react';
import { Box, Button, Divider, useMediaQuery, useTheme } from '@mui/material'
import { pipe, applyTo } from 'ramda';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { logEvent } from 'firebase/analytics';

import { AppContext } from 'context';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { analytics } from 'lib/firebase';

import EventForm from 'components/forms/EventForm';
import ScrollView from 'components/ScrollView';
import { COMMUNITY_EVENTS_QUERY } from 'data/queries/event';
import { CREATE_EVENT } from 'data/mutations/event';
import withLeadRequired from 'components/withLeadRequired';
import BackHeader from 'components/BackHeader';

export default applyTo(() => {
  const { onError } = useContext(AppContext);
  const { community: slug } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const formRef = useRef();

  const { data, error } = useQuery(COMMUNITY_EVENTS_QUERY, {
    variables: {
      slug,
      input: {
        filter: 'UPCOMING',
      }
    }
  });
  const [createEvent, { loading: creating }] = useMutation(CREATE_EVENT);

  const handleCreateEvent = useCallback((values) => {
    if (creating) return;
    createEvent({
      variables: {
        input: {
          ...values,
          communityId: data?.whoami.community?.node?.id,
        }
      }
    })
      .then((eventData) => {
        logEvent(analytics, 'create_event', {
          eventSlug: eventData?.event?.slug,
          communitySlug: data?.whoami.community?.node?.slug
        });
        navigate(`/c/${slug}/events`);
      })
      .catch(err => onError(err.message))
  }, [createEvent, data, onError, creating, navigate, slug]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  if (error) return onError(error.message);

  return (
    <ScrollView
      top={<BackHeader
        pageTitle={'New Event'}
        right={isDesktop && <Button
          variant='contained'
          fullWidth
          onClick={handleSubmit}
          disabled={creating}
        >{'Create'}</Button>}
        to={'/community'}
      />}
      bottomHeight={96}
      bottom={!isDesktop && <Box sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#F8F8F8',
      }}>
        <Divider />
        <Box sx={{ p: '20px' }}>
          <Button
            variant='regular'
            fullWidth
            onClick={handleSubmit}
            style={{ width: '100%' }}
            disabled={creating}
          >{'Create'}</Button>
        </Box>
      </Box>}
    >
      <Box sx={{
        maxWidth: '700px',
        margin: '0 auto',
        paddingTop: isDesktop ? '40px' : '8px'
      }}>
        <EventForm
          communitySlug={data?.whoami.community?.node?.slug}
          onSubmit={handleCreateEvent}
          formRef={formRef}
        />
      </Box>
    </ScrollView>
  );
}, pipe(
  withLeadRequired,
  propTypes({}),
  defaultProps({}),
  displayName('CreateEvent'),
  memo,
));
