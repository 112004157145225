import { Button } from "@mui/material";
import SingleCommunityForm, { CommunitySchema, INITIAL_VALUES } from "components/forms/SingleCommunityForm";
import { Formik } from "formik";
import { evolve, filter, map, omit, pathOr, pick, toLower } from "ramda";

const FormWrapper = ({ initialValues, onUpdate, updating }) => {

  const handleSave = async (values) => {
    const updates = {
      ...pick(
        ['title', 'slug', 'description', 'locations', 'categories',
          'logoId', 'yearFounded', 'type', 'bannerIds', 'isPrivate'],
        values
      ),
      type: values.type === 'OTHER'
        ? values.otherType?.toUpperCase()
        : values.type,
      cover: values.coverId ? {
        type: 'IMAGE',
        asset: values.coverId
      } : null,
      onboardingQuestions: values.isPrivate
        ? (values.onboardingQuestions || [])
          .filter(q => !!q.question)
          .map(pick(['type', 'question']))
        : []
    }

    return onUpdate(evolve({
      slug: toLower,
      bannerIds: filter(Boolean)
    }, updates));
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={CommunitySchema}
      initialValues={initialValues ? {
        ...initialValues,
        locations: map(omit(["__typename"]), pathOr([], ["locations"], initialValues)),
        coverId: pathOr(null, ['cover', 'asset', 'id'], initialValues),
        cover: pathOr(null, ['cover', 'asset'], initialValues),
      } : INITIAL_VALUES}
      onSubmit={async (values, { isSubmitting, resetForm }) => {
        if (isSubmitting) return;
        await handleSave(values);
        resetForm({ values });
      }}
    >
      {(props) => (
        <>
          <SingleCommunityForm {...props} editSlug={true} />
          <Button
            variant='regular'
            fullWidth
            onClick={props.handleSubmit}
            style={{ marginTop: 30 }}
            disabled={updating}
          >{'Save'}</Button>
        </>
      )}
    </Formik>
  )
}

export default FormWrapper;