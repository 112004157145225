import { Button, Stack, Typography } from "@mui/material";
import BottomDrawer from "components/BottomDrawer";

export default function ConfirmMenu({ isOpen, onClose, label, onSubmit }) {
  return (
    <BottomDrawer
      isOpen={isOpen}
      onClose={onClose}
    >
      <Stack
        direction="column"
        gap={1}
      >
        {label && <Typography sx={{ mb: 2 }}>{label}</Typography>}
        <Button
          variant='regular'
          fullWidth
          onClick={onSubmit}
          style={{ width: '100%' }}
        >{'Confirm'}</Button>
        <Button
          variant='regular'
          fullWidth
          onClick={onClose}
          style={{ width: '100%' }}
        >{'Cancel'}</Button>
      </Stack>
    </BottomDrawer>
  )
}