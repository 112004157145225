import { memo, useContext, useEffect } from 'react';
import { pipe, applyTo, path, pathEq } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Progress from 'components/Progress';
import { PRIVATE_COMMUNITY } from 'data/queries/community';
import { UPDATE_COMMUNITY_APPLICATION } from 'data/mutations/members';
import { AppContext } from 'context';


export default applyTo(() => {
  const { community: slug } = useParams();
  const location = useLocation();
  const { onError } = useContext(AppContext);

  const navigate = useNavigate();
  const [updateCommunityApplication, {
    loading: updateInProgress,
  }] = useMutation(UPDATE_COMMUNITY_APPLICATION);

  const { data, loading, error } = useQuery(PRIVATE_COMMUNITY, {
    variables: {
      slug
    },
  });

  useEffect(() => {
    const updateApplication = async () => {
      const communityId = path(['whoami', 'community', 'node', 'id'], data);
      const queryParams = new URLSearchParams(location.search);
      const userId = queryParams.get('userId');
      const status = queryParams.get('status');
      if (communityId && userId && status) {
        await updateCommunityApplication({
          variables: {
            input: {
              communityId,
              userId,
              status
            }
          }
        })
          .then(() => navigate(`/c/${slug}/channel/activity`))
          .catch(err => onError(err.message))
      } else {
        navigate(`/c/${slug}/channel/activity`)
      }
    }
    if (updateInProgress) return;
    const isLead = pathEq(['whoami', 'community', 'isLead'], true, data);
    if (!loading && !error && data && isLead) {
      updateApplication()
    }
    if (error) {
      navigate(`/c/${slug}/channel/activity`)
    }
  }, [updateInProgress, data, navigate, slug, updateCommunityApplication, location, loading, error, onError]);

  return <Progress />;
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('Application'),
  memo,
));
