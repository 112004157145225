import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = 'black' }) => {
    return (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0684 2.45383H14.0007V1.16912C14.0007 0.801104 13.7004 0.5 13.3333 0.5C12.9663 0.5 12.666 0.801104 12.666 1.16912V2.45383H7.32733V1.16912C7.32733 0.801104 7.02703 0.5 6.65999 0.5C6.29296 0.5 5.99266 0.801104 5.99266 1.16912V2.45383H4.92493C2.21555 2.45383 0 4.66862 0 7.39863V15.5552C0 18.2852 2.21555 20.5 4.9316 20.5H15.0684C17.7911 20.5 20 18.2785 20 15.5552V7.39863C20 4.66862 17.7845 2.45383 15.0684 2.45383ZM4.9316 3.79207H5.99933V5.27752C5.99933 5.64553 6.29963 5.94664 6.66667 5.94664C7.0337 5.94664 7.334 5.64553 7.334 5.27752V3.79207H12.6727V5.27752C12.6727 5.64553 12.973 5.94664 13.34 5.94664C13.707 5.94664 14.0073 5.64553 14.0073 5.27752V3.79207H15.0751C17.0571 3.79207 18.672 5.41134 18.672 7.39863V9.0915H1.33467V7.39863C1.33467 5.41134 2.94962 3.79207 4.9316 3.79207ZM15.0684 19.1685H4.9316C2.94962 19.1685 1.33467 17.5492 1.33467 15.5619V10.4364H18.672V15.5619C18.672 17.5492 17.0571 19.1685 15.0751 19.1685H15.0684Z" fill={color} />
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('EventsIcon'),
    memo,
));
