import { Box, Button, Divider, useMediaQuery, useTheme } from "@mui/material";
import BackHeader from "components/BackHeader";
import ScrollView from "components/ScrollView";
import SingleCommunityForm, { CommunitySchema, INITIAL_VALUES } from "components/forms/SingleCommunityForm";
import { Formik } from "formik";
import { evolve, filter, map, omit, pathOr, pick, toLower } from "ramda";


const FormWrapper = ({ initialValues, onUpdate, onDelete, updating }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleSave = async (values) => {
    const updates = {
      ...pick(
        ['title', 'slug', 'description', 'locations', 'categories',
          'logoId', 'yearFounded', 'type', 'bannerIds', 'isPrivate'],
        values
      ),
      type: values.type === 'OTHER'
        ? values.otherType?.toUpperCase()
        : values.type,
      cover: values.coverId ? {
        type: 'IMAGE',
        asset: values.coverId
      } : null,
      onboardingQuestions: values.isPrivate
        ? (values.onboardingQuestions || [])
          .filter(q => !!q.question)
          .map(pick(['type', 'question']))
        : []
    }

    return onUpdate(evolve({
      slug: toLower,
      bannerIds: filter(Boolean)
    }, updates));
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={CommunitySchema}
      initialValues={initialValues ? {
        ...initialValues,
        locations: map(omit(["__typename"]), pathOr([], ["locations"], initialValues)),
        coverId: pathOr(null, ['cover', 'asset', 'id'], initialValues),
        cover: pathOr(null, ['cover', 'asset'], initialValues),
      } : INITIAL_VALUES}
      onSubmit={async (values, { isSubmitting, resetForm }) => {
        if (isSubmitting) return;
        await handleSave(values);
        resetForm({ values });
      }}
    >
      {(props) => (
        <ScrollView
          top={<BackHeader
            pageTitle={'Manage Group'}
            right={isDesktop && <Button
              variant='contained'
              fullWidth
              onClick={props.handleSubmit}
              disabled={updating}
            >{'Save'}</Button>}
            to={`/c/${initialValues.slug}`}
          />}
          bottomHeight={96}
          bottom={!isDesktop && <Box sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#F8F8F8',
          }}>
            <Divider />
            <Box sx={{ p: '20px' }}>
              <Button
                variant='regular'
                fullWidth
                onClick={props.handleSubmit}
                style={{ width: '100%' }}
                disabled={updating}
              >{'Save'}</Button>
            </Box>
          </Box>}
        >
          <Box sx={{
            maxWidth: '700px',
            margin: '0 auto',
            paddingTop: isDesktop ? '40px' : '8px'
          }}>
            <SingleCommunityForm {...props} editSlug={false} onDelete={onDelete} />
          </Box>
        </ScrollView>
      )}
    </Formik>
  )
}

export default FormWrapper;