import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = 'black' }) => {
    return (
      <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.81583 20.8023C5.70533 20.8023 5.59484 20.7747 5.49125 20.7194C5.21501 20.5744 5.0769 20.2636 5.14596 19.9598L6.67906 13.1022H0.691657C0.415422 13.1022 0.16681 12.9365 0.0563154 12.681C-0.0541789 12.4255 0.00106826 12.1354 0.194433 11.9351L11.2369 0.409205C11.451 0.188217 11.7894 0.13297 12.0587 0.277993C12.335 0.423017 12.4731 0.733782 12.404 1.03764L10.8709 7.89519H16.8583C17.1346 7.89519 17.3832 8.06093 17.4937 8.31645C17.6042 8.57197 17.5489 8.86202 17.3556 9.06229L6.31305 20.5882C6.18184 20.7263 5.99538 20.8023 5.81583 20.8023ZM2.30763 11.7211H7.53539C7.74257 11.7211 7.94284 11.8177 8.07405 11.9835C8.20526 12.1492 8.2536 12.3633 8.21217 12.5636L7.03126 17.8466L15.2424 9.27637H10.0146C9.80743 9.27637 9.60716 9.17969 9.47595 9.01395C9.34474 8.84821 9.29639 8.63412 9.33783 8.43385L10.5187 3.15084L2.30763 11.7211Z" fill={color} />
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('ActivityIcon'),
    memo,
));
