import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
      <rect width="38" height="38" rx="8" fill="black" fillOpacity="0.75" />
      <path d="M27.8635 22.4684H23.2589L23.9543 15.5322H27.8634C28.107 15.5322 28.3321 15.4022 28.4539 15.1913C28.5757 14.9803 28.5757 14.7204 28.4539 14.5095C28.3321 14.2985 28.107 14.1686 27.8634 14.1686H24.0908L24.509 9.97752C24.5333 9.73396 24.426 9.4959 24.2272 9.35299C24.0283 9.21009 23.7685 9.18405 23.5454 9.28481C23.3222 9.38547 23.1697 9.59758 23.1454 9.84115L22.6908 14.1684H15.7909L16.2137 9.97739C16.2313 9.79567 16.1753 9.61441 16.0583 9.47436C15.9412 9.33421 15.773 9.2469 15.591 9.23194C15.4109 9.21167 15.2303 9.2648 15.0899 9.37947C14.9497 9.49415 14.8615 9.66054 14.8455 9.84102L14.4137 14.1683H9.68183C9.43827 14.1683 9.21318 14.2982 9.09141 14.5092C8.96953 14.7202 8.96953 14.98 9.09141 15.191C9.21318 15.402 9.43829 15.5319 9.68183 15.5319H14.2864L13.591 22.4682H9.68188C9.43831 22.4682 9.21322 22.5981 9.09145 22.809C8.96958 23.02 8.96958 23.2799 9.09145 23.4909C9.21323 23.7019 9.43834 23.8318 9.68188 23.8318H13.4545L13.0363 28.0228C13.012 28.2664 13.1194 28.5044 13.3181 28.6473C13.517 28.7902 13.7768 28.8163 13.9999 28.7155C14.2231 28.6148 14.3756 28.4027 14.3999 28.1592L14.8317 23.8319H21.7544L21.3317 28.0229C21.3073 28.2665 21.4147 28.5046 21.6135 28.6475C21.8124 28.7904 22.0722 28.8164 22.2953 28.7156C22.5185 28.615 22.671 28.4029 22.6953 28.1593L23.1499 23.832H27.8636C28.1071 23.832 28.3322 23.7021 28.454 23.4911C28.5758 23.2801 28.5758 23.0203 28.454 22.8093C28.3322 22.5983 28.1071 22.4684 27.8636 22.4684L27.8635 22.4684ZM14.9636 22.4684L15.6545 15.5322H22.5818L21.8909 22.4684H14.9636Z" fill="white" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('HashtagIcon'),
  memo,
));
