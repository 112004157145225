import { Form, Formik } from "formik";
import { createRef } from "react";
import { TextField, Button, Stack, Typography, Box } from "@mui/material";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";
import ImageDropZone from "components/ImageDropZone";
import { pick } from "ramda";
import FormButtons from "components/FormButtons";

const schema = Yup.object().shape({
  make: Yup.string().required("A make is required"),
  model: Yup.string().required("A model is required"),
  year: Yup.date().required("A year is required"),
});

export const AddToInventory = ({ resource, onSubmit, loading, onClose }) => {
  const dropzoneRef = createRef();

  const openFileDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={resource || { year: new Date() }}
        onSubmit={async (values, { setSubmitting, isSubmitting }) => {
          if (isSubmitting) return;

          await onSubmit({
            ...pick(["photoId", "inventoryId"], values),
            name: `${values.make} ${values.model}`,
            // only vehicles right now
            type: "VEHICLE",
            vehicle: {
              year: values.year ? new Date(values.year).getFullYear() : null,
              ...pick(["type", "make", "model", "category"], values),
            },
          });
          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
          resetForm,
          getFieldProps,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack gap={2} sx={{ paddingBottom: 5 }}>
              <Box sx={{ width: "fit-content" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  justifyContent="start"
                >
                  <ImageDropZone
                    sx={{
                      width: "160px",
                      height: "129px",
                      my: 0,
                      mx: 0,

                      objectPosition: "center center",
                    }}
                    initialFile={values.photoUrl}
                    dropzoneRef={dropzoneRef}
                    onComplete={({ id, url }) => {
                      setFieldValue("logo", { id, url });
                      setFieldValue("logoId", id);
                    }}
                  ></ImageDropZone>

                  <Stack gap={1}>
                    <Button variant="tertiary" onClick={openFileDialog}>
                      Change Photo
                    </Button>
                    <Typography variant="subtitle2">
                      Landscape photo recommended
                    </Typography>
                  </Stack>
                </Stack>
              </Box>

              <DatePicker
                views={["year"]}
                label="Year"
                disableFuture
                minDate={new Date("1867-01-01")}
                maxDate={new Date()}
                value={values.year}
                onChange={(value) => setFieldValue("year", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={() => setFieldTouched("year", true)}
                    error={touched.startDate && !!errors.startDate}
                    helperText={errors.startDate}
                    fullWidth
                    required
                  />
                )}
              />
              <TextField
                label="Make"
                variant="outlined"
                fullWidth
                error={touched.make && !!errors.make}
                helperText={errors.make}
                {...getFieldProps("make")}
              />
              <TextField
                label="Model"
                variant="outlined"
                fullWidth
                error={touched.model && !!errors.model}
                helperText={errors.model}
                {...getFieldProps("model")}
              />
              <FormButtons
                loading={loading}
                onCancel={() => {
                  resetForm();
                  onClose();
                }}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
