import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  border-radius: 8px;
  background-color: ${theme.palette.transparentColors.dark.twenty};
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  & input {
    background-color: transparent;
    color: white;
    height: 40px;
    box-sizing: border-box;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }

  & input::placeholder {
    color: white;
    font-size: 14px;
  }
`,
);

function Tag(props) {
  const { label, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  border: 1px solid white;
  color: white;
  border-radius: 8px;
  box-sizing: content-box;
  padding: 5px 15px;
  outline: 0;
  overflow: hidden;
  cursor: pointer;
`,
);
