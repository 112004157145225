import React, { memo, useState } from "react";
import {
  Typography,
  Button,
  Stack,
  Grid,
  Dialog,
  IconButton,
  useTheme,
} from "@mui/material";
import { pipe, applyTo, pick } from "ramda";
import { propTypes, defaultProps, displayName } from "lib/react";
import CloseIcon from "components/icons/CloseIcon";

import Image from "components/Image";
import LoadingList from "components/LoadingList";

export default applyTo(
  ({
    user,
    readOnly = true,
    onEdit = () => { },
    onOpenOptions = () => { },
    loading
  }) => {
    const theme = useTheme();
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState("");

    const handleImageClick = (i, index) => {
      if (!readOnly) {
        onOpenOptions("GARAGE", {
          ...pick(["make", "model", "year", "type", "category"], i?.node || {}),
          inventoryId: i?.node?.id,
          photoId: i?.node?.photo?.id,
          photoUrl: i?.node?.photo?.url,
        });
      } else {
        setSelectedPhotoUrl(i?.node.photo?.url);
      }
    };

    return loading ? <LoadingList count={1} /> : user?.inventory?.edges?.length > 0 && (
      <>
        <Stack gap={2} sx={{
          width: '100%',
          borderRadius: 4,
          backgroundColor: "rgba(255,255,255,.7)",
          padding: 4,
        }}>

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography variant="h5">Garage</Typography>
            {!readOnly && (
              <Button
                sx={{
                  backgroundColor: "#bbbbbb",
                  color: "white",
                  textTransform: "none",
                  fontWeight: "normal",
                  padding: "0 20px",
                  borderRadius: "8px",
                }}
                onClick={onEdit("GARAGE")}
              >
                Add
              </Button>
            )}
          </Stack>
          {user?.inventory?.edges?.length > 0 && (
            <Grid container spacing={2}>
              {user?.inventory?.edges.map((i, index) => (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleImageClick(i, index)}
                >
                  {i?.node.photo && (
                    <Image
                      src={i?.node.photo?.url}
                      alt={i?.node.name}
                      sx={{ width: 1, borderRadius: 2 }}
                    />
                  )}

                  <Typography>
                    {i?.node.year} {i?.node.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </Stack>
        {
          readOnly && (
            <Dialog
              open={!!selectedPhotoUrl}
              onClose={() => {
                setSelectedPhotoUrl();
              }}
              PaperProps={{
                sx: {
                  minWidth: "335px",
                  margin: "0 auto",
                  borderRadius: "30px",
                  backgroundColor: theme.palette.overlay.secondary,
                  backgroundImage: "none !important",
                  overflow: "hidden",
                },
              }}
            >
              <IconButton
                onClick={() => {
                  setSelectedPhotoUrl();
                }}
                sx={{ position: "absolute", top: 10, right: 10 }}
              >
                <CloseIcon />
              </IconButton>
              <img src={selectedPhotoUrl} alt="garage contents" />
            </Dialog>
          )
        }
      </>
    );
  },
  pipe(propTypes({}), defaultProps({}), displayName("Garage"), memo)
);
