import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ width = 41, height = 40, onClick = () => null }) => {
  return (
    <svg onClick={onClick} width={width} height={height} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.45752" y="0.5" width="39" height="39" rx="7.5" stroke="#E6E6E6" />
      <line x1="10.9575" y1="13" x2="30.9575" y2="13" stroke="#999999" stroke-width="2" />
      <line x1="10.9575" y1="19" x2="30.9575" y2="19" stroke="#999999" stroke-width="2" />
      <line x1="10.9575" y1="25" x2="30.9575" y2="25" stroke="#999999" stroke-width="2" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('HamburgerIcon'),
  memo,
));
