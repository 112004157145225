import React, { memo, useContext } from "react";
import { applyTo, find, pathEq, pipe } from "ramda";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { propTypes, defaultProps, displayName } from "lib/react";
import { Stack } from "@mui/system";
import { useState } from "react";

import UnreadIcon from "components/icons/UnreadIcon";
import Image from "components/Image";
import { AppContext } from "context";
import AddLeadDialog from "components/AddLeadDialog";
import ActivityIcon from "components/icons/Nav/ActivityIcon";
import CommunityIcon from "components/icons/Nav/CommunityIcon";
import EventsIcon from "components/icons/Nav/EventsIcon";
import MediaIcon from "components/icons/Nav/MediaIcon";
import MembersIcon from "components/icons/Nav/MembersIcon";
import AboutIcon from "components/icons/Nav/AboutIcon";
import CloseIcon from "components/icons/CloseIcon";
import { useMutation } from "@apollo/client";
import {
  LEAVE_COMMUNITY,
  UPDATE_COMMUNITY_MEMBERSHIP,
} from "data/mutations/community";
import InviteLink from "components/InviteLink";
import ConfirmMenu from "components/ConfirmMenu";

export default applyTo(
  ({
    channels = [],
    isLead,
    isOwner,
    community,
    refetch,
    showWelcome,
    showEditTooltip,
  }) => {
    const [addLeadOpen, setAddLeadOpen] = useState(false);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const { closeNav, onError } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [updateCommunityMembership] = useMutation(
      UPDATE_COMMUNITY_MEMBERSHIP
    );
    const [leaveCommunity] = useMutation(LEAVE_COMMUNITY);
    const [addInviteOpen, setAddInviteOpen] = useState(false);
    const [confirmLeaveOpen, setConfirmLeaveOpen] = useState(false);

    const activityChannel = find(
      pathEq(["node", "slug"], "activity"),
      channels
    );
    const welcomeChannel = find(
      pathEq(["node", "slug"], "start-here"),
      channels
    );
    const eventsChannel = find(pathEq(["node", "slug"], "events"), channels);
    const mediaChannel = find(pathEq(["node", "slug"], "media"), channels);

    const onConfirmLeaveGroup = async () => {
      await leaveCommunity({
        variables: {
          input: {
            communityId: community?.id,
          },
        },
      })
        .then(() => {
          setConfirmLeaveOpen(false);
          window.location.href = `/c/${community?.slug}`;
        })
        .catch((err) => onError(err.message));
    }

    return (
      <Box sx={{ height: '100%' }}>
        {community && !isDesktop && (
          <Box sx={{ px: 3, pt: 5 }}>
            <Image
              src={community?.logo?.url}
              alt={community?.title}
              sx={{
                width: 50,
                height: 50,
                borderRadius: 2,
                backgroundColor: "black",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/c/${community?.slug}`)}
            />
            <Typography
              variant="h1"
              sx={{
                fontWeight: 400,
                fontSize: 14,
                color: "#666666",
                mt: '15px'
              }}
            >
              {community?.title}
            </Typography>
          </Box>
        )}

        <Stack gap={1} sx={{
          flexGrow: 2,
          overflow: "scroll",
          p: 3,
          borderRight: '1px solid #E6E6E6',
          height: '100%'
        }}>
          {showWelcome && (
            <Link to={`/c/${community?.slug}/welcome`} onClick={closeNav}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  fontSize: "16px",
                  py: "15px",
                  borderRadius: "8px",
                  opacity: location.pathname.includes("/welcome") ? 0.4 : 1,
                }}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  👋
                  <Typography variant="h2" sx={{ fontSize: "16px !important" }}>
                    Getting Started
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  {welcomeChannel?.node?.hasUnreadMessages && <UnreadIcon />}
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      updateCommunityMembership({
                        variables: {
                          input: {
                            communityId: community?.id,
                            showWelcome: false,
                          },
                        },
                      })
                        .then(() => refetch())
                        .catch((err) => onError(err.message));
                    }}
                  >
                    <CloseIcon width={10} height={10} />
                  </IconButton>
                </Stack>
              </Stack>
            </Link>
          )}

          {isLead && (
            <Link to={`/c/${community?.slug}/activity`} onClick={closeNav}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  fontSize: "16px",
                  py: "15px",
                  borderRadius: "8px",
                  opacity: location.pathname.includes("/activity") ? 0.4 : 1,
                }}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <ActivityIcon color={theme.palette.svg.main} />
                  <Typography variant="h2" sx={{ fontSize: "16px !important" }}>
                    Activity
                  </Typography>
                </Stack>
                {activityChannel?.node?.hasUnreadMessages && <UnreadIcon />}
              </Stack>
            </Link>
          )}

          <Link to={`/c/${community?.slug}/channels`} onClick={closeNav}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                fontSize: "16px",
                py: "15px",
                borderRadius: "8px",
                opacity: location.pathname.includes("/channel") ? 0.4 : 1,
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <CommunityIcon color={theme.palette.svg.main} />
                <Typography variant="h2" sx={{ fontSize: "16px !important" }}>
                  Channels
                </Typography>
              </Stack>
              {/* {details?.node?.hasUnreadMessages && <UnreadIcon />} */}
            </Stack>
          </Link>

          <Link to={`/c/${community?.slug}/events`} onClick={closeNav}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                fontSize: "16px",
                py: "15px",
                borderRadius: "8px",
                opacity: location.pathname.includes("/events") ? 0.4 : 1,
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <EventsIcon color={theme.palette.svg.main} />
                <Typography variant="h2" sx={{ fontSize: "16px !important" }}>
                  Events
                </Typography>
              </Stack>
              {eventsChannel?.node?.hasUnreadMessages && <UnreadIcon />}
            </Stack>
          </Link>

          <Link to={`/c/${community?.slug}/media`} onClick={closeNav}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                fontSize: "16px",
                py: "15px",
                borderRadius: "8px",
                opacity: location.pathname.includes("/media") ? 0.4 : 1,
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <MediaIcon color={theme.palette.svg.main} />
                <Typography variant="h2" sx={{ fontSize: "16px !important" }}>
                  Media
                </Typography>
              </Stack>
              {mediaChannel?.node?.hasUnreadMessages && <UnreadIcon />}
            </Stack>
          </Link>

          <Link to={`/c/${community?.slug}/members`} onClick={closeNav}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                fontSize: "16px",
                py: "15px",
                borderRadius: "8px",
                opacity: location.pathname.includes("/members") ? 0.4 : 1,
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <MembersIcon color={theme.palette.svg.main} />
                <Typography variant="h2" sx={{ fontSize: "16px !important" }}>
                  Members
                </Typography>
              </Stack>
            </Stack>
          </Link>

          <Link to={`/c/${community?.slug}/about`} onClick={closeNav}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                fontSize: "16px",
                py: "15px",
                borderRadius: "8px",
                opacity:
                  location.pathname === `/c/${community?.slug}/about` ? 0.4 : 1,
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <AboutIcon color={theme.palette.svg.main} />
                <Typography variant="h2" sx={{ fontSize: "16px !important" }}>
                  About
                </Typography>
              </Stack>
            </Stack>
          </Link>

          <Button
            sx={{
              width: '100%',
              backgroundColor: 'rgba(245, 213, 97, 0.15)',
              mt: '25px'
            }}
            onClick={() => setAddInviteOpen(true)}
            variant="regular"
          >
            Invite Members
          </Button>

          {isLead && <Button
            sx={{
              width: '100%',
              backgroundColor: 'transparent',
            }}
            onClick={() => {
              navigate(`/c/${community?.slug}/settings/details`);
            }}
            variant="regular"
          >
            Manage Group
          </Button>}

          {!isOwner && <Button
            sx={{
              width: '100%',
              backgroundColor: 'transparent',
            }}
            onClick={() => {
              setConfirmLeaveOpen(true);
            }}
            variant="regular"
          >
            Leave Group
          </Button>}

          {isLead && (
            <AddLeadDialog
              communityId={community?.id}
              isOpen={addLeadOpen}
              onClose={() => setAddLeadOpen(false)}
            />
          )}
        </Stack>

        <InviteLink
          isLead={isLead}
          community={community}
          isOpen={addInviteOpen}
          onClose={() => setAddInviteOpen(false)}
        />
        <ConfirmMenu
          isOpen={confirmLeaveOpen}
          onClose={() => setConfirmLeaveOpen(false)}
          onSubmit={onConfirmLeaveGroup}
          label='Are you sure you want to leave group?'
        />
      </Box>
    );
  },
  pipe(propTypes({}), defaultProps({}), displayName("channels"), memo)
);
