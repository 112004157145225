import { useMutation } from "@apollo/client";
import { Divider, MenuItem, MenuList, Typography } from "@mui/material";
import BottomDrawer from "components/BottomDrawer";
import ReplyInput from "components/Lexical/ReplyInput";
import { AppContext } from "context";
import {
  ADD_COMMUNITY_LEADER,
  ADD_COMMUNITY_MEMBERS,
  REMOVE_COMMUNITY_LEADER,
  REMOVE_COMMUNITY_MEMBERS,
} from "data/mutations/members";
import { path, tap } from "ramda";
import { useCallback, useContext, useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "lib/firebase";
import { MESSAGE_USERS } from "data/mutations/users";

export default function MemberActions({
  community,
  isOpen,
  onClose,
  user,
  isLead,
}) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmType, setConfirmType] = useState();
  const [messageOpen, setMessageOpen] = useState(false);
  const { chat, onError } = useContext(AppContext);
  const [messageUsers] = useMutation(MESSAGE_USERS);
  const [addCommunityLeader] = useMutation(ADD_COMMUNITY_LEADER);
  const [removeCommunityLeader] = useMutation(REMOVE_COMMUNITY_LEADER);
  const [addCommunityMembers] = useMutation(ADD_COMMUNITY_MEMBERS);
  const [removeCommunityMembers] = useMutation(REMOVE_COMMUNITY_MEMBERS);

  const confirmUpgrade = useCallback(() => {
    return addCommunityLeader({
      variables: {
        input: {
          userId: user.id,
          communityId: community?.id,
        },
      },
    })
      .then(
        tap(() => {
          logEvent(analytics, "new_lead", {
            communitySlug: community?.slug,
          });
        })
      )
      .catch((err) => onError(err.message));
  }, [user, addCommunityLeader, community, onError]);

  const confirmDowngrade = useCallback(() => {
    return removeCommunityLeader({
      variables: {
        input: {
          userId: user.id,
          communityId: community?.id,
        },
      },
    })
      .then(
        tap(() => {
          logEvent(analytics, "lead_removed", {
            communitySlug: community?.slug,
          });
        })
      )
      .catch((err) => onError(err.message));
  }, [user, removeCommunityLeader, community, onError]);

  const confirmRemoval = useCallback(() => {
    return removeCommunityMembers({
      variables: {
        input: {
          userIds: [user.id],
          communityId: community?.id,
        },
      },
    }).catch((err) => onError(err.message));
  }, [user, removeCommunityMembers, community, onError]);

  const confirmAdd = useCallback(() => {
    return addCommunityMembers({
      variables: {
        input: {
          userIds: [user.id],
          communityId: community?.id,
        },
      },
    }).catch((err) => onError(err.message));
  }, [user, addCommunityMembers, community, onError]);

  const handleUndo = useCallback(() => {
    if (confirmType === "upgrade")
      confirmDowngrade().then(() => {
        setConfirmOpen(true);
        setConfirmType("downgrade");
      });
    if (confirmType === "downgrade")
      confirmUpgrade().then(() => {
        setConfirmOpen(true);
        setConfirmType("upgrade");
      });
    if (confirmType === "removal")
      confirmAdd().then(() => {
        setConfirmOpen(true);
        setConfirmType("add");
      });
  }, [confirmType, confirmAdd, confirmDowngrade, confirmUpgrade]);

  const handleSendMessage = async (msg) => {
    const channel = await messageUsers({
      variables: {
        input: {
          name: user?.displayName,
          userIds: [user.id],
        },
      },
    })
      .then(path(["data", "messageUsers", "channel"]))
      .catch((err) => {
        onError(err.message);
        return;
      });
    if (!channel) return;
    // setup chat room on the fly and send message
    return chat
      ?.getConversationBySid(channel?.twilioSid)
      .then((chatRoom) => chatRoom.sendMessage(msg))
      .then(() => {
        setMessageOpen(false);
        logEvent(analytics, "send_message", {
          channel: channel?.slug,
        });
      })
      .catch(() => onError("Unable to message user. Please try again."));
  };
  return (
    <>
      <BottomDrawer isOpen={isOpen} onClose={onClose}>
        {confirmOpen ? (
          <MenuList>
            {confirmType === "upgrade" && (
              <Typography color="text.secondary">
                {user?.displayName} was promoted{" "}
                <span style={{ color: "white" }}>community lead</span>
              </Typography>
            )}
            {confirmType === "downgrade" && (
              <Typography color="text.secondary">
                {user?.displayName}'s role was changed to{" "}
                <span style={{ color: "white" }}>member</span>
              </Typography>
            )}
            {confirmType === "removal" && (
              <Typography color="text.secondary">
                {user?.displayName} was{" "}
                <span style={{ color: "white" }}>removed</span>
              </Typography>
            )}
            {confirmType === "add" && (
              <Typography color="text.secondary">
                {user?.displayName} was{" "}
                <span style={{ color: "white" }}>added to the community</span>
              </Typography>
            )}
            <Divider sx={{ my: 2 }} />
            <MenuItem sx={{ color: "text.secondary" }} onClick={handleUndo}>
              Undo
            </MenuItem>
          </MenuList>
        ) : (
          <MenuList direction="column" divider={<Divider />} gap={2}>
            <Divider />
            <MenuItem
              onClick={() => {
                setMessageOpen(true);
              }}
            >
              Message
            </MenuItem>
            {isLead && (
              <>
                <Divider />
                {user?.isLead ? (
                  <MenuItem
                    onClick={async () => {
                      confirmDowngrade().then(() => {
                        setConfirmOpen(true);
                        setConfirmType("downgrade");
                      });
                    }}
                  >
                    Change role to member
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={async () => {
                      confirmUpgrade().then(() => {
                        setConfirmOpen(true);
                        setConfirmType("upgrade");
                      });
                    }}
                  >
                    Promote to community lead
                  </MenuItem>
                )}
                <Divider />
                <MenuItem
                  onClick={() => {
                    confirmRemoval().then(() => {
                      setConfirmOpen(true);
                      setConfirmType("removal");
                    });
                  }}
                >
                  Remove from the community
                </MenuItem>
              </>
            )}
          </MenuList>
        )}
      </BottomDrawer>
      <BottomDrawer
        isOpen={messageOpen}
        onClose={() => {
          setMessageOpen(false);
          onClose();
        }}
        title={`Message ${user?.displayName}`}
      >
        <ReplyInput onSend={handleSendMessage} />
      </BottomDrawer>
    </>
  );
}
