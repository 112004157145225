import { useContext, useState } from "react";
import { Box, Button, Divider, IconButton, MenuItem, MenuList, Stack, Typography, useTheme } from "@mui/material";
import styles from './ChatMessage.module.scss';

import { MoreHoriz } from "@mui/icons-material";
import RichText from "./RichText";
import ReplyInput from "../ReplyInput";
import BottomDrawer from "components/BottomDrawer";
import MessageReactions from "components/MessageReactions";
import { Link, useParams } from "react-router-dom";
import { AppContext } from "context";
import ApprovedIcon from "components/icons/ApprovedIcon";
import RejectedIcon from "components/icons/RejectedIcon";
import ReplyIcon from "components/icons/ReplyIcon";
import { formatDate } from "lib/date";
import UserAvatar from "components/UserAvatar";
import LoadingIconButton from "components/LoadingIconButton";
import CheckedIcon from "components/icons/CheckedIcon";
import CloseIcon from "components/icons/CloseIcon";

const parseBody = (body) => {
  try {
    const parsedBody = JSON.parse(body);
    if (
      !body
      || parsedBody?.root?.children?.length === 0
      || parsedBody?.editorState?.root?.children?.length === 0
    ) return { editorState: null, media: [] };
    return parsedBody?.editorState || parsedBody?.media
      ? { editorState: JSON.stringify(parsedBody.editorState), media: parsedBody.media }
      : { editorState: body, media: [] };
  } catch (err) {
    return { editorState: null, media: [] };
  }
}

export default function ChatMessage({
  dateCreated,
  body,
  author,
  attributes,
  isLead,
  replyCount,
  editable,
  deletable,
  flaggable,
  onEdit,
  onAddMedia,
  onDelete,
  onReply,
  onFlag,
  onUpdateApplication,
  onUndoFlag,
  onReaction,
  onlyMedia
}) {
  const { community: slug } = useParams();
  const { featuredHexCode } = useContext(AppContext);
  const [editing, setEditing] = useState(false);
  const [confirmType, setConfirmType] = useState();
  const [showOptions, setShowOptions] = useState(false);
  const theme = useTheme();

  const { editorState, media } = parseBody(body);
  if (!editorState) return null;

  const handleEdit = async (text) => {
    return onEdit(text).then(() => setEditing(false));
  };

  const handleDelete = async () => {
    return onDelete().then(() => {
      setConfirmType();
      setShowOptions(false);
    });
  }

  if (attributes.isLead && !isLead) return null;

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.message.background,
        borderRadius: '30px'
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{ p: '20px', pb: '15px' }}
      >
        <Stack direction='row' alignItems='center'>
          {!onlyMedia && <Link
            to={`/${author?.__typename === 'User' ? 'u' : 'c'}/${author?.slug}`}
          >
            <UserAvatar
              src={author?.avatar?.url}
              variant='rounded'
              width={45}
              height={45}
              sx={{ mr: 1.5 }}
            /></Link>}
          <Box>
            <Link
              to={`/${author?.__typename === 'User' ? 'u' : 'c'}/${author?.slug}`}
            >
              <Typography
                variant='h5'
              >{author?.displayName}</Typography>
            </Link>
            <Typography
              variant='subtitle1'
              color='transparentColors.dark.fortyFive'
            >{formatDate(dateCreated)}</Typography>
          </Box>
        </Stack>
        {isLead && attributes.type === 'APPLICATION' && attributes.status === 'PENDING' && (
          <Stack direction='row' gap={1}>
            <IconButton
              onClick={() => onUpdateApplication('REJECTED')}
              // disabled={loading}
              sx={{
                backgroundColor: theme.palette.overlay.secondary,
                p: 2,
              }}
            >
              <CloseIcon />
            </IconButton>
            <LoadingIconButton
              // loading={loading}
              onClick={() => onUpdateApplication('ACCEPTED')}
              type="submit"
            >
              <CheckedIcon />
            </LoadingIconButton>
          </Stack>
        )}
      </Stack>
      <div>
        <Box sx={{ mb: 1 }}>
          {isLead && attributes.type === 'APPLICATION' && attributes.status === 'PENDING' && (
            <Typography
              variant='h5'
              sx={{ px: '20px' }}
            >Requested to join</Typography>
          )}
          {isLead && attributes.type === 'SUBSCRIPTION' && attributes.status === 'UNPAID' && (
            <Typography
              variant='h5'
              sx={{ px: '20px' }}
            >Payment failed</Typography>
          )}
          {editing
            ? <ReplyInput
              author={author}
              isLead={isLead}
              editorState={editorState}
              onSend={handleEdit}
              files={media}
            />
            : (!attributes.status || attributes.status === 'PENDING')
              ? <RichText
                editorState={editorState}
                files={media}
                onAddMedia={onAddMedia}
              /> : null
          }
          {isLead && attributes.type === 'APPLICATION' && attributes.status !== 'PENDING' && (
            <Typography sx={{ px: '20px' }}>{
              attributes.status === 'ACCEPTED'
                ? <><ApprovedIcon /> Approved to join</>
                : <><RejectedIcon /> Request to join denied</>
            }</Typography>
          )}
        </Box>
        {isLead && attributes.type === 'SUBSCRIPTION' && (
          <Box sx={{ px: '20px' }}>
            <Button
              component={Link}
              to={`/c/${slug}/settings/funding`}
              variant='tertiary'
            >
              Update payment details
            </Button>
          </Box>
        )}
        {(!onReaction && !onReply && !onEdit) && (
          <Box sx={{ pb: '20px' }} />
        )}
        {(onReaction || onReply) && <Stack
          direction='row'
          alignItems='center'
          gap={1}
          flexWrap='wrap'
          sx={{ p: '20px', pt: '5px' }}
        >
          {onReaction && <MessageReactions
            reactions={Object.entries(attributes?.reactions || [])}
            onReaction={onReaction}
          />}
          {onReply && <Stack direction='row'>
            <IconButton
              onClick={onReply}
              sx={{
                background: theme.palette.message.button,
                borderTopRightRadius: replyCount ? '0px' : '4px',
                borderBottomRightRadius: replyCount ? '0px' : '4px',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
                p: '3px 5px'
              }}
            >
              <ReplyIcon sx={{ width: 16, height: 20 }} color={theme.palette.text.secondary} />
              <Typography
                color='text.secondary'
                sx={{ ml: 1, fontSize: 14, fontWeight: 500 }}
              >Reply</Typography>
            </IconButton>
            {replyCount && <Typography
              sx={{
                backgroundColor: featuredHexCode,
                p: '3px 5px',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
                color: 'white'
              }}
            >
              {replyCount}
            </Typography>}
          </Stack>}

          {(editable || deletable || flaggable) && <IconButton
            onClick={() => setShowOptions(true)}
            sx={{
              background: theme.palette.message.button,
              borderRadius: '4px',
              p: '5px 7px'
            }}
          >
            <MoreHoriz
              fontSize='small'
              color={theme.palette.text.secondary}
            />
          </IconButton>}
          {attributes?.reported && <div
            className={styles.draft}
            style={{ backgroundColor: featuredHexCode }}
          >
            Flagged
          </div>}
        </Stack>}
      </div>
      <BottomDrawer
        isOpen={showOptions}
        onClose={() => {
          setShowOptions(false);
          setConfirmType();
        }}
      >
        <MenuList
          direction="column"
          gap={2}
        >
          {confirmType
            ? (
              <div>
                {confirmType === 'Delete' && <MenuItem onClick={handleDelete}>Confirm</MenuItem>}
                {confirmType === 'Flag' && <Typography
                  className={styles.confirmText}
                >This post <span>was flagged</span>.</Typography>}
                {confirmType === 'Unflag' && <Typography
                  className={styles.confirmText}
                >The flag <span>was removed</span>.</Typography>}
                {(confirmType === 'Delete' || confirmType === 'Media') &&
                  <>
                    <Divider sx={{ my: 2 }} />
                    <MenuItem
                      onClick={() => setConfirmType()}>Cancel
                    </MenuItem>
                  </>
                }
              </div>
            )
            : (
              <div>
                {editable && (
                  <MenuItem onClick={() => {
                    setEditing(true);
                    setShowOptions(false);
                  }}>Edit Post</MenuItem>
                )}
                {deletable && (
                  <div>
                    <Divider />
                    <MenuItem
                      onClick={() => setConfirmType('Delete')}
                    >Delete Post</MenuItem>
                  </div>
                )}
                {isLead && attributes?.reported && (
                  <div>
                    <Divider />
                    <MenuItem
                      onClick={() => onUndoFlag().then(() => setConfirmType('Unflag'))}
                    >Remove Flag</MenuItem>
                  </div>
                )}
                {flaggable && !attributes?.reported && (
                  <div>
                    <Divider />
                    <MenuItem
                      onClick={() => onFlag().then(() => setConfirmType('Flag'))}
                    >Flag As Inappropriate</MenuItem>
                  </div>
                )}
              </div>
            )
          }
        </MenuList>
      </BottomDrawer>
    </Box>
  );
}
