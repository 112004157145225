import React, { useRef, useState } from 'react';
import { Divider, FormControlLabel, IconButton, Popover, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { omit } from 'ramda';
import EmojiPicker, { Emoji } from 'emoji-picker-react';
import HashtagIcon from 'components/icons/HashtagIcon';
import FormButtons from 'components/FormButtons';

const INITIAL_VALUES = {
  isPublic: true,
  isOpen: false,
  isPublished: true,
  name: '',
  description: '',
  section: '',
  emoji: ''
}

const EventSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  isPublic: Yup.string()
    .required('Private or public setting is required'),
  isOpen: Yup.string()
    .required('Protected setting is required'),
});

export default function ChannelForm({ onSubmit, onDelete, initialValues }) {
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const anchorEl = useRef();

  return (
    <Formik
      enableReinitialize
      validationSchema={EventSchema}
      initialValues={initialValues ? initialValues : INITIAL_VALUES}
      onSubmit={async (values, { setSubmitting, isSubmitting, resetForm }) => {
        if (isSubmitting) return;
        await onSubmit({
          ...omit(['isPublic', 'isOpen', 'section'], values),
          isPrivate: !values.isPublic,
          isProtected: !values.isOpen,
          isPublished: values.isPublished
        }).then(resetForm);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        resetForm,
        setFieldValue,
        getFieldProps,
        isSubmitting,
      }) => (
        <Form>
          <Stack spacing={2} sx={{ pb: '70px' }}>
            <Popover
              open={emojiOpen}
              anchorEl={anchorEl}
              onClose={() => setEmojiOpen(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <EmojiPicker
                emojiStyle='native'
                theme='dark'
                previewConfig={{
                  defaultCaption: 'Pick channel emoji'
                }}
                onEmojiClick={(emojiProps) => {
                  setFieldValue('emoji', emojiProps.unified);
                  setEmojiOpen(false);
                }}
              />
            </Popover>
            <Stack direction='row' alignItems='center' gap={1} ref={anchorEl}>
              <Tooltip title='Channel Emoji'>
                <IconButton
                  onClick={() => setEmojiOpen(true)}
                  sx={{ backgroundColor: 'black' }}
                >
                  {values.emoji
                    ? <Emoji unified={values.emoji} />
                    : <HashtagIcon />
                  }
                </IconButton>
              </Tooltip>
              <TextField
                label="Name"
                fullWidth
                disabled={values.section === 'defaults'}
                required
                error={touched.name && !!errors.name}
                helperText={errors.name}
                containerSx={{ mb: 0 }}
                InputLabelProps={{
                  shrink: !!values.name,
                }}
                sx={{
                  opacity: values.section === 'defaults' ? 0.5 : 1
                }}
                {...getFieldProps('name')}
              />
            </Stack>
            <TextField
              label="Channel description (optional)"
              fullWidth
              multiline
              error={touched.description && !!errors.description}
              helperText={errors.description}
              InputLabelProps={{
                shrink: !!values.description,
              }}
              {...getFieldProps('description')}
            />
            {/* <TextField 
              label="Add to a section"
              variant="outlined"
              fullWidth
              multiline
              error={ touched.section && !!errors.section }
              helperText={ errors.section }
              {...getFieldProps('section')}
            /> */}
            <FormControlLabel
              control={<Switch
                inputProps={{ 'aria-label': 'controlled' }}
                name="isPublic"
                checked={values.isPublic}
                onChange={(evt) => setFieldValue('isPublic', evt.target.checked)}
                disabled={values.section === 'defaults'}
              />}
              label="Public channel"
            />
            <Divider />
            <FormControlLabel
              control={<Switch
                inputProps={{ 'aria-label': 'controlled' }}
                name="isOpen"
                checked={values.isOpen}
                onChange={(evt) => setFieldValue('isOpen', evt.target.checked)}
                disabled={values.section === 'defaults'}
              />}
              label="Everyone can post"
            />
            {initialValues && values.section !== 'defaults' && <Divider />}
            {/* <FormControlLabel 
              control={<Switch
                inputProps={{ 'aria-label': 'controlled' }}
                name="isPublished"
                checked={ values.isPublished }
                onChange={ (evt) => setFieldValue('isPublished', evt.target.checked) }
              />} 
              label="Publish now"
            /> */}
            {initialValues && values.section !== 'defaults' && <Typography
              sx={{ cursor: 'pointer' }}
              onClick={async () => {
                setIsDeleting(true);
                await onDelete();
                setIsDeleting(false);
              }}
            >Delete Channel</Typography>}
            <FormButtons
              onConfirm={handleSubmit}
              onCancel={resetForm}
              loading={isSubmitting || isDeleting}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
