import React, { memo, useContext } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { AppContext } from 'context';

export default applyTo(({ width = 9.7, height = 9.7, count }) => {
  const { featuredHexCode } = useContext(AppContext);

  return (
    <div style={{
      width,
      height,
      fontSize: '12px',
      padding: count ? '0 5px' : 0,
      backgroundColor: featuredHexCode, 
      borderRadius: count ? '4px' : '50%',
      color: 'black'
    }}>{count}</div>
  );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('UnreadIcon'),
    memo,
));
