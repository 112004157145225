import React, { useContext, useState } from 'react';
import Image from 'components/Image';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { AppContext } from 'context';
import { fill } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";
import Slider from 'react-slick';
import CloseIcon from 'components/icons/CloseIcon';
import LoadingIconButton from 'components/LoadingIconButton';
import CarouselModal from './CarouselModal';

const ImageCarousel = ({ images = [], onDelete, isEditing, onAddMedia, imageSx }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const imageNodes = images && images.length > 0 ? images.map((image, idx) => (
    <ImageSlide
      {...image}
      imageSx={imageSx}
      onClick={() => {
        setSelected(idx);
        setOpen(true);
      }}
      onDelete={() => onDelete(idx)}
      isEditing={isEditing}
    />
  )) : null;

  return (
    <>
      {isDesktop || isEditing ? (
        <Stack direction='row' gap={1} flexWrap='wrap'>
          {imageNodes}
        </Stack>
      ) : (
        <Slider {...settings}>
          {imageNodes}
        </Slider>
      )}
      <CarouselModal
        isOpen={open}
        onClose={() => setOpen(false)}
        images={images}
        selected={selected}
        onSelect={setSelected}
        onAddMedia={onAddMedia}
      />
    </>
  )
}

export default ImageCarousel;

const ImageSlide = ({ imageSx, alt, publicId, src, width = 400, height = 500, onClick, onDelete, isEditing }) => {
  const { cloudinary } = useContext(AppContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [loading, setLoading] = useState(false);

  const resizedUrl = publicId ? cloudinary
    .image(publicId)
    .resize(fill().width(
      isEditing ? width / 4 : width * 1.5
    ).height(
      isEditing ? height / 4 : height * 1.5
    ).gravity(autoGravity()))
    .toURL() : src;

  const handleDelete = async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
  }

  return (
    <Box sx={{ position: 'relative', width: 'fit-content' }}>
      {isEditing && (
        <LoadingIconButton loading={loading} sx={{
          position: 'absolute',
          zIndex: 2,
          top: '5px',
          right: '5px',
          width: '25px',
          height: '25px',
          p: '6px'
        }} onClick={handleDelete}>
          <CloseIcon />
        </LoadingIconButton>
      )}
      <Image
        src={resizedUrl}
        altText={alt}
        onClick={onClick}
        loading={!publicId && !src}
        sx={{
          width: isEditing ? width / 4 : width,
          height: !publicId && !src
            ? isEditing ? height / 4 : height
            : 'auto',
          maxHeight: isDesktop
            ? isEditing ? '143px' : '312px'
            : '100%',
          maxWidth: isDesktop
            ? isEditing ? '115px' : '250px'
            : '100%',
          objectFit: 'cover',
          objectPosition: 'center',
          borderRadius: isDesktop || isEditing ? '15px' : 0,
          display: 'block',
          marginBottom: '8px',
          cursor: 'pointer',
          ...imageSx
        }}
      />
    </Box>
  )
}