import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ width = 25, height = 25 }) => {
    return (
      <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="12" y1="25" x2="12" y2="4.37114e-08" stroke="#D9D9D9" strokeWidth="2"/>
      <line x1="25" y1="12" x2="4.37114e-08" y2="12" stroke="#D9D9D9" strokeWidth="2"/>
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('PlusIcon'),
    memo,
));
