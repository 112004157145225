import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";

import styles from './Theme.module.scss';
import { ImageNode } from "./nodes/ImageNode";
import { MentionLinkNode } from "./nodes/MentionLinkNode";
import { QuoteNode } from './nodes/QuoteNode';

const config = {
  theme: {
    paragraph: styles.paragraph,
    quote: styles.quote,
    // link: styles.link,
    // mention: styles.mention
  },
  // Handling of errors during update
  onError(error) {
    console.error(error);
  },
  // Any custom nodes go here
  nodes: [
    ListNode,
    ListItemNode,
    QuoteNode,
    AutoLinkNode,
    LinkNode,
    ImageNode,
    MentionLinkNode
  ]
};

export default config;