import { memo, useCallback, useContext, useEffect, useState } from "react";
import { pipe, applyTo, pathOr, equals, cond, T } from "ramda";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useMutation, useQuery } from "@apollo/client";
import { logEvent } from "firebase/analytics";
import { Box, Typography } from "@mui/material";

import UserProfile from "components/UserProfile";
import BottomDrawer from "components/BottomDrawer";
import { propTypes, defaultProps, displayName } from "lib/react";
import { analytics } from "lib/firebase";
import { AppContext } from "context";

import { EditProfile, AddToInventory, ChangePersonalInfo } from "./forms";
import SettingsMenu from "./SettingsMenu";
import SubMenu from "./SubMenu";
import config from "config";
import { WHO_AM_I } from "data/queries/users";
import {
  ADD_TO_INVENTORY,
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  DELETE_INVENTORY,
  UPDATE_INVENTORY,
  UPDATE_USER,
} from "data/mutations/users";

export default applyTo(({ hideCommunities = false, hideGarage = false }) => {
  const { logout, onError } = useContext(AppContext);
  const [settingsOpen, setSettingsOpen] = useState(
    window.location.pathname.includes("/settings")
  );
  const [submenu, setSubmenu] = useState({
    open: false,
    type: "",
    resource: null,
  });
  const [editState, setEditState] = useState({
    open: false,
    type: "",
    resource: null,
  });
  const { data, loading, error } = useQuery(WHO_AM_I);
  const [updateUser] = useMutation(UPDATE_USER);
  const [addToMyInventory] = useMutation(ADD_TO_INVENTORY);
  const [updateInventory] = useMutation(UPDATE_INVENTORY);
  const [deleteInventory] = useMutation(DELETE_INVENTORY);
  const [deleteAccount] = useMutation(DELETE_ACCOUNT);
  const [requestPasswordReset] = useMutation(CHANGE_PASSWORD);
  const emailNotificationSettings = pathOr(
    {
      directMessages: true,
      channelMessages: true,
      directMention: true,
      newEvents: true,
      eventReminders: true,
      communityAnnouncements: true,
      groupMessageSummary: true,
      communityUpdate: true,
    },
    ["whoami", "notifications", "email"],
    data
  );

  useEffect(() => {
    logEvent(analytics, "my_profile_view");
  });

  const resetSubmenu = () =>
    setSubmenu({ open: false, type: "", resource: null });
  const resetEditState = () =>
    setEditState({ open: false, type: "", resource: null });

  const EditComponent = applyTo(
    editState.type,
    cond([
      [equals("PROFILE"), () => EditProfile],
      [equals("ADDITIONAL"), () => ChangePersonalInfo],
      [equals("GARAGE"), () => AddToInventory],
      [T, () => null],
    ])
  );

  const editHeader = applyTo(
    editState.type,
    cond([
      [
        equals("PROFILE"),
        () => <Typography variant="h3">Update profile</Typography>,
      ],
      [
        equals("ADDITIONAL"),
        () => (
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: "600",
            }}
          >
            Add personal info
          </Typography>
        ),
      ],
      [
        equals("GARAGE"),
        () =>
          editState.resource ? (
            <Typography variant="h3">Update vehicle</Typography>
          ) : (
            <Typography variant="h3">Add vehicle</Typography>
          ),
      ],
      [T, () => null],
    ])
  );

  const handleDeleteAccount = useCallback(() => {
    deleteAccount()
      .then(() =>
        logout({
          logoutParams: {
            returnTo: `${window.location.protocol}//${config.BASE_URL}`,
          },
        })
      )
      .catch((err) => onError(err.message));
  }, [deleteAccount, logout, onError]);

  const handleDeleteInventory = useCallback(() => {
    if (!submenu.resource?.inventoryId) return;
    deleteInventory({
      variables: {
        input: { inventoryId: submenu.resource.inventoryId },
      },
    })
      .then(() => {
        logEvent(analytics, "deleted_vehicle");
        resetSubmenu();
      })
      .catch((err) => onError(err.message));
  }, [deleteInventory, submenu.resource, onError]);

  const handleSubmit = useCallback(
    (values) => {
      if (editState.type === "GARAGE") {
        const mutation = editState.resource
          ? updateInventory
          : addToMyInventory;
        return mutation({
          variables: {
            input: values,
          },
        })
          .then(() => {
            logEvent(
              analytics,
              editState.resource ? "updated_vehicle" : "added_vehicle"
            );
            resetEditState();
          })
          .catch((err) => onError(err.message));
      } else {
        updateUser({
          variables: {
            input: values,
          },
        })
          .then(() => {
            logEvent(analytics, "updated_profile");
            resetEditState();
          })
          .catch((err) => onError(err.message));
      }
    },
    [updateUser, addToMyInventory, updateInventory, editState, onError]
  );

  const handleChangePassword = () => {
    requestPasswordReset()
      .then(() => {
        setSettingsOpen(false);
        setSubmenu({ open: true, type: "CHANGE_PASSWORD" });
      })
      .catch(() =>
        onError("Unable to send password reset email. Please try again.")
      );
  };

  const handleEdit = (type) => () =>
    setEditState({ open: true, type, resource: null });
  const handleOpenOptions = (type, resource) =>
    setSubmenu({ open: true, type, resource });
  const handleLogout = () =>
    logout({
      logoutParams: {
        returnTo: `${window.location.protocol}//${config.BASE_URL}`,
      },
    });

  if (error) return null;

  return (
    <>
      <UserProfile
        loading={loading}
        user={data?.whoami}
        readOnly={false}
        onOpenSettings={() => setSettingsOpen(true)}
        onEdit={handleEdit}
        onOpenOptions={handleOpenOptions}
        hideCommunities={hideCommunities}
        hideGarage={hideGarage}
      />
      <BottomDrawer
        title={editHeader}
        isOpen={editState.open}
        onClose={resetEditState}
      >
        {EditComponent && (
          <Box sx={{ m: 3 }}>
            <EditComponent
              onSubmit={handleSubmit}
              user={{ ...(data?.whoami || {}) }}
              resource={editState.resource}
              onClose={resetEditState}
              loading={loading}
            />
          </Box>
        )}
      </BottomDrawer>
      <SettingsMenu
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        emailNotificationSettings={emailNotificationSettings}
        onSubmit={handleSubmit}
        onChangePassword={handleChangePassword}
        onLogout={handleLogout}
        onDeleteAccount={() => {
          setSettingsOpen(false);
          setSubmenu({ open: true, resource: null, type: "DELETE_ACCOUNT" });
        }}
      />
      <SubMenu
        open={submenu.open}
        onClose={resetSubmenu}
        type={submenu.type}
        onDeleteAccount={handleDeleteAccount}
        onEditInventory={() => {
          setEditState({
            open: true,
            type: "GARAGE",
            resource: submenu.resource,
          });
        }}
        onConfirmDeleteInventory={() => {
          setSubmenu({ ...submenu, type: "CONFIRM_DELETE_INVENTORY" });
        }}
        onDeleteInventory={handleDeleteInventory}
      />
    </>
  );
}, pipe(withAuthenticationRequired, propTypes({}), defaultProps({}), displayName("Profile"), memo));
