import { gql } from "@apollo/client";
import { Vert } from "lib/graphql";

export const COMMUNITY_EVENT = gql`
    ${Vert.fragments.Event}
    query CommunityEvent($eventSlug: String!) {
      whoami {
        id
        displayName
        event(slug: $eventSlug) {
          isAttending
          isInterested
          node {
            ...EventFields
            community { 
              id 
              slug 
              isPrivate
              membership {
                isLead
              }
            }
          }
        }
      }
    }
`;

export const PUBLIC_COMMUNITY_EVENT = gql`
  query Event($eventSlug: String!) {
    event(slug: $eventSlug) {
      id
      community { id title slug }
      title
      subtitle
      description
      slug
      startDate
      endDate
      isPrivate
      websiteUrl
      ticketingUrl
      location {
        title
        subtitle
        googlePlaceId
        lat
        lng
        url
      }
      banners {
        ...on Image {
          id
          url(input: {
            transformations: [{
              aspect_ratio: "4:5",
              crop: "fill",
            }]
          })
        }
      }
    }
  }
`;

export const COMMUNITY_EVENTS_QUERY = gql`
    query CommunityEvents($slug: String!, $input: CommunityEventsInput) {
      whoami {
        id
        displayName
        community(slug: $slug) {
          isLead
          isMember
          node {
            id
            slug
            isPaused
            channel(slug: "events") {
              node {
                id
                emoji
                twilioSid
                description
              }
            }
            events(input: $input) {
              edges {
                isAttending
                isInterested
                node {
                  id
                  slug
                  title
                  subtitle
                  banners {
                    ...on Image {
                      id
                      url(input: {
                        transformations: [{
                          aspect_ratio: "4:5",
                          crop: "fill",
                        }]
                      })
                      width
                      height
                    }
                  }
                  isPublished
                  startDate
                  endDate
                }
              }
            }
          }
        }
      }
    }
`;