import {
  Box,
  Dialog,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

import styles from "./BottomDrawer.module.scss";
import CloseIcon from "components/icons/CloseIcon";

const iOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

const BottomDrawer = ({
  title,
  subtitle,
  description,
  isOpen,
  onClose = () => { },
  showClose = true,
  children,
  anchor = "bottom",
  fullScreen = false,
  size = 'small',
  ...props
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return isDesktop ? (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: size === 'large' ? '50%' : "335px",
          width: isDesktop && size === 'large' ? '60%' : "auto",
          maxWidth: isDesktop ? '60%' : "100%",
          margin: "0 auto",
          borderRadius: "15px",
          backgroundColor: theme.palette.overlay.secondary,
          backgroundImage: "none !important",
        },
      }}
    >
      <Box sx={{ p: '20px', position: 'relative' }}>
        {(title || subtitle || description) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: '10px' }}
          >
            <Box>
              <Typography variant="h2">{title}</Typography>
              <Typography variant="h4">{subtitle}</Typography>
              <Typography variant="subtitle1">{description}</Typography>
            </Box>
            {showClose && (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        )}
        {children}
      </Box>
    </Dialog>
  ) : (
    <SwipeableDrawer
      {...props}
      style={{ zIndex: 1300 }}
      disableBackdropTransition={!iOS}
      disableDiscovery={true}
      disableSwipeToOpen={true}
      anchor={anchor}
      open={isOpen}
      onClose={onClose}
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        square: false,
        sx: {
          margin: "0 auto",
          borderTopLeftRadius: "20px !important",
          borderTopRightRadius: "20px !important",
          backgroundColor: theme.palette.overlay.secondary,
          backgroundImage: "none !important",
        },
      }}
    >
      {!fullScreen && (
        <Box
          className={styles.puller}
          sx={{ backgroundColor: theme.palette.primary.light }}
        />
      )}
      <Box sx={{ p: 3, position: 'relative' }}>
        {(title || subtitle || description) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: "20px" }}
          >
            <Box>
              <Typography variant="h3">{title}</Typography>
              <Typography variant="h4">{subtitle}</Typography>
              <Typography variant="subtitle1">{description}</Typography>
            </Box>
            {showClose && (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        )}
        {children}
      </Box>
    </SwipeableDrawer>
  );
};

BottomDrawer.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
};

export default BottomDrawer;
