import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = 'black' }) => {
    return (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0684 0.5H4.9316C2.21555 0.5 0 2.71555 0 5.4316V15.5684C0 18.2911 2.21555 20.5 4.9316 20.5H15.0684C17.7911 20.5 20 18.2845 20 15.5684V5.4316C20 2.70888 17.7845 0.5 15.0684 0.5ZM18.6653 15.5684C18.6653 17.5504 17.0504 19.1653 15.0684 19.1653H4.9316C2.94962 19.1653 1.33467 17.5504 1.33467 15.5684V5.4316C1.33467 3.44962 2.94962 1.83467 4.9316 1.83467H15.0684C17.0504 1.83467 18.6653 3.44962 18.6653 5.4316V15.5684Z" fill={color} />
        <path d="M10.0045 8.59484C11.3191 8.59484 12.3868 7.52711 12.3868 6.21246C12.3868 4.89781 11.3191 3.83008 10.0045 3.83008C8.6898 3.83008 7.62207 4.89781 7.62207 6.21246C7.62207 7.52711 8.6898 8.59484 10.0045 8.59484ZM10.0045 5.16475C10.585 5.16475 11.0522 5.63188 11.0522 6.21246C11.0522 6.79304 10.585 7.26017 10.0045 7.26017C9.42387 7.26017 8.95674 6.79304 8.95674 6.21246C8.95674 5.63188 9.42387 5.16475 10.0045 5.16475Z" fill={color} />
        <path d="M10.004 9.40869C8.67597 9.40869 7.60156 10.4898 7.60156 11.8111V14.7607C7.60156 16.0887 8.68264 17.1631 10.004 17.1631C11.3253 17.1631 12.4064 16.082 12.4064 14.7607V11.8111C12.4064 10.4831 11.3253 9.40869 10.004 9.40869ZM11.0717 14.7607C11.0717 15.348 10.5912 15.8284 10.004 15.8284C9.41671 15.8284 8.93623 15.348 8.93623 14.7607V11.8111C8.93623 11.2238 9.41671 10.7434 10.004 10.7434C10.5912 10.7434 11.0717 11.2238 11.0717 11.8111V14.7607Z" fill={color} />
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('AboutIcon'),
    memo,
));
