import React, { memo } from 'react';
import { pipe, applyTo } from 'ramda';
import FlexCenter from 'components/FlexCenter';
import { propTypes, defaultProps, displayName } from 'lib/react';
// import rallyLoader from '../../../assets/Logo_Animation_Small.gif';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

export default applyTo(() => {
  const theme = useTheme();
  return (
    <FlexCenter>
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ width: '92px', height: '92px', margin: '0 auto' }}>
          {/* <img
            src={rallyLoader}
            alt="Creating community"
            style={{ width: '100%' }}
          /> */}
          <CircularProgress />
        </Box>
        <Typography variant='h4' color={theme.palette.transparentColors.dark.fortyFive}>
          One second while we create your<br />community space ...
        </Typography>
      </Box>
    </FlexCenter>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('Creating'),
  memo,
));
