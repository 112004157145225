import { memo, useContext, useEffect, useState } from 'react';
import { pipe, applyTo, path, pathOr, join, filter, reduce } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { AppContext } from 'context';
import { USER_CHANNEL } from 'data/queries/channel';
import Channel from 'components/Channel';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(() => {
  const { message: slug } = useParams();
  const { clientIdentity, changeClientIdentity } = useContext(AppContext);
  const { data, loading, error } = useQuery(USER_CHANNEL, {
    variables: {
      slug
    },
  });
  const navigate = useNavigate();
  const channel = data?.whoami.channel?.node;
  const [section, setSection] = useState('chat');

  useEffect(() => {
    if (clientIdentity && clientIdentity !== data?.whoami?.id) {
      changeClientIdentity(data?.whoami?.id)
    }
  }, [changeClientIdentity, clientIdentity, data?.whoami?.id]);

  const isMsgEditable = msg => {
    return data?.whoami?.id === msg.author;
  }

  const isMsgDeletable = msg => {
    return data?.whoami?.id === msg.author;
  }

  if (error) return null;

  // 25 characters total
  const title = applyTo(channel, pipe(
    pathOr([], ['members', 'edges']),
    filter((c) => c?.node?.id !== data?.whoami?.id),
    reduce((acc, node) => {
      const name = path(['node', 'displayName'], node);
      if (!acc.title) {
        acc.title = name;
      } else if (acc.title.length + name.length > 23) {
        acc.count++
      } else {
        acc.title = join(', ', [acc.title, name]);
      };
      return acc;
    }, { title: '', count: 0 }),
    ({ title, count }) => count > 0
      ? <>{title} <span style={{ color: 'grey' }}>+{count}</span></>
      : title
  ));

  return (
    <>
      <Channel
        loading={loading || (clientIdentity && clientIdentity !== data?.whoami?.id)}
        title={title}
        author={data?.whoami}
        channel={channel}
        isMsgEditable={isMsgEditable}
        isMsgDeletable={isMsgDeletable}
        canPost={true}
        onBack={() => navigate('/messages')}
        isDM={true}
        section={section}
        setSection={setSection}
      />
    </>
  );
}, pipe(
  withAuthenticationRequired,
  propTypes({}),
  defaultProps({}),
  displayName('Messages'),
  memo,
));
