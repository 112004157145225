import { memo, useCallback, useContext, useRef, useState } from 'react';
import { pipe, applyTo, pick, omit } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import Progress from 'components/Progress';
import { AppContext } from 'context';

import EventDetails from 'components/EventDetails';
import { Button } from '@mui/material';
import BottomDrawer from 'components/BottomDrawer';
import EventForm from 'components/forms/EventForm';
import NavLayout from 'components/NavLayout';
import Navigation from 'components/Navigation';
import ScrollView from 'components/ScrollView';
import BackHeader from 'components/BackHeader';
import { COMMUNITY_EVENT } from 'data/queries/event';
import { DELETE_EVENT, UPDATE_EVENT } from 'data/mutations/event';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(() => {
  const { onError } = useContext(AppContext);
  const [editOpen, setEditOpen] = useState(false);
  const [updateEvent, { loading: isUpdating }] = useMutation(UPDATE_EVENT);
  const [deleteEvent, { loading: isDeleting }] = useMutation(DELETE_EVENT);
  const { event: eventSlug } = useParams();
  const { data, loading, error, refetch } = useQuery(COMMUNITY_EVENT, {
    variables: {
      eventSlug
    },
  });
  const navigate = useNavigate();
  const formRef = useRef(null);
  const event = data?.whoami.event?.node;
  const community = event?.community;
  const membership = community?.membership;

  const handleUpdateEvent = useCallback(async (values) => {
    if (isUpdating) return;
    await updateEvent({
      variables: {
        input: {
          communityId: community?.id,
          ...pick([
            'eventId',
            'bannerIds',
            'title',
            'subtitle',
            'description',
            'startDate',
            'endDate',
            'isPromoted',
            'isPublished',
            'isPrivate',
            'tags',
            'authorId',
            'channelId',
            'websiteUrl',
            'ticketingUrl',
            'requiredFields',
            'allowGuests',
          ], values),
          location: pick([
            'title',
            'subtitle',
            'googlePlaceId',
            'lat',
            'lng'
          ], values.location)
        }
      }
    })
      .then(() => setEditOpen(false))
      .catch(err => onError(err.message))
  }, [updateEvent, community, onError, isUpdating, setEditOpen]);

  const handleRemoveEvent = useCallback(async () => {
    if (isDeleting) return;

    await deleteEvent({
      variables: {
        input: {
          eventId: event.id,
          communityId: community?.id
        }
      }
    }).then(() => {
      setEditOpen(false);
      navigate(`/c/${event?.community?.slug}/events`);
    }).catch(err => onError(err.message));
  }, [event, isDeleting, deleteEvent, community, navigate, onError]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  if (event?.isPrivate && !membership) {
    return <Navigate to={`/c/${event?.community?.slug}`} />;
  }
  if (loading || (event?.isPrivate && !membership)) {
    return <Progress />;
  }
  if (error) return onError(error.message);

  return (
    <NavLayout
      navigation={<Navigation />}
      showSidebar={!!membership}
    >
      <ScrollView top={
        <>
          <BackHeader
            pageTitle={event?.title}
            to={`/c/${event?.community?.slug}/events`}
            right={
              membership?.isLead && <Button
                variant='tertiary'
                onClick={() => setEditOpen(true)}
                disabled={loading}
              >
                Edit
              </Button>
            }
          />
        </>
      }>
        <EventDetails
          isMember={true}
          details={event}
          isAttending={data?.whoami.event?.isAttending}
          isInterested={data?.whoami.event?.isInterested}
          community={event?.community}
          refetch={refetch}
          loading={loading}
        />
        {
          membership?.isLead && (
            <BottomDrawer
              isOpen={editOpen}
              title={'Update Event'}
              onClose={() => setEditOpen(false)}
              size='large'
            >
              <EventForm
                formRef={formRef}
                communitySlug={community?.slug}
                onSubmit={handleUpdateEvent}
                onDelete={handleRemoveEvent}
                initialValues={{
                  ...omit(['location', 'startDate', 'endDate', 'community', 'author', 'channel'], event),
                  location: {
                    ...event.location,
                    description: event.location?.title + (event.location?.subtitle
                      ? `, ${event.location?.subtitle}` : '')
                  },
                  startDate: new Date(event.startDate),
                  startTime: new Date(event.startDate),
                  endDate: event.endDate ? new Date(event.endDate) : null,
                  endTime: event.endDate ? new Date(event.endDate) : null,
                  channelId: event?.channel?.id,
                  bannerIds: event?.banners?.map(banner => banner.id) || []
                }}
              />
              <Button
                variant='regular'
                fullWidth
                onClick={handleSubmit}
                style={{ width: '100%' }}
                disabled={isUpdating}
              >{'Save'}</Button>
            </BottomDrawer>
          )
        }
      </ScrollView>
    </NavLayout >
  );
}, pipe(
  withAuthenticationRequired,
  propTypes({}),
  defaultProps({}),
  displayName('Events'),
  memo,
));
