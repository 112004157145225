import { gql } from "@apollo/client";

export const ADD_COMMUNITY_LEADER = gql`
  mutation addCommunityLeader($input: AddCommunityLeaderInput!) {
    addCommunityLeader(input: $input) {
      community {
        id
        slug
        leads {
          edges {
            node {
              id
              firstName
              lastName
              username
              avatar { id url }
            }
          }
        }
        members {
          edges {
            node {
              id
              firstName
              lastName
              username
              avatar { id url }
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_COMMUNITY_LEADER = gql`
  mutation removeCommunityLeader($input: RemoveCommunityLeaderInput!) {
    removeCommunityLeader(input: $input) {
      community {
        id
        leads {
          edges {
            node {
              id
              firstName
              lastName
              username
              avatar { id url }
            }
          }
        }
        members {
          edges {
            node {
              id
              firstName
              lastName
              username
              avatar { id url }
            }
          }
        }
      }
    }
  }
`;

export const ADD_COMMUNITY_MEMBERS = gql`
  mutation AddCommunityMembers($input: AddCommunityMembersInput!) {
    addCommunityMembers(input: $input) {
      community {
        id
        leads {
          edges {
            node {
              id
              firstName
              lastName
              username
              avatar { id url }
            }
          }
        }
        members {
          edges {
            node {
              id
              firstName
              lastName
              username
              avatar { id url }
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_COMMUNITY_MEMBERS = gql`
  mutation RemoveCommunityMembers($input: RemoveCommunityMembersInput!) {
    removeCommunityMembers(input: $input) {
      community {
        id
        leads {
          edges {
            node {
              id
              firstName
              lastName
              username
              avatar { id url }
            }
          }
        }
        members {
          edges {
            node {
              id
              firstName
              lastName
              username
              avatar { id url }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_COMMUNITY_APPLICATION = gql`
mutation UpdateCommunityApplication($input: UpdateCommunityApplicationInput!) {
  updateCommunityApplication(input: $input) {
    community {
      id
    }
  }
}
`;