import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ width = 33, height = 24 }) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 33 24" fill="none">
      <path d="M29.5 3H23.7208L22.9486 0.68375C22.8126 0.275438 22.4304 0 22 0H11C10.5696 0 10.1874 0.275438 10.0513 0.68375L9.27925 3H3.5C1.84575 3 0.5 4.34575 0.5 6V21C0.5 22.6542 1.84575 24 3.5 24H29.5C31.1542 24 32.5 22.6542 32.5 21V6C32.5 4.34575 31.1542 3 29.5 3ZM3.5 5H6C6.55137 5 7 5.44863 7 6C7 6.55137 6.55137 7 6 7H3.5C2.94863 7 2.5 6.55137 2.5 6C2.5 5.44863 2.94863 5 3.5 5ZM3.5 22C2.94863 22 2.5 21.5514 2.5 21V20H9.02038C9.52688 20.7552 10.1447 21.4296 10.8501 22H3.5ZM16.5 22C12.6402 22 9.5 18.8598 9.5 15C9.5 11.1402 12.6402 8 16.5 8C20.3598 8 23.5 11.1402 23.5 15C23.5 18.8598 20.3598 22 16.5 22ZM30.5 21C30.5 21.5514 30.0514 22 29.5 22H22.1499C22.8552 21.4296 23.4731 20.7552 23.9796 20H30.5V21ZM30.5 18H24.9853C25.3181 17.0612 25.5 16.0515 25.5 15C25.5 10.0374 21.4626 6 16.5 6C11.5374 6 7.5 10.0374 7.5 15C7.5 16.0515 7.68188 17.0612 8.01475 18H2.5V8.828C2.813 8.93894 3.14944 9 3.5 9H6C7.65425 9 9 7.65425 9 6C9 5.64944 8.93894 5.313 8.828 5H10C10.4304 5 10.8126 4.72456 10.9487 4.31625L11.7207 2H21.2792L22.0514 4.31625C22.1874 4.72456 22.5696 5 23 5H29.5C30.0514 5 30.5 5.44863 30.5 6V18Z" fill="#999999" />
      <path d="M16.5 9C13.1916 9 10.5 11.6916 10.5 15C10.5 18.3084 13.1916 21 16.5 21C19.8084 21 22.5 18.3084 22.5 15C22.5 11.6916 19.8084 9 16.5 9ZM16.5 19C14.2944 19 12.5 17.2056 12.5 15C12.5 12.7944 14.2944 11 16.5 11C18.7056 11 20.5 12.7944 20.5 15C20.5 17.2056 18.7056 19 16.5 19Z" fill="#999999" />
      <path d="M19.5 3H13.5C12.9477 3 12.5 3.44769 12.5 4C12.5 4.55231 12.9477 5 13.5 5H19.5C20.0523 5 20.5 4.55231 20.5 4C20.5 3.44769 20.0523 3 19.5 3Z" fill="#999999" />
      <path d="M28.5 6H25.5C24.9477 6 24.5 6.44769 24.5 7C24.5 7.55231 24.9477 8 25.5 8H28.5C29.0523 8 29.5 7.55231 29.5 7C29.5 6.44769 29.0523 6 28.5 6Z" fill="#999999" />
      <path d="M16.5 16C17.0523 16 17.5 15.5523 17.5 15C17.5 14.4477 17.0523 14 16.5 14C15.9477 14 15.5 14.4477 15.5 15C15.5 15.5523 15.9477 16 16.5 16Z" fill="#999999" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('UploadIcon'),
  memo,
));
