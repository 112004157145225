import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = 'black' }) => {
    return (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0684 3.3028H14.9082C14.3677 3.3028 13.9072 2.93577 13.7804 2.41525C13.5135 1.28745 12.5125 0.5 11.358 0.5H8.6353C7.47414 0.5 6.47981 1.28745 6.21288 2.41525C6.08609 2.94244 5.62563 3.3028 5.08509 3.3028H4.92493C2.21555 3.3028 0 5.51835 0 8.2344V15.5684C0 18.2911 2.21555 20.5 4.9316 20.5H15.0684C17.7911 20.5 20 18.2845 20 15.5684V8.2344C20 5.51168 17.7845 3.3028 15.0684 3.3028ZM18.6653 15.5684C18.6653 17.5504 17.0504 19.1653 15.0684 19.1653H4.9316C2.94962 19.1653 1.33467 17.5504 1.33467 15.5684V8.2344C1.33467 6.25242 2.94962 4.63747 4.9316 4.63747H5.09176C6.25292 4.63747 7.24725 3.85002 7.51418 2.72222C7.64097 2.2017 8.10143 1.83467 8.64198 1.83467H11.3647C11.9052 1.83467 12.3657 2.2017 12.4925 2.72222C12.7594 3.85002 13.7604 4.63747 14.9149 4.63747H15.0751C17.0571 4.63747 18.672 6.25242 18.672 8.2344V15.5684H18.6653Z" fill={color} />
        <path d="M10.0037 7.73389C7.66801 7.73389 5.76611 9.63579 5.76611 11.9715C5.76611 14.3071 7.66801 16.209 10.0037 16.209C12.3394 16.209 14.2413 14.3071 14.2413 11.9715C14.2413 9.63579 12.3394 7.73389 10.0037 7.73389ZM10.0037 14.8744C8.40208 14.8744 7.10078 13.5731 7.10078 11.9715C7.10078 10.3699 8.40208 9.06855 10.0037 9.06855C11.6053 9.06855 12.9066 10.3699 12.9066 11.9715C12.9066 13.5731 11.6053 14.8744 10.0037 14.8744Z" fill={color} />
        <path d="M15.2959 6.92627C14.8755 6.92627 14.5352 7.26661 14.5352 7.68703C14.5352 8.10745 14.8755 8.44779 15.2959 8.44779C15.7163 8.44779 16.0567 8.10745 16.0567 7.68703C16.0567 7.26661 15.7163 6.92627 15.2959 6.92627Z" fill={color} />
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('MediaIcon'),
    memo,
));
