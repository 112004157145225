import { memo, useState } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Box } from '@mui/system';
import { Tab, Tabs, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { ADMIN_SEARCH_COMMUNITIES } from 'data/queries/community';
import CommunityTab from './CommunityTab';
import EventTab from './EventTab';

export default applyTo(() => {
  const { data, loading, refetch } = useQuery(ADMIN_SEARCH_COMMUNITIES);
  const [value, setValue] = useState(0);

  return (
    <Box sx={{ p: 3, height: 'calc(100dvh - 60px)' }}>
      <Box sx={{ mb: 2 }}>
        <Tabs value={value} onChange={(e, num) => setValue(num)}>
          <Tab label="Communities" />
          <Tab label="Events" />
          <Tab label="Users" />
        </Tabs>
      </Box>
      {value === 0 && <CommunityTab
        data={data}
        loading={loading}
        refetch={refetch}
      />}
      {value === 1 && (
        <EventTab
          data={data}
          loading={loading}
        />
      )}
      {value === 2 && (
        <Typography sx={{ mb: 1 }}>
          <b>Total</b> {data?.platform?.stats?.userCount}
        </Typography>
      )}
    </Box>
  )
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('AdminHome'),
  memo,
));
