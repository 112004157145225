const { Typography, MenuList, MenuItem, IconButton, Box } = require("@mui/material")
const { DataGrid, GridToolbar } = require("@mui/x-data-grid")
const { default: BottomDrawer } = require("components/BottomDrawer")
const { default: FormWrapper } = require("./FormWrapper")
const { useMutation } = require("@apollo/client")
const { DELETE_FILE } = require("data/mutations/files")
const { UPDATE_COMMUNITY } = require("data/mutations/community")
const { useState, useContext, useCallback, createRef } = require("react")
const { AppContext } = require("context")
const { prop } = require("ramda")
const { Edit } = require("@mui/icons-material")
const { Link } = require("react-router-dom")
const { default: ImageDropZone } = require("components/ImageDropZone")

const CommunityTab = ({ data, loading, refetch }) => {
  const [updateCommunity, { loading: updating }] = useMutation(UPDATE_COMMUNITY);
  const [deleteFile] = useMutation(DELETE_FILE);
  const [drawer, setDrawer] = useState({
    open: false,
    banner: null,
    community: null,
  });
  const [editDrawer, setEditDrawer] = useState({
    open: false,
    community: null,
  });
  const { onError } = useContext(AppContext);

  const handleUpdate = useCallback(async (values) => {
    if (updating) return;
    await updateCommunity({
      variables: {
        input: {
          communityId: editDrawer?.community?.id,
          ...values
        }
      }
    }).then(() => {
      setEditDrawer({ open: false, community: null });
    })
      .catch(err => onError(err.message))
  }, [updateCommunity, updating, onError, editDrawer?.community?.id]);

  const handleOpenEdit = (row) => {
    setEditDrawer({ open: true, community: row });
  }

  const handleFeaturedImageUpload = async (communityId, image) => {
    await updateCommunity({
      variables: {
        input: {
          communityId,
          featuredImageId: image?.id
        }
      }
    })
      .then(async () => {
        if (drawer.banner) {
          await deleteFile({
            variables: {
              input: {
                fileId: drawer.banner?.id
              }
            }
          })
            .then(() => setDrawer({ open: false, banner: null, community: null }))
            .catch(err => onError(err.message));
        } else {
          setDrawer({ open: false, banner: null, community: null });
        }
      })
      .catch(err => onError(err.message))
  }

  const rows = (data?.adminSearchCommunities?.edges || []).map(prop('node'));
  const columns = [
    {
      headerName: 'Edit',
      width: 50,
      renderCell: ({ row }) => {
        return <IconButton size="small" onClick={() => handleOpenEdit(row)}>
          <Edit />
        </IconButton>
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 250,
      renderCell: ({ row }) => {
        return <Link
          to={`/c/${row.slug}`}
        >{`${row.title}`}</Link>
      },
    },
    {
      field: 'slug',
      headerName: 'Slug',
      width: 200,
      renderCell: ({ row }) => {
        return <Box
          onClick={() => navigator.clipboard.writeText(row.slug)}
          sx={{ cursor: 'pointer' }}
        >{`${row.slug}`}</Box>
      },
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      width: 200,
    },
    {
      field: 'isPrivate',
      headerName: 'Private',
      width: 80,
      renderCell: ({ row }) => {
        return <Box
          sx={{ color: row.isPrivate ? 'green' : 'red' }}
        >{`${row.isPrivate}`}</Box>
      },
    },
    {
      field: 'memberCount',
      headerName: 'Members',
      width: 80,
      valueGetter: (params) => params.row.members?.total
    },
    {
      field: 'featuredImage',
      headerName: 'Featured Image',
      width: 120,
      renderCell: ({ row }) => {
        const dropzoneRef = createRef();

        return <ImageDropZone
          dropzoneRef={dropzoneRef}
          initialFile={row?.featuredImage}
          sx={{ width: 50, height: 50 }}
          onComplete={(image) => handleFeaturedImageUpload(row.id, image)}
          onClick={(image) => {
            setDrawer({ open: !!image, community: row, banner: image });
            if (!image && dropzoneRef.current) {
              dropzoneRef.current.open();
            }
          }}
          noClick
          noKeyboard
        />
      },
    },
    {
      field: 'fullName',
      headerName: 'Owner name',
      width: 150,
      valueGetter: (params) => {
        return `${params.row.owner?.firstName || ''} ${params.row.owner?.lastName || ''}`
      },
    },
    {
      field: 'email',
      headerName: 'Owner email',
      width: 300,
      valueGetter: (params) => params.row.owner?.email
    },
  ];

  const handleDeletePhoto = async () => {
    updateCommunity({
      variables: {
        input: {
          communityId: drawer.community?.id,
          featuredImageId: null
        }
      }
    }).then(async () => {
      await deleteFile({
        variables: {
          input: {
            fileId: drawer.banner?.id
          }
        }
      })
      setDrawer({ open: false, banner: null, community: null });
      refetch();
    })
  }

  return (
    <>
      <Typography sx={{ mb: 1 }}>
        <b>Total</b> {data?.platform?.stats?.communityCount}
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading || !data}
        slots={{ toolbar: GridToolbar }}
      />
      <BottomDrawer
        isOpen={drawer.open}
        onClose={() => setDrawer({ open: false, banner: null, community: null })}
      >
        <MenuList>
          <MenuItem onClick={handleDeletePhoto}>Delete photo</MenuItem>
        </MenuList>
      </BottomDrawer>
      <BottomDrawer
        isOpen={editDrawer.open}
        onClose={() => setEditDrawer({ open: false, community: null })}
      >
        <FormWrapper
          initialValues={editDrawer.community}
          onUpdate={handleUpdate}
          updating={updating}
        />
      </BottomDrawer>
    </>
  )
}

export default CommunityTab;