import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import Option from "components/Option";
import { useCallback } from "react";

export const EVENT_REGISTRATIONS = [
  'T_SHIRT_SIZE',
  'BIRTHDAY',
  'ACTIVITIES',
  'ADDRESS',
]

export const EVENT_REGISTRATION_LABELS = {
  T_SHIRT_SIZE: 'T-Shirt Size',
  BIRTHDAY: 'Birthday',
  ACTIVITIES: 'Activities',
  ADDRESS: 'Address',
  NAME: 'Name'
}

export default function RegistrationForm({ requiredFields, allowGuests, setRequiredFields, setAllowGuests }) {
  const handleFieldPress = useCallback((field) => {
    if (requiredFields.includes(field)) {
      setRequiredFields(requiredFields.filter((f) => f !== field));
    } else {
      setRequiredFields([...requiredFields, field]);
    }
}, [requiredFields, setRequiredFields]);

  return (
    <>
      <Typography 
        color='text.secondary' 
        sx={{ mb: '10px' }}
      >
        Attendee name is always required for registration
      </Typography>
      <Option
        selected={true} 
        label='Name'
        position='single'
      />
      <Typography 
        color='text.secondary' 
        sx={{ mb: '10px', mt: '20px' }}
      >
        What other information do you need from attendees when they register?
      </Typography>
      {EVENT_REGISTRATIONS.map((field, i) => (
          <Option 
              key={field}
              selected={requiredFields.includes(field)}
              label={EVENT_REGISTRATION_LABELS[field]}
              onSelect={() => handleFieldPress(field)}
              position={EVENT_REGISTRATIONS.length - 1 === i 
                ? 'bottom' 
                : i === 0 ? 'top' : 'middle'}
          />
      ))}
      <Typography 
        color='text.secondary' 
        sx={{ mb: '10px', mt: '20px' }}
      >
        Registration Settings
      </Typography>
      <Stack 
        direction="row" 
        justifyContent="space-between" 
        alignItems="center"
        sx={{
          borderRadius: '8px',
          border: '1px solid #999999',
          px: '15px',
          py: '10px',
        }}
      >
        <Typography style={{
          color: '#666666'
        }}>Allow attendees to bring guests</Typography>
        <FormControlLabel
          sx={{ alignItems: 'flex-start', mr: 0 }}
          control={<Switch
            inputProps={{ 'aria-label': 'controlled' }}
            name="isPublic"
            checked={allowGuests}
            onChange={(evt) => {
              setAllowGuests(evt.target.checked)
            }}
          />}
        />
      </Stack>
    </>
  )
}