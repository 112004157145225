import { useRef } from "react";

export default function usePostLocking({container, ids}) {
  const scrollPosition = useRef(undefined);
  // is the id of the post at the top of the screen, before locking
  const lastTopId = useRef(undefined);

  function getPositionOf(id) {
    return container?.current?.querySelector(`#${id}`)?.getBoundingClientRect().top;
  }

  return {
    lock: () => {
      const last = ids?.[0];
      lastTopId.current = last;

      scrollPosition.current = getPositionOf(last);
    },
    unlock: () => {
      const scrollTop = getPositionOf(lastTopId.current);

      container.current.scrollTop = scrollTop - scrollPosition.current;
    }
  }
}