import { useQuery } from "@apollo/client";
import { Box, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Image from "components/Image";
import LoadingIconButton from "components/LoadingIconButton";
import PlusIcon from "components/icons/PlusIcon";
import { AppContext } from "context";
import { IS_ALBUM_MEDIA } from "data/queries/media";
import { useContext, useEffect, useRef, useState } from "react";

const originalSizeUrl = (image, cloudinary) => {
  if (!image) return '';
  return image.publicId ? cloudinary
    .image(image.publicId)
    .toURL() : image.src;
}

export default function CarouselModal({ isOpen, onClose, images, selected, onSelect, onAddMedia }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { cloudinary } = useContext(AppContext);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const sliderRef = useRef();
  const addMediaRef = useRef();
  const [loading, setLoading] = useState(false);
  const { data, loading: isMediaLoading } = useQuery(IS_ALBUM_MEDIA, {
    skip: !isOpen || !images?.[selected]?.id,
    variables: {
      fileId: images?.[selected]?.id
    }
  });

  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isRightSwipe) {
      onSelect(selected === 0 ? images.length - 1 : selected - 1);
    }
    if (isLeftSwipe) {
      onSelect(selected === images.length - 1 ? 0 : selected + 1);
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sliderRef.current && !sliderRef.current.contains(event.target)
        && (images.length <= 1 ||
          (addMediaRef.current && !addMediaRef.current.contains(event.target)))) {
        onClose();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleAddMedia = async () => {
    if (!onAddMedia || data?.isAlbumMedia) return;
    setLoading(true);
    await onAddMedia(images?.[selected]?.id);
    setLoading(false);
  }

  if (!images || images.length === 0) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          margin: 0,
          backgroundColor: 'transparent !important',
          boxShadow: 'none !important',
          height: '100%',
          width: '100%',
          maxHeight: '100%',
          maxWidth: '100%',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
        }
      }}
    >
      <Stack
        direction='row'
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        justifyContent='center'
        ref={sliderRef}
      >
        {isDesktop && images?.length > 1 && (
          <IconButton onClick={() => onSelect(selected === 0 ? images.length - 1 : selected - 1)}>
            <LeftArrow />
          </IconButton>
        )}
        <Image
          src={originalSizeUrl(images?.[selected], cloudinary)}
          altText={images?.[selected]?.alt}
          sx={{
            width: '100%',
            maxHeight: 'calc(100dvh - 180px)',
            objectFit: 'contain',
            maxWidth: '648px',
          }}
        />
        {isDesktop && images?.length > 1 && (
          <IconButton onClick={() => onSelect(selected === images.length - 1 ? 0 : selected + 1)}>
            <RightArrow />
          </IconButton>
        )}
      </Stack>
      {images.length > 1 && (
        <Stack direction='row' gap={1} justifyContent='center' sx={{ mt: '20px' }}>
          {images?.map((image, idx) => (<Ellipsis selected={idx === selected} />))}
        </Stack>
      )}
      {onAddMedia && !isMediaLoading && <Box ref={addMediaRef} sx={{
        backgroundColor: theme.palette.transparentColors.dark.ninety,
        width: '100%',
        cursor: 'pointer',
        position: 'absolute',
        bottom: 0
      }}
        onClick={handleAddMedia}
      >
        {data?.isAlbumMedia ? (
          <Typography color='white' fontSize='14px' sx={{ p: '15px' }}>
            Photo added to media
          </Typography>
        )
          : (<LoadingIconButton
            variant='tertiary'
            sx={{ p: '15px', fontSize: '14px' }}
            loading={loading}
            onClick={() => null}
            color='white'
          >
            <Stack direction='row' gap={1} alignItems='center'>
              <PlusIcon width={16} height={16} />
              <Typography color='white' fontSize='14px'>Add to media</Typography>
            </Stack>
          </LoadingIconButton>)}
      </Box>}
    </Dialog>
  )
}

function Ellipsis({ selected }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
      <circle cx="4" cy="4" r="4" fill="white" opacity={selected ? 1 : 0.4} />
    </svg>
  )
}

function LeftArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g opacity="0.4">
        <path d="M40 17.5H9.575L23.55 3.525L20 0L0 20L20 40L23.525 36.475L9.575 22.5H40V17.5Z" fill="white" />
      </g>
    </svg>
  )
}

function RightArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g opacity="0.4">
        <path d="M0 17.5H30.425L16.45 3.525L20 0L40 20L20 40L16.475 36.475L30.425 22.5H0V17.5Z" fill="white" />
      </g>
    </svg>
  )
}
