import React, { memo, useCallback, useState } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import withMemberRequired from 'components/withMemberRequired';
import NavLayout from 'components/NavLayout';
import MemberRow from 'components/MemberRow';
import MemberActions from 'components/MemberActions';
import { PRIVATE_COMMUNITY } from 'data/queries/community';
import ScrollView from 'components/ScrollView';

export default applyTo(() => {
  const { community: slug } = useParams();
  const [actionsOpen, setActionsOpen] = useState(false);
  const [member, setMember] = useState();
  const theme = useTheme();
  const { data, error } = useQuery(PRIVATE_COMMUNITY, {
    variables: {
      slug,
    },
  });
  const community = data?.whoami?.community?.node;
  const isLead = data?.whoami?.community?.isLead;

  const openActions = (user) => {
    setMember(user);
    setActionsOpen(true);
  }

  const closeActions = useCallback(() => {
    setMember();
    setActionsOpen(false);
  }, [setMember, setActionsOpen]);

  if (error) return null;

  return (
    <NavLayout>
      <ScrollView top={
        <Typography variant="h2">{community?.title}</Typography>
      }>
        {community?.slug && <Typography variant="h3" color="transparentColors.dark.fortyFive">
          @{community?.slug}
        </Typography>}
        <Typography sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
          {community?.description}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant='body1' color="transparentColors.dark.eighty" fontWeight='bold'>
              Founded
            </Typography>
            <Typography>{community?.yearFounded}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body1' color="transparentColors.dark.eighty" fontWeight='bold'>
              Members
            </Typography>
            <Typography>{community?.members.total}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack direction='row' gap={1} alignItems='center'>
              <Typography variant='body1' color="transparentColors.dark.eighty" fontWeight='bold'>
                Location
              </Typography>
              {community?.locations?.map((location, i) => (
                <Typography
                  key={i}
                  variant='body2'
                  color='white'
                  sx={{
                    padding: '8px 15px',
                    backgroundColor: theme.palette.transparentColors.dark.twentyFive,
                    borderRadius: '6px'
                  }}
                >{location.locality}, {location.state}</Typography>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Typography variant='body1' color="transparent.dark.eighty" fontWeight='bold'>
          Leaders
        </Typography>
        {community?.leads.edges.map((m, i) => {
          return (
            <MemberRow
              key={i}
              member={m.node}
              onOpenActions={m.node.id === data?.whoami?.id
                ? false
                : () => openActions({
                  isLead: m?.node?.community?.isLead,
                  ...(m?.node || {})
                })}
            />
          )
        })}
        <MemberActions
          community={community}
          isOpen={actionsOpen}
          onClose={closeActions}
          user={member}
          isLead={isLead}
        />
      </ScrollView>
    </NavLayout>
  );
}, pipe(
  withMemberRequired,
  propTypes({}),
  defaultProps({}),
  displayName('About'),
  memo,
));
