import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { pipe, applyTo, path, replace, tap, find, filter, map, prop } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Stack } from '@mui/system';

import ReplyInput from 'components/Lexical/ReplyInput';
import useChatRoom, { CHAT_LIMIT } from 'hooks/chat/useChatRoom';

import { useMutation } from '@apollo/client';
import ChatThread from 'components/ChatThread';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'lib/firebase';
import { useLocation } from 'react-router-dom';
import Chat from './Chat';
import ChannelMembers from 'components/Channel/ChannelMembers';
import Header from './Header';
import Notifications from './Notifications';
import { REACT_TO_POST, NOTIFY_COMMUNITY_MEMBERS, NOTIFY_CHANNEL_MEMBERS } from 'data/mutations/channel';
import NoContentModal from 'components/NoContentModal';
import { CREATE_ALBUM } from 'data/mutations/community';
import { COMMUNITY_ALBUMS } from 'data/queries/channel';
import { AppContext } from 'context';

export default applyTo(({
  loading,
  channel,
  author,
  community,
  isLead,
  canPost,
  readOnly,
  enableReactions = true,
  enableReply = true,
  section,
  setSection,
  onBack,
  isDM
}) => {
  const [chatRoom, { loading: messagesLoading, loadingPrev }] = useChatRoom(channel?.twilioSid);
  const [focused, setFocused] = useState(false);
  const [replyTo, setReplyTo] = useState();
  const [reactToPost, { loading: reacting }] = useMutation(REACT_TO_POST);
  const [notifyCommunityMembers] = useMutation(NOTIFY_COMMUNITY_MEMBERS);
  const [notifyChannelMembers] = useMutation(NOTIFY_CHANNEL_MEMBERS);
  const [createAlbum] = useMutation(CREATE_ALBUM, {
    refetchQueries: [{
      query: COMMUNITY_ALBUMS,
      variables: {
        slug: community?.slug,
        channelSlug: 'media'
      }
    }]
  });
  const { hash } = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const replyRef = useRef();
  const inputRef = useRef();
  const [showNoMessages, setShowNoMessages] = useState(false);
  const bottomRef = useRef();
  const [replyHeight, setReplyHeight] = useState(replyRef?.current?.clientHeight);
  const { onError } = useContext(AppContext);

  const handleScrollToBottom = useCallback(() => {
    bottomRef?.current?.scrollIntoView({ behavior: 'instant' });
  }, []);

  useEffect(() => {
    if (focused) handleScrollToBottom();
    setReplyHeight(replyRef?.current?.clientHeight);
  }, [focused, handleScrollToBottom]);

  useEffect(() => {
    if (!messagesLoading && chatRoom?.messages?.length === 0) {
      setShowNoMessages(true);
    } else {
      setShowNoMessages(false);
    }
  }, [messagesLoading, chatRoom?.messages]);

  useEffect(() => {
    if (loading || loadingPrev || chatRoom?.messages?.length === 0) return;

    if (chatRoom?.messages?.length <= CHAT_LIMIT) {
      // delay scrolling slightly to allow view to render
      setTimeout(() => {
        handleScrollToBottom();
      }, [200]);
    } else {
      // show new message notification
    }
  }, [bottomRef, chatRoom.messages, loading, loadingPrev, handleScrollToBottom]);

  useEffect(() => {
    if (messagesLoading) return;
    if (hash) {
      if (chatRoom?.search?.attributes?.replyTo) {
        const replyTo = chatRoom?.messages?.find(msg => msg.sid === chatRoom?.search?.attributes?.replyTo);
        if (replyTo) {
          setReplyTo(replyTo);
          setSection('thread');
        }
      }
      const el = document.getElementById(replace('#', '', hash));
      el?.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatRoom, messagesLoading, hash, setSection]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (replyRef.current && !replyRef.current.contains(event.target)) {
        setFocused(false);
      } else if (replyRef.current) {
        setFocused(true);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleSend = async (text, attributes = {}, mentions = []) => {
    if (!text) return;

    attributes = replyTo ? { ...attributes, replyTo: replyTo.sid } : attributes;
    return chatRoom?.sendMessage(text, attributes)
      .then(tap(() => {
        logEvent(analytics, "send_message", {
          community: community?.slug,
          channel: channel?.slug,
        })
      }))
      .then(tap(async (index) => {
        const post = await chatRoom.getMessages(1, index).then(path(['items', 0]));
        handleScrollToBottom();
        if (!post) return;
        if (isLead
          && find(mention => mention.id === 'ALL', Object.values(mentions))
        ) {
          notifyCommunityMembers({
            variables: {
              input: {
                postId: post.sid,
                channelId: channel.id,
                communityId: community.id
              }
            }
          }).catch(err => onError(err.message));
        }
        const memberMentions = filter(mention => mention.id !== 'ALL', Object.values(mentions));
        if (memberMentions.length > 0) {
          notifyChannelMembers({
            variables: {
              input: {
                postId: post.sid,
                channelId: channel.id,
                memberIds: map(prop('id'), memberMentions)
              }
            }
          }).catch(err => onError(err.message));
        }
      }));
  };

  const handleAddMedia = fileId => {
    return createAlbum({
      variables: {
        input: {
          communityId: community?.id,
          coverImageId: fileId,
          fileIds: [fileId]
        }
      }
    })
      .catch(err => onError(err.message));
  }

  const handleReaction = msg => async emojiType => {
    if (reacting) return;

    await reactToPost({
      variables: {
        input: {
          postId: msg.sid,
          emojiType,
          conversationSid: channel?.twilioSid,
        }
      }
    })
      .then(() => {
        logEvent(analytics, 'post_reaction', {
          channel: channel?.slug,
          community: community?.slug
        });
      })
      .catch(err => onError(err.message))
  };

  const handleOpenThread = (msg) => {
    setSection('thread');
    setReplyTo(msg);
  }

  return (
    <Stack>
      {section !== 'thread' && <Header
        channel={channel}
        isLead={isLead}
        onMembersClick={setSection && (() => setSection('members'))}
        onNotificationsClick={setSection && (() => setSection('notifications'))}
        onTitleClick={() => setSection('chat')}
        section={section}
        onBack={onBack}
      />}
      {section === 'thread' && (
        <ChatThread
          threads={chatRoom?.threads}
          author={author}
          community={community}
          channel={channel}
          isLead={isLead}
          onAddMedia={isLead && handleAddMedia}
          onReaction={enableReactions && handleReaction}
          onSend={handleSend}
          onBack={() => {
            if (setSection) setSection('chat');
            setReplyTo();
          }}
          onReplyTo={enableReply && ((msg) => handleOpenThread(msg))}
          replyTo={replyTo}
        />
      )}
      {section === 'chat' && <Chat
        loading={loading || messagesLoading}
        channel={channel}
        community={community}
        isLead={isLead}
        author={author}
        readOnly={readOnly}
        canPost={canPost}
        enableReactions={enableReactions}
        enableReply={enableReply}
        onOpenThread={handleOpenThread}
        chatRoom={chatRoom}
        onAddMedia={isLead && handleAddMedia}
        replyOpen={focused}
        bottomRef={bottomRef}
        replyHeight={replyHeight}
      />}
      {section === 'members' &&
        <ChannelMembers
          community={community}
          channel={channel}
          isAdmin={isLead}
        />
      }
      {section === 'notifications' && (
        <Notifications
          community={community}
          channel={channel}
        />
      )}
      {!loading && section === 'chat' && (isDM || !community?.isPaused) && canPost && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: isDesktop ? '320px' : 0,
            right: 0,
            backgroundColor: theme.palette.transparentColors.light.ninety,
            zIndex: 10
          }}
          onFocus={() => setFocused(true)}
          ref={replyRef}
        >
          <ReplyInput
            onSend={handleSend}
            focused={focused}
            disabled={!canPost}
            isLead={isLead}
            community={community}
            author={author}
            channelName={channel?.name}
            inputRef={inputRef}
          />
        </Box>
      )}
      <NoContentModal
        isOpen={showNoMessages}
        onClose={() => {
          setFocused(true);
          inputRef?.current?.focus();
        }}
        message='There are currently no posts in this channel.'
        ctaText={canPost ? 'New Post' : null}
      />
    </Stack>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('Channel'),
  memo,
));
