import { Box, Button, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

export default function EventChannel({ link, channelSlug, style = {} }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.overlay.secondary,
        borderRadius: '10px',
        padding: '20px',
        ...style
      }}
    >
      <Typography
        variant='h3'
        color={theme.palette.transparentColors.dark.fortyFive}
        style={{
          marginBottom: '15px'
        }}
      >
        Join the conversation
      </Typography>
      <Button variant='tertiary' component={Link} to={link}>
        #{channelSlug}
      </Button>
    </Box>
  )
}