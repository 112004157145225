import { Box } from "@mui/material";
import UpgradeAlertIcon from "components/icons/UpgradeAlertIcon";
import { Link } from "react-router-dom";

export default function UpgradeAlert({ subscription, slug }) {
  if (!subscription || ((subscription?.status !== 'PENDING_CANCELLATION'
    && subscription?.status !== 'TRIALING'))) {
    return null;
  }

  return (
    <Box
      component={Link}
      to={`/c/${slug}/settings/funding`}
      sx={{ mt: 0.6 }}
    >
      <UpgradeAlertIcon />
    </Box>
  )
}