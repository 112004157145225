import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";

export default function Option({ selected, label, position, sx = {}, onSelect }) {
  const borderRadius = useMemo(() => {
    if (position === 'single') return '8px';
    if (position === 'top') return '8px 8px 0px 0px';
    if (position === 'bottom') return '0px 0px 8px 8px';
    return '0px';
  }, [position]);

  return (
    <Box
      sx={{
        width: '100%',
        border: '1px solid #CCCCCC',
        borderRadius,
        padding: '13px 20px',
        borderTopWidth: position === 'top' || position === 'single' ? '1px' : '0px',
        backgroundColor: selected ? '#FFFFFF' : 'transparent',
        ...sx
      }}
      onClick={onSelect}
    >
      <Stack direction='row' gap='20px' alignItems='center'>
        {selected ? <CheckmarkIcon /> : <Box sx={{ width: '18px', height: '18px' }}/>}
        <Box sx={{ height: '25px', width: '1px', backgroundColor: '#CCCCCC' }} />
        <Typography color={selected ? 'text.primary' : 'text.secondary'}>{label}</Typography>
      </Stack>
    </Box>
  )
}

function CheckmarkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M1 10.5L7 15.5L16.5 2" stroke="black" stroke-width="2" stroke-linecap="round"/>
    </svg>
  )
}