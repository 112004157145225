import { Button, IconButton, Stack } from "@mui/material";
import { Link, useLocation, useMatch } from "react-router-dom";
import MessagesIcon from 'components/icons/Nav/MessagesIcon';
import UpgradeAlert from "../UpgradeAlert";
import UserAvatar from "components/UserAvatar";
import useCustomAuth0 from "hooks/useCustomAuth0";

export default function RightNav({ communityView, communitySlug, isMember, isLead, avatarUrl, subscription }) {
  const { pathname } = useLocation();
  const { isAuthenticated, loginWithRedirect } = useCustomAuth0();
  const isReactNative = window.IS_REACT_NATIVE;

  const isProfile = useMatch('/profile');

  const handleLogin = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'login',
      },
      appState: {
        returnTo: window.location.href,
      },
    })
  }

  return (
    <Stack direction='row' alignItems='center'>
      {isLead && communityView && <UpgradeAlert
        subscription={subscription}
        slug={communitySlug}
      />}
      {communitySlug && !isMember && !isProfile && (
        <Button
          variant='contained'
          component={Link}
          to={`/c/${communitySlug}`}
        >
          Join @{communitySlug}
        </Button>
      )}
      {!isAuthenticated && (
        <Button
          variant='outlined'
          onClick={handleLogin}
        >
          Sign In
        </Button>
      )}
      {isAuthenticated && !isReactNative && (
        <>
          <IconButton component={Link} to='/messages'>
            <MessagesIcon
              color={'#999999'}
              active={pathname.includes('/messages')}
            />
          </IconButton>
          <IconButton component={Link} to='/profile'>
            <UserAvatar
              sx={{ width: 38, height: 38 }}
              src={avatarUrl}
            />
          </IconButton>
        </>
      )}
    </Stack>
  )
}