import { gql } from "@apollo/client";

export const MESSAGE_USERS = gql`
  mutation MessageUsers($input: MessageUsersInput!) {
    messageUsers(input: $input) {
      channel {
        id
        slug
        twilioSid
      }
    }
  }
`;

export const STRIPE_PORTAL = gql`
query StripePortal {
  whoami {
    id
    customer {
      portalUrl
    }
  }
}
`;

export const UPDATE_USER = gql`
    mutation UpdateProfile($input: UpdateProfileInput!) {
      updateProfile(input: $input) {
        user {
          id
          displayName
          firstName
          lastName
          username
          email
          phoneNumber
          bio
          pronouns
          gender
          shirtSize
          birthday
          skillLevel
          avatar {
            id
            url
          }
          socialLinks { instagram }
          address {
            address1
            address2
            locality
            administrativeArea
            postalCode
            country
          }
          notifications {
            email {
              directMessages
              channelMessages
              directMention
              newEvents
              eventReminders
              communityAnnouncements
              groupMessageSummary
              communityUpdate
            }
          }
        }
    }
}
`;

export const ADD_TO_INVENTORY = gql`
  mutation AddToMyInventory($input: AddToMyInventoryInput!) {
    addToMyInventory(input: $input) {
      user {
        id
        inventory {
          edges {
            node {
              ...on Vehicle {
                id
                name
                make
                model
                type
                category
                year
                photo {
                  url(input: {
                    transformations: [{
                      width: 500
                    }, {
                      height: 500
                    }]
                  })
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_INVENTORY = gql`
  mutation UpdateInventory($input: UpdateInventoryInput!) {
    updateInventory(input: $input) {
      user {
        id
        inventory {
          edges {
            node {
              ...on Vehicle {
                id
                name
                make
                model
                type
                category
                year
                photo {
                  url(input: {
                    transformations: [{
                      width: 500
                    }, {
                      height: 500
                    }]
                  })
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_INVENTORY = gql`
  mutation DeleteInventory($input: DeleteInventoryInput!) {
    deleteInventory(input: $input) {
      user {
        id
        inventory {
          edges {
            node {
              ...on Vehicle { id }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount {
    deleteAccount {
      success
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation RequestPasswordReset {
    requestPasswordReset {
      success
    }
  }
`;