import React, { useMemo } from 'react';
import { DecoratorNode } from "lexical";
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MENTION } from 'data/queries/channel';

function convertMentionElement(domNode) {
  const text = domNode.textContent;
  const mentionType = domNode.mentionType;
  const slug = domNode.slug;
  const communitySlug = domNode.communitySlug;

  if (text && mentionType && slug) {
    const node = $createMentionLinkNode({ text, mentionType, slug, communitySlug });
    return {
      node
    };
  }

  return null;
}

const Mention = ({ id, text, slug, mentionType, communitySlug }) => {
  const { community } = useParams();
  const { data, loading } = useQuery(MENTION, {
    skip: !id || (text && slug) || id === 'ALL',
    variables: {
      id
    }
  });

  const route = useMemo(() => {
    if (mentionType === 'section') {
      return `/c/${communitySlug || community}/${slug || data?.node?.slug}`;
    }
    return mentionType === 'channel' || data?.node?.__typename === 'Channel'
      ? data?.node?.section === 'system'
        ? `/c/${communitySlug || community}/${slug || data?.node?.slug}`
        : `/c/${communitySlug || community}/channel/${slug || data?.node?.slug}`
      : `/u/${slug || data?.node?.slug}`
  }, [mentionType, slug, communitySlug, community, data]);

  return (
    <Link to={id === 'ALL' ? '' : route} disabled={loading} style={{ fontWeight: 'bold' }}>
      {id === 'ALL' ? 'here' : text || data?.node?.text}
    </Link>
  )
}

export class MentionLinkNode extends DecoratorNode {
  id
  text
  mentionType
  slug
  communitySlug

  static getType() {
    return "mention";
  }

  static clone(node) {
    return new MentionLinkNode(
      node.id,
      node.text,
      node.mentionType,
      node.slug,
      node.communitySlug,
    );
  }

  static importJSON(serializedNode) {
    const {
      id,
      text,
      mentionType,
      slug,
      communitySlug
    } = serializedNode;

    const node = $createMentionLinkNode({
      id,
      text,
      mentionType,
      slug,
      communitySlug
    });

    return node;
  }

  exportDOM() {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-mention", "true");
    element.textContent = this.text;
    element.mentionType = this.mentionType;
    element.slug = this.slug;
    element.communitySlug = this.communitySlug;
    return { element };
  }

  static importDOM() {
    return {
      span: (node) => ({
        conversion: convertMentionElement,
        priority: 0
      })
    };
  }

  constructor(
    id,
    text,
    mentionType,
    slug,
    communitySlug,
    key,
  ) {
    super(key);
    this.id = id;
    this.text = text;
    this.mentionType = mentionType;
    this.slug = slug;
    this.communitySlug = communitySlug;
  }

  exportJSON() {
    return {
      id: this.id,
      version: 1,
      type: "mention",
      mentionType: this.mentionType,
      text: this.text,
      slug: this.slug
    };
  }

  createDOM(config) {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.mention;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return window.location.origin + this.getRoute();
  }

  decorate() {
    return (
      <Mention
        id={this.id}
        text={this.text}
        mentionType={this.mentionType}
        slug={this.slug}
        communitySlug={this.communitySlug}
      />
    );
  }
}

export function $createMentionLinkNode({
  id,
  text,
  mentionType,
  slug,
  key,
  communitySlug
}) {
  return new MentionLinkNode(
    id,
    text,
    mentionType,
    slug,
    communitySlug,
    key,
  );
}

export function $isMentionLinkNode(node) {
  return node instanceof MentionLinkNode;
}
