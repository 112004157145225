import React, { memo, useContext, useMemo, useRef } from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material'
import { pipe, applyTo, omit, reverse } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Stack } from '@mui/system';
import { motion } from 'framer-motion';

import ChatMessage from 'components/Lexical/ChatMessage';

import styles from '../Channel.module.scss';
import { useMutation } from '@apollo/client';
import { AppContext } from 'context';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'lib/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { REACT_TO_POST, REPORT_POST } from 'data/mutations/channel';
import { UPDATE_COMMUNITY_APPLICATION } from 'data/mutations/members';
import LoadingList from '../../LoadingList';
import { useNavbar, useNavbarRetractor } from "../../../context/NavBarContext";
import usePostLocking from "../../../hooks/chat/usePostLocking";

export default applyTo(({
  loading,
  chatRoom,
  channel,
  author,
  community,
  isLead,
  readOnly,
  canPost,
  enableReactions,
  enableReply,
  onOpenThread,
  onAddMedia,
  replyOpen,
  bottomRef,
  replyHeight
}) => {
  const { showAlert, onError } = useContext(AppContext);
  const [reactToPost, { loading: reacting }] = useMutation(REACT_TO_POST);
  const [flagPost, { loading: flagging }] = useMutation(REPORT_POST);
  const [updateCommunityApplication] = useMutation(UPDATE_COMMUNITY_APPLICATION);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const scrollContainer = useRef();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { show } = useNavbar();

  const { messages, ids } = useMemo(() => {
    const m = reverse(chatRoom?.messages || []);

    return {
      messages: m,
      ids: m.map((i) => i.sid),
    }
  }, [chatRoom?.messages]);

  const { lock, unlock } = usePostLocking({
    container: scrollContainer,
    ids
  });

  useNavbarRetractor({
    container: scrollContainer,
    direction: 'up',
    showTop: false
  });

  const handleReaction = msg => async emojiType => {
    if (reacting) return;

    await reactToPost({
      variables: {
        input: {
          postId: msg.sid,
          emojiType,
          conversationSid: channel?.twilioSid,
        }
      }
    })
      .then(() => {
        logEvent(analytics, 'post_reaction', {
          channel: channel?.slug,
          community: community?.slug
        });
      })
      .catch(err => onError(err.message));
  };

  const handleFlagMsg = msg => async () => {
    if (flagging) return;

    await flagPost({
      variables: {
        input: {
          postId: msg.sid,
          channelId: channel?.id,
        }
      }
    })
      .then(() => {
        logEvent(analytics, 'flagged_post', {
          channel: channel?.slug,
          community: community?.slug
        });
      })
      .catch(err => onError(err.message));
  }

  const handleUndoFlagOnMsg = msg => async () => {
    if (isLead) {
      msg.updateAttributes(omit(['reported'], msg?.attributes));
      navigate(pathname);
    }
  }

  const handleUpdateApplication = async (msg, status) => {
    if (!isLead) return;

    await updateCommunityApplication({
      variables: {
        input: {
          communityId: community?.id,
          userId: msg?.author,
          status
        }
      }
    })
      .catch(err => onError(err.message));
    msg.updateAttributes({
      ...msg?.attributes,
      status
    });
  }

  return (
    <motion.div
      ref={scrollContainer}
      style={{
        height: '100%',
        maxHeight: `calc(100dvh - ${showAlert ? '120px' : '70px'})`,
        overflowY: 'scroll',
      }}
    >
      <Stack
        gap={1}
        className={styles.chatContainer}
        sx={{ px: isDesktop ? 3 : '10px', paddingTop: '70px' }}
      >
        {chatRoom.hasPrevPage && <Button
          variant='tertiary'
          onClick={async () => {
            lock();
            await chatRoom.loadPrevMessages(() => {
              setTimeout(() => {
                unlock();
                setTimeout(() => {
                  show({ instant: true });
                }, 20);
              }, 5);
            });
          }}
          style={{
            margin: '0 auto',
          }}
        >
          Load Previous Messages
        </Button>}
        <div />
        {loading && <LoadingList />}
        {!loading && messages.map(msg => (
          <div key={msg.sid} id={msg.sid}>
            <ChatMessage
              dateCreated={msg.dateCreated}
              body={msg.body}
              author={msg.state?.userData}
              attributes={msg.attributes}
              readOnly={readOnly}
              canPost={canPost}
              isLead={isLead}
              onUpdateApplication={(update) => handleUpdateApplication(msg, update)}
              replyCount={chatRoom?.threads[msg.sid]?.length}
              onEdit={canPost && ((body) => msg.updateBody(body))}
              onDelete={() => {
                return msg.remove().then(() => navigate(pathname));
              }}
              onReply={enableReply && (() => onOpenThread(msg))}
              onFlag={handleFlagMsg(msg)}
              onUndoFlag={handleUndoFlagOnMsg(msg)}
              onAddMedia={onAddMedia}
              onReaction={enableReactions && handleReaction(msg)}
              editable={canPost && (author?.id === msg.author ||
                (msg.author === community?.id && isLead))}
              deletable={!readOnly && (isLead || author?.id === msg.author)}
              flaggable={!readOnly && !!community?.id}
              pinnable={isLead}
            />
          </div>
        ))}
        <div
          ref={bottomRef}
          style={{
            minHeight: replyOpen ? `${replyHeight}px`
              : (!community?.isPaused && canPost && chatRoom?.messages &&
                chatRoom?.messages?.length > 0) ? '50px' : '40px'
          }}
        ></div>
      </Stack>
    </motion.div>
  );
}, pipe(
  propTypes({}),
  defaultProps({
    isMsgEditable: () => false,
    isMsgDeletable: () => false
  }),
  displayName('Chat'),
  memo,
));
