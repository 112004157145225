export const Vert = {
  fragments: {
    User: `
            fragment UserFields on User {
                id
                username
                email
                firstName
                lastName
                phoneNumber
            }
        `,
    Event: `
            fragment EventFields on Event {
              id
              slug
              title
              subtitle
              description
              websiteUrl
              activities
              ticketingUrl
              location {
                title
                subtitle
                googlePlaceId
                lat
                lng
                url
              }
              reactions
              channel {
                id
                slug
                emoji
              }
              attending(input: { first: 10 }) {
                total
                edges {
                  node {
                    id
                    displayName
                    avatar {
                      id
                      url(input: {
                        transformations: [{
                          width: 100,
                          height: 100,
                          crop: "fill",
                          gravity: "auto"
                        }]
                      })
                    }
                  }
                }
              }
              interested(input: { first: 10 }) {
                total
                edges {
                  node {
                    id
                    displayName
                    avatar {
                      id
                      url(input: {
                        transformations: [{
                          width: 100,
                          height: 100,
                          crop: "fill",
                          gravity: "auto"
                        }]
                      })
                    }
                  }
                }
              }
              pending(input: { first: 100 }) {
                total
                edges {
                  node {
                    id
                    displayName
                    avatar {
                      id
                      url(input: {
                        transformations: [{
                          width: 100,
                          height: 100,
                          crop: "fill",
                          gravity: "auto"
                        }]
                      })
                    }
                  }
                }
              }
              banners {
                ...on Image {
                  id
                  url(input: {
                    transformations: [{
                      aspect_ratio: "4:5",
                      crop: "fill",
                    }]
                  })
                }
              }
              isPublished
              isPromoted
              isPrivate
              startDate
              endDate
              tags
              author {
                ...on User {
                  id
                  slug: displayName
                }
                ...on Community {
                  id
                  slug
                }
              }
            }
        `
  }
}