import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(() => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1.70711" y1="1.63615" x2="13.0208" y2="12.9499" stroke="#FF4D00" strokeWidth="2" />
      <line y1="-1" x2="16" y2="-1" transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.6992 2.34326)" stroke="#FF4D00" strokeWidth="2" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('SendIcon'),
  memo,
));
