import { Dialog, Divider, IconButton, Slide, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import BackArrow from 'components/icons/BackArrow';

const Transition = React.forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FullScreenDialog = ({
  title,
  isOpen = false,
  onClose,
  children,
  className,
  PaperProps,
  ...props
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen
      {...props}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: { backgroundColor: theme.palette.overlay.secondary, backgroundImage: 'none' },
        ...PaperProps
      }}
    >
      <Stack sx={{ maxHeight: '100dvh', height: 1 }} className={className}>
        {(onClose || title) && <Stack
          direction='row'
          gap={1}
          alignItems='center'
          sx={{ mx: '20px', mt: '20px' }}
        >
          <IconButton onClick={onClose} sx={{ p: 0 }}>
            <BackArrow />
          </IconButton>
          <Typography variant='h3'>{title}</Typography>
        </Stack>}
        {(onClose || title) && isDesktop && <Divider sx={{ mt: 2 }} />}
        {(onClose || title)
          ? <Stack sx={{ height: 1, pt: 2, mb: 2, overflow: 'auto' }}>{children}</Stack>
          : children}
      </Stack>
    </Dialog>
  )
}

FullScreenDialog.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node
}

export default FullScreenDialog;