import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ width = 13, height = 14 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 14" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6607 9.51629L12.2016 10.3349C12.3942 10.3831 12.5387 10.5275 12.4905 10.7442V11.2739C12.4905 11.4906 12.2979 11.6833 12.0812 11.6833H0.909316C0.692619 11.7073 0.5 11.5147 0.5 11.2739V10.7442C0.5 10.5516 0.644464 10.359 0.837083 10.3349L2.37804 9.51629V5.30275C2.37804 3.49695 3.65414 1.95599 5.38771 1.61891V1.54668C5.38771 0.896587 5.86926 0.415039 6.51935 0.415039C7.16944 0.415039 7.65098 0.896587 7.65098 1.54668V1.61891C9.38456 1.95599 10.6607 3.49695 10.6607 5.30275V9.51629ZM8.25292 12.4537C7.96399 13.1279 7.26575 13.5854 6.51935 13.5854C5.77295 13.5854 5.09878 13.1279 4.78578 12.4537H8.25292Z" fill="white" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('NotificationsIcon'),
  memo,
));
