import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2402 5.58984L11.6602 16.1698L7.48016 11.9998L6.07016 13.4098L11.6602 18.9998L23.6602 6.99984L22.2402 5.58984ZM18.0002 6.99984L16.5902 5.58984L10.2502 11.9298L11.6602 13.3398L18.0002 6.99984ZM6.00016 18.9998L0.410156 13.4098L1.83016 11.9998L7.41016 17.5898L6.00016 18.9998Z" fill="#2FAC4A" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('AttendingIcon'),
  memo,
));
