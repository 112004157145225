import { memo, useCallback, useContext } from 'react';
import { pipe, applyTo, path } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';
import { publicClient } from 'lib/apollo';
import { AppContext } from 'context';
import { Stack, Typography, Button, Divider, Box } from '@mui/material';
import Image from "components/Image";
import PropTypes from 'prop-types';
import { PUBLIC_COMMUNITY } from 'data/queries/community';
import LoadingList from 'components/LoadingList';
import useCustomAuth0 from 'hooks/useCustomAuth0';

export default applyTo(({
  actions,
  loading: parentLoading
}) => {

  const { community: slug } = useParams();
  const { pathname } = useLocation();

  const { loginWithRedirect, isAuthenticated } = useCustomAuth0();

  // this was created to "loop" logins for a being used in a "booth" setting
  const isBoothMode = pathname === `/c/${slug}/booth`;

  const { onError } = useContext(AppContext);

  const { data, loading, error } = useQuery(PUBLIC_COMMUNITY, {
    variables: {
      slug,
    },
    client: publicClient,
  });
  const loadingState = parentLoading || loading;

  const community = pathOr({}, ['community'], data);
  const logoUrl = path(
    ["logo", "url"],
    community
  );
  const locations = pathOr([], ['locations'], community);

  const handleLogin = useCallback((screen_hint) => async () => {
    // NOTE: that you cannot signup with an organziation. only login
    // https://community.auth0.com/t/cant-reach-signup-page-when-using-organizations/66468/9
    loginWithRedirect({
      authorizationParams: {
        screen_hint: screen_hint || 'login',
      },
      appState: {
        // this route will add the authed user to the community and redirect to the private community home
        returnTo: isBoothMode ? `/c/${slug}/join/booth` : `/c/${slug}/join`,
      },
    })
  }, [loginWithRedirect, slug, isBoothMode]);

  if (error) return onError(error.message);

  return (
    <Stack sx={{
      backgroundColor: '#F8F8F8',
      height: '100dvh',
      maxHeight: '100dvh',
    }}>
      {community?.cover?.asset?.url && <Box sx={{
        height: '200px',
        width: '100%',
        backgroundImage: `url(${community?.cover?.asset?.url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }} />}
      <Stack gap={0} sx={{
        maxWidth: 470,
        width: '100%',
        pt: '40px',
        px: '30px',
        zIndex: 1,
        margin: '0 auto'
      }}>
        <Stack gap={2}>
          <Image
            src={logoUrl}
            alt="rally moto"
            sx={{ alignSelf: "start", borderRadius: "17px", width: '80px', height: '80px' }}
            placeholder='https://placehold.co/80x80/999999/999999'
            loading={loadingState}
          />
          {loadingState ? <LoadingList count={1} height={100} /> : <Stack gap={0}>
            <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
              {community.title}
            </Typography>
          </Stack>}

          {!loadingState && locations?.length > 0 && (
            <Stack gap={0.5} direction="row" flexWrap='wrap'>
              {locations.map((location) => (
                <Typography variant="p" key={location.locality} sx={{
                  borderRadius: '6px',
                  backgroundColor: 'rgba(0,0,0,0.25)',
                  padding: '8px 15px',
                  color: '#fff',
                  whiteSpace: 'nowrap',
                  fontSize: '12px',
                }}>
                  {location.locality}, {location.state}
                </Typography>
              ))}
            </Stack>
          )}

          {loadingState ? <LoadingList count={1} height={200} /> : <Stack gap={0.5}>
            <Typography variant="p" fontSize={14}>
              {community.description}
            </Typography>
          </Stack>}


          {!loadingState && isAuthenticated && (
            actions
          )}

          {!loadingState && !isAuthenticated && (
            <>
              <Divider />
              <Stack gap={0.5}>
                <Typography variant="h3" fontSize={18}>
                  Join the community
                </Typography>
                <Typography variant="p" fontSize={14}>
                  First sign in or create a Rally account
                </Typography>
              </Stack>
            </>
          )}

          {!loadingState && !isAuthenticated && (
            <Stack gap={2} direction="row" alignItems='center'>
              <Button
                variant="regular"
                onClick={handleLogin('signup')}
              >Create Account</Button>
              <Button
                variant="regular"
                onClick={handleLogin('login')}
                sx={{ background: 'transparent' }}
              >Sign In</Button>
            </Stack>
          )}

        </Stack>
      </Stack>
    </Stack >
  );
}, pipe(
  propTypes({
    actions: PropTypes.node,
  }),
  defaultProps({
    actions: null,
  }),
  displayName('CommunityHomePublic'),
  memo,
));
