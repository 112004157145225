import { useState } from "react";

import { Container, Button } from "@mui/material";

import BottomDrawer from "components/BottomDrawer";
import LinkIcon from "components/icons/LinkIcon";

export default function InviteLink({ isLead, community, isOpen, onClose }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleAction = (action, value) => {
    switch (action) {
      case "copy":
        navigator.clipboard.writeText(value);
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2500);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <BottomDrawer isOpen={isOpen} onClose={onClose} title="Invite Link">
        <Container sx={{ mt: 2 }}>
          <Button
            sx={{
              backgroundColor: "#292929",
              color: "#A0A0A0",
              textTransform: "none",
              fontWeight: "normal",
              padding: "15px 30px",
              borderRadius: "10px",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              margin: "20px 0",
              gap: "40px",
              textAlign: "left",
              transition: "background-color 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: "rgba(41,41,41,.7)",
                color: "#A0A0A0",
              },
            }}
            onClick={() =>
              handleAction("copy", `https://${community?.slug}.letsrally.io`)
            }
          >
            {isCopied ? "Link copied..." : `${community?.slug}.letsrally.io`}

            <LinkIcon />
          </Button>
        </Container>
      </BottomDrawer>
    </>
  );
}
