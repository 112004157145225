import { memo, useCallback, useContext } from 'react';
import { Box } from '@mui/material'
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useMutation } from '@apollo/client';

import ChannelForm from 'components/forms/ChannelForm';
import { AppContext } from 'context';
import BottomDrawer from 'components/BottomDrawer';
import { CREATE_CHANNEL } from 'data/mutations/channel';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(({ onComplete, onClose, community, isOpen }) => {
  const { onError } = useContext(AppContext);
  const [createChannel] = useMutation(CREATE_CHANNEL);

  const handleSubmit = useCallback(async (values) => {
    await createChannel({
      variables: {
        input: {
          communityId: community?.id,
          ...values
        }
      }
    })
      .then(() => onComplete())
      .catch(err => onError(err.message))
  }, [createChannel, community, onComplete, onError]);

  return (
    <BottomDrawer
      isOpen={isOpen}
      onClose={onClose}
      title='New Channel'
      size='large'
    >
      <Box sx={{ mt: 2 }}>
        <ChannelForm onSubmit={handleSubmit} />
      </Box>
    </BottomDrawer>
  );
}, pipe(
  withAuthenticationRequired,
  propTypes({}),
  defaultProps({}),
  displayName('CreateChannelDialog'),
  memo,
));
