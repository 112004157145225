import React, { memo, useContext, useEffect, useState } from 'react';
import { pipe, applyTo, tap, find, filter, map, prop, path } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { AppContext } from 'context';
import useChatRoom from 'hooks/chat/useChatRoom';
import { Box, Button, MenuItem, MenuList, Stack, Typography } from '@mui/material';
import ChatThread from 'components/ChatThread';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'lib/firebase';
import withMemberRequired from 'components/withMemberRequired';
import NavLayout from 'components/NavLayout';
import BottomDrawer from 'components/BottomDrawer';
import ConfirmMenu from 'components/ConfirmMenu';
import ScrollView from 'components/ScrollView';
import { COMMUNITY_ALBUMS } from 'data/queries/channel';
import { JOIN_CHANNEL, NOTIFY_CHANNEL_MEMBERS, NOTIFY_COMMUNITY_MEMBERS, REACT_TO_POST } from 'data/mutations/channel';
import NoContentModal from 'components/NoContentModal';
import PlusIcon from 'components/icons/PlusIcon';
import UploadDialog from 'components/UploadDialog';
import { CREATE_ALBUM } from 'data/mutations/community';
import Album from './Album';
import { EMPTY_MESSAGE } from 'lib/lexical';
import { DELETE_ALBUM } from 'data/mutations/files';


export default applyTo(() => {
  const { community: slug } = useParams();
  const { data, loading, error, refetch } = useQuery(COMMUNITY_ALBUMS, {
    variables: {
      slug,
      channelSlug: 'media'
    },
  });
  const isLead = data?.whoami?.community?.isLead;
  const channel = data?.whoami.community?.node.channel?.node;
  const { onError } = useContext(AppContext);
  const albums = data?.whoami.community?.node.albums?.edges;
  const [chatRoom] = useChatRoom(channel?.twilioSid, 100, 'forward');
  const [threadOpen, setThreadOpen] = useState(false);
  const [replyTo, setReplyTo] = useState();
  const [reactToPost, { loading: reacting }] = useMutation(REACT_TO_POST);
  const [joinChannel, { error: joinError }] = useMutation(JOIN_CHANNEL);
  const [notifyCommunityMembers] = useMutation(NOTIFY_COMMUNITY_MEMBERS);
  const [notifyChannelMembers] = useMutation(NOTIFY_CHANNEL_MEMBERS);
  const [createAlbum] = useMutation(CREATE_ALBUM);
  const [media, setMedia] = useState();
  const [optionsOpen, setShowOptions] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showNoMessages, setShowNoMessages] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteAlbum] = useMutation(DELETE_ALBUM);

  useEffect(() => {
    if (!loading && albums?.length === 0) {
      setShowNoMessages(true);
    } else {
      setShowNoMessages(false);
    }
  }, [loading, albums]);

  useEffect(() => {
    if (!loading && data && !joinError && !channel) {
      joinChannel({
        variables: {
          input: {
            channelSlug: 'media',
            communityId: data?.whoami.community?.node.id
          }
        }
      })
        .then(refetch)
        .catch(err => onError(err.message))
    }
  }, [channel, data, loading, joinChannel, refetch, joinError, onError])

  const handleSend = async (text, attributes = {}, mentions = []) => {
    if (!text) return;

    attributes = replyTo ? { ...attributes, replyTo: replyTo.sid } : attributes;
    await chatRoom?.sendMessage(text, attributes)
      .then(tap(() => logEvent(analytics, "send_message", {
        community: slug,
        channel: channel?.slug,
      })))
      .then(tap(async (index) => {
        const post = await chatRoom.getMessages(1, index).then(path(['items', 0]));
        if (!post) return;
        if (data?.whoami.community?.node?.isLead
          && find(mention => mention.id === 'ALL', Object.values(mentions))
        ) {
          notifyCommunityMembers({
            variables: {
              input: {
                postId: post.sid,
                channelId: channel.id,
                communityId: data?.whoami.community?.node?.id
              }
            }
          }).catch(err => onError(err.message));
        }
        const memberMentions = filter(mention => mention.id !== 'ALL', Object.values(mentions));
        if (memberMentions.length > 0) {
          notifyChannelMembers({
            variables: {
              input: {
                postId: post.sid,
                channelId: channel.id,
                memberIds: map(prop('id'), memberMentions)
              }
            }
          }).catch(err => onError(err.message));
        }
      }));
  };

  const handleAddMedia = async (fileId) => {
    await createAlbum({
      variables: {
        input: {
          communityId: data?.whoami.community?.node?.id,
          coverImageId: fileId,
          fileIds: [fileId]
        }
      }
    })
      .then(refetch)
      .catch(err => onError(err.message));
  }

  const handleReaction = postId => async emojiType => {
    if (reacting) return;

    await reactToPost({
      variables: {
        input: {
          postId,
          emojiType,
        }
      }
    })
      .then(refetch)
      .catch(err => onError(err.message))
  };

  const handleOnReply = album => {
    const media = album?.media?.edges?.[0]?.node;

    setReplyTo({
      sid: media?.id,
      dateCreated: new Date(media?.createdDate),
      state: {
        userData: media?.createdBy
      },
      attributes: {
        reactions: media?.reactions
      },
      body: JSON.stringify({
        editorState: EMPTY_MESSAGE,
        media: [media?.file]
      })
    });
    setThreadOpen(true);
  }

  if (error) return null;

  return (
    <NavLayout>
      {threadOpen ? (
        <ChatThread
          onlyMedia={true}
          threads={chatRoom?.threads}
          isLead={data?.whoami?.community?.isLead}
          userId={data?.whoami?.id}
          author={data?.whoami}
          community={data?.whoami?.community?.node}
          channel={channel}
          onAddMedia={data?.whoami?.community?.isLead && handleAddMedia}
          onReaction={handleReaction}
          onSend={handleSend}
          onBack={() => {
            setThreadOpen(false);
            setReplyTo();
          }}
          onReplyTo={(msg) => {
            setReplyTo(msg);
          }}
          replyTo={replyTo}
        />
      ) : (
        <ScrollView top={<>
          <Typography variant="h2">Media</Typography>
          <Box sx={{ flexGrow: 1 }} />
          {data?.whoami.community.isLead && !data?.whoami.community?.node?.isPaused && (
            <Button
              variant='tertiary'
              onClick={() => setIsOpen(true)}
            >
              <Stack direction='row' gap={1} alignItems='center'>
                Add Photo
                <PlusIcon width={16} height={16} />
              </Stack>
            </Button>
          )}
        </>}>
          <Stack direction='row' gap={2} flexWrap='wrap'>
            {albums?.map(album => {
              const media = album?.node?.media?.edges?.[0]?.node;

              return <Album
                album={album?.node}
                onReaction={handleReaction(media?.id)}
                onReply={() => handleOnReply(album?.node)}
                replyCount={chatRoom?.threads?.[media?.id]?.length}
                isLead={isLead}
                onOptionsClick={() => {
                  setShowOptions(true);
                  setMedia(album?.node);
                }}
              />
            })}
          </Stack>
        </ScrollView>
      )}
      <BottomDrawer
        isOpen={optionsOpen}
        onClose={() => {
          setShowOptions(false);
          setMedia();
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              setConfirmOpen(true);
              setShowOptions(false);
            }}
          >Delete</MenuItem>
        </MenuList>
      </BottomDrawer>
      <ConfirmMenu
        isOpen={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onSubmit={async () => {
          await deleteAlbum({
            variables: {
              input: {
                albumId: media?.id
              }
            }
          }).then(() => {
            refetch();
            setConfirmOpen(false);
            setMedia();
          }).catch(err => onError(err.message));
        }}
        label="Are you sure you want to delete media?"
      />
      <NoContentModal
        isOpen={showNoMessages}
        onClose={() => {
          setIsOpen(true);
        }}
        message='No media has been added yet.'
        ctaText={isLead ? 'Add Photo' : null}
      />
      <UploadDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={async (photos) => {
          await Promise.all(photos.map(photo => handleAddMedia(photo.id)));
          setIsOpen(false);
        }}
      />
    </NavLayout>
  );
}, pipe(
  withMemberRequired,
  propTypes({}),
  defaultProps({}),
  displayName('MediaChannel'),
  memo,
));
