import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(() => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 11L9 18L21 1" stroke="#2FAC4A" strokeOpacity="0.7" strokeWidth="2" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('CheckedIcon'),
  memo,
));
