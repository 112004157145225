import { memo } from "react";
import { pipe, applyTo } from "ramda";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { propTypes, defaultProps, displayName } from "lib/react";
import NavLayout from "components/NavLayout";
import TopBar from "components/TopBar";
import Content from "./Content";
import ScrollView from "components/ScrollView";

export default applyTo(() => {

  return (
    <NavLayout
      showSidebar={false}
      appBar={<TopBar communityView={false} showSidebar={false} />}
      communityView={false}
    >
      <ScrollView>
        <Content />
      </ScrollView>
    </NavLayout>
  );
}, pipe(withAuthenticationRequired, propTypes({}), defaultProps({}), displayName("Profile"), memo));
