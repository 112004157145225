import { memo, useCallback, useEffect, useMemo } from 'react';
import { pipe, applyTo, pathOr, map, head } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Divider, Stack, Typography, Button, CircularProgress } from '@mui/material';
import Image from 'components/Image';
import { useQuery } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import useLocalStorage from 'hooks/useLocalStorage';
import { WHO_AM_I } from 'data/queries/users';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(() => {
  const [lastCommunitySlug] = useLocalStorage('lastCommunitySlug')
  const [lastCommunityPath] = useLocalStorage('lastCommunityPath', '')
  const { data, loading, error } = useQuery(WHO_AM_I);
  const navigate = useNavigate();

  const communities = useMemo(() => applyTo(data, pipe(
    pathOr([], ['whoami', 'communities', 'edges']),
    map(pathOr({}, ['node'])),
  )), [data]);

  const handleCommunityClick = useCallback((community) => () => {
    // navigate to the community
    navigate(`/c/${community?.slug}`);
  }, [navigate])

  useEffect(() => {
    // if one community - go to that community (and previous view via localstorage)
    if (communities.length === 1) {
      const community = head(communities)
      navigate(`/c/${community?.slug}${lastCommunityPath}`)
      return;
    }

    if (lastCommunitySlug) {
      const community = communities.find(({ slug }) => slug === lastCommunitySlug)
      if (community) navigate(`/c/${community?.slug}${lastCommunityPath}`)
    }
  }, [communities, lastCommunitySlug, lastCommunityPath, navigate]);

  const content = useMemo(() => {
    return (
      <Stack sx={{ width: '100%' }}>
        {communities.map((community) => {
          const logoUrl = pathOr(
            `https://via.placeholder.com/${45}`,
            ["logo", "url"],
            community
          );
          return (
            <Stack
              key={community?.id}
              onClick={handleCommunityClick(community)}
              sx={{
                width: '100%',
                backgroundColor: '#FFFFFF80',
                borderRadius: '10px',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                marginBottom: '10px'
              }}
            >
              <Stack direction="row" gap={2} alignItems='center' sx={{ width: '100%', padding: '15px 25px' }}>
                <Image src={logoUrl} alt={"community.name"} sx={{ alignSelf: "start", borderRadius: "9px", width: 45 }} />
                <Typography variant='p'>{community?.title}</Typography>
              </Stack>
            </Stack>
          )
        })
        }
      </Stack >
    );
  }, [communities, handleCommunityClick]);

  if (error) {
    navigate('/profile');
    return;
  }

  if (loading) {
    return (
      <Stack flexGrow={1} sx={{
        minHeight: '100dvh',
        backgroundColor: '#D6D6D6',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <CircularProgress sx={{ color: '#00000073' }} />
      </Stack>
    );
  }

  if (!loading && communities.length === 0) {
    return (
      <Stack flexGrow={1} sx={{
        minHeight: '100dvh',
        backgroundColor: '#D6D6D6',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 10px',
      }}>
        <Stack gap={2} sx={{
          maxWidth: 300,
          width: '100%',
          alignItems: 'center',
        }}>
          <img
            src='/imgs/horns.svg'
            alt="rally moto"
            sx={{ alignSelf: "start", margin: 'auto', width: 100, padding: "4px" }}
          />
          <Typography variant='p' color="#00000073" textAlign='center'>This account is not currently associated with any active communities</Typography>

          <Button component={Link} to='/create' variant='contained' sx={{ padding: '18px 40px', fontSize: 14, color: '#000' }}>Start a Community</Button>

          <Typography variant='p' sx={{ fontSize: 14, color: "#00000073" }}>To learn more, visit <a href="https://letsrally.io" target="_blank" rel="noreferrer">Letsrally.io</a></Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack flexGrow={1} sx={{
      backgroundColor: '#D6D6D6',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100dvh',
    }}>
      <Stack gap={2} sx={{
        padding: '10px',
        maxWidth: 420,
        width: '100%',
        alignItems: 'center',
        height: '100%',
        maxHeight: '100dvh',
        overflowY: 'scroll',
      }}>
        <img
          src='/imgs/horns.svg'
          alt="rally moto"
          sx={{ margin: 'auto', width: 100 }}
        />
        <Typography variant='p' color="#00000073">Your communities</Typography>

        {content}

        <Divider sx={{ height: 2, width: '100%', backgroundColor: '#fff' }} />
        <Button component={Link} to='/create' variant='contained' sx={{ padding: '18px 40px', fontSize: 14, color: '#000' }}>Start a Community</Button>

        <Typography variant='p' sx={{ fontSize: 14, color: "#00000073" }}>To learn more, visit <a href="https://letsrally.io" target="_blank" rel="noreferrer">Letsrally.io</a></Typography>
      </Stack>
    </Stack>
  );
}, pipe(
  withAuthenticationRequired,
  propTypes({}),
  defaultProps({}),
  displayName('CommunityRouter'),
  memo,
));
