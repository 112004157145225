import { gql } from "@apollo/client";

export const UPDATE_COMMUNITY_MEMBERSHIP = gql`
  mutation UpdateCommunityMembership($input: UpdateCommunityMembershipInput!) {
    updateCommunityMembership(input: $input) {
      community {
        id
      }
    }
  }
`;

export const LEAVE_COMMUNITY = gql`
  mutation LeaveCommunity($input: LeaveCommunityInput!) {
    leaveCommunity(input: $input) {
      community {
        id
      }
    }
  }
`;

export const SUBSCRIBE = gql`
mutation Subscribe($input: SubscribeInput!) {
  subscribe(input: $input) {
    redirect
    community {
      id
      subscription {
        status
        actionDate
      }
    }
  }
}
`;

export const APPLY_PROMO_CODE = gql`
mutation ApplyPromoCode($input: ApplyPromoCodeInput!) {
  applyPromoCode(input: $input) {
    success
  }
}
`;

export const UPDATE_COMMUNITY = gql`
  mutation UpdateCommunity($input: UpdateCommunityInput!) {
    updateCommunity(input: $input) {
      community {
        id
        featuredImage {
          id
          url(input: {
            transformations: [{
              aspect_ratio: "1:1"
              crop: "fill",
            }]
          })
        }
        slug
        title
        yearFounded
        type
        otherType: type
        color
        isPrivate
        isPromoted
        isPublished
        isPaused
        description
        location {
          locality
          state
        }
        onboardingQuestions {
          type
          question
        }
        categories
        locations {
          description
          googlePlaceId
          lat
          lng
          locality
          postalCode
          state
        }
        logo {
            id
            url(input: {
              transformations: [{
                aspect_ratio: "1:1"
                crop: "fill",
              }]
            })
        }
        cover {
          type
          asset {
            ...on Image {
              id
              url
            }
            ...on Background {
              background
            }
          }
        }
        banners {
          id
          url(input: {
            transformations: [{
              aspect_ratio: "3:2"
              crop: "fill",
              gravity: "auto"
            }]
          })
        }
      }
    }
  }
`;

export const DELETE_COMMUNITY = gql`
mutation DeleteCommunity($input: DeleteCommunityInput!) {
  deleteCommunity(input: $input) {
    success
  }
}
`;

export const JOIN_COMMUNITY = gql`
    mutation JoinCommunity($input: JoinCommunityInput!) {
        joinCommunity(input: $input) {
            community {
                id
                slug
            }
        }
    }
`;

export const CREATE_COMMUNITY = gql`
  mutation CreateCommunity($input: CreateCommunityInput!) {
    createCommunity(input: $input) {
      community {
        id
        slug
      }
    }
  }
`;

export const CREATE_ALBUM = gql`
  mutation CreateAlbum($input: CreateAlbumInput!) {
    createAlbum(input: $input) {
      album {
        id
      }
    }
  }
`;