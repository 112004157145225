import { Avatar, Skeleton } from "@mui/material";

export default function UserAvatar({ src, width = 45, height = 45, sx = {}, loading, variant, ...props }) {
  return (
    <Avatar
      src={src || 'https://res.cloudinary.com/lets-rally/image/upload/v1/rallymoto-prod/shared/default-avatar.svg'}
      sx={{
        overflow: 'hidden',
        width,
        height,
        backgroundColor: 'black',
        ...sx
      }}
      variant={variant}
      {...props}
    >
      <Skeleton
        variant={variant}
        style={{
          width,
          height,
        }}
      />
    </Avatar>
  )
}