import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'views/App';
import reportWebVitals from './reportWebVitals';
import 'global.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Auth0Provider } from '@auth0/auth0-react';
import config from './config';
import PageViewAnalytics from 'components/PageViewAnalytics';
import RallyApolloProvider from 'lib/apollo';

const onRedirectCallback = (appState) => {
  // if explicity set returnTo - go there (e.g /c/the1moto/join)
  if (appState?.returnTo) {
    console.log('>>> callback redirecting to', appState?.returnTo)
    window.location.href = appState?.returnTo
    return
  }
  console.log('>>> no returnTo - redirecting to /community')
  window.location.href = '/community'
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Auth0Provider
          domain={config.AUTH0_DOMAIN}
          clientId={config.AUTH0_CLIENT_ID}
          onRedirectCallback={onRedirectCallback}
          cacheLocation='localstorage'
          authorizationParams={{
            redirect_uri: `${window.location.origin}/callback`,
            ...(config.AUTH0_AUDIENCE ? { audience: config.AUTH0_AUDIENCE, } : null),
          }}>
          <PageViewAnalytics>

            {/* default to private client - override with public client when needed */}
            <RallyApolloProvider>
              <App />
            </RallyApolloProvider>
          </PageViewAnalytics>
        </Auth0Provider>
      </LocalizationProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
