import { memo } from 'react';
import { Container, Typography, Button, Divider, Stack, Box } from '@mui/material'
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(() => {
    return (
        <Container>
            <Stack gap={2}>
                <Stack gap={2}>
                    <Typography variant="h2">Colors</Typography>
                    <Divider />
                    <Box sx={{ color: "theme.pallette.primary", }}>
                        White
                    </Box>
                </Stack>
                <Stack gap={2}>
                    <Typography variant="h2">Buttons</Typography>
                    <Divider />
                    <Stack direction="row" gap={2}>
                        <Button>variant="text"</Button>
                        <Button disabled>Disabled</Button>
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <Button variant="outlined">variant="outlined"</Button>
                        <Button variant="outliend" disabled>Disabled</Button>
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <Button variant="contained">variant="contained"</Button>
                        <Button variant="contained" disabled>Disabled</Button>
                    </Stack>
                </Stack>
                <Stack gap={2}>
                    <Typography variant="h2">Typography</Typography>
                    <Divider />
                    <Typography variant="h1">Heading 1</Typography>
                    <Typography variant="h2">Heading 2</Typography>
                    <Typography variant="h3">Heading 3</Typography>
                    <Typography variant="h4">Heading 4</Typography>
                    <Typography variant="h5">Heading 5</Typography>
                    <Typography variant="h6">Heading 6</Typography>
                    <Typography variant="body1">Body 1</Typography>
                    <Typography variant="body2">Body 2</Typography>
                    <Typography variant="button">Button</Typography>
                    <Typography variant="caption">Caption</Typography>
                    <Typography variant="overline">Overline</Typography>
                </Stack>
            </Stack>
        </Container >
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('Design'),
    memo,
));
