import { memo, useState } from "react";
import { pipe, applyTo } from "ramda";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Container } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import UserProfile from "components/UserProfile";
import { propTypes, defaultProps, displayName } from "lib/react";
import MessageDrawer from "components/MessageDrawer";

import NavLayout from "components/NavLayout";
import Navigation from "components/Navigation";
import TopBar from "components/TopBar";
import ScrollView from "components/ScrollView";
import { USER } from "data/queries/users";

export default applyTo(() => {
  const { username } = useParams();
  const [isMessageOpen, setIsMessageOpen] = useState(false);

  const { data, loading, error } = useQuery(USER, {
    variables: {
      username: username,
    },
  });

  if (error) return null;
  if (!loading && !data?.user) {
    return (
      <Container>
        <h1>User not found</h1>
      </Container>
    );
  }

  return (
    <NavLayout
      navigation={<Navigation />}
      showSidebar={false}
      appBar={<TopBar communityView={false} />}
      communityView={false}
    >
      <ScrollView>
        <UserProfile
          user={data?.user}
          readOnly={true}
          onMessageUser={() => setIsMessageOpen(true)}
          loading={loading}
        />
      </ScrollView>
      <MessageDrawer
        isOpen={isMessageOpen}
        onClose={() => {
          setIsMessageOpen(false);
        }}
        displayName={data?.user?.displayName}
        userId={data?.user?.id}
      />
    </NavLayout>
  );
}, pipe(withAuthenticationRequired, propTypes({}), defaultProps({}), displayName("User"), memo));
