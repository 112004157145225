import { useCallback, useContext, useEffect, useState } from 'react';
import { Container, Divider, MenuItem, MenuList, Stack, TextField, Typography } from '@mui/material'
import { pathOr, tap } from 'ramda';
import { useLazyQuery, useMutation } from '@apollo/client';

import MemberRow from 'components/MemberRow';
import MessageDrawer from 'components/MessageDrawer';
import FullScreenDialog from 'components/FullScreenDialog';
import BottomDrawer from 'components/BottomDrawer';
import { analytics } from 'lib/firebase';
import { logEvent } from 'firebase/analytics';
import { AppContext } from 'context';
import { SEARCH_COMMUNITY_MEMBERS } from 'data/queries/community';
import { ADD_COMMUNITY_LEADER } from 'data/mutations/members';

export default function AddLeadDialog({ isOpen, onClose, communityId }) {
  const [searchTerm, setSearchTerm] = useState();
  const [messageOpen, setMessageOpen] = useState(false);
  const [optionsDrawer, setOptionsDrawer] = useState({
    open: false,
    resource: null,
    confirmed: false
  });
  const { onError } = useContext(AppContext);
  const [searchUsers, { data, loading, refetch }] = useLazyQuery(SEARCH_COMMUNITY_MEMBERS);
  const [addCommunityLeader] = useMutation(ADD_COMMUNITY_LEADER);

  useEffect(() => {
    if (isOpen && communityId) searchUsers({
      variables: {
        input: {
          first: 10
        },
        communityId
      }
    })
  }, [searchUsers, communityId, isOpen]);

  useEffect(() => {
    if (!searchTerm || searchTerm?.length < 2 || loading) return;

    searchUsers({
      variables: {
        input: {
          searchTerm,
          first: 5
        },
        communityId
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchUsers]);

  const handlePromoteToLead = useCallback(() => {
    if (optionsDrawer.resource) {
      return addCommunityLeader({
        variables: {
          input: {
            userId: optionsDrawer.resource.id,
            communityId
          }
        }
      })
        .then(tap(({ data }) => {
          logEvent(analytics, 'new_lead', {
            communitySlug: data?.addCommunityLeader?.community?.slug
          });
          setOptionsDrawer(s => ({ ...s, confirmed: true }));
          refetch();
        }))
        .catch(err => onError(err.message))
    }
  }, [optionsDrawer.resource, addCommunityLeader, communityId, onError, refetch]);

  return (
    <FullScreenDialog
      isOpen={isOpen}
      onClose={onClose}
      title='Add lead'
    >
      <Container sx={{ mt: 2 }}>
        <TextField
          label="Search users"
          variant="outlined"
          fullWidth
          onChange={(evt) => setSearchTerm(evt.target.value)}
          sx={{ my: 2 }}
        />
        <Stack spacing={2} sx={{ mb: 3, overflow: 'auto' }}>
          {pathOr([], ['searchUsers', 'edges'], data).map((m) => (
            <MemberRow
              key={m?.node?.id}
              member={m?.node}
              onOpenActions={() => setOptionsDrawer({
                confirmed: false, open: true, resource: m?.node
              })}
            />
          ))}
        </Stack>
      </Container>
      <BottomDrawer
        isOpen={optionsDrawer.open}
        onClose={() => setOptionsDrawer({
          confirmed: false, open: false, resource: null
        })}
      >
        {optionsDrawer.confirmed
          ? <Typography
            color='text.secondary'
          >
            {optionsDrawer.resource?.displayName} was <span style={{
              color: 'white'
            }}>promoted to lead</span>
          </Typography>
          : <MenuList>
            <MenuItem
              onClick={() => setMessageOpen(true)}
            >Message</MenuItem>
            {optionsDrawer.resource && !optionsDrawer.resource?.community?.isLead && (
              <Divider sx={{ mt: 2 }} />
            )}
            {optionsDrawer.resource && !optionsDrawer.resource?.community?.isLead && (
              <MenuItem
                onClick={handlePromoteToLead}
              >Promote to Lead</MenuItem>
            )}
          </MenuList>}
      </BottomDrawer>
      <MessageDrawer
        isOpen={messageOpen}
        onClose={() => {
          setOptionsDrawer({ confirmed: false, open: false, resource: null });
          setMessageOpen(false);
        }}
        userId={optionsDrawer.resource?.id}
        displayName={optionsDrawer.resource?.displayName}
      />
    </FullScreenDialog>
  );
}