import { memo, useEffect } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useNavigate } from 'react-router-dom';
import Progress from 'components/Progress';
import { useQuery } from '@apollo/client';
import { STRIPE_PORTAL } from 'data/mutations/users';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(() => {
  const navigate = useNavigate();
  const { data } = useQuery(STRIPE_PORTAL);

  useEffect(() => {
    if (data?.whoami?.customer?.portalUrl) {
      window.open(data?.whoami?.customer?.portalUrl);
      navigate('/profile');
    }
  }, [data?.whoami?.customer?.portalUrl, navigate]);

  return <Progress />;
}, pipe(
  withAuthenticationRequired,
  propTypes({}),
  defaultProps({}),
  displayName('Billing'),
  memo,
));
