import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $insertNodes, $isRootOrShadowRoot, COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { useContext, useEffect } from "react";
import { mergeRegister, $wrapNodeInElement } from '@lexical/utils';
// import { fill } from "@cloudinary/url-gen/actions/resize";
// import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";

import { AppContext } from "context";
import { $createImageNode, ImageNode } from "../nodes/ImageNode";

export const INSERT_IMAGE_COMMAND = createCommand(
  "INSERT_IMAGE_COMMAND"
);

export default function ImagesPlugin({
  captionsEnabled
}) {
  const [editor] = useLexicalComposerContext();
  const { cloudinary } = useContext(AppContext);

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_IMAGE_COMMAND,
        ({ publicId, type }) => {
          const src = cloudinary
            .image(publicId)
            // .resize(fill().width(500).height(500).gravity(autoGravity()))
            .toURL();
          const imageNode = $createImageNode({ src, publicId });
          $insertNodes([imageNode]);

          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [captionsEnabled, editor, cloudinary]);

  return null;
}
