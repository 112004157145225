import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export const ChangeAge = (props) => {
  const { values, touched, errors, setFieldValue, setFieldTouched } = props;
  return (
    <>
      <DatePicker
        label="Birthday"
        disableFuture
        value={values.birthday}
        onChange={(value) => setFieldValue("birthday", value)}
        slotProps={{
          layout: {
            sx: {
              ".MuiPickersDay-root": {
                color: "#f8bbd0",
                borderRadius: 6,
                borderWidth: 2,
                borderColor: "#e91e63",
                border: "2px solid",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              },
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={() => setFieldTouched("birthday", true)}
            onSelect={() => setFieldTouched("birthday", true)}
            error={touched.startDate && !!errors.startDate}
            helperText={errors.startDate}
            fullWidth
          />
        )}
      />
    </>
  );
};
export default ChangeAge;
