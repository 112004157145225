import { MenuItem, FormControl, TextField } from "@mui/material";

export const ChangeGender = (props) => {
  const { values, touched, errors, getFieldProps } = props;

  return (
    <FormControl fullWidth sx={{ width: "100%", color: "black" }}>
      {/* <InputLabel>Gender</InputLabel> */}
      <TextField
        select
        label="Gender"
        sx={{ width: "100%", background: "none", color: "black" }}
        value={values.gender}
        error={touched.gender && !!errors.gender}
        helperText={errors.gender}
        selectProps={{ native: true }}
        {...getFieldProps("gender")}
      >
        <MenuItem value="MALE">Male</MenuItem>
        <MenuItem value="FEMALE">Female</MenuItem>
        <MenuItem value="NONBINARY">Nonbinary</MenuItem>
      </TextField>
    </FormControl>
  );
};
export default ChangeGender;
