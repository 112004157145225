import { createRef, useCallback, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { omit } from 'ramda';
import GoogleAutoComplete from 'components/GoogleAutocomplete';
import ImageDropZone from 'components/ImageDropZone';
import ConfirmMenu from 'components/ConfirmMenu';
import Switch from 'components/Switch';
import CategoriesForm from 'components/CategoriesForm';
import ActivitiesIcon from 'components/icons/ActivitiesIcon';
import RegistrationForm from './RegistrationForm';

// const DEFAULT_TAGS = [
//   'Motorcycle',
//   'Dirt Bike',
//   'Auto',
//   'Overlander',
//   'Ride',
//   'Drive',
//   'Campout',
//   'Show',
//   'Live Music',
//   'Art',
//   'Fundraiser',
//   'Cookout'
// ]

const INITIAL_VALUES = {
  isPublic: true,
  isPromoted: true,
  isPublished: true,
  title: '',
  description: '',
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  location: null,
  notifyMembers: true,
}

// eslint-disable-next-line no-useless-escape
const URL_REGEX = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

const EventSchema = Yup.object().shape({
  title: Yup.string()
    .required('Event name is required'),
  description: Yup.string(),
  location: Yup.object()
    .required('Location is required')
    .nullable(),
  startDate: Yup.date()
    .required('Start date is required')
    .nullable(),
  bannerIds: Yup.array().of(Yup.string()).required('You must upload a image for your event'),
  endDate: Yup.date().min(
    Yup.ref('startDate'),
    'End date cannot be before start date'
  ).nullable(),
  ticketingUrl: Yup.string()
    .matches(URL_REGEX, 'Enter a valid url')
    .nullable(),
  websiteUrl: Yup.string()
    .matches(URL_REGEX, 'Enter a valid url')
    .nullable(),
  isPromoted: Yup.boolean(),
  isPublished: Yup.boolean(),
  notifyMembers: Yup.boolean(),
});

const formatDateTime = (date, time) => {
  if (!date) return;
  if (!time) return date;
  const hours = time.getHours();
  const minutes = time.getMinutes();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

export default function EventForm({ onDelete, onSubmit, initialValues, formRef }) {
  const [includeEndDate, setIncludeEndDate] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showBannerError, setShowBannerError] = useState(false);
  const [registrationType, setRegistrationType] = useState('IN_APP');
  // const { data } = useQuery(PUBLIC_COMMUNITY_CHANNELS, {
  //   variables: {
  //     slug: communitySlug,
  //   },
  // });
  // const [tags, setTags] = useState(initialValues?.tags || []);
  const dropzoneRef = createRef();

  const openFileDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  };

  const handleNewRegistrationType = useCallback((tab, setFields) => {
    setRegistrationType(tab);
    if(tab === 'In-App') {
        setFields('ticketingUrl', '');
    } else {
        setFields('requiredFields', []);
    }
}, []);

  return (
    <Formik
      innerRef={formRef}
      validationSchema={EventSchema}
      initialValues={initialValues ? initialValues : INITIAL_VALUES}
      onSubmit={async (values, { setSubmitting, isSubmitting }) => {
        if (isSubmitting) return;
        const bannerIds = (values.bannerIds
          ? values.bannerIds
          : [initialValues?.banners[0]?.id]).filter(Boolean)
        const startDate = formatDateTime(values.startDate, values.startTime);
        const endDate = values.endDate ? formatDateTime(values.endDate, values.endTime) : startDate;
        await onSubmit({
          ...omit(['isPublic', 'startDate', 'endDate', 'startTime', 'endTime', 'author', 'notifyMembers'], values),
          description: values.description.replace(/↵/g, "\n"),
          isPrivate: !values.isPublic,
          isPromoted: values.isPublic,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          location: omit(['description'], values.location),
          // authorId: author?.value,
          // tags,
          eventId: values.id,
          bannerIds
        })
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Stack spacing={2}>
          <Stack gap={2}>
              <Stack
                direction='row'
                alignItems='center'
                gap={2}
              >
                <ImageDropZone
                  dropzoneRef={dropzoneRef}
                  sx={{ width: '140px', height: '186px', my: 0, mx: 0, maxWidth: '140px' }}
                  initialFile={values?.banners && values.banners[0]}
                  width={1080}
                  height={1350}
                  onComplete={(file) => {
                    setShowBannerError(true);
                    setFieldValue('bannerIds', [file.id])
                  }}
                />
                <Stack gap={1}>
                  <Button
                    variant='tertiary'
                    onClick={openFileDialog}
                  >Add image</Button>
                  <Typography
                    variant='subtitle2'
                  >Image will be cropped to a 4:5 ratio</Typography>
                </Stack>
              </Stack>
              {showBannerError && errors.bannerIds && <Typography color='error'>{errors.bannerIds}</Typography>}
            </Stack>
            <TextField
              label="Event Name"
              variant="outlined"
              fullWidth
              required
              error={touched.title && !!errors.title}
              helperText={errors.title}
              {...getFieldProps('title')}
            />
            <GoogleAutoComplete
              error={touched.location && !!errors.location}
              helperText={errors.location}
              required
              fullWidth
              label={null}
              placeholder='Add a location *'
              value={values.location?.description}
              onBlur={() => setFieldTouched('location', true)}
              onOptionSelected={(location) => {
                if (!location) return setFieldValue('location', location);
                setFieldValue('location', {
                  description: location?.description,
                  googlePlaceId: location?.place_id,
                  title: location?.structured_formatting?.main_text,
                  subtitle: location?.structured_formatting?.secondary_text,
                  lat: location?.geometry?.location?.lat(),
                  lng: location?.geometry?.location?.lng()
                });
              }}
            />
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={1}>
              <DatePicker
                label="Start Date"
                disablePast
                value={values.startDate}
                onChange={value => setFieldValue('startDate', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={() => setFieldTouched('startDate', true)}
                    error={touched.startDate && !!errors.startDate}
                    helperText={errors.startDate}
                    fullWidth
                    required
                  />
                )}
              />
              <TimePicker
                label="Start Time"
                disablePast
                value={values.startTime}
                onChange={value => setFieldValue('startTime', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={() => setFieldTouched('startTime', true)}
                    error={touched.startTime && !!errors.startTime}
                    helperText={errors.startTime}
                    fullWidth
                    required
                  />
                )}
              />
            </Stack>
            {includeEndDate && (
              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={1}>
                <DatePicker
                  label="End Date"
                  disablePast
                  value={values.endDate}
                  onChange={value => setFieldValue('endDate', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onBlur={() => setFieldTouched('endDate', true)}
                      error={touched.endDate && !!errors.endDate}
                      helperText={errors.endDate}
                      fullWidth
                      required
                    />
                  )}
                />
                <TimePicker
                  label="End Time"
                  disablePast
                  value={values.endTime}
                  onChange={value => setFieldValue('endTime', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onBlur={() => setFieldTouched('endTime', true)}
                      error={touched.endTime && !!errors.endTime}
                      helperText={errors.endTime}
                      fullWidth
                    />
                  )}
                />
              </Stack>
            )}
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  value={includeEndDate}
                  onChange={(evt) => setIncludeEndDate(evt.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={'Add end date / time'}
              labelPlacement="end"
            />
            <Box sx={{
              borderRadius: '8px',
              border: '1px solid #999999',
              px: '20px',
              pb: '20px',
              pt: '16px'
            }}>
              <Stack direction='row' gap={2} alignItems='center' sx={{ mb: 3 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V2H18V0H0ZM0 12H6V10H0V12ZM12 7H0V5H12V7Z" fill="#999999"/>
                </svg>
                <Typography color='text.secondary'>Description</Typography>
              </Stack>
              <TextField
                // label="Description"
                variant="outlined"
                fullWidth
                multiline
                error={touched.description && !!errors.description}
                helperText={errors.description}
                {...getFieldProps('description')}
                value={(values.description || '').replace(/↵/g, "\n")}
              />
            </Box>
            <Box sx={{
              borderRadius: '8px',
              border: '1px solid #999999',
              px: '20px',
              pb: '20px',
              pt: '16px'
            }}>
              <Stack direction='row' gap={2} alignItems='center' sx={{ mb: 3 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                  <path d="M5.7203 10.9653L1.4531 6.69814L0 8.14101L5.7203 13.8613L18 1.5816L16.5571 0.138733L5.7203 10.9653Z" fill="#999999"/>
                </svg>
                <Typography color='text.secondary'>Registration</Typography>
              </Stack>
              <Stack direction="row" justifyContent="center" sx={{ mb: '20px' }}>
                <Box sx={{
                  borderRadius: '8px 0px 0px 8px',
                  padding: '5px 43px',
                  backgroundColor: registrationType === 'IN_APP' ? '#FFF' : '#E6E6E6',
                  border: '1px solid #CCCCCC',
                  cursor: 'pointer'
                }} onClick={() => handleNewRegistrationType('IN_APP', setFieldValue)}>
                  <Typography>In-App</Typography>
                </Box>
                <Box sx={{
                  borderRadius: '0px 8px 8px 0px',
                  padding: '5px 10px',
                  backgroundColor: registrationType === 'OUTSIDE_APP' ? '#FFF' : '#E6E6E6',
                  border: '1px solid #CCCCCC',
                  cursor: 'pointer'
                }} onClick={() => handleNewRegistrationType('OUTSIDE_APP', setFieldValue)}>
                  <Typography>Outside of App</Typography>
                </Box>
              </Stack>
              {registrationType === 'IN_APP' ? <RegistrationForm
                requiredFields={values.requiredFields || []}
                setRequiredFields={(requiredFields) => setFieldValue('requiredFields', requiredFields)}
                allowGuests={values.allowGuests}
                setAllowGuests={(allowGuests) => setFieldValue('allowGuests', allowGuests)}
              /> : (
              <TextField
                  label="Ticketing URL"
                  variant="outlined"
                  fullWidth
                  error={touched.ticketingUrl && !!errors.ticketingUrl}
                  helperText={errors.ticketingUrl}
                  {...getFieldProps('ticketingUrl')}
                />
              )}
            </Box>
            <Box sx={{
              borderRadius: '8px',
              border: '1px solid #999999',
              px: '20px',
              pb: '20px',
              pt: '16px'
            }}>
              <Stack direction='row' gap={2} alignItems='center' sx={{ mb: 3 }}>
              <ActivitiesIcon />
                <Typography color='text.secondary'>Activities</Typography>
              </Stack>
              <CategoriesForm
                categories={values.activities || []}
                setCategories={(activities) => setFieldValue('activities', activities)}
              />
            </Box>
            {/* <TextField
              label="Website URL (Optional)"
              variant="outlined"
              fullWidth
              error={touched.websiteUrl && !!errors.websiteUrl}
              helperText={errors.websiteUrl}
              {...getFieldProps('websiteUrl')}
            /> */}
            {/* <TextField
              select
              label="Link a Channel"
              variant="outlined"
              fullWidth
              error={touched.channelId && !!errors.channelId}
              helperText={errors.channelId}
              {...getFieldProps('channelId')}
            >
              {channels.map((c) => (
                <MenuItem key={c?.node.id} value={c?.node.id}>
                  {c?.node.name}
                </MenuItem>
              ))}
            </TextField> */}
            <Stack 
              direction="row" 
              justifyContent="space-between" 
              alignItems="center"
              sx={{
                borderRadius: '8px',
                border: '1px solid #999999',
                px: '20px',
                py: '15px',
              }}
            >
              <Typography style={{
                color: '#666666'
              }}><b>Publish</b> when I tap Done</Typography>
              <FormControlLabel
                sx={{ alignItems: 'flex-start', mr: 0 }}
                control={<Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  name="isPublic"
                  checked={values.isPublished}
                  onChange={(evt) => {
                    setFieldValue('isPublished', evt.target.checked)
                  }}
                />}
              />
            </Stack>
            <Stack 
              direction="row" 
              justifyContent="space-between" 
              alignItems="center"
              sx={{
                borderRadius: '8px',
                border: '1px solid #999999',
                px: '20px',
                py: '15px',
              }}
            >
              <Typography style={{
                color: '#666666'
              }}><b>Promote</b> in the Rally event feed</Typography>
              <FormControlLabel
                sx={{ alignItems: 'flex-start', mr: 0 }}
                control={<Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  name="isPublic"
                  checked={values.isPromoted}
                  onChange={(evt) => {
                    setFieldValue('isPromoted', evt.target.checked)
                  }}
                />}
              />
            </Stack>
            <Stack 
              direction="row" 
              justifyContent="space-between" 
              alignItems="center"
              sx={{
                borderRadius: '8px',
                border: '1px solid #999999',
                px: '20px',
                py: '15px',
              }}
            >
              <Typography style={{
                color: '#666666'
              }}><b>Notify</b> group members</Typography>
              <FormControlLabel
                sx={{ alignItems: 'flex-start', mr: 0 }}
                control={<Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  name="isPublic"
                  checked={values.isPrivate}
                  onChange={(evt) => {
                    setFieldValue('isPrivate', evt.target.checked)
                  }}
                />}
              />
            </Stack>
            {onDelete && <Typography
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => setConfirmOpen(true)}
            >Delete Event</Typography>}
            <Box sx={{ pb: 2 }} />
            <ConfirmMenu
              isOpen={confirmOpen}
              onClose={() => setConfirmOpen(false)}
              onSubmit={onDelete}
              label='Are you sure you want to delete this event?'
            />
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
