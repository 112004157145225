import { IconButton } from "@mui/material";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import SendIcon from "components/icons/SendIcon";
import { $getRoot } from "lexical";
import { pick } from "ramda";

export default function ReplyButton({ onSend, files, onFileUpload }) {
  const [editor] = useLexicalComposerContext();
  const isEmpty = useLexicalIsTextContentEmpty(editor);

  return (
    <IconButton
      color="inherit"
      aria-label="menu"
      sx={{
        borderRadius: '10px',
        border: `1px solid rgba(0, 0, 0, 0.10)`,
        minWidth: `52px`,
        height: '40px'
      }}
      onClick={() => {
        if (isEmpty && !files) return;
        const editorState = editor.getEditorState();
        editor.update(() => $getRoot().clear());
        editor.blur();
        const media = files ? files.map(pick(['id', 'publicId'])) : [];
        onSend(JSON.stringify({ editorState, media }), {})
          .then(() => onFileUpload());
      }}
    >
      <SendIcon />
    </IconButton>
  );
}
