import { memo, useCallback, useContext, useMemo } from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useParams, Link, Navigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { pathOr, path } from 'ramda';
import { AppContext } from 'context';
import Overview from './Overview';
import { PRIVATE_COMMUNITY } from 'data/queries/community';
import { JOIN_COMMUNITY } from 'data/mutations/community';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(() => {
  const { community: slug } = useParams();
  const { onError } = useContext(AppContext);

  const { data, loading, error, refetch } = useQuery(PRIVATE_COMMUNITY, {
    variables: {
      slug
    },
  });

  const community = pathOr({}, ['whoami', 'community', 'node'], data);
  const applicationStatus = pathOr(null, ['whoami', 'community', 'application', 'status'], data);

  const [joinCommunity] = useMutation(JOIN_COMMUNITY);

  const handleJoin = useCallback(async () => {
    await joinCommunity({
      variables: {
        input: {
          communityId: path(['whoami', 'community', 'node', 'id'], data),
          application: {
            note: ''
          }
        }
      }
    })
      .then(refetch)
      .catch(err => onError(err.message));
  }, [joinCommunity, data, refetch, onError]);

  const isMember = pathOr(false, ['whoami', 'community', 'isMember'], data);
  const isPrivate = pathOr(false, ['whoami', 'community', 'node', 'isPrivate'], data);

  const ctaButtons = useMemo(() => {
    if (isPrivate) {
      return (
        <Stack gap={2} direction="row">
          {applicationStatus === 'PENDING' && (
            <Typography variant="body2" color='white'>Your request to join is pending approval</Typography>
          )}
          {(!applicationStatus || applicationStatus === 'REJECTED') && (
            <Button variant="regular" as={Link} to={`/c/${community.slug}/apply`}
              disabled={loading}>Request access</Button>
          )}
        </Stack>
      );
    }
    return (
      <Stack gap={2} direction="row">
        <Button variant="contained" onClick={handleJoin} sx={{
          padding: '9px 40px'
        }} disabled={loading}>Join</Button>
      </Stack>
    )
  }, [handleJoin, isPrivate, community, applicationStatus, loading]);

  if (error) return onError(error.message);

  return isMember
    ? <Navigate to={`/c/${slug}/channels`} />
    : <Overview actions={ctaButtons} loading={loading} />;
}, pipe(
  withAuthenticationRequired,
  propTypes({}),
  defaultProps({}),
  displayName('CommunityHomePrivate'),
  memo,
));
