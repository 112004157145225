import { MenuItem, FormControl, TextField } from "@mui/material";

export const ChangeSize = (props) => {
  const { values, touched, errors, getFieldProps } = props;
  return (
    <FormControl fullWidth>
      {/* <InputLabel>T-Shirt Size (Unisex)</InputLabel> */}
      <TextField
        select
        label="T-Shirt Size (Unisex)"
        variant="outlined"
        sx={{ width: "100%", background: "none", color: "black" }}
        value={values.shirtSize}
        error={touched.shirtSize && !!errors.shirtSize}
        helperText={errors.shirtSize}
        selectProps={{ native: true }}
        {...getFieldProps("shirtSize")}
      >
        <MenuItem value="XS">Extra small</MenuItem>
        <MenuItem value="SM">Small</MenuItem>
        <MenuItem value="M">Medium</MenuItem>
        <MenuItem value="L">Large</MenuItem>
        <MenuItem value="XL">Extra large</MenuItem>
        <MenuItem value="TWOXL">XXL</MenuItem>
        <MenuItem value="THREEXL">XXXL</MenuItem>
      </TextField>
    </FormControl>
  );
};
export default ChangeSize;
