import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Progress from "./Progress";
import useCustomAuth0 from "hooks/useCustomAuth0";

const withAuthenticationRequired = (Component, options = {}) => {
  return function WithAuthenticationRequired(props) {
    const {
      context,
    } = options;
    const { isAuthenticated, isLoading } = useCustomAuth0(context);
    const navigate = useNavigate();

    useEffect(() => {
      if (isLoading || isAuthenticated) {
        return;
      } else {
        navigate(`/login`)
      }
    }, [
      isLoading,
      isAuthenticated,
      navigate
    ]);

    return isAuthenticated ? <Component {...props} /> : <Progress />;
  };
};

export default withAuthenticationRequired;