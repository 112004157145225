import React, { memo, useContext, useEffect } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import Channel from 'components/Channel';
import withMemberRequired from 'components/withMemberRequired';
import NavLayout from 'components/NavLayout';
import { COMMUNITY_CHANNEL } from 'data/queries/channel';
import { JOIN_CHANNEL } from 'data/mutations/channel';
import { AppContext } from 'context';

export default applyTo(() => {
  const { community: slug } = useParams();
  const { onError } = useContext(AppContext);
  const { data, error, loading, refetch } = useQuery(COMMUNITY_CHANNEL, {
    variables: {
      slug,
      channelSlug: 'activity'
    },
  });
  const [joinChannel, { error: joinError }] = useMutation(JOIN_CHANNEL);
  const channel = data?.whoami.community?.node.channel?.node;

  useEffect(() => {
    if (!loading && data && !joinError && !channel) {
      joinChannel({
        variables: {
          input: {
            channelSlug: 'activity',
            communityId: data?.whoami.community?.node.id
          }
        }
      })
        .then(refetch)
        .catch(err => onError(err.message))
    }
  }, [channel, data, loading, joinChannel, refetch, joinError, onError])

  if (error) return null;

  return (
    <NavLayout>
      <Channel
        channel={{
          ...channel,
          name: 'Activity'
        }}
        section='chat'
        canPost={data?.whoami.community?.node.channel?.canPost}
        enableReply={false}
        enableReactions={false}
        isLead={data?.whoami?.community?.isLead}
        author={data?.whoami}
        community={data?.whoami?.community?.node}
        memberTotal={channel?.isPrivate ? channel?.members?.total : data?.whoami.community?.node?.members?.total}
      />
    </NavLayout>
  );
}, pipe(
  withMemberRequired,
  propTypes({}),
  defaultProps({}),
  displayName('ActivityChannel'),
  memo,
));
