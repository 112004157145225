import { gql } from "@apollo/client";
import { Vert } from "lib/graphql";

export const ATTEND_EVENT = gql`
  ${Vert.fragments.Event}
  mutation AttendEvent($input: AttendEventInput!) {
    attendEvent(input: $input) {
      event {
        id
        ...EventFields
      }
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      event {
        id
        slug
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  ${Vert.fragments.Event}
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      event {
        id
        ...EventFields
      }
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DeleteEvent($input: DeleteEventInput!) {
    deleteEvent(input: $input) {
      success
    }
  }
`;