import { Box, Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import AttendingIcon from "components/icons/AttendingIcon";
import InterestedIcon from "components/icons/InterestedIcon";
import RSVPList from "./RSVPList";
import useCustomAuth0 from "hooks/useCustomAuth0";

export default function EventRSVP({
  onAttend,
  onInterested,
  isAttending,
  isInterested,
  attending,
  interested,
  pending,
  onJoin,
  community,
  style = {}
}) {
  const theme = useTheme();
  const { isAuthenticated } = useCustomAuth0();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.overlay.secondary,
        borderRadius: '10px',
        padding: '20px',
        ...style
      }}
    >
      {isAuthenticated ? (
        <>
          <Button
            onClick={onAttend}
            variant='contained'
            sx={{
              borderRadius: '6px 6px 0px 0px',
              width: '100%',
              backgroundColor: isAttending
                ? theme.palette.transparentColors.light.seventy
                : theme.palette.transparentColors.dark.five,
              border: '1px solid #E0E0E0'
            }}
            startIcon={<AttendingIcon />}
          >
            I'm Attending
          </Button>
          <Button
            variant='contained'
            onClick={onInterested}
            sx={{
              borderRadius: '0px 0px 6px 6px',
              width: '100%',
              backgroundColor: isInterested
                ? theme.palette.transparentColors.light.seventy
                : theme.palette.transparentColors.dark.five,
              border: '1px solid #E0E0E0',
              marginTop: '-1px'
            }}
            startIcon={<InterestedIcon />}
          >
            I'm Interested
          </Button>
          <Divider sx={{ my: 2 }} />
          <Stack gap={2}>
            <RSVPList title='Attending' members={attending} />
            <RSVPList title='Interested' members={interested} />
            <RSVPList title={`Hasn't Responded`} members={pending} />
          </Stack>
        </>
      ) : (
        <>
          <Typography
            variant='h3'
            color={theme.palette.transparentColors.dark.fortyFive}
            style={{
              marginBottom: '15px'
            }}
          >
            Join {community?.title} to RSVP, see who’s attending, and join the conversation.
          </Typography>
          <Button variant='tertiary' onClick={onJoin}>
            Join
          </Button>
        </>
      )}
    </Box>
  )
}