import { useCallback } from "react";
import { cond, includes, omit, pipe, prop } from "ramda";

import GoogleAutoComplete from "components/GoogleAutocomplete";

export const ChangeAddress = (props) => {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleReset,
  } = props;

  const formatAddress = useCallback((components = []) => {
    const address = {};
    components.forEach(
      cond([
        [
          pipe(prop("types"), includes("street_number")),
          ({ short_name }) => (address.streetNumber = short_name),
        ],
        [
          pipe(prop("types"), includes("route")),
          ({ short_name }) => (address.route = short_name),
        ],
        [
          pipe(prop("types"), includes("locality")),
          ({ short_name }) => (address.locality = short_name),
        ],
        [
          pipe(prop("types"), includes("administrative_area_level_1")),
          ({ short_name }) => (address.administrativeArea = short_name),
        ],
        [
          pipe(prop("types"), includes("country")),
          ({ short_name }) => (address.country = short_name),
        ],
        [
          pipe(prop("types"), includes("postal_code")),
          ({ short_name }) => (address.postalCode = short_name),
        ],
      ])
    );
    return {
      ...omit(["streetNumber", "route"], address),
      address1: `${address.streetNumber || ""} ${address.route || ""}`.trim(),
    };
  }, []);

  const getAddressDescription = () => {
    const address = values?.address;
    if (!address) return "";

    const {
      address1,
      address2,
      locality,
      administrativeArea,
      postalCode,
      country,
    } = address;

    const parts = [
      address1 || "",
      address2 || "",
      locality || "",
      administrativeArea || "",
      postalCode || "",
      country || "",
    ];

    return parts.filter((part) => part.trim() !== "").join(", ");
  };

  const description = values?.description || getAddressDescription();

  return (
    <GoogleAutoComplete
      error={touched.location && !!errors.location}
      helperText={errors.location}
      fullWidth
      label={"Mailing Address"}
      placeholder={"Mailing Address"}
      value={description}
      onBlur={(e) => {
        const currentValue = e.target.value;
        const newValue = currentValue.includes("undefined") ? "" : currentValue;
        setFieldValue("description", newValue);
        setFieldTouched("address", true);
      }}
      onOptionSelected={(location) => {
        if (!location) {
          handleReset();
          setFieldValue("description", "");
          setFieldValue("address", {});
          setFieldError("location", "Invalid address. Please try again.");

          return;
        }
        const formattedAddress = formatAddress(location.address_components);
        if (!formattedAddress.address1) {
          setFieldError("location", "Invalid address. Please try again.");
          return;
        }
        const addressDescription = location.description || "";
        setFieldValue("description", addressDescription);
        setFieldValue("address", {
          ...formattedAddress,
          googlePlaceId: location.place_id,
        });
      }}
    />
  );
};

export default ChangeAddress;
