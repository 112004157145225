import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextField, Stack } from "@mui/material";
import "yup-phone";
import { omit, pick, replace } from "ramda";
import FormButtons from "components/FormButtons";
import ImageDropZone from "components/ImageDropZone";
import UserAvatar from "components/UserAvatar";

const INITIAL_VALUES = {
  avatarUrl: "",
  username: "",
  firstName: "",
  lastName: "",
  instagram: "",
  bio: "",
  phoneNumber: "",
  email: "",
  emailNotifications: {
    directMessages: true,
    channelMessages: true,
    directMention: true,
    newEvents: true,
    eventReminders: true,
    communityAnnouncements: true,
    groupMessageSummary: true,
    communityUpdate: true,
  },
  gender: "",
  birthday: "",
};

const usernameRules = /^(?=.{3})[A-Za-z\d]+$/gi;

const UserSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Must be at least 3 letters or numbers")
    .max(25, "Must be 25 characters or less")
    .matches(usernameRules, {
      message: "Your username must contain only letters or numbers",
    })
    .required("Username is required"),
  firstName: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
});

export const EditProfile = ({ onSubmit, user, onClose, loading }) => {
  return (
    <>
      <Formik
        validationSchema={UserSchema}
        initialValues={
          user
            ? {
              ...pick(
                [
                  "firstName",
                  "lastName",
                  "email",
                  "username",
                  "bio",
                  "phoneNumber",
                ],
                user
              ),
              emailNotifications: omit(
                ["__typename"],
                user?.notifications?.email
              ),
              avatarUrl: user?.avatar?.url,
              instagram: replace(
                "https://instagram.com/",
                "",
                user?.socialLinks?.instagram || ""
              ),
            }
            : INITIAL_VALUES
        }
        onSubmit={async (values, { setSubmitting, isSubmitting }) => {
          if (isSubmitting) return;

          await onSubmit(
            omit(["avatarUrl", "instagram"], {
              ...values,
              socialLinks: values.instagram
                ? { instagram: `https://instagram.com/${values.instagram}` }
                : null,
            })
          );
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          resetForm,
          handleSubmit,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form
            onSubmit={handleSubmit}
            style={{
              width: "720px",
              maxWidth: "100%",
            }}
          >
            <Stack gap={2} paddingBottom={5}>
              <ImageDropZone
                onComplete={({ id, url }) => {
                  setFieldValue("avatarUrl", url);
                  setFieldValue("avatarId", id);
                }}
              >
                <UserAvatar
                  src={values?.avatarUrl}
                  alt="member profile photo"
                  sx={{ width: 150, height: 150 }}
                />
              </ImageDropZone>
              <TextField
                label="Username"
                variant="filled"
                fullWidth
                required
                InputProps={{
                  disableUnderline: true,
                }}
                error={touched.username && !!errors.username}
                helperText={errors.username}
                {...getFieldProps("username")}
              />
              <TextField
                label="First Name"
                variant="filled"
                fullWidth
                multiline
                InputProps={{
                  disableUnderline: true,
                }}
                error={touched.firstName && !!errors.firstName}
                helperText={errors.firstName}
                {...getFieldProps("firstName")}
              />
              <TextField
                label="Last Name"
                variant="filled"
                fullWidth
                multiline
                InputProps={{
                  disableUnderline: true,
                }}
                error={touched.lastName && !!errors.lastName}
                helperText={errors.lastName}
                {...getFieldProps("lastName")}
              />
              <TextField
                label="Bio"
                variant="filled"
                fullWidth
                multiline
                InputProps={{
                  disableUnderline: true,
                }}
                error={touched.bio && !!errors.bio}
                helperText={errors.bio}
                {...getFieldProps("bio")}
              />
              <TextField
                label="Instagram"
                variant="filled"
                fullWidth
                InputProps={{
                  startAdornment: "@",
                  disableUnderline: true,
                }}
                error={touched.instagram && !!errors.instagram}
                helperText={errors.instagram}
                {...getFieldProps("instagram")}
              />
              <TextField
                label="email"
                variant="filled"
                fullWidth
                required
                multiline
                InputProps={{
                  disableUnderline: true,
                }}
                error={touched.email && !!errors.email}
                helperText={errors.email}
                {...getFieldProps("email")}
              />
              {/* TODO: Joe to do notifications bits - just don't want to keep adding bad data to DB */}

              <FormButtons
                loading={loading}
                onCancel={() => {
                  resetForm();
                  onClose();
                }}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
