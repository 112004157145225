import { memo, useContext } from 'react';
import { Box, FormControlLabel, Stack, Typography, useTheme, Switch } from '@mui/material'
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_CHANNEL_MEMBERSHIP } from 'data/mutations/channel';
import { COMMUNITY_CHANNEL } from 'data/queries/channel';
import { AppContext } from 'context';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(({ community, channel }) => {
  const theme = useTheme();
  const { onError } = useContext(AppContext);
  const { data, refetch } = useQuery(COMMUNITY_CHANNEL, {
    variables: {
      slug: community?.slug,
      channelSlug: channel?.slug
    },
    skip: !community || !channel
  });
  const [updateChannelMembership, { loading }] = useMutation(UPDATE_CHANNEL_MEMBERSHIP);
  const notifications = data?.whoami?.community?.node?.channel?.notifications || {};

  const handleUpdate = (checked) => {
    if (loading) return;
    return updateChannelMembership({
      variables: {
        input: {
          channelId: channel.id,
          newMessageNotifications: checked
        }
      }
    })
      .then(refetch)
      .catch(err => onError(err.message))
  }

  return (
    <Stack sx={{ mx: 3, mt: 3, pt: '70px' }}>
      <Typography variant='h3'>Channel Notifications</Typography>
      <Box sx={{
        backgroundColor: theme.palette.message.background,
        borderRadius: '10px',
        p: '20px',
        mt: 2
      }}>
        <FormControlLabel
          control={<Switch
            inputProps={{ 'aria-label': 'controlled' }}
            checked={notifications.newMessageNotifications}
            onChange={(evt) => {
              handleUpdate(evt.target.checked)
            }}
          />}
          label="New messages as they are sent"
        />
      </Box>
      <Typography
        sx={{
          mt: 1,
        }}
        variant='subtitle1'
      >You will always receive a notification when your name is @mentioned in a post.</Typography>
    </Stack >
  );
}, pipe(
  withAuthenticationRequired,
  propTypes({}),
  defaultProps({}),
  displayName('Notifications'),
  memo,
));
