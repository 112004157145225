import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { TEXT_FORMAT_TRANSFORMERS, TEXT_MATCH_TRANSFORMERS, QUOTE } from "@lexical/markdown";

import editorConfig from '../editorConfig';

// import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ImagesPlugin from "../plugins/ImagesPlugin";
import MentionsPlugin from "../plugins/MentionsPlugin";
import ToolbarPlugin from "../plugins/ToolbarPlugin";
import { AutoLinkPlugin } from "../plugins/AutoLinkPlugin";
import { useContext, useState } from "react";
import { omit, remove } from "ramda";
import { AppContext } from "context";
import Input from "./Input";
import { Avatar, Box, Divider, MenuItem, MenuList, Stack, Typography, useTheme } from "@mui/material";
import BottomDrawer from "components/BottomDrawer";
import ImageCarousel from "components/ImageCarousel";
import { useMutation } from "@apollo/client";
import { DELETE_FILE } from "data/mutations/files";

const URL_MATCHER = /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const MATCHERS = [
  (text) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith("http") ? fullMatch : `https://${fullMatch}`,
      attributes: { rel: 'noopener', target: '_blank' }, // Optional link attributes
    };
  }
];

export default function ReplyInput({
  onSend,
  disabled = false,
  editorState,
  isLead,
  community,
  author,
  focused = true,
  files: filesInput,
  channelName,
  ...props
}) {
  const [mentions, setMentions] = useState({});
  const [files, setFiles] = useState(filesInput);
  const [deleteFile] = useMutation(DELETE_FILE);
  const { featuredHexCode, clientIdentity, changeClientIdentity, onError } = useContext(AppContext);
  const theme = useTheme();
  const [postAsDrawerOpen, setPostAsDrawerOpen] = useState(false);
  const postAs = clientIdentity === community?.id ? community : author;

  const handlePostAsChange = (author) => {
    if (!author || (author?.id === clientIdentity)) return;

    return changeClientIdentity(author.id)
      .then(() => setPostAsDrawerOpen(false));
  }

  const handleAddMention = (mention) => {
    setMentions({
      ...mentions,
      [mention.__key]: mention
    });
  }
  const handleRemoveMention = (key) => {
    setMentions(omit([key], mentions));
  }

  const handleRemoveImage = async (index) => {
    const file = files[index];
    if (!file) return;
    return deleteFile({
      variables: {
        input: {
          fileId: file.id
        }
      }
    })
      .then(() => {
        setFiles(remove(index, 1, files));
      })
      .catch(err => onError(err.message));
  }

  return disabled
    ? <Box style={{ position: 'relative' }} {...props}>
      <Box
        style={{
          margin: '0 auto',
          color: 'white',
          position: 'relative',
          lineHeight: '20px',
          fontWeight: 400,
          textAlign: 'left',
          borderRadius: '20px',
          width: '100%'
        }}
      >
        <div style={{
          position: 'relative',
          display: 'flex'
        }}>
          <div className={{
            color: '#999',
            resize: 'none',
            fontSize: '15px',
            caretColor: theme.palette.transparentColors.dark.fortyFive,
            position: 'relative',
            tabSize: 1,
            outline: 0,
            padding: '15px 10px 15px 22px',
            flexGrow: 2,
            alignSelf: 'center'
          }}>
            Messages disabled on certain channels
          </div>
        </div>
      </Box>
    </Box>
    : (
      <LexicalComposer
        initialConfig={{
          editorState,
          ...editorConfig,
          theme: {
            ...editorConfig.theme,
            quoteColor: featuredHexCode,
          }
        }}
      >
        <Box
          style={{ position: 'relative' }}
          {...props}
        >
          {focused && (
            <Stack
              direction='row'
              sx={{ mx: '20px', mt: '20px', mb: '10px' }}
              alignItems='center'
              justifyContent='space-between'
            >
              {channelName && <Typography>Post to #{channelName}</Typography>}
              {isLead && community && (
                <Stack
                  direction='row'
                  alignItems='center'
                  gap={1}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setPostAsDrawerOpen(true)}
                >
                  <Avatar
                    src={postAs?.avatar?.url || postAs?.logo?.url}
                    alt={`${postAs?.displayName || postAs?.slug} avatar`}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography
                    fontSize={12}
                  >Post As</Typography>
                </Stack>
              )}
            </Stack>
          )}
          <Box
            style={{
              margin: '0 auto',
              position: 'relative',
              lineHeight: '20px',
              fontWeight: 400,
              textAlign: 'left',
              width: '100%',
              backgroundColor: focused ? 'rgba(0, 0, 0, 0.03)' : 'none',
              padding: '15px 10px 15px 22px',
            }}
          >
            <Box
              sx={{
                maxHeight: '200px',
                height: '100%',
                minHeight: '20px',
                overflowY: 'scroll',
              }}
            >
              <ImageCarousel
                images={files}
                onDelete={handleRemoveImage}
                isEditing={true}
              />
              <RichTextPlugin
                contentEditable={<Input
                  onSend={onSend}
                  mentions={mentions}
                  onFileUpload={setFiles}
                  files={files}
                  focused={focused}
                />}
                placeholder={
                  <div style={{
                    color: theme.palette.transparentColors.dark.fortyFive,
                    overflow: 'hidden',
                    position: 'absolute',
                    textOverflow: 'ellipsis',
                    bottom: '15px',
                    left: '22px',
                    fontSize: '15px',
                    userSelect: 'none',
                    display: 'inline-block',
                    pointerEvents: 'none'
                  }}>{community && !focused
                    ? `Message #${channelName} ...`
                    : 'Message ...'
                    }</div>
                }
              />
            </Box>
            <ListPlugin />
            <LinkPlugin />
            <MarkdownShortcutPlugin transformers={[
              ...TEXT_FORMAT_TRANSFORMERS,
              ...TEXT_MATCH_TRANSFORMERS,
              QUOTE
            ]} />
            <ClearEditorPlugin />
            <AutoLinkPlugin matchers={MATCHERS} />
            <ImagesPlugin />
            <MentionsPlugin
              onAddMention={handleAddMention}
              onRemoveMention={handleRemoveMention}
              isLead={isLead}
            />
          </Box>
          {focused && <ToolbarPlugin
            isLead={isLead}
            community={community}
            author={author}
            onSend={onSend}
            mentions={mentions}
            onFileUpload={setFiles}
            files={files}
          />}
        </Box>
        {isLead && (
          <BottomDrawer
            isOpen={postAsDrawerOpen}
            onClose={() => setPostAsDrawerOpen(false)}
          >
            <MenuList
              direction="column"
              gap={2}
            >
              <Typography color='text.secondary' sx={{ mb: 3 }}>
                Post as
              </Typography>
              <MenuItem
                onClick={() => {
                  handlePostAsChange(author)
                }}
              >{author?.displayName}</MenuItem>
              <Divider sx={{ mt: 2 }} />
              <MenuItem
                onClick={() => {
                  handlePostAsChange(community)
                }}
              >{community?.title}</MenuItem>
            </MenuList>
          </BottomDrawer>
        )}
      </LexicalComposer>
    );
}
