import { Stack } from "@mui/material";
import ImageCarousel from "components/ImageCarousel";
import MediaActions from "../MediaActions";

export default function Album({ album, replyCount, onOptionsClick, onReply, onReaction, isLead }) {
  const featuredImage = album?.media?.edges?.[0]?.node;

  return (
    <Stack>
      <ImageCarousel
        images={[featuredImage?.file]}
        imageSx={{
          borderRadius: '15px'
        }}
      />
      <MediaActions
        reactions={featuredImage?.reactions}
        onReaction={onReaction}
        onReply={onReply}
        replyCount={replyCount}
        isLead={isLead}
        onOptionsClick={onOptionsClick}
      />
    </Stack>
  )
}