import { memo } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import CommunityHomePublic from './CommunityHomePublic';
import CommunityHomePrivate from './CommunityHomePrivate';
import useCustomAuth0 from 'hooks/useCustomAuth0';

export default applyTo(() => {
  const { isAuthenticated } = useCustomAuth0();

  return isAuthenticated ? <CommunityHomePrivate /> : <CommunityHomePublic />
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('CommunityHome'),
  memo,
));
