import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = '#3FF8AA' }) => {
    return (
      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 7.94748L7.12701 12.5428L14.63 1.45703" stroke={color} stroke-width="2"/>
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('ApprovedIcon'),
    memo,
));
