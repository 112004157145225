import { Box, Stack, Typography, useTheme } from "@mui/material";
import UserAvatar from "components/UserAvatar";

export default function RSVPList({ members = [], title }) {
  const theme = useTheme();

  return members.length > 0 && (
    <Box>
      <Typography
        variant='subtitle1'
        color={theme.palette.overlay.info}
        sx={{ mb: 1 }}
      >
        {title}
      </Typography>
      <Stack gap='5px'>
        {members.map(({ node }, i) => (
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
            sx={{
              backgroundColor: theme.palette.overlay.secondary,
              padding: '8px 15px',
              borderRadius: '6px'
            }}
          >
            <UserAvatar
              size='small'
              src={node?.avatar?.url}
            />
            <Typography
              variant='body2'
            >{node.displayName}</Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}