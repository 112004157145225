import { memo } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import Message from './Message';
import { useParams } from 'react-router-dom';
import MessageList from './MessageList';
import TopBar from 'components/TopBar';
import NavLayout from 'components/NavLayout';
import FindOrStart from './FindOrStart';
import withAuthenticationRequired from 'components/withAuthenticationRequired';

export default applyTo(() => {
  const { message: slug } = useParams();

  return (
    <NavLayout
      navigation={<MessageList />}
      appBar={<TopBar communityView={false} />}
      communityView={false}
    >
      {slug ? <Message /> : <FindOrStart />}
    </NavLayout>
  );
}, pipe(
  withAuthenticationRequired,
  propTypes({}),
  defaultProps({}),
  displayName('User'),
  memo,
));
