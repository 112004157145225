import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

import { Box, Drawer, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { AppContext } from 'context';
import TopBar from 'components/TopBar';
import Navigation from 'components/Navigation';
import { PRIVATE_COMMUNITY } from 'data/queries/community';
import useCustomAuth0 from 'hooks/useCustomAuth0';

export default applyTo(({
  children,
  navigation = <Navigation />,
  showSidebar = true,
  appBar = <TopBar />,
  fallbackSlug,
  variant = 'primary',
  communityView = true,
}) => {
  const { community: slug } = useParams();
  const { isAuthenticated } = useCustomAuth0();
  const { closeNav, isNavOpen, showAlert } = useContext(AppContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const lastCommunitySlug = window.localStorage.getItem('lastCommunitySlug');
  const { data } = useQuery(PRIVATE_COMMUNITY, {
    variables: { slug: slug || fallbackSlug || lastCommunitySlug },
    skip: !isAuthenticated || (!slug && !lastCommunitySlug),
  });
  // const { data: publicData } = useQuery(PUBLIC_COMMUNITY, {
  //   variables: {
  //     slug: slug || fallbackSlug || lastCommunitySlug,
  //   },
  //   skip: isAuthenticated,
  //   client: publicClient,
  // });
  const { pathname } = useLocation();

  const isMember = data?.whoami?.community?.isMember;
  // const community = data?.whoami?.community?.node || publicData?.community;

  if (pathname.includes('/join/booth')) return children;
  return (
    <Stack direction='row' container sx={{
      height: '100svh',
      maxHeight: '100svh',
      overflow: 'hidden'
    }}>
      {appBar}
      {showSidebar && isDesktop && (!communityView || isMember) && (
        <Box component='header' sx={{
          position: 'fixed',
          top: showAlert ? '120px' : '70px',
          pb: showAlert ? '120px' : '70px',
          height: '100%',
          maxHeight: `100svh`,
          overflow: 'auto',
          minWidth: '320px',
          width: '100%',
          maxWidth: '320px',
          zIndex: 1,
        }}>
          {navigation}
        </Box>
      )}
      {showSidebar && !isDesktop && (!communityView || isMember) && (
        <Drawer
          variant={'temporary'}
          anchor="left"
          open={isNavOpen}
          onClose={closeNav}
          component='header'
          sx={{
            maxWidth: variant === 'secondary' ? '100%' : 326,
            width: isDesktop
              ? 326
              : variant === 'secondary' ? '100%' : '75%',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              maxWidth: variant === 'secondary' ? '100%' : 326,
              width: isDesktop
                ? 326
                : variant === 'secondary' ? '100%' : '75%',
              boxSizing: 'border-box',
              borderRadius: 0,
              backgroundColor: theme.palette.simple.main
            },
          }}
        >
          {navigation}
        </Drawer>
      )}
      <Box component={'main'} sx={{
        position: 'fixed',
        left: isDesktop && showSidebar ? '320px' : 0,
        top: showAlert ? '50px' : '0px',
        right: 0,
        maxWidth: isDesktop && !showSidebar ? '970px' : '100%',
        height: '100%',
        pb: showAlert ? '120px' : '70px',
        ml: 'auto',
        mr: 'auto',
        zIndex: 1,
      }}>
        <div
          style={{
            backgroundColor: communityView ? theme.palette.simple.main : 'none',
            position: 'relative',
            height: '100%',
            width: '100%',
            marginTop: '70px'
          }}
        >
          {children}
        </div>
      </Box>
    </Stack>
  )
}, pipe(
  propTypes({
    children: PropTypes.node,
    className: PropTypes.string,
  }),
  defaultProps({
    children: null,
    className: null,
  }),
  displayName('NavLayout'),
  memo,
));
