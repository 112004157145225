import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import EventRSVP from "../EventRSVP";
import EventChannel from "../EventChannel";

export default function MobileTabs({
  details,
  community,
  onAttend,
  onInterested,
  isAttending,
  isInterested,
  onJoin
}) {
  const [mobileSection, setMobileSection] = useState('ATTENDING');
  const theme = useTheme();

  return (
    <Grid container
      style={{
        marginTop: '15px'
      }}
    >
      <Grid
        item
        xs={4}
        style={{
          textAlign: 'center',
        }}
        onClick={() => setMobileSection('ATTENDING')}
      >
        <Typography
          variant='h5'
          style={{
            cursor: 'pointer',
            backgroundColor: mobileSection === 'ATTENDING'
              ? theme.palette.overlay.secondary
              : theme.palette.transparentColors.dark.twentyFive,
            color: mobileSection === 'ATTENDING'
              ? theme.palette.transparentColors.dark.thirty
              : theme.palette.transparentColors.light.eighty,
            padding: '11px 28px',
            borderRadius: '8px 0px 0px 0px',
          }}
        >Attending</Typography>
      </Grid>
      <Grid
        item xs={4}
        style={{ textAlign: 'center' }}
        onClick={() => setMobileSection('DESC')}
      >
        <Typography
          variant='h5'
          style={{
            cursor: 'pointer',
            backgroundColor: mobileSection === 'DESC'
              ? theme.palette.overlay.secondary
              : theme.palette.transparentColors.dark.twentyFive,
            color: mobileSection === 'DESC'
              ? theme.palette.transparentColors.dark.thirty
              : theme.palette.transparentColors.light.eighty,
            padding: '11px 28px',
          }}
        >Details</Typography>
      </Grid>
      {details.channel && <Grid
        item xs={4}
        style={{ textAlign: 'center' }}
        onClick={() => setMobileSection('CHANNEL')}
      >
        <Typography
          variant='h5'
          style={{
            cursor: 'pointer',
            backgroundColor: mobileSection === 'CHANNEL'
              ? theme.palette.overlay.secondary
              : theme.palette.transparentColors.dark.twentyFive,
            color: mobileSection === 'CHANNEL'
              ? theme.palette.transparentColors.dark.thirty
              : theme.palette.transparentColors.light.eighty,
            padding: '11px 28px',
            borderRadius: '0px 8px 0px 0px'
          }}
        >Channel</Typography>
      </Grid>}
      <Grid
        item
        xs={12}
      >
        {mobileSection === 'ATTENDING' && <EventRSVP
          onAttend={onAttend}
          onInterested={onInterested}
          isAttending={isAttending}
          isInterested={isInterested}
          attending={details.attending?.edges}
          interested={details.interested?.edges}
          pending={[]}
          onJoin={onJoin}
          style={{
            borderRadius: '0px 0px 20px 20px',
          }}
          community={community}
        />}
        {mobileSection === 'DESC' && (
          <Box
            style={{
              backgroundColor: theme.palette.transparentColors.light.seventy,
              borderRadius: '0px 0px 20px 20px',
              padding: '30px',
              color: theme.palette.transparentColors.dark.eighty,
              whiteSpace: 'pre-wrap'
            }}
          >
            {details.description}
          </Box>
        )}
        {mobileSection === 'CHANNEL' && details.channel && <EventChannel
          style={{
            borderRadius: '0px 0px 20px 20px',
          }}
          link={`/c/${community.slug}/channel/${details.channel?.slug}`}
          channelSlug={details.channel?.slug}
        />}
      </Grid>
    </Grid>
  )
}