import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = 'white' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
      <g opacity="0.5">
        <line x1="4.25" y1="6" x2="14.25" y2="6" stroke="black" />
        <line x1="4.25" y1="9" x2="14.25" y2="9" stroke="black" />
        <line x1="4.25" y1="3" x2="14.25" y2="3" stroke="black" />
        <line x1="1.25" y1="0.5" x2="1.25" y2="11.5" stroke="black" />
      </g>
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('FormatQuoteIcon'),
  memo,
));
