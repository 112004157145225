import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M8.99844 16.2001L4.79844 12.0001L3.39844 13.4001L8.99844 19.0001L20.9984 7.0001L19.5984 5.6001L8.99844 16.2001Z" fill="#FFB800" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('InterestedIcon'),
  memo,
));
