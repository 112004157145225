import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextField, Stack, Typography, Button } from "@mui/material";
import { pathOr } from "ramda";

const INITIAL_VALUES = {
  questions: [],
  note: "",
  updateProfile: false,
};

const Schema = Yup.object().shape({
  questions: Yup.array(Yup.string()),
  note: Yup.string(),
  updateProfile: Yup.boolean(),
});

export const QuestionsForm = ({ onSubmit, community }) => {
  const questions = pathOr([], ["onboardingQuestions"], community);
  return (
    <>
      <Formik
        validationSchema={Schema}
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, { setSubmitting, isSubmitting }) => {
          if (isSubmitting) return;
          await onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          isValid,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
            style={{
              width: "720px",
              maxWidth: "100%",
            }}
          >

            <Stack
              gap={2}
              sx={{
                padding: '30px',
              }}
            >
              <Stack justifyContent="space-between" gap={2}>
                <Typography
                  variant="h2"
                >
                  Tell us about yourself
                </Typography>

                {questions.map((question, index) => (
                  <Stack
                    key={`question-${index}`}
                    gap={2}
                    sx={{
                      width: "100%",
                      borderRadius: 5,
                    }}
                  >
                    <Typography variant="h4">{question.question}</Typography>
                    <TextField
                      placeholder="Answer ..."
                      fullWidth
                      error={touched[`questions[${index}]`] && !!errors[`questions[${index}]`]}
                      helperText={errors[`questions[${index}]`]}
                      {...getFieldProps(`questions[${index}]`)}
                    />
                  </Stack>
                ))}

                <Stack
                  gap={2}
                  sx={{
                    width: "100%",
                    borderRadius: 5,
                  }}
                >
                  <Stack gap={2} sx={{ width: "100%" }}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h4">Personal message</Typography>
                      <Typography
                        variant="p"
                        sx={{
                          color: "rgba(0, 0, 0, 0.45)",
                        }}
                      >
                        Optional
                      </Typography>
                    </Stack>
                    <TextField
                      placeholder="Message to community leader ..."
                      fullWidth
                      error={touched.username && !!errors.username}
                      helperText={errors.username}
                      {...getFieldProps('note')}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              gap={2}
              sx={{
                width: "100%",
                padding: '0 30px 30px',
              }}
            >
              <Stack
                gap={2}
                direction="row"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  sx={{
                    paddingX: 4,
                    paddingY: 2,
                  }}
                  disabled={isSubmitting || !isValid}
                  type="submit"
                >
                  Send Request
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default QuestionsForm;
