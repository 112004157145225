import { memo, useEffect, useContext } from 'react';
import { pipe, applyTo, path } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Progress from 'components/Progress';
import useLocalStorage from 'hooks/useLocalStorage';
import { Button, Stack, Box, Typography } from '@mui/material'
import config from 'config';
import { AppContext } from 'context';
import { PRIVATE_COMMUNITY } from 'data/queries/community';
import { JOIN_COMMUNITY } from 'data/mutations/community';

async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default applyTo(() => {
  const { logout } = useContext(AppContext);
  const { community: slug, mode } = useParams();
  const navigate = useNavigate();
  const [application, setApplication] = useLocalStorage('application');

  const { data, loading } = useQuery(PRIVATE_COMMUNITY, {
    variables: {
      slug
    },
  });
  const [joinCommunity] = useMutation(JOIN_COMMUNITY);

  useEffect(() => {
    const join = async () => {
      const communityId = path(['community', 'id'], data);
      if (mode === 'booth') {
        // we need to wait a few seconds to make sure the user has been created
        await delay(3500);
      }
      if (communityId) {
        await joinCommunity({
          variables: {
            input: {
              communityId,
              application: {
                note: application
              }
            }
          }
        });
        setApplication('');
        if (mode !== "booth") {
          navigate(`/c/${slug}`)
        }
      }
    }
    join()
  }, [data, joinCommunity, navigate, slug, application, setApplication]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!loading && mode === 'booth') {
    const title = path(['community', 'title'], data);
    return (
      <Box width="100%" height="100%" display="flex">
        <Stack flex={1} justifyContent="center" alignItems="center" >
          <Stack maxWidth="400px" gap={3}>
            <Typography variant="h4">
              {title ? `Welcome to the ${path(['community', 'title'], data)} community space!` : "Welcome to the community space!"}
            </Typography>
            <Box>
              <Button variant="outlined" onClick={() => {
                localStorage.setItem('booth-community-slug', slug);
                logout({
                  logoutParams: {
                    returnTo: `${window.location.protocol}//${config.BASE_URL}/booth`
                  },
                });
              }}>Logout</Button>
            </Box>
          </Stack>

        </Stack>
      </Box>
    );
  }

  return <Progress />;
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('Join'),
  memo,
));
