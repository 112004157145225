import React, { memo, useContext, useEffect, useState } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import Channel from 'components/Channel';
import { AppContext } from 'context';
import withMemberRequired from 'components/withMemberRequired';
import NavLayout from 'components/NavLayout';
import { COMMUNITY_CHANNEL } from 'data/queries/channel';
import { JOIN_CHANNEL } from 'data/mutations/channel';

export default applyTo(() => {
  const { community: slug, channel: channelSlug } = useParams();
  const { onError } = useContext(AppContext);
  const [section, setSection] = useState('chat');
  const { data, loading, error, refetch } = useQuery(COMMUNITY_CHANNEL, {
    variables: {
      slug,
      channelSlug
    },
  });
  const navigate = useNavigate();
  const [joinChannel, { error: joinError }] = useMutation(JOIN_CHANNEL);
  const channel = data?.whoami.community?.node.channel?.node;
  const community = data?.whoami?.community?.node;
  const isLead = data?.whoami?.community?.isLead;

  useEffect(() => {
    if (!loading && data && !joinError && !channel) {
      joinChannel({
        variables: {
          input: {
            channelSlug,
            communityId: data?.whoami.community?.node.id
          }
        }
      })
        .then(refetch)
        .catch(err => onError(err.message))
    }
  }, [channel, data, loading, joinChannel, refetch, joinError, channelSlug, onError])

  if (error) return null;

  return (
    <NavLayout>
      <Channel
        loading={loading}
        channel={channel}
        section={section}
        setSection={setSection}
        canPost={data?.whoami.community?.node.channel?.canPost}
        readOnly={channelSlug === 'activity'}
        isLead={isLead}
        author={data?.whoami}
        community={community}
        onBack={() => navigate(`/c/${slug}/channels`)}
      />
    </NavLayout>
  );
}, pipe(
  withMemberRequired,
  propTypes({}),
  defaultProps({}),
  displayName('Channel'),
  memo,
));
