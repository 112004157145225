import { MenuList, Divider, MenuItem } from "@mui/material";
import BottomDrawer from "components/BottomDrawer";
import { applyTo, cond, equals } from "ramda";

export default function SubMenu({ 
  open, 
  onClose,
  type,
  onDeleteAccount,
  onConfirmDeleteInventory,
  onDeleteInventory,
  onEditInventory
}) {
  const content = applyTo(type, cond([
    [equals('DELETE_ACCOUNT'), () => (
      <MenuList
        direction="column"
        gap={2}
      >
        <MenuItem onClick={onDeleteAccount}>Confirm</MenuItem>
        <Divider sx={{ mt: 2 }} />
        <MenuItem onClick={onClose}>Cancel</MenuItem>
      </MenuList>
    )],
    [equals('GARAGE'), () => (
      <MenuList
        direction="column"
        gap={2}
      >
        <MenuItem onClick={onEditInventory}>Edit</MenuItem>
        <Divider sx={{ mt: 2 }} />
        <MenuItem onClick={onConfirmDeleteInventory}>Delete</MenuItem>
      </MenuList>
    )],
    [equals('CONFIRM_DELETE_INVENTORY'), () => (
      <MenuList
        direction="column"
        gap={2}
      >
        <MenuItem onClick={onDeleteInventory}>Confirm</MenuItem>
        <Divider sx={{ mt: 2 }} />
        <MenuItem onClick={onClose}>Cancel</MenuItem>
      </MenuList>
    )],
  ]))
  const contentDesc = applyTo(type, cond([
    [equals('DELETE_ACCOUNT'), () => 'Are you sure? Your account will be permanently deleted.'],
    [equals('CONFIRM_DELETE_INVENTORY'), () => 'Are you sure? Your item will be permanently deleted.'],
    [equals('CHANGE_PASSWORD'), () => 'Please check your email to complete the change password request.']
  ]))

  return (
    <BottomDrawer
      isOpen={open}
      onClose={onClose}
      subtitle={contentDesc}
      showClose={false}
    >
      { content }
    </BottomDrawer>
  )
}