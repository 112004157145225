import { memo, useCallback, useContext } from 'react';
import { pipe, applyTo, pathOr } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from 'context';
import withLeadRequired from 'components/withLeadRequired';
import { PRIVATE_COMMUNITY } from 'data/queries/community';
import { DELETE_COMMUNITY, UPDATE_COMMUNITY } from 'data/mutations/community';
import { WHO_AM_I } from 'data/queries/users';
import FormWrapper from './FormWrapper';

export default applyTo(() => {
  const { community: slug } = useParams();
  const { data } = useQuery(PRIVATE_COMMUNITY, {
    variables: {
      slug
    }
  });
  const [deleteCommunity] = useMutation(DELETE_COMMUNITY, {
    refetchQueries: [WHO_AM_I]
  });
  const navigate = useNavigate();
  const { onError } = useContext(AppContext);
  const [updateCommunity, { loading: updating }] = useMutation(UPDATE_COMMUNITY);

  const community = pathOr({}, ['whoami', 'community', 'node'], data);

  const handleUpdate = useCallback(async (values) => {
    if (updating) return;
    await updateCommunity({
      variables: {
        input: {
          communityId: community?.id,
          ...values
        }
      }
    }).then(({ data }) => {
      // handle redirects for slug changes
      if (data?.updateCommunity?.community.slug !== community?.slug) {
        navigate(`/c/${data?.updateCommunity?.community.slug}/settings/details`);
      }
    })
      .catch(err => onError(err.message))
  }, [updateCommunity, updating, onError, community?.id, community?.slug, navigate]);

  const handleDelete = useCallback(async () => {
    if (!data?.whoami?.community?.isOwner) return;

    await deleteCommunity({
      variables: {
        input: {
          communityId: community?.id,
        }
      }
    })
      .then(() => {
        window.localStorage.removeItem('lastCommunitySlug');
        if (window.IS_REACT_NATIVE) {
          window.ReactNativeWebView.postMessage(JSON.stringify({
            action: 'close_community_sheet',
          }));
        } else {
          navigate('/profile');
        }
      })
      .catch(err => onError(err.message));
  }, [community?.id, deleteCommunity, navigate, data?.whoami, onError]);

  return (
    <FormWrapper
      initialValues={community}
      onUpdate={handleUpdate}
      updating={updating}
      onDelete={handleDelete}
    />
  );
}, pipe(
  withLeadRequired,
  propTypes({}),
  defaultProps({}),
  displayName('CommunitySettings'),
  memo,
));
