import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = 'black', unread = false, active = false }) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
        <g opacity={active ? "0.3" : "1"}>
          <path d="M25 12.4717C25 6.65563 19.3933 1.92432 12.5 1.92432C5.60675 1.92432 0 6.65563 0 12.4717C0 22.0428 11.735 26.6656 12.2305 26.8659C12.3348 26.9076 12.4478 26.9243 12.5522 26.9243C12.726 26.9243 12.8912 26.8743 13.0389 26.7825C13.2823 26.6239 13.4214 26.3652 13.4214 26.0899V23.0024C19.8713 22.6019 25 18.0291 25 12.4801V12.4717ZM12.5435 21.3502C12.0654 21.3502 11.6742 21.7257 11.6742 22.1847V24.7714C8.80563 23.3696 1.73853 19.2224 1.73853 12.4717C1.73853 7.57352 6.56293 3.59321 12.5 3.59321C18.4371 3.59321 23.2615 7.57352 23.2615 12.4717C23.2615 17.3699 18.4545 21.3335 12.5435 21.3502Z" fill={color} />
          <path d="M7.39572 11.3364C6.57861 11.3364 5.91797 11.9706 5.91797 12.755C5.91797 13.5394 6.57861 14.1735 7.39572 14.1735C8.21282 14.1735 8.87346 13.5394 8.87346 12.755C8.87346 11.9706 8.21282 11.3364 7.39572 11.3364Z" fill={color} />
          <path d="M12.4998 11.3364C11.6827 11.3364 11.0221 11.9706 11.0221 12.755C11.0221 13.5394 11.6827 14.1735 12.4998 14.1735C13.3169 14.1735 13.9776 13.5394 13.9776 12.755C13.9776 11.9706 13.3169 11.3364 12.4998 11.3364Z" fill={color} />
          <path d="M17.6008 11.3364C16.7837 11.3364 16.123 11.9706 16.123 12.755C16.123 13.5394 16.7837 14.1735 17.6008 14.1735C18.4179 14.1735 19.0785 13.5394 19.0785 12.755C19.0785 11.9706 18.4179 11.3364 17.6008 11.3364Z" fill={color} />
          { unread && <circle cx="23.1515" cy="4.92417" r="4.84848" fill="#F5D561"/> }
        </g>
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('MessagesIcon'),
    memo,
));
