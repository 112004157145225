import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ width = 17, height = 20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.9812 14H0.019043C0.274355 10.6438 3.07848 8 6.5001 8C9.92171 8 12.7258 10.6438 12.9812 14Z" fill="white" fillOpacity="0.8" />
      <circle cx="6.51904" cy="3.5" r="3.5" fill="white" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('MembersIcon'),
  memo,
));
