import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import RetractableHeader from "components/RetractableHeader";
import { AppContext } from "context";
import { useNavbarRetractor } from "context/NavBarContext";
import { useContext, useRef } from "react";
import { motion } from "framer-motion";

export function TopHeader({ top }) {

  return (
    <RetractableHeader
      sx={{
        height: '70px',
      }}
      style={{
        position: 'absolute',
        width: '100%',
        zIndex: 9,
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ mx: 3, height: '100%' }}
      >
        {top}
      </Stack>
    </RetractableHeader>
  )
}

export default function ScrollView({ top, bottom, children, sx, bottomHeight = 70 }) {
  const { showAlert } = useContext(AppContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const scrollContainer = useRef();
  useNavbarRetractor({ container: scrollContainer, direction: 'up', enabled: !!top });

  return (
    <Stack>
      {top && <TopHeader top={top} />}
      <motion.div
        ref={scrollContainer}
        style={{
          height: '100%',
          maxHeight: `calc(100dvh - ${showAlert
            ? bottom ? `${bottomHeight + 50}px` : '0px'
            : bottom ? `${bottomHeight}px` : '0px'})`,
          minHeight: `calc(100dvh - ${showAlert
            ? bottom ? `${bottomHeight + 50}px` : '0px'
            : bottom ? `${bottomHeight}px` : '0px'})`,
          overflowY: 'scroll',
          padding: isDesktop ? '80px 30px' : '80px 20px 20px',
          ...sx
        }}
      >
        {children}
      </motion.div>
      {bottom && <Box sx={{
        position: 'absolute',
        backgroundColor: theme.palette.transparentColors.light.ninety,
        bottom: 0,
        right: 0,
        left: 0,
        height: `${bottomHeight}px`
      }}>
        {bottom}
      </Box>}
    </Stack>
  )
}