import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ width = 17, height = 18 }) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 18" fill="none">
      <g opacity="0.3">
        <path d="M7.11868 14.7285C8.72155 14.7296 10.2773 14.1866 11.5314 13.1883L15.8401 17.497L17 16.3371L12.6913 12.0284C13.6903 10.7654 14.2312 9.20059 14.2255 7.59041C14.2197 5.97997 13.6676 4.41927 12.6596 3.16336C11.6515 1.90754 10.2471 1.03091 8.67616 0.676837C7.10509 0.322951 5.46057 0.512536 4.01133 1.21472C2.56209 1.917 1.39418 3.09018 0.69817 4.54247C0.00239064 5.99474 -0.17999 7.6402 0.18094 9.20958C0.541779 10.779 1.42457 12.1796 2.68502 13.182C3.94529 14.1845 5.50849 14.7297 7.1189 14.7284L7.11868 14.7285ZM7.11868 2.14427C8.56978 2.14427 9.96152 2.7207 10.9876 3.74677C12.0137 4.77293 12.5901 6.16445 12.5901 7.61573C12.5901 9.06702 12.0137 10.4586 10.9876 11.4847C9.96148 12.5108 8.56996 13.0872 7.11868 13.0872C5.66739 13.0872 4.27583 12.5108 3.24971 11.4847C2.22364 10.4585 1.64721 9.06702 1.64721 7.61573C1.64721 6.16445 2.22364 4.77289 3.24971 3.74677C4.27587 2.7207 5.66739 2.14427 7.11868 2.14427Z" fill="white" />
        <path d="M16.2929 16.3371L12.3377 12.382L12.023 12.0673L12.2991 11.7182C13.2279 10.544 13.7308 9.08917 13.7255 7.59218L16.2929 16.3371ZM16.2929 16.3371L15.8401 16.7899L11.8849 12.8348L11.5692 12.5191L11.22 12.7971C10.0684 13.7138 8.64322 14.2173 7.17247 14.2283L7.11849 14.2284C5.62127 14.2296 4.16795 13.7227 2.99628 12.7907L2.99624 12.7907C1.82442 11.8588 1.0037 10.5567 0.668227 9.09754L0.66822 9.09751C0.33267 7.63849 0.502217 6.10873 1.14906 4.75856L16.2929 16.3371ZM4.22935 1.66469C5.57669 1.01187 7.10558 0.835605 8.56622 1.1646L1.14909 4.75851C1.7962 3.40827 2.88201 2.31759 4.22935 1.66469ZM4.22935 1.66469L4.01139 1.21484L4.22935 1.66469ZM11.3412 3.39323L11.3412 3.39321C10.2213 2.27337 8.70238 1.64427 7.11868 1.64427C5.53477 1.64427 4.01609 2.27338 2.89617 3.3932L2.89615 3.39323C1.77632 4.51311 1.14721 6.03184 1.14721 7.61573C1.14721 9.19964 1.77632 10.7183 2.89614 11.8382L2.89617 11.8383C4.01605 12.9581 5.53478 13.5872 7.11868 13.5872C8.70258 13.5872 10.2213 12.9581 11.3412 11.8383L11.3412 11.8382C12.461 10.7184 13.0901 9.19963 13.0901 7.61573C13.0901 6.03183 12.461 4.51315 11.3412 3.39323Z" stroke="white" stroke-opacity="0.1" />
      </g>
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('SearchIcon'),
  memo,
));
