import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Progress from "./Progress";
import { PRIVATE_COMMUNITY } from "data/queries/community";
import useCustomAuth0 from "hooks/useCustomAuth0";

const withLeadRequired = (Component, options = {}) => {
  return function WithLeadRequired(props) {
    const {
      context,
    } = options;
    const { community: slug } = useParams();
    const { isAuthenticated, isLoading } = useCustomAuth0(context);
    const { data, loading } = useQuery(PRIVATE_COMMUNITY, {
      variables: {
        slug,
      },
      skip: !isAuthenticated,
    });
    const navigate = useNavigate();
    const community = data?.whoami?.community;

    const routeIsAuthenticated = isAuthenticated
      && community?.isLead;

    useEffect(() => {
      if (loading || isLoading || routeIsAuthenticated) {
        return;
      } else {
        navigate(`/c/${slug}`)
      }
    }, [
      isLoading,
      routeIsAuthenticated,
      loading,
      community,
      slug,
      navigate
    ]);

    return routeIsAuthenticated ? <Component {...props} /> : <Progress />;
  };
};

export default withLeadRequired;