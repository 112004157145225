import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

import { AppBar, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { Link, useParams } from 'react-router-dom';
import HamburgerIcon from 'components/icons/HamburgerIcon';
import Image from 'components/Image';
import { useQuery } from '@apollo/client';
import { AppContext } from 'context';
import BackArrow from 'components/icons/BackArrow';
import { publicClient } from 'lib/apollo';
import CommunitySwitcher from './CommunitySwitcher';
import RightNav from './RightNav';
import { COMMUNITY_SUBSCRIPTION, PRIVATE_COMMUNITY, PUBLIC_COMMUNITY } from 'data/queries/community';
import AlertIcon from 'components/icons/AlertIcon';
import { differenceInDays } from 'date-fns';
import { motion } from 'framer-motion';
import { useNavbar } from "../../context/NavBarContext";
import { truncate } from 'lib/string';
import useCustomAuth0 from 'hooks/useCustomAuth0';

export default applyTo(({
  communityView = true,
  fallbackSlug,
  showSidebar = true,
  showBack = true
}) => {
  const { community: slug } = useParams();
  const { isAuthenticated } = useCustomAuth0();
  const { openNav, showAlert, setShowAlert } = useContext(AppContext);
  const [switchOpen, setSwitchOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const lastCommunitySlug = window.localStorage.getItem('lastCommunitySlug');
  const { data } = useQuery(PRIVATE_COMMUNITY, {
    variables: { slug: slug || fallbackSlug || lastCommunitySlug },
    skip: !isAuthenticated || (!slug && !lastCommunitySlug),
  });
  const { data: publicData } = useQuery(PUBLIC_COMMUNITY, {
    variables: {
      slug: slug || fallbackSlug || lastCommunitySlug,
    },
    skip: isAuthenticated,
    client: publicClient,
  });
  const isLead = data?.whoami?.community?.isLead;
  const { retraction } = useNavbar();
  const isMember = data?.whoami?.community?.isMember;
  const community = data?.whoami?.community?.node || publicData?.community;
  const { data: subData } = useQuery(COMMUNITY_SUBSCRIPTION, {
    variables: { slug: community?.slug },
    skip: !isLead,
  });
  const subscription = subData?.whoami?.community?.node?.subscription;

  useEffect(() => {
    if (isLead && (subscription?.status === 'TRIALING'
      || subscription?.status === 'UNPAID'
      || community?.isPaused) && !showAlert && communityView) {
      setShowAlert(true);
    }
    if (!communityView && showAlert) {
      setShowAlert(false);
    }
  }, [community?.isPaused, communityView, isLead, setShowAlert, showAlert, subscription?.status]);

  const getAlert = () => {
    if (subscription?.status === 'TRIALING') {
      return <Typography color='white'>
        <b>Trial period</b> - you have {differenceInDays(
          new Date(subscription?.actionDate),
          new Date()
        )} days left.
      </Typography>;
    } else if (subscription?.status === 'UNPAID') {
      return <Typography color='white'>
        <b>Payment failure</b> - <Link style={{
          color: 'white',
          textDecoration: 'underline'
        }} to={`/c/${slug}/settings/funding`}>
          update payment info
        </Link>.
      </Typography >;
    } else if (subscription?.status === 'CANCELED') {
      return <Typography color='white'>
        <b>Subscription canceled</b> - <Link style={{
          color: 'white',
          textDecoration: 'underline'
        }} to={`/c/${slug}/settings/funding`}>
          resubscribe
        </Link>.
      </Typography >;
    } else if (community?.isPaused) {
      return <Typography color='white'>
        <b>Community is paused</b> - <Link style={{
          color: 'white',
          textDecoration: 'underline'
        }} to={`/c/${slug}/settings/funding`}>
          unpause subscription
        </Link>.
      </Typography >;
    }
  }

  return (
    <>
      {
        showAlert && (
          <AppBar
            item
            xs={12}
            sx={{
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1101,
              width: 'auto',
              backgroundImage: 'none',
              boxShadow: 'none',
              backgroundColor: 'rgb(50, 52, 53)',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              height: '50px',
              color: 'white'
            }}
          >
            <Stack direction='row' gap={1}>
              <AlertIcon />
              {getAlert()}
            </Stack>
          </AppBar>
        )
      }

      <MotionAppBar
        item
        xs={12}
        style={{
          y: retraction
        }}
        sx={{
          top: showAlert ? 50 : 0,
          left: 0,
          right: 0,
          width: 'auto',
          backgroundImage: 'none',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          justifyContent: isDesktop ? 'center' : 'space-between',
          borderBottom: isDesktop ? '1px solid #E6E6E6' : 'unset',
          height: '70px',
        }}
      >
        {isDesktop
          ? <Stack
            direction='row'
            justifyContent='space-between'
            gap={2}
            sx={{ px: 2, height: '60px' }}
          >
            <Stack
              direction='row'
              alignItems='center'
              alignContent='center'
              gap={2}
              onClick={() => setSwitchOpen(true)}
              style={{
                cursor: 'pointer'
              }}
            >
              {communityView ? (
                <>
                  <Image
                    src={community?.logo?.url}
                    alt={community?.title}
                    sx={{
                      width: 50,
                      height: 50,
                      borderRadius: 2,
                      backgroundColor: 'black',
                    }}
                  />
                  <Typography sx={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: 'black'
                  }}>{community?.title}</Typography>
                  <Dropdown />
                </>
              ) : (
                <Button
                  startIcon={<BackArrow />}
                  variant='tertiary'
                  component={Link}
                  to='/community'
                  sx={{
                    backgroundColor: theme.palette.transparentColors.dark.ten
                  }}
                >
                  <Typography
                    sx={{ ml: 2 }}
                    color='white'
                  >Back to Community</Typography>
                </Button>
              )}
            </Stack>
            <RightNav
              communitySlug={community?.slug}
              avatarUrl={data?.whoami?.avatar?.url}
              isLead={isLead}
              isMember={isMember}
              subscription={subscription}
              communityView={communityView}
            />
          </Stack>
          : <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            gap={'10px'}
            sx={{ px: 2, height: '70px' }}
          >
            {(communityView && isMember) || showSidebar ? (
              <>
                <Stack direction='row' alignItems='center' gap='10px'>
                  <HamburgerIcon onClick={openNav} />
                  <Typography sx={{
                    fontWeight: 400,
                    fontSize: 12,
                    color: 'black'
                  }}>{truncate(community?.title, 25)}</Typography>
                  <Dropdown onClick={() => setSwitchOpen(true)} />
                </Stack>
              </>
            ) : showBack ? (
              <Button
                startIcon={<BackArrow />}
                variant='tertiary'
                sx={{
                  backgroundColor: theme.palette.transparentColors.dark.ten,
                  height: 'fit-content'
                }}
              >
                <Typography
                  sx={{ ml: 2 }}
                  color='white'
                  component={Link}
                  to='/community'
                >Back</Typography>
              </Button>) : <div />}
            <RightNav
              communitySlug={community?.slug}
              avatarUrl={data?.whoami?.avatar?.url}
              openSwitch={() => setSwitchOpen(true)}
              isLead={data?.whoami?.community?.isLead}
              isMember={data?.whoami?.community?.isMember}
              subscription={subscription}
            />
          </Stack>
        }
        <CommunitySwitcher
          isOpen={switchOpen}
          onClose={() => setSwitchOpen(false)}
        />
      </MotionAppBar>
    </>
  );
}, pipe(
  propTypes({
    children: PropTypes.node,
    className: PropTypes.string,
  }),
  defaultProps({
    children: null,
    className: null,
  }),
  displayName('TopBar'),
  memo,
));

// make AppBar a motion component
const MotionAppBar = motion(AppBar);

function Dropdown({ width = 12, height = 8, color = '#999999', onClick = () => null }) {
  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 8" fill="none">
      <path d="M0.95752 1.71472L5.97503 6.28538L11.0875 1.71472" stroke={color} stroke-width="2" stroke-miterlimit="10" />
    </svg>
  )
}