import React, { useContext } from "react";
import {
  Stack,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { logEvent } from "firebase/analytics";

import Image from "components/Image";
import { analytics } from "lib/firebase";

import { useNavigate } from "react-router-dom";
import AttendingIcon from "components/icons/AttendingIcon";
import InterestedIcon from "components/icons/InterestedIcon";
import { ATTEND_EVENT } from "data/mutations/event";
import { AppContext } from "context";
import { format, isSameDay, isThisYear } from "date-fns";

const EventCard = ({
  details,
  community,
  isAttending,
  isInterested,
  refetch,
}) => {
  const [attendEvent, { loading: savingAttending }] = useMutation(ATTEND_EVENT);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const { onError } = useContext(AppContext);

  const handleAttend = async (confirmed) => {
    if (savingAttending) return;

    await attendEvent({
      variables: {
        input: {
          eventId: details.id,
          communityId: community?.id,
          confirmed,
        },
      },
    })
      .then(() => {
        refetch();
        logEvent(analytics, "attend_event", {
          eventId: details.id,
          community: community?.slug,
          confirmed,
        });
      })
      .catch((err) => onError(err.message));
  };

  const handleNavigate = () => navigate(`/e/${details.slug}`);

  let dateFormat = 'E MMM d\',\' haaa';
  if (!isThisYear(new Date(details.startDate))) dateFormat += ' Y';

  return (
    <Stack
      direction={isLarge ? "row" : "column"}
      gap={2}
      sx={{
        mx: 3,
        backgroundColor: theme.palette.message.background,
        borderRadius: "30px",
      }}
      alignItems={isLarge ? 'center' : 'start'}
    >
      {details.banners[0]?.url && (
        <Box sx={{ maxWidth: isLarge ? '150px' : '100%' }}>
          <Image
            src={details.banners[0]?.url}
            onClick={handleNavigate}
            sx={{
              width: 1,
              borderTopRightRadius: "15px",
              borderTopLeftRadius: "15px",
              borderBottomRightRadius: isLarge ? '15px' : 0,
              borderBottomLeftRadius: isLarge ? '15px' : 0,
              cursor: "pointer",
              margin: isLarge ? 2 : 0,
            }}
          />
        </Box>
      )}

      <Box sx={{ px: 2, pt: 1, flexGrow: 2, width: '100%' }}>
        <Typography
          variant="h2"
          onClick={handleNavigate}
          sx={{ cursor: "pointer", fontSize: 22 }}
        >
          {details.title}
        </Typography>
        <Typography
          sx={{
            color: '#999',
            fontSize: 15,
          }}
        >
          {format(new Date(details.startDate), dateFormat)}
          {details.endDate && details.startDate !== details.endDate
            ? isSameDay(new Date(details.startDate), new Date(details.endDate))
              ? ` - ${format(new Date(details.endDate), ' haaa')}`
              : ` - ${format(new Date(details.endDate), dateFormat)}`
            : ''}
        </Typography>
      </Box>
      <Stack
        direction={isLarge ? 'column' : 'row'}
        gap={'6px'}
        justifyContent={'space-between'}
        sx={{ p: 2, width: '100%' }}
      >
        <Button
          onClick={() => handleAttend(true)}
          variant="regular"
          startIcon={<AttendingIcon />}
          disabled={isAttending}
          style={{
            width: '100%'
          }}
        >
          Attending
        </Button>
        <Button
          variant="regular"
          onClick={() => handleAttend(false)}
          startIcon={<InterestedIcon />}
          disabled={isInterested}
          style={{
            width: '100%'
          }}
        >
          Interested
        </Button>
      </Stack>
    </Stack>
  );
};

export default EventCard;
