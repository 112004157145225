import React, { useContext } from 'react';
import { Stack, Typography, Link as MuiLink, Box, Button, Grid, useTheme, useMediaQuery } from '@mui/material';
import { format, isSameDay, isThisYear } from 'date-fns';
import { useMutation } from '@apollo/client';

import Image from 'components/Image';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'lib/firebase';
import Calendar from 'components/Calendar';

import EventDescription from './EventDescription';
import EventRSVP from './EventRSVP';
import EventChannel from './EventChannel';
import MobileTabs from './MobileTabs';
import { useNavigate } from 'react-router-dom';
import { ATTEND_EVENT } from 'data/mutations/event';
import LoadingList from 'components/LoadingList';
import { AppContext } from 'context';

const EventDetails = ({
  details,
  community,
  isAttending,
  isInterested,
  refetch,
  loading
}) => {
  const [attendEvent, { loading: savingAttending }] = useMutation(ATTEND_EVENT);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const { onError } = useContext(AppContext);

  const handleAttend = async (confirmed) => {
    if (savingAttending) return;

    await attendEvent({
      variables: {
        input: {
          eventId: details.id,
          communityId: community?.id,
          confirmed
        }
      }
    })
      .then(() => {
        refetch();
        logEvent(analytics, 'attend_event', {
          eventId: details.id,
          community: community?.slug,
          confirmed
        });
      })
      .catch(err => onError(err.message))
  };

  const handleJoin = () => navigate(`/c/${community.slug}`);

  let dateFormat = 'E MMM d';
  if (!isThisYear(new Date(details.startDate))) dateFormat += ' Y';
  if (details.endDate && isSameDay(new Date(details.startDate), new Date(details.endDate))) {
    dateFormat += ' \'at\' haaa';
  }

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          {loading ? <Box sx={{ mb: 3 }}>
            <LoadingList count={1} height={100} />
          </Box> : (
            <Stack direction='row' gap={2} sx={{ mb: 3 }} alignItems='center'>
              <Calendar date={new Date(details.startDate)} />
              <Box>
                <Typography
                  variant='h2'
                  sx={{ mb: 1, fontSize: '28px', lineHeight: '32px' }}
                >{details.title}</Typography>
                <Stack direction='row' gap={1}>
                  <Button
                    variant='tertiary'
                  >
                    {format(new Date(details.startDate), dateFormat)}
                    {details.endDate ? ` - ${format(new Date(details.endDate), dateFormat)}` : ''}
                  </Button>
                  <Button
                    variant='tertiary'
                    component={MuiLink}
                    href={details.location?.url}
                    target="_blank"
                    rel="noopener"
                  >
                    {details.location?.title}
                  </Button>
                </Stack>
              </Box>
            </Stack>
          )}
          <Stack gap={2}>
            <Image
              src={details.banners[0]?.url}
              placeholder='https://placehold.co/370x270/999999/999999'
              sx={{
                width: 1,
                height: loading ? '270px' : 1,
                borderRadius: '15px',
              }}
              loading={loading}
            />
            {details.description && isLarge && (
              <EventDescription description={details.description} />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          {loading ? <LoadingList count={1} /> : (
            <Stack gap={2}>
              {details.ticketingUrl && <Button
                component={MuiLink}
                href={details.ticketingUrl}
                variant='contained'
                target="_blank"
                rel="noopener"
                sx={{
                  width: '100%'
                }}
              >
                Get Tickets
              </Button>}
              {isLarge && (
                <>
                  <EventRSVP
                    onAttend={() => handleAttend(true)}
                    onInterested={() => handleAttend(false)}
                    isAttending={isAttending}
                    isInterested={isInterested}
                    attending={details.attending?.edges}
                    interested={details.interested?.edges}
                    pending={details.pending?.edges || []}
                    onJoin={handleJoin}
                    community={community}
                  />
                  {details.channel && <EventChannel
                    link={`/c/${community.slug}/channel/${details.channel?.slug}`}
                    channelSlug={details.channel?.slug}
                  />}
                </>
              )}
            </Stack>
          )}
        </Grid>
      </Grid>
      {!isLarge && <MobileTabs
        details={details}
        community={community}
        onAttend={() => handleAttend(true)}
        onInterested={() => handleAttend(false)}
        isAttending={isAttending}
        isInterested={isInterested}
        onJoin={handleJoin}
      />}
    </Stack>
  )
};

export default EventDetails;