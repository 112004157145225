import React, { createRef, useEffect, useState } from 'react';
import { FieldArray, Form } from 'formik';
import * as Yup from 'yup';
import { pathOr, path, reduce, assoc, remove } from 'ramda';

import { Box, Button, Chip, FormControl, FormControlLabel, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ImageDropZone from 'components/ImageDropZone';
import CustomInput from 'components/CustomInput';
import GoogleAutoComplete from 'components/GoogleAutocomplete';
import UploadIcon from 'components/icons/UploadIcon';
import CustomSwitch from 'components/Switch';
import FullScreenDialog from 'components/FullScreenDialog';
import ConfirmMenu from 'components/ConfirmMenu';
import BottomDrawer from 'components/BottomDrawer';
import { truncate } from 'lib/string';
import CategoriesForm from 'components/CategoriesForm';
import ActivitiesIcon from 'components/icons/ActivitiesIcon';

const categoryToLabelMap = {
  'BIKE_ROAD': 'Road (Bike)',
  'BIKE_GRAVEL': 'Gravel (Bike)',
  'BIKE_MOUNTAIN': 'Mountain (Bike)',
  'MOTO_CLASSIC': 'Classic (Moto)',
  'MOTO_ADVENTURE': 'Adventure (Moto)',
  'MOTO_ELECTRIC': 'Electric (Moto)',
  'RUN_ROAD': 'Road (Run)',
  'RUN_TRAIL': 'Trail (Run)',
  'CLIMB': 'Climb',
  'CHALLENGE': 'Challenge',
  'FISH': 'Fish',
  'HIKE': 'Hike',
  'OVERLAND': 'Overland',
  'RUCK': 'Ruck',
  'SURF': 'Surf'
}

export const getAddressComopnents = (location) => {
  const address_components = pathOr([], ['address_components'], location);
  return reduce((acc, component) => {
    const type = path(['types', 0], component);
    if (type) return assoc(type, component, acc)
    return acc;
  }, {}, address_components)
}

export const INITIAL_VALUES = {
  isPrivate: false,
  isPromoted: true,
  color: '#3FF8AA',
  locations: [],
  planType: '50',
  categories: []
}

export const CommunitySchema = Yup.object().shape({
  isPrivate: Yup.boolean()
    .required('Private or public setting is required'),
  description: Yup.string().nullable().max(1000),
  title: Yup.string()
    .matches(/^[a-zA-Z0-9-_' ]{3,30}$/g, { message: 'No special characters are allowed' })
    .required('Title is required'),
  locations: Yup.array(Yup.object().shape({
    description: Yup.string(),
    locality: Yup.string(),
    googlePlaceId: Yup.string(),
    lat: Yup.number(),
    lng: Yup.number(),
    state: Yup.string(),
    postalCode: Yup.string().nullable(),
  }))
    .test({
      message: 'Location is required',
      test: arr => arr.length > 0,
    }),
  categories: Yup.array(Yup.string()).required('Category is required')
});

export default function SingleCommunityForm({
  values,
  setFieldValue,
  touched,
  errors,
  getFieldProps,
  editSlug,
  resetForm,
  onDelete
}) {
  const logoDropzoneRef = createRef();
  const coverDropzoneRef = createRef();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [screeningQuestionsOpen, setScreeningQuestionsOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [activitiesOpen, setActivitiesOpen] = useState(false);

  useEffect(() => {
    if (values?.isPrivate && values?.onboardingQuestions?.length === 0) {
      setFieldValue('onboardingQuestions', [{ type: 'STRING', question: '' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.isPrivate, values?.onboardingQuestions]);

  const categories = values.categories || [];

  return (
    <Form sx={{ height: '100%' }}>
      <Box style={{ marginBottom: isDesktop ? '25px' : '20px' }}>
        <Stack
          direction='row'
          alignItems='center'
          gap={2}
        >
          <ImageDropZone
            sx={{ width: '90px', height: '90px', my: 0, mx: 0, maxWidth: '90px' }}
            initialFile={values?.logo}
            dropzoneRef={logoDropzoneRef}
            onComplete={({ id, url }) => {
              setFieldValue('logo', { id, url });
              setFieldValue('logoId', id);
            }}
          />
          <Stack gap={1}>
            <Button
              variant='tertiary'
              onClick={() => logoDropzoneRef?.current?.open()}
            >Change icon</Button>
            <Typography
              variant='subtitle2'
            >Square image recommended</Typography>
          </Stack>
        </Stack>
      </Box>
      <CustomInput
        placeholder='Your community'
        variant="outlined"
        fullWidth
        error={touched.title && !!errors.title}
        helperText={errors.title}
        style={{ marginBottom: isDesktop ? '25px' : '20px' }}
        {...getFieldProps('title')}
      />
      {editSlug && <CustomInput
        placeholder="Community handle"
        variant="outlined"
        inlineLabel={true}
        fullWidth
        InputProps={{
          startAdornment: '@',
        }}
        error={touched.slug && !!errors.slug}
        helperText={errors.slug}
        style={{ marginBottom: isDesktop ? '25px' : '20px' }}
        {...getFieldProps('slug')}
      />}
      <CustomInput
        placeholder="Group Bio"
        variant="outlined"
        inlineLabel={true}
        fullWidth
        multiline
        error={touched.description && !!errors.description}
        helperText={errors.description}
        style={{ marginBottom: isDesktop ? '25px' : '20px' }}
        {...getFieldProps('description')}
      />
      <ImageDropZone
        sx={{ my: 0, height: isDesktop ? '300px' : '180px' }}
        initialFile={values?.cover}
        dropzoneRef={coverDropzoneRef}
        onComplete={({ id, url }) => {
          setFieldValue('cover', { id, url });
          setFieldValue('coverId', id);
        }}
        width={800}
        height={600}
        placeholder={<Box sx={{ textAlign: "center" }}>
          <UploadIcon />
          <Typography sx={{ mt: 1 }} variant='subtitle1' color="#999">
            Cover Photo
          </Typography>
        </Box>}
      />
      <GoogleAutoComplete
        multiple
        error={touched.locations && !!errors.locations}
        helperText={errors.locations}
        hiddenLabel={true}
        fullWidth
        value={values?.locations}
        style={{
          marginTop: isDesktop ? '25px' : '20px',
          marginBottom: values.locations?.length > 0 ? '10px' : 0,
          width: '100%',
        }}
        onOptionSelected={(location) => {
          if (!location) return;
          const addressComponents = getAddressComopnents(location);
          const geoLocation = path(['geometry', 'location'], location)
          let lat, lng = null;
          if (geoLocation) {
            lat = geoLocation.lat();
            lng = geoLocation.lng();
          }
          const locations = [...values.locations, {
            description: pathOr("", ['formatted_address'], location),
            googlePlaceId: path(['place_id'], location),
            locality: pathOr("", ['locality', 'short_name'], addressComponents),
            state: pathOr("", ['administrative_area_level_1', 'short_name'], addressComponents),
            lat,
            lng,
            postalCode: path(['postal_code', 'short_name'], addressComponents),
          }]
          setFieldValue('locations', locations)
        }}
      />
      <Box style={{ marginBottom: isDesktop ? '25px' : '20px' }}>
        <Stack gap={1} direction="row" flexWrap="wrap" width="100%">
          {values.locations.map((location, index) => (
            <Chip
              key={location.description}
              size="small"
              variant="outlined"
              label={location.description}
              onDelete={() => {
                const locations = remove(index, 1, values.locations);
                setFieldValue('locations', locations)
              }} />
          ))}
        </Stack>
      </Box>
      <FormControl style={{ marginBottom: isDesktop ? '25px' : '20px', width: '100%' }}>
      <Button
        variant='regular'
        sx={{
          width: '100%',
        }}
        onClick={() => setActivitiesOpen(true)}
      >
        <Stack justifyContent={'space-between'} direction={'row'} alignItems='center' sx={{ width: '100%' }}>
          <Stack direction='row' gap={2} alignItems='center'>
            <ActivitiesIcon />
            <Typography
              color='text.secondary'
            >Activities</Typography>
          </Stack>
          {categories.length > 0 && <Typography color='text.secondary'>
            {truncate(categories.map(category => categoryToLabelMap[category]).join(', '), 40)}
            </Typography>}
          <ArrowIcon />
        </Stack>
      </Button>
      </FormControl>
      <Stack direction='row'>
        <FormControlLabel
          sx={{ alignItems: 'flex-start', ml: 0 }}
          control={<CustomSwitch
            inputProps={{ 'aria-label': 'controlled' }}
            name="isPrivate"
            checked={!values.isPrivate}
            onChange={evt => {
              const isPrivate = !evt.target.checked;
              if (!isPrivate) {
                resetForm({
                  ...values,
                  onboardingQuestions: undefined
                });
              }
              setFieldValue('isPrivate', isPrivate);
            }}
          />}
          label={values.isPrivate
            ? <Typography sx={{ fontWeight: 900 }}>Private</Typography>
            : <Typography sx={{ fontWeight: 900 }}>Public</Typography>}
        />
        {values.isPrivate && (
          <Button
            variant='tertiary'
            onClick={() => setScreeningQuestionsOpen(true)}
          >
            Screening Questions
          </Button>
        )}
      </Stack>
      {onDelete && <Button
        variant='tertiary'
        style={{ marginTop: '30px' }}
        onClick={() => setDeleteConfirmOpen(true)}
      >
        Delete Group
      </Button>}
      <FullScreenDialog
        isOpen={screeningQuestionsOpen}
        onClose={() => setScreeningQuestionsOpen(false)}
        title={'Screening Questions'}
      >
        <Box sx={{ p: '20px' }}>
          <FieldArray
            name="onboardingQuestions"
            render={arrayHelpers => (
              <Stack gap={1}>
                {(values?.onboardingQuestions)?.map((question, index) => (
                  <CustomInput
                    key={index}
                    placeholder={`Screening Question`}
                    variant="outlined"
                    fullWidth
                    {...getFieldProps(`onboardingQuestions[${index}].question`)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        arrayHelpers.push({ type: 'STRING', question: '' });
                      }
                    }}
                  />
                ))}
              </Stack>
            )}
          />
          <Button
            variant='tertiary'
            sx={{ float: 'right', mt: '15px' }}
            onClick={() => {
              setFieldValue('onboardingQuestions', [
                ...(values.onboardingQuestions || []),
                { type: 'STRING', question: '' }
              ]);
            }}
          >Add Question</Button>
        </Box>
      </FullScreenDialog>
      <ConfirmMenu
        isOpen={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        onSubmit={onDelete}
        label={<>Are you sure you want to <b>delete your group?</b></>}
      />
      <BottomDrawer
        isOpen={activitiesOpen}
        onClose={() => setActivitiesOpen(false)}
        title="Activities"
      >
        <CategoriesForm
          categories={values.categories}
          setCategories={(categories) => setFieldValue('categories', categories)}
        />
      </BottomDrawer>
    </Form>
  )
}

function ArrowIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
      <path d="M1 1L7 7L1 13" stroke="#999999" stroke-width="2"/>
    </svg>
  )
}