import React from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system';
import { MoreVert } from '@mui/icons-material';
import MembersIcon from 'components/icons/MembersIcon';
import NotificationsIcon from 'components/icons/NotificationsIcon';
import BackArrow from 'components/icons/BackArrow';
import RetractableHeader from "../../RetractableHeader";

const Header = ({
  channel,
  isLead,
  onMembersClick,
  onNotificationsClick,
  onTitleClick,
  onOptionsClick,
  section,
  onBack
}) => {
  const theme = useTheme();

  return (
    <RetractableHeader
      sx={{
        backgroundColor: theme.palette.transparentColors.light.eighty,
        height: '70px',
        backdropFilter: 'contrast(.7) blur(10px)',
      }}
      style={{
        position: 'absolute',
        width: '100%',
        zIndex: 9,
      }}
    >
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mx: 3, height: '100%' }}>
        <Stack direction='row' alignItems='center' gap={1}>
          {onBack && <>
            <IconButton onClick={onBack}>
              <BackArrow />
            </IconButton>
            <Box sx={{
              width: '2px',
              height: '30px',
              mr: 1,
              backgroundColor: theme.palette.transparentColors.dark.ten
            }} />
          </>}
          <Typography
            variant="h2"
            onClick={onTitleClick}
            sx={{
              cursor: 'pointer'
            }}
          >{channel?.name}</Typography>
        </Stack>
        <Stack direction='row' gap={1}>
          {onMembersClick && <IconButton
            disabled={section === 'members'}
            variant='tertiary'
            onClick={onMembersClick}
          >
            <MembersIcon />
          </IconButton>}
          {onNotificationsClick && <IconButton
            disabled={section === 'notifications'}
            variant='tertiary'
            onClick={onNotificationsClick}
          >
            <NotificationsIcon />
          </IconButton>}
          {isLead && onOptionsClick && <IconButton
            onClick={onOptionsClick}
            sx={{ p: 0 }}
          >
            <MoreVert />
          </IconButton>}
        </Stack>
      </Stack>
    </RetractableHeader>
  );
}

export default Header;