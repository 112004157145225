import { Form, Formik } from "formik";
import { Stack, Typography } from "@mui/material";
import ChangeAddress from "./ChangeAddress";
import ChangeAge from "./ChangeAge";
import ChangeGender from "./ChangeGender";
import ChangeSize from "./ChangeSize";
import { pick } from "ramda";
import FormButtons from "components/FormButtons";

export const ChangePersonalInfo = ({ onSubmit, onClose, user, loading }) => {
  return (
    <Formik
      initialValues={user}
      onSubmit={async (values, { setSubmitting, isSubmitting }) => {
        if (isSubmitting) return;

        await onSubmit({
          ...pick(["shirtSize", "gender", "birthday"], values),
          address: pick(
            [
              "address1",
              "address2",
              "locality",
              "administrativeArea",
              "postalCode",
              "country",
            ],
            values.address
          ),
        });
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm, ...props }) => (
        <Form onSubmit={handleSubmit}>
          <Typography sx={{ marginBottom: 3 }}>
            Adding personal information to your profile is completely optional
            and will only be seen by community leaders of groups you either
            belong to or have applied to be a part of.{" "}
          </Typography>
          <Stack gap={2} sx={{ paddingBottom: 5 }}>
            <ChangeGender {...props} />
            <ChangeSize {...props} />
            <ChangeAddress {...props} />
            <ChangeAge {...props} />
            <FormButtons
              loading={loading}
              onCancel={() => {
                resetForm();
                onClose();
              }}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
