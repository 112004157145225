import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import EmojiIcon from 'components/icons/EmojiIcon';
import ReactionsPopover from 'components/ReactionsPopover';
import { Emoji } from 'emoji-picker-react';
import { REACTIONS_MAP } from 'lib/reactions';
import React, { useState } from 'react';

const MessageReactions = ({ reactions = [], onReaction }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleReaction = (type) => {
    return onReaction(type).then(handlePopoverClose);
  }

  return (
    <Stack direction='row' alignItems='center' gap={1}>
      {reactions.map(([type, count]) => (
        <>{count > 0 && (
          <IconButton
            onClick={() => handleReaction(type)}
            sx={{
              background: theme.palette.message.button,
              borderRadius: '4px',
              p: '3px 5px'
            }}
          >
            <Emoji unified={REACTIONS_MAP[type]} size={16} />
            <Typography sx={{ ml: 1 }} color='text.secondary'>{count}</Typography>
          </IconButton>
        )}</>
      )
      )}
      <IconButton
        onClick={handlePopoverOpen}
        sx={{
          background: theme.palette.message.button,
          borderRadius: '4px',
          p: '5px 7px'
        }}
      >
        <EmojiIcon width={20} height={20} color={theme.palette.text.secondary} />
      </IconButton>
      <ReactionsPopover
        onSelect={handleReaction}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      />
    </Stack>
  );
}

export default MessageReactions;