import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createTextNode,
  $insertNodes,
  createCommand,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $wrapNodes, $isAtNodeEnd } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $isListNode,
  ListNode
} from "@lexical/list";
import {
  $createQuoteNode,
  $isHeadingNode
} from "@lexical/rich-text";
import { Box, Button, IconButton, Stack, TextField } from "@mui/material";
import FormatLinkIcon from "components/icons/FormatLinkIcon";
import FormatQuoteIcon from "components/icons/FormatQuoteIcon";
import styles from './ToolbarPlugin.module.scss';
import ReplyButton from "../ReplyInput/ReplyButton";
import CameraIcon from "components/icons/CameraIcon";
import useMediaUpload from "hooks/useMediaUpload";
import { AppContext } from "context";

const LowPriority = 1;

function positionEditorElement(editor, rect) {
  editor.style.opacity = "1";
}

const CREATE_LINK_TEXT_COMMAND = createCommand(
  "CREATE_LINK_TEXT_COMMAND"
);

function FloatingLinkEditor({ editor, onClose }) {
  const editorRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [linkText, setLinkText] = useState("");

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
        setLinkText(parent.getTextContent());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
        setLinkText(node.getTextContent());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
    } else if (!activeElement) {
      positionEditorElement(editorElem, null);
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      ),
      editor.registerCommand(
        CREATE_LINK_TEXT_COMMAND,
        () => {
          const linkNode = $createTextNode(linkText);
          $insertNodes([linkNode]);
          const link = {
            url: linkUrl
          }
          link.rel = 'noopener noreferrer'
          link.target = '_blank'
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, link);
          onClose();
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor, linkText, linkUrl, onClose]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  const onAddLink = () => {
    editor.dispatchCommand(CREATE_LINK_TEXT_COMMAND);
  }

  return (
    <div ref={editorRef} className={styles.linkEditor}>
      <TextField
        sx={{ mb: 2 }}
        label="Text"
        variant="filled"
        fullWidth
        value={linkText}
        onChange={(event) => {
          setLinkText(event.target.value);
        }}
      />
      <TextField
        sx={{ mb: 2 }}
        label="Link"
        variant="filled"
        fullWidth
        value={linkUrl}
        onChange={(event) => {
          setLinkUrl(event.target.value);
        }}
      />
      <Button
        variant='tertiary'
        onClick={onAddLink}
      >Add Link</Button>
    </div>
  );
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

export default function ToolbarPlugin({ isLead, onSend, onFileUpload, files, mentions }) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [blockType, setBlockType] = useState("paragraph");
  const [isLink, setIsLink] = useState(false);
  const [addLinkOpen, setAddLinkOpen] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [uploadFiles] = useMediaUpload();
  const fileInputRef = useRef();
  const { onError } = useContext(AppContext);

  const handleFilesAdded = useCallback(async (evt) => {
    console.log(evt)
    if (evt.target.files.length > 5) {
      onError('You can only upload 5 images at a time.');
      return;
    }
    const fileArray = Array.from(evt.target.files);
    onFileUpload([...(files || []), ...fileArray.map(() => ({ uploaded: false }))]);
    const { data, error } = await uploadFiles(fileArray);
    if (error) {
      onError(error);
      return;
    };

    onFileUpload([...(files || []), ...data.map((file) => ({ ...file, uploaded: true }))]);
  }, [files, uploadFiles, onError, onFileUpload]);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
        setAddLinkOpen(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
    );
  }, [editor, updateToolbar]);

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const toggleLink = useCallback(() => {
    if (!isLink) {
      setIsLink(!isLink);
    }
    setAddLinkOpen(!addLinkOpen);
  }, [isLink, addLinkOpen]);

  return (
    <Stack direction='row' ref={toolbarRef} gap={1} sx={{ mx: '20px', mb: '20px', mt: '10px' }}>
      {addLinkOpen && <FloatingLinkEditor
        editor={editor}
        onClose={() => setAddLinkOpen(false)}
      />}
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        onChange={handleFilesAdded}
        accept="image/*"
        multiple={true}
      />
      <IconButton
        color="inherit"
        aria-label="menu"
        sx={{
          borderRadius: '10px',
          border: `1px solid rgba(0, 0, 0, 0.10)`,
          minWidth: `48px`,
          height: '40px'
        }}
        onClick={() => fileInputRef.current.click()}
      >
        <CameraIcon />
      </IconButton>
      <IconButton
        onClick={toggleLink}
        aria-label="Insert Link"
        sx={{
          borderRadius: '10px',
          border: `1px solid rgba(0, 0, 0, 0.10)`,
          backgroundColor: isLink ? 'rgba(0, 0, 0, 0.07)' : 'transparent',
          minWidth: `48px`,
          height: '40px'
        }}
      >
        <FormatLinkIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
        aria-label="Format Bold"
        sx={{
          borderRadius: '10px',
          border: `1px solid rgba(0, 0, 0, 0.10)`,
          minWidth: `48px`,
          height: '40px',
          backgroundColor: isBold ? 'rgba(0, 0, 0, 0.07)' : 'transparent',
        }}
      >
        <b>B</b>
      </IconButton>
      <IconButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
        aria-label="Format Italics"
        sx={{
          borderRadius: '10px',
          border: `1px solid rgba(0, 0, 0, 0.10)`,
          minWidth: `48px`,
          height: '40px',
          backgroundColor: isItalic ? 'rgba(0, 0, 0, 0.07)' : 'transparent',
        }}
      >
        <i>It</i>
      </IconButton>
      {isLead && <IconButton
        onClick={formatQuote}
        aria-label="Format Quote"
        sx={{
          borderRadius: '10px',
          border: `1px solid rgba(0, 0, 0, 0.10)`,
          minWidth: `48px`,
          height: '40px',
          backgroundColor: blockType === 'quote' ? 'rgba(0, 0, 0, 0.07)' : 'transparent',
        }}
      >
        <FormatQuoteIcon />
      </IconButton>}
      <Box sx={{ flexGrow: 1 }} />
      <ReplyButton
        className={{
          alignSelf: 'end',
          padding: '5px 10px 5px 0'
        }}
        onSend={(text, attributes) => onSend(text, attributes, mentions)}
        files={files}
        onFileUpload={onFileUpload}
      />
    </Stack>
  );
}
