import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = 'white' }) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
        <path d="M9.50645 4.96437C9.33734 4.79526 8.13243 3.7806 5.63807 3.69605V1.2651C5.63581 1.09599 5.53276 0.944625 5.37648 0.880076C5.22021 0.815528 5.04015 0.850633 4.91936 0.969161L0.120887 5.70422C-0.0402955 5.8688 -0.0402955 6.13152 0.120887 6.2961L4.89822 11.0946C5.01901 11.2131 5.19869 11.2482 5.35534 11.1837C5.51162 11.1195 5.61467 10.9681 5.61731 10.7986V8.28313C6.67424 8.21972 8.95722 8.45224 10.754 11.4539L11.0288 11.9401C11.1032 12.0688 11.2394 12.1492 11.3882 12.1515H11.4939C11.6656 12.1194 11.8 11.985 11.8321 11.8133L11.9378 11.2637C11.9378 11.2214 12.6142 7.54328 9.50682 4.96437H9.50645Z" fill={color} />
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('ReplyIcon'),
    memo,
));
