export default function ActivitiesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clip-path="url(#clip0_6854_14365)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2911 0.539617C15.2461 0.332617 15.1111 0.161617 14.9221 0.0626172C14.7331 -0.0273828 14.5081 -0.0273828 14.3281 0.0626172L13.7971 0.332617C11.8981 1.28662 10.9891 3.45562 11.6011 5.44462L8.30708 8.73862C8.04608 8.99962 8.04608 9.43162 8.30708 9.69262C8.56808 9.95362 9.00008 9.95362 9.26108 9.69262L12.5551 6.39862C14.5441 7.01062 16.7221 6.10162 17.6671 4.20262L17.9371 3.67162C18.0271 3.48262 18.0361 3.26662 17.9371 3.07762C17.8471 2.88862 17.6671 2.75362 17.4601 2.70862L15.6511 2.34862L15.2911 0.539617ZM16.3081 3.86062C15.5881 5.00362 14.1841 5.51662 12.8971 5.10262C12.4831 3.81562 12.9961 2.41162 14.1391 1.69162L14.4091 3.05962C14.4631 3.32962 14.6701 3.53662 14.9401 3.59062L16.3081 3.86062Z" fill="#999999"/>
        <path d="M9.24288 5.59798C8.48688 5.49898 7.72188 5.64298 7.05588 6.00298C6.38988 6.36298 5.84088 6.92098 5.50788 7.60498C5.16588 8.28898 5.04888 9.05398 5.17488 9.80998C5.29188 10.557 5.64288 11.259 6.18288 11.799C6.72288 12.339 7.40688 12.699 8.15388 12.834C8.90088 12.969 9.67488 12.861 10.3589 12.528C11.0429 12.204 11.6099 11.664 11.9789 10.998C12.3479 10.332 12.5009 9.56699 12.4109 8.81099C12.3659 8.44199 12.6359 8.10899 13.0049 8.06399C13.3739 8.01899 13.7069 8.28898 13.7519 8.65798C13.8689 9.69299 13.6619 10.737 13.1579 11.646C12.6539 12.555 11.8799 13.284 10.9349 13.734C9.99888 14.184 8.93688 14.328 7.91088 14.148C6.88488 13.968 5.93988 13.473 5.21088 12.735C4.48188 11.997 3.99588 11.043 3.83388 10.017C3.67188 8.99099 3.83388 7.93798 4.29288 7.00199C4.75188 6.06598 5.49888 5.30098 6.41688 4.81498C7.33488 4.31998 8.37888 4.13098 9.41388 4.26598C9.78288 4.31098 10.0439 4.65298 9.99888 5.02198C9.95388 5.39098 9.61188 5.65198 9.24288 5.60698V5.59798Z" fill="#999999"/>
        <path d="M5.64293 2.49298C7.09193 1.81798 8.72093 1.62898 10.2779 1.95298C10.6469 2.02498 10.9979 1.79098 11.0789 1.43098C11.1509 1.06198 10.9169 0.71098 10.5569 0.62998C8.71193 0.25198 6.78593 0.46798 5.07593 1.26898C3.36593 2.06998 1.95293 3.39298 1.06193 5.04898C0.161926 6.71398 -0.180074 8.62198 0.0899265 10.485C0.359927 12.348 1.22393 14.085 2.55593 15.417C3.88793 16.749 5.61593 17.622 7.47893 17.901C9.34193 18.18 11.2499 17.847 12.9149 16.956C14.5799 16.065 15.9119 14.661 16.7129 12.951C17.5139 11.241 17.7479 9.32398 17.3699 7.46998C17.2979 7.10098 16.9379 6.86698 16.5779 6.93898C16.2089 7.01098 15.9749 7.37098 16.0469 7.73098C16.3619 9.29698 16.1729 10.917 15.4889 12.366C14.8139 13.815 13.6799 15.003 12.2759 15.759C10.8719 16.515 9.25193 16.794 7.67693 16.56C6.10193 16.326 4.63493 15.588 3.50993 14.454C2.38493 13.32 1.64693 11.862 1.42193 10.278C1.18793 8.69398 1.47593 7.08298 2.24093 5.67898C2.99693 4.27498 4.19393 3.14998 5.64293 2.47498V2.49298Z" fill="#999999"/>
      </g>
      <defs>
        <clipPath id="clip0_6854_14365">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}