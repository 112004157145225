import { memo, useCallback, useContext, useState } from 'react';
import { pipe, applyTo, pathOr } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Box, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import QuestionsForm from './QuestionsForm';
import Sent from './Sent';
import { JOIN_COMMUNITY } from 'data/mutations/community';
import { AppContext } from 'context';

const QUERY = gql`
  query Community($slug: String!) {
    community(slug: $slug) {
      id
      title
      slug
      onboardingQuestions {
        type
        question
      }
      cover {
        type
        asset {
          ...on Image {
            id
            url
          }
          ...on Background {
            background
          }
        }
      }
    }
    whoami {
      id
      username
    }
  }
`;

export default applyTo(() => {
  const [view, setView] = useState('questions'); // questions | profile | sent
  const { community: slug } = useParams();
  const [joinCommunity] = useMutation(JOIN_COMMUNITY);
  const { data } = useQuery(QUERY, { variables: { slug } });
  const community = pathOr({}, ["community"], data);
  const { onError } = useContext(AppContext);

  const handleSubmit = useCallback(async (values) => {
    await joinCommunity({
      variables: {
        input: {
          communityId: pathOr(null, ['id'], community),
          application: {
            answers: pathOr([], ['questions'], values),
            note: pathOr('', ['note'], values),
          }
        }
      }
    }).then(() => {
      setView('sent');
    }).catch(err => onError(err.message));
  }, [community, joinCommunity, setView, onError]);

  return (
    <>
      {community?.cover?.asset?.url && <Box sx={{
        height: '200px',
        width: '100%',
        backgroundImage: `url(${community?.cover?.asset?.url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }} />}
      <Stack direction='row' gap={2} sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {view === 'questions' && <QuestionsForm onSubmit={handleSubmit} community={community} />}
        {view === 'sent' && <Sent community={community} />}
      </Stack >
    </>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('Apply'),
  memo,
));
