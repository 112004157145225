import { Button, Typography, useTheme } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";

export default function NoContentModal({ isOpen, onClose, ctaText, message }) {
  const theme = useTheme();

  return (
    <AnimatePresence>
      {isOpen && <motion.div
        key='no_content'
        style={{
          position: 'absolute',
          top: '50%',
          width: 'calc(100% - 20px)',
          transform: 'translate(0%, -50%)',
          borderRadius: '20px',
          background: theme.palette.transparentColors.light.eighty,
          padding: '30px',
          margin: '10px'
        }}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1
        }}
        exit={{
          opacity: 0
        }}
      >
        <Typography
          sx={{ mb: 1 }}
        >{message}</Typography>
        {ctaText && <Button
          variant='tertiary'
          onClick={onClose}
        >
          {ctaText}
        </Button>}
      </motion.div>}
    </AnimatePresence>
  )
}