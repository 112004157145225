import { MoreHoriz } from "@mui/icons-material";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import MessageReactions from "components/MessageReactions";
import ReplyIcon from "components/icons/ReplyIcon";
import { AppContext } from "context";
import { useContext } from "react";

export default function MediaActions({ reactions, onReaction, onReply, replyCount, isLead, onOptionsClick }) {
  const theme = useTheme();
  const { featuredHexCode } = useContext(AppContext);

  return (
    <Stack direction='row' alignItems='center' gap={1} flexWrap='wrap'>
      <MessageReactions
        reactions={Object.entries(reactions || [])}
        onReaction={onReaction}
      />
      <Stack direction='row'>
        <IconButton
          onClick={onReply}
          sx={{
            background: theme.palette.message.button,
            borderTopRightRadius: replyCount ? '0px' : '4px',
            borderBottomRightRadius: replyCount ? '0px' : '4px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            p: '3px 5px'
          }}
        >
          <ReplyIcon sx={{ width: 16, height: 20 }} color={theme.palette.text.secondary} />
          <Typography
            color='text.secondary'
            sx={{ ml: 1, fontSize: 14, fontWeight: 500 }}
          >Reply</Typography>
        </IconButton>
        {replyCount && <Typography
          sx={{
            backgroundColor: featuredHexCode,
            p: '3px 5px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            color: 'white'
          }}
        >
          {replyCount}
        </Typography>}
      </Stack>

      {isLead && <IconButton
        onClick={onOptionsClick}
        sx={{
          background: theme.palette.message.button,
          borderRadius: '4px',
          p: '5px 7px'
        }}
      >
        <MoreHoriz
          fontSize='small'
          color={theme.palette.text.secondary}
        />
      </IconButton>}
    </Stack>
  )
}