import { Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import BackArrow from "components/icons/BackArrow";
import { useNavigate } from "react-router-dom";
import RetractableHeader from "../RetractableHeader";

export default function BackHeader({ to, onClick, pageTitle, right }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) onClick();
    if (to) navigate(to);
  }

  return (
    <RetractableHeader
      style={{
        position: 'absolute',
        width: '100%',
        top: '0px',
        left: '0px',
        height: '70px',
        zIndex: 20,
        backgroundColor: '#F8F8F8'
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ mx: 3, height: '100%' }}
      >
        <Stack direction='row' gap={1} alignItems='center'>
          {(to || onClick) && <IconButton onClick={handleClick}>
            <BackArrow />
          </IconButton>}
          <Typography
            variant="h2"
            sx={{
              cursor: 'pointer',
              fontSize: '18px'
            }}
          >{pageTitle}</Typography>
        </Stack>
        {right}
      </Stack>
      {isDesktop && <Divider />}
    </RetractableHeader>
  )
}