import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ width = 10, height = 12 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 12" fill="none">
      <path d="M1.04529 3.91954C1.04493 2.93291 1.43656 1.98667 2.13412 1.28911C2.83133 0.591201 3.77757 0.199219 4.76385 0.199219C5.75012 0.199219 6.69601 0.59085 7.39322 1.28841C8.09078 1.98562 8.48241 2.93185 8.48241 3.91813V5.10742C8.48241 5.14817 8.46625 5.18715 8.43745 5.21596C8.40865 5.24476 8.36966 5.26091 8.32892 5.26091H6.84599C6.76099 5.26091 6.69215 5.19207 6.69215 5.10707V3.91918C6.69215 3.40708 6.48913 2.91605 6.1277 2.55322C5.76628 2.19074 5.2756 1.98597 4.76349 1.98421C4.25034 1.98421 3.75825 2.18793 3.39542 2.55076C3.03259 2.91359 2.82887 3.40567 2.82887 3.91883V5.10813C2.82887 5.14887 2.81272 5.18786 2.78392 5.21666C2.75511 5.24546 2.71613 5.26162 2.67538 5.26162H1.19245C1.10781 5.26162 1.03861 5.19277 1.03861 5.10777L1.04493 3.91848L1.04529 3.91954ZM0.000351238 6.4474V11.0574C0.000351238 11.2548 0.0786774 11.4438 0.218119 11.5832C0.357561 11.7226 0.546878 11.801 0.743923 11.801H8.78061C8.97765 11.8017 9.16732 11.7241 9.30747 11.5853C9.44761 11.4466 9.52664 11.2576 9.52769 11.0602V6.45021C9.52769 6.25316 9.44937 6.06385 9.30993 5.9244C9.17048 5.78496 8.98117 5.70664 8.78412 5.70664H0.747084C0.550039 5.70593 0.360371 5.78321 0.220226 5.9223C0.0800824 6.06139 0.00105372 6.25 0 6.4474H0.000351238Z" fill="black" fill-opacity="0.25" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('LockIcon'),
  memo,
));
