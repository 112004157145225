import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ width = 24, height = 24, color = 'white' }) => {
    return (
      <svg width={width} height={height} viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M12.4971 7.58178C12.765 7.65743 13.0495 7.70785 13.3425 7.71626C12.5473 16.3151 0.310999 15.9957 0.00132321 7.32964C-0.0991417 2.34517 5.54201 -1.00863 9.83561 1.42056C9.70171 1.67269 9.59286 1.93332 9.5176 2.2107C5.77639 -0.00835842 0.754623 2.93359 0.838319 7.32966C1.10613 14.9535 11.9281 15.1636 12.4972 7.58179L12.4971 7.58178ZM5.49179 5.56446C5.47503 4.37928 3.70906 4.37928 3.69232 5.56446C3.70908 6.74963 5.47505 6.74963 5.49179 5.56446ZM9.45061 5.56446C9.43386 4.37928 7.66789 4.37928 7.65115 5.56446C7.6679 6.74963 9.43387 6.74963 9.45061 5.56446ZM6.66353 10.3556C7.49212 10.3556 8.29564 10.0026 9.04887 9.30491C9.21629 9.14519 9.2246 8.88464 9.074 8.70816C8.91496 8.54002 8.65552 8.53167 8.4798 8.68291C7.28292 9.80085 6.06935 9.79243 4.65488 8.65767C4.47077 8.51478 4.21128 8.54002 4.06898 8.72491C3.92668 8.90981 3.95182 9.17042 4.13593 9.31334C4.99802 9.99417 5.84337 10.3388 6.67192 10.3388L6.66353 10.3556ZM13.6103 6.89253C9.11583 6.76646 9.0405 0.16811 13.6103 0C18.1299 0.142897 18.1299 6.74963 13.6103 6.89253ZM13.6103 0.840552C10.162 0.966621 10.2039 5.95111 13.6103 6.05198C17.0251 5.96791 17.0251 0.924607 13.6103 0.840552ZM14.8574 3.02599H14.0288C13.987 2.63095 14.2297 1.79039 13.6103 1.76516C12.991 1.78199 13.2336 2.63095 13.1918 3.02599C12.8069 3.06799 11.9531 2.83268 11.9364 3.44626C11.9699 4.05985 12.7482 3.82455 13.1918 3.86654C13.2336 4.25316 12.9993 5.10213 13.6103 5.11896C14.2213 5.10214 13.987 4.25318 14.0288 3.86654H14.8574C15.4098 3.86654 15.4014 3.02599 14.8574 3.02599Z" fill={color} />
      </svg>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('EmojiIcon'),
    memo,
));
