import { memo, useCallback, useContext, useMemo } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';
import { publicClient } from 'lib/apollo';
import { AppContext } from 'context';
import { Button, Stack } from '@mui/material';

import Overview from './Overview';
import { PUBLIC_COMMUNITY } from 'data/queries/community';
import useCustomAuth0 from 'hooks/useCustomAuth0';

export default applyTo(() => {
  const { community: slug } = useParams();
  const { pathname } = useLocation();

  const { loginWithRedirect } = useCustomAuth0();

  // this was created to "loop" logins for a being used in a "booth" setting
  const isBoothMode = pathname === `/c/${slug}/booth`;

  const { onError } = useContext(AppContext);

  const { data, loading, error } = useQuery(PUBLIC_COMMUNITY, {
    variables: {
      slug,
    },
    client: publicClient,
  });

  const community = pathOr({}, ['community'], data);
  const isPrivate = pathOr(false, ['isPrivate'], community);

  const handleAuth = useCallback((screen_hint) => async () => {
    // NOTE: that you cannot signup with an organziation. only login
    // https://community.auth0.com/t/cant-reach-signup-page-when-using-organizations/66468/9
    loginWithRedirect({
      authorizationParams: {
        screen_hint: screen_hint || 'login',
      },
      appState: {
        // this route will add the authed user to the community and redirect to the private community home
        returnTo: isBoothMode ? `/c/${slug}/join/booth` : `/c/${slug}/join`,
      },
    })
  }, [loginWithRedirect, slug, isBoothMode]);

  const ctaButtons = useMemo(() => {
    if (isPrivate) {
      return (
        <Stack gap={2} direction="row" justifyContent='start'>
          <Button variant="regular" onClick={handleAuth('login')}>Request to Join</Button>
        </Stack>
      );
    }
    return (
      <Stack gap={2} direction="row" justifyContent='start'>
        <Button variant="regular" onClick={handleAuth('login')}>Join</Button>
      </Stack>
    );
  }, [handleAuth, isPrivate]);

  if (error) return onError(error.message);

  return (
    <Overview
      actions={ctaButtons}
      loading={loading}
    />
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('CommunityHomePublic'),
  memo,
));
