import { Box, Button, Stack, Typography, useTheme } from "@mui/material"
import EditTooltipIcon from "components/icons/EditTooltipIcon";
import HashtagIcon from "components/icons/HashtagIcon";
import LockIcon from "components/icons/LockIcon";
import UnreadIcon from "components/icons/UnreadIcon";
import { Emoji } from "emoji-picker-react";
import { path, uniqBy } from "ramda";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ChannelCard({ channel, community, onEdit }) {
  const [memberString, setMemberString] = useState('');
  const theme = useTheme();

  useEffect(() => {
    if (channel?.node?.members?.edges) {
      const members = uniqBy(path(['node', 'id']), channel?.node?.members?.edges || []);
      const memberString = members.map(({ node }) => node.displayName).join(', ');
      setMemberString(memberString);
    }
  }, [channel?.node?.members?.edges]);

  return (
    <Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          fontSize: '16px',
          backgroundColor: theme.palette.transparentColors.light.seventy,
          width: '100%',
          height: '100%',
          borderRadius: channel?.node?.description ? '8px 8px 0px 0px' : '8px',
        }}
        flexWrap='wrap'
      >
        <Link
          to={`/c/${community?.slug}/channel/${channel?.node?.slug}`}
          style={{ flexGrow: 1 }}
        >
          <Stack direction='row' alignItems='center' gap='15px'>
            <Stack justifyContent='center' alignItems='center' sx={{
              width: '60px',
              height: '60px',
              backgroundColor: theme.palette.transparentColors.light.twentyFive,
              borderRadius: '8px',
            }}>
              {channel?.node?.emoji
                ? <Emoji unified={channel?.node?.emoji} size={25} />
                : <HashtagIcon width={20} height={20} />}
            </Stack>
            <Typography variant='h4'>{channel?.node?.name} {channel?.node?.isPrivate && <LockIcon />}</Typography>
            {channel?.node?.isPrivate ? (
              <Typography variant='body2' color={theme.palette.transparentColors.dark.fortyFive}>
                {memberString}
              </Typography>
            ) : (<Typography variant='body2' color={theme.palette.transparentColors.dark.fortyFive}>
              Everyone
            </Typography>)}
            <Stack direction='row' alignItems='center' gap={1} sx={{ mr: 3 }}>
              {channel?.node?.hasUnreadMessages && <UnreadIcon />}
            </Stack>
          </Stack>
        </Link>
        {onEdit && <Button variant='outlined' sx={{
          borderRadius: '8px',
          border: '1px solid rgba(0, 0, 0, 0.10)',
          m: '10px',
          padding: 0,
          height: '36px',
          width: '45px',
          minWidth: '45px',
          maxWidth: '45px'
        }} onClick={onEdit}>
          <EditTooltipIcon />
        </Button>}
      </Stack>
      {channel?.node?.description && <Box sx={{
        borderRadius: '0px 0px 10px 10px',
        backgroundColor: theme.palette.transparentColors.light.fifty,
        p: '10px 15px'
      }}>
        <Typography fontSize={14}>{channel?.node?.description}</Typography>
      </Box>}
    </Stack>
  )
}