import { gql } from "@apollo/client";

export const COMMUNITY_ALBUMS = gql`
    query CommunityAlbums($slug: String!, $channelSlug: String!) {
      whoami {
        id
        displayName
        community(slug: $slug) {
          isLead
          node { 
            id
            title
            slug
            isPaused
            albums {
              edges {
                node {
                  id
                  sid: id
                  twilioSid
                  coverImage {
                    id
                    ...on Image {
                      publicId: cloudinaryPublicId
                    }
                  }
                  media {
                    edges {
                      node {
                        id
                        reactions
                        createdDate
                        file {
                          id
                          ...on Image {
                            publicId: cloudinaryPublicId
                          }
                        }
                        createdBy {
                          id
                          displayName
                          avatar { url }
                        }
                      }
                    }
                  }
                }
              }
            }
            channel(slug: $channelSlug) {
              notifications {
                newMessageNotifications
              }
              canPost
              node {
                id
                twilioSid
                section
                name
                hasUnreadMessages
                slug
                emoji
                description
                isPrivate
                members {
                  total
                }
              }
            }
          }
        }
      }
    }
`;

export const COMMUNITY_CHANNEL = gql`
    query CommunityChannel($slug: String!, $channelSlug: String!) {
      whoami {
        id
        displayName
        community(slug: $slug) {
          isLead
          node { 
            id
            title
            slug
            isPaused
            members {
              total
            }
            channel(slug: $channelSlug) {
              notifications {
                newMessageNotifications
              }
              canPost
              node {
                id
                twilioSid
                section
                name
                hasUnreadMessages
                slug
                emoji
                description
                isPrivate
                members {
                  total
                }
              }
            }
          }
        }
      }
    }
`;

export const COMMUNITY_PRIVATE_CHANNEL_MEMBERS = gql`
  query PrivateChannelMembers($communitySlug: String!, $channelSlug: String!, $after: String) {
      whoami {
        id
        community(slug: $communitySlug) {
          node { 
            id
            channel(slug: $channelSlug) {
              node {
                id
                members(input: { first: 100, after: $after }) {
                  pageInfo {
                    hasNextPage
                  }
                  edges {
                    cursor
                    node {
                      id
                      displayName
                      avatar { id url }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`;

export const COMMUNITY_PUBLIC_CHANNEL_MEMBERS = gql`
  query PublicChannelMembers($communitySlug: String!, $after: String) {
      whoami {
        id
        community(slug: $communitySlug) {
          node { 
            id
            leads(input: { first: 100 }) {
              total
              edges {
                node {
                  id
                  displayName
                  avatar { id url }
                }
              }
            }
            members(input: { first: 100, after: $after }) {
              pageInfo {
                hasNextPage
              }
              edges {
                cursor
                node {
                  id
                  displayName
                  avatar { id url }
                }
              }
            }
          }
        }
      }
    }
`;

export const SEARCH_CHANNELS = gql`
  query SearchChannels($input: SearchChannelsInput!) {
    searchChannels(input: $input) {
      edges {
        node {
          id
          slug
          emoji
        }
      }
    }
  }
`;

export const MENTION = gql`
  query Mention($id: ID!) {
    node(id: $id) {
      ...on User {
        id
        slug: username
        text: displayName
      }
      ...on Channel {
        id
        slug
        text: slug
        section
      }
    }
  }
`;

export const USER_CHANNEL = gql`
    query UserChannel($slug: String!) {
      whoami {
        id
        displayName
        channel(slug: $slug) {
          node {
            id
            twilioSid
            name
            description
            members {
              total
              edges {
                cursor
                node {
                  id
                  firstName
                  lastName
                  displayName
                  username
                  avatar { id url }
                }
              }
            }
          }
        }
      }
    }
`;