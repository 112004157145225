import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ color = 'black', opacity = '0.5' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="10" viewBox="0 0 21 10" fill="none">
      <path opacity={opacity} fill-rule="evenodd" clip-rule="evenodd" d="M2.4 5C2.4 3.29 3.79 1.9 5.5 1.9H9.5V0H5.5C2.74 0 0.5 2.24 0.5 5C0.5 7.76 2.74 10 5.5 10H9.5V8.1H5.5C3.79 8.1 2.4 6.71 2.4 5ZM6.5 6H14.5V4H6.5V6ZM11.5 0H15.5C18.26 0 20.5 2.24 20.5 5C20.5 7.76 18.26 10 15.5 10H11.5V8.1H15.5C17.21 8.1 18.6 6.71 18.6 5C18.6 3.29 17.21 1.9 15.5 1.9H11.5V0Z" fill={color} />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('FormatLinkIcon'),
  memo,
));
