import { adjust, join, pipe, toLower, toUpper } from "ramda";

export const capitalize = pipe(
  toLower,
  adjust(0, toUpper),
  join('')
)

export const truncate = (str, maxLength = 50) => {
  if (!str) return '';
  return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
}