import React from 'react';
import { DecoratorNode } from "lexical";
import ImageCarousel from 'components/ImageCarousel';

function convertImageElement(domNode) {
  if (domNode instanceof HTMLImageElement) {
    const { alt: altText, src, publicId } = domNode;
    const node = $createImageNode({ altText, src, publicId });
    return { node };
  }
  return null;
}

export class ImageNode extends DecoratorNode {
  __src
  __publicId
  __width
  __height
  __altText

  static getType() {
    return "image";
  }

  static clone(node) {
    return new ImageNode(
      node.__src,
      node.__publicId,
      node.__width,
      node.__height,
      node.__altText,
      node.__key
    );
  }

  static importJSON(serializedNode) {
    const {
      altText,
      src,
      publicId,
      width,
      height
    } = serializedNode;
    const node = $createImageNode({
      altText,
      src,
      publicId,
      width,
      height
    });

    return node;
  }

  exportDOM() {
    const element = document.createElement("img");
    element.setAttribute("src", this.__src);
    element.setAttribute("publicId", this.publicId);
    element.setAttribute("width", this.width);
    element.setAttribute("height", this.height);
    element.setAttribute("alt", this.__altText);
    return { element };
  }

  static importDOM() {
    return {
      img: (node) => ({
        conversion: convertImageElement,
        priority: 0
      })
    };
  }

  constructor(
    src,
    publicId,
    width,
    height,
    altText,
    key,
  ) {
    super(key);
    this.__src = src;
    this.__publicId = publicId;
    this.__width = width;
    this.__height = height;
    this.__altText = altText;
  }

  exportJSON() {
    return {
      altText: this.getAltText(),
      src: this.getSrc(),
      publicId: this.__publicId,
      width: this.__width,
      height: this.__height,
      type: "image",
      version: 1,
    };
  }

  setShowCaption(showCaption) {
    const writable = this.getWritable();
    writable.__showCaption = showCaption;
  }

  // View

  createDOM(config) {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getAltText() {
    return this.__altText;
  }

  decorate() {
    return (
      <ImageCarousel
        images={[{
          src: this.__src,
          publicId: this.__publicId,
          width: this.__width,
          height: this.__height,
          alt: this.__altText
        }]}
      />
    );
  }
}

export function $createImageNode({
  altText,
  src,
  publicId,
  width,
  height,
  key
}) {
  return new ImageNode(
    src,
    publicId,
    width,
    height,
    altText,
    key
  );
}

export function $isImageNode(node) {
  return node instanceof ImageNode;
}
