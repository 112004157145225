import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Box, Skeleton } from '@mui/material'

export default applyTo(({
  src,
  placeholder = 'https://placehold.co/400x500/999999/999999',
  alt,
  sx,
  loading,
  ...props
}) => {
  return (
    <Box sx={{ position: 'relative', height: sx?.height, width: sx?.width || 1 }}>
      {loading && <Skeleton
        variant='rectangular'
        {...props}
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          zIndex: 1,
          ...sx
        }}
      />}
      <Box
        {...props}
        component="img"
        sx={{
          ...sx,
          visibility: loading ? 'hidden' : 'visible',
        }}
        alt={alt}
        src={src || placeholder}
      />
    </Box>
  );
}, pipe(
  propTypes({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    sx: PropTypes.shape({})
  }),
  defaultProps({
    sx: {},
    alt: "",
  }),
  displayName('Image'),
  memo,
));
