import { useContext, useEffect } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { AppContext } from "context";
import editorConfig from '../../editorConfig';
import styles from '../ChatMessage.module.scss';
import ImageCarousel from "components/ImageCarousel";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const StateUpdaterPlugin = ({ editorState }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editorState) {
      const updates = editor.parseEditorState(editorState);
      editor.setEditorState(updates);
    }
  }, [editorState, editor]);

  return;
}

const RichText = ({ editorState, textSize = 'small', files, onAddMedia }) => {
  const { featuredHexCode, theme } = useContext(AppContext);
  const muiTheme = useTheme();
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));

  return (
    <LexicalComposer initialConfig={{
      editorState,
      editable: false,
      ...editorConfig,
      theme: {
        ...editorConfig.theme,
        quoteColor: featuredHexCode,
        link: theme === 'dark' ? styles.link : styles.linkLight,
        mention: theme === 'dark' ? styles.mention : styles.mentionLight,
        paragraph: styles[`${textSize}Paragraph`]
      }
    }}>
      <StateUpdaterPlugin editorState={editorState} />
      <Box sx={{ px: isDesktop ? '20px' : 0 }}>
        <ImageCarousel images={files} onAddMedia={onAddMedia} />
      </Box>
      <Box sx={{ px: '20px' }}>
        <RichTextPlugin
          contentEditable={<ContentEditable />}
        />
      </Box>
      <LinkPlugin />
    </LexicalComposer>
  );
}

export default RichText;